import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  CardContent,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC, useState } from "react";
import FeatureCheckCircle from "../../assets/icons/FeatureCheckCircle.svg";
import { CHIP_COLOURS, KeyIssueChip } from "../atoms/KeyIssueChip";
import { useAppSelector } from "../redux/hook";
import { UserInfo } from "../redux/user/types";
import { APP_URL } from "../styling/constants";
import { ALTRA_APPS } from "../util/custom-types";
import { PRODUCTS } from "../util/LandingPageContent";

interface PremiumOptionsModalProps {
  open: boolean;
  title: string;
  subtitle?: string;
  appName: ALTRA_APPS;
  cancelText: string;
  onCancel: () => void;
  onClose: () => void;
}

/**
 * Info modal to educate users about premium features used throughout the app
 * @param title
 * @param subtitle
 * @param open
 * @param appName
 * @param cancelText
 * @param onCancel
 * @param onClose
 * @constructor
 */
export const PremiumOptionsModal: FC<PremiumOptionsModalProps> = ({
  title,
  subtitle,
  open,
  appName,
  cancelText,
  onCancel,
  onClose,
}) => {
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);

  const [loadingPrice, setLoadingPrice] = useState<
    keyof typeof PRODUCTS | undefined
  >();

  /**
   * Calls lambda function to generate stripe checkout URL
   * @param title
   */
  const createSubscription = () => {
    const title = "Altra Early bird - Individual";

    console.log(title);
    console.log(user.id);

    setLoadingPrice(title as keyof typeof PRODUCTS);

    if (
      process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_URL &&
      process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_API_KEY &&
      user.id
    ) {
      console.log("fetching");
      fetch(process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_URL, {
        method: "POST",
        headers: {
          "X-Api-Key":
            process.env.REACT_APP_STRIPE_GENERATE_CHECKOUT_LAMBDA_API_KEY,
        },
        body: JSON.stringify({
          product: title,
          isAnnual: false,
          role: "teacher",
          returnUrl: process.env[APP_URL[appName]],
          userId: user.id.toString(),
        }),
      })
        .then((response) => {
          setLoadingPrice(undefined);
          return response.json();
        })
        .then((data) => {
          setLoadingPrice(undefined);
          return (window.location.href = data);
        });
    }
  };

  const theme = useTheme();
  const renderFeatureRow = (text: string) => (
    <StyledButtonRow>
      <StyledImageColumn>
        <img
          style={{ maxHeight: "30px", width: "100%" }}
          src={FeatureCheckCircle}
          alt={"Diagram"}
        />
      </StyledImageColumn>

      <Typography variant={"caption"} color={"textSecondary"}>
        {text}
      </Typography>
    </StyledButtonRow>
  );

  return (
    <Dialog open={open}>
      <CardContent>
        <StyledTopRow>
          <StyledTopRowContent>
            <IconButton
              onClick={onClose}
              style={{
                color: theme.palette.error.main,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </StyledTopRowContent>
        </StyledTopRow>
        <StyledCardContainer>
          <Typography variant={"h1"}>{title}</Typography>
          {subtitle && (
            <Typography variant={"subtitle2"}>{subtitle}</Typography>
          )}

          <StyledInnerContainer sx={{ marginTop: "2%" }}>
            <StyledFeaturesColumn>
              {renderFeatureRow("Unlimited resources")}
              {renderFeatureRow("No watermarks")}
              {renderFeatureRow("New features in 2022")}
            </StyledFeaturesColumn>
            <StyledProductBuyColumn>
              <StyledProductCard>
                <KeyIssueChip
                  label={"Save 40%"}
                  showAll={true}
                  color={CHIP_COLOURS.PINK}
                />
                <Typography variant={"subtitle2"}>
                  Altra Early Bird - Individual
                </Typography>
                <Typography
                  variant={"h2"}
                  fontWeight={800}
                  style={{ color: theme.palette.primary.main }}
                >
                  4.99 / month
                </Typography>
                <LoadingButton
                  loading={loadingPrice === "Altra Early bird - Individual"}
                  onClick={createSubscription}
                  sx={{ marginTop: "10%" }}
                  variant={"contained"}
                  size={"large"}
                >
                  Buy now
                </LoadingButton>
              </StyledProductCard>
            </StyledProductBuyColumn>
          </StyledInnerContainer>
          <Button onClick={onCancel} sx={{ width: "50%" }} variant={"outlined"}>
            {cancelText}
          </Button>
        </StyledCardContainer>
      </CardContent>
    </Dialog>
  );
};

const StyledInnerContainer = styled("div")`
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;
const StyledFeaturesColumn = styled("div")`
  flex-direction: column;
  display: flex;
  width: 50%;
`;
const StyledProductBuyColumn = styled("div")`
  flex-direction: column;
  display: flex;
  width: 40%;
`;

const StyledProductCard = styled("div")`
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 36px rgba(66, 84, 102, 0.08);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
`;
const StyledButtonRow = styled("div")`
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-content: space-evenly;
  align-items: center;
  flex-direction: row;
  //background-color: yellow;
`;
const StyledImageColumn = styled("div")`
  width: 20%;
`;
const StyledCardContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
}));
