import { useUpdateSubmissionMutation } from "@altra-apps/common/src/graphql/types";
import { HeadingElement } from "@altra-apps/common/src/molecules/resourceBuilderElements/HeadingElement";
import { Leaf } from "@altra-apps/common/src/molecules/resourceBuilderElements/Leaf";
import { MathElement } from "@altra-apps/common/src/molecules/resourceBuilderElements/MathElement";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import { userAppInfoUpdateSubmissionAnswerFeedbackBlock } from "@altra-apps/common/src/redux/user/actions";
import {
  EditableSubmissionAnswerFeedbackProp,
  SubmissionIdNameTitle,
} from "@altra-apps/common/src/redux/user/types";
import { alterCurriculumExplorerTheme } from "@altra-apps/common/src/styling/altra-curriculum-explorer-theme";
import { useDebounce } from "@altra-apps/common/src/util/use-debounce";
import { Skeleton } from "@mui/lab";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createEditor, Descendant } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import { updateLoadingStatus } from "../../redux/applicationContext/actions";
import { altraProgressManagerTheme } from "../../styling/altra-progress-manager-theme";
import { Status } from "../../util/custom-types";
import { getUnionOfValuesForKeys } from "../../util/helpers";
import { useIsMobile } from "../../util/useIsMobile";
import {
  onKeyDownSubmission,
  withCustomSubmissionNormaliser,
  withInlines,
} from "../CustomEditorExtractedLogic";
import { CustomSubmissionEditor } from "./CustomSubmissionEditor";
import { ViewSubmissionAnswerElement } from "./ViewSubmissionAnswerElement";

interface SubmissionEditorProps {
  blockId: number;
}

const initialSubmissionValue: Descendant[] = [
  {
    //@ts-expect-error
    type: "paragraph",
    children: [{ text: "" }],
  },
];
const noAnswerValue: Descendant[] = [
  {
    //@ts-expect-error
    type: "paragraph",
    children: [{ text: "No answer has been provided for this question" }],
  },
];

/**
 * Simple editor used for answers and feedback for submissions
 * @param blockId
 * @constructor
 */
export const ReviewEditor: FC<SubmissionEditorProps> = ({ blockId }) => {
  const routerParams = useParams();
  const dispatch = useAppDispatch();
  const [initialLoad, setInitialLoad] = useState(true);
  const isTabletOrMobile = useIsMobile();

  const submissionBlocks: EditableSubmissionAnswerFeedbackProp[] =
    useAppSelector((state) => state.userAppInfo.editableSubmissionBlocks) || [];

  const reduxSubmissionDetails: SubmissionIdNameTitle | undefined =
    useAppSelector((state) => {
      const submission = getUnionOfValuesForKeys(
        state.userAppInfo.submissions
      ).find(
        //@ts-expect-error
        (b) => b.submissionId === parseInt(routerParams?.id)
      );
      return submission;
    });

  const submissionAnswerAndFeedbackBlock: EditableSubmissionAnswerFeedbackProp =
    useAppSelector((state) => {
      return (
        state.userAppInfo.editableSubmissionBlocks?.find(
          (b) => b.block_id === blockId
        ) || {
          //@ts-expect-error
          submission_id: routerParams?.id,
          block_id: blockId,
          requester_answer: initialSubmissionValue,
          marker_feedback: initialSubmissionValue,
        }
      );
    });

  const [updateSubmissionMutation] = useUpdateSubmissionMutation();
  const [value, setValue] = useState<undefined | Descendant[]>(undefined);
  const [requesterAnswer, setRequesterAnswer] = useState<
    undefined | Descendant[]
  >(undefined);
  const debouncedValueChange = useDebounce(value, 1500);

  useEffect(() => {
    dispatch(
      updateLoadingStatus({
        status: Status.LOADING,
        message: "Saving feedback",
      })
    );
  }, [value]);

  /**
   * After debounce, update the value in redux and the database
   */
  const updateBlocksInDbFollowingFrontEndChange = async () => {
    //@ts-expect-error
    const submissionId: number = routerParams?.id;

    if (submissionId && submissionAnswerAndFeedbackBlock) {
      //Get all submission feedback currently stored in redux excluding the current block
      const feedbackExcludingCurrent: EditableSubmissionAnswerFeedbackProp[] =
        submissionBlocks?.filter((b) => b.block_id !== blockId);

      //Store answer and feedback  for all blocks in redux
      let answersForEntireSubmission: { [blockId: number]: Descendant[] } = {};
      let feedbackForEntireSubmission: { [blockId: number]: Descendant[] } = {};

      feedbackExcludingCurrent.map((b) => {
        feedbackForEntireSubmission[b.block_id] =
          b.marker_feedback || initialSubmissionValue;
        answersForEntireSubmission[b.block_id] =
          b.requester_answer || initialSubmissionValue;
      });

      const unChangedAnswerForCurrentBlock =
        submissionBlocks.find((b) => b.block_id === blockId)
          ?.requester_answer || initialSubmissionValue;

      let allSubmissionFeedbackForUpdate = {
        ...feedbackForEntireSubmission,
        [blockId]: debouncedValueChange,
      };
      let allSubmissionAnswersForUpdate = {
        ...answersForEntireSubmission,
        [blockId]: unChangedAnswerForCurrentBlock,
      };

      const updatedSubmission = await updateSubmissionMutation({
        variables: {
          submission_id: submissionId,
          submission_spec: {
            questions_feedback: allSubmissionFeedbackForUpdate,
            submission_answers: allSubmissionAnswersForUpdate,
          },
        },
      });

      if (updatedSubmission) {
        dispatch(
          userAppInfoUpdateSubmissionAnswerFeedbackBlock({
            block_id: blockId,
            marker_feedback: debouncedValueChange,
            submission_id: submissionId,
          })
        );
        dispatch(
          updateLoadingStatus({
            status: Status.IDLE,
            message: "Saved resource",
          })
        );
      }
    }
  };

  useEffect(() => {
    if (debouncedValueChange && !initialLoad) {
      console.log("Not Initial load", debouncedValueChange);
      updateBlocksInDbFollowingFrontEndChange();
    }
    if (initialLoad && debouncedValueChange) {
      console.log("Initial load", debouncedValueChange);
      setInitialLoad(false);
    }
  }, [debouncedValueChange]);

  /**
   * Set the intial value
   */
  useEffect(() => {
    if (submissionAnswerAndFeedbackBlock && !value) {
      setValue(
        submissionAnswerAndFeedbackBlock.marker_feedback &&
          submissionAnswerAndFeedbackBlock.marker_feedback?.length > 0
          ? submissionAnswerAndFeedbackBlock.marker_feedback
          : initialSubmissionValue
      );
    } else {
      setValue(initialSubmissionValue);
    }

    if (submissionAnswerAndFeedbackBlock && !requesterAnswer) {
      setRequesterAnswer(
        !submissionAnswerAndFeedbackBlock?.requester_answer ||
          submissionAnswerAndFeedbackBlock?.requester_answer.length === 0 ||
          (submissionAnswerAndFeedbackBlock?.requester_answer.length === 1 &&
            //prettier-ignore
            submissionAnswerAndFeedbackBlock?.requester_answer[0]
                  //@ts-ignore-error
                  ?.children[0]?.text === "")
          ? noAnswerValue
          : submissionAnswerAndFeedbackBlock?.requester_answer
      );
    } else {
      setRequesterAnswer(noAnswerValue);
    }
  }, []);

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "heading-1":
        return <HeadingElement type={"ONE"} {...props} editor={editor} />;
      case "math":
        return <MathElement {...props} editor={editor} />;
      default:
        return <div {...props}>{props.children}</div>;
    }
  }, []);

  // Define a leaf rendering function that is memoized with `useCallback`.
  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  const [editor] = useState(() =>
    withReact(
      withHistory(withInlines(withCustomSubmissionNormaliser(createEditor())))
    )
  );

  if (value === undefined) {
    return (
      <>
        <Skeleton animation="wave" height={20} />
        <Skeleton animation="wave" height={20} />
        <Skeleton animation="wave" height={20} />
        <Skeleton animation="wave" height={20} />
      </>
    );
  }

  return (
    <CustomAnswerAndFeedbackContainer
      style={{ flexDirection: isTabletOrMobile ? "column" : "row" }}
    >
      <CustomAnswerContainer
        style={{
          width: isTabletOrMobile ? "inherit" : "100%",

          border: `${alterCurriculumExplorerTheme.palette.primary.main} solid 2px`,
        }}
      >
        <Typography
          sx={{
            margin: "10px",
            color: alterCurriculumExplorerTheme.palette.primary.main,
          }}
          variant={"h2"}
        >
          {reduxSubmissionDetails?.requesterName
            ? reduxSubmissionDetails.requesterName
            : "Student"}
          's answer
        </Typography>
        {requesterAnswer && (
          <ViewSubmissionAnswerElement block={requesterAnswer} />
        )}
      </CustomAnswerContainer>

      <CustomFeedbackContainer
        style={{
          border: `${altraProgressManagerTheme.palette.primary.main} 2px solid`,
          position: isTabletOrMobile ? "inherit" : "absolute",
          width: isTabletOrMobile ? "100%" : "30%",
          marginTop: isTabletOrMobile ? "1em" : "0",
        }}
      >
        <Slate editor={editor} value={value} onChange={setValue}>
          <CustomSubmissionEditor>
            <Editable
              renderLeaf={renderLeaf}
              renderElement={renderElement}
              placeholder="Put your feedback here"
              onKeyDown={(e) => onKeyDownSubmission(e, editor)}
            />
          </CustomSubmissionEditor>
        </Slate>
      </CustomFeedbackContainer>
    </CustomAnswerAndFeedbackContainer>
  );
};

const CustomAnswerAndFeedbackContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "5px",
}));

const CustomAnswerContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  padding: "10px",
}));

const CustomFeedbackContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  float: "right",
  right: "5em",
  backgroundColor: "white",
  boxShadow: "-1px 7px 6px 2px rgb(60 64 67 / 51%)",
}));
