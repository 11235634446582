import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, styled, Typography, useTheme } from "@mui/material";
import { MathfieldConfig, MathfieldElement } from "mathlive";
import React, { FC, MutableRefObject, useEffect, useRef } from "react";
import { altraResourceBuilderTheme } from "../styling/altra-resource-builder-theme";

const DEFAULT_MATH_LIVE_OPTIONS: Partial<MathfieldConfig> = {
  virtualKeyboardMode: "onfocus",
};

type BlockMathsRenderProps = {
  latexString: string;
  onLatexChange: (latex: string) => void;
  onFocus?: (target: Element) => void;
  onBlur?: () => void;
  onRemove: () => void;
  onArrowKeyPress: (
    key: string,
    mathRef: MutableRefObject<MathfieldElement | null>,
    divRef: MutableRefObject<HTMLDivElement | null>
  ) => void;
};
/**
 * Component for editing Maths block inside text
 *
 * - Update latex value on change
 * - Update maths element values based on props
 * - remove entire maths blocks
 *
 * @param latexString
 * @param onLatexChange
 * @param fullContainerString
 * @param onFocus
 * @param onBlur
 * @constructor
 */
export const EditBlockMaths: FC<BlockMathsRenderProps> = ({
  latexString,
  onLatexChange,
  onFocus,
  onBlur,
  onRemove,
  onArrowKeyPress,
}) => {
  const theme = useTheme();
  const divRef = useRef<HTMLDivElement | null>(null);
  const mathsRef = useRef<MathfieldElement | null>(null);

  useEffect(() => {
    console.log(divRef);
    console.log(mathsRef);
  }, [divRef, mathsRef]);

  /**
   * Mount the mathfield component
   */
  useEffect(() => {
    if (divRef.current && !mathsRef.current) {
      mathsRef.current = new MathfieldElement(DEFAULT_MATH_LIVE_OPTIONS);
      divRef.current.appendChild(mathsRef.current);
    }
    return () => {
      divRef.current = null;
      mathsRef.current = null;
    };
  }, [divRef]);

  /**
   * Set the refed value on state change from parent
   */
  useEffect(() => {
    if (mathsRef.current?.getValue() !== latexString) {
      mathsRef.current?.setValue(latexString);
    }
  }, [latexString]);

  /**
   * Attach listener to change value based on text or remove block
   */
  useEffect(() => {
    const listener = (e) => {
      const value = e.target.getValue();

      if (value !== latexString) {
        onLatexChange(value);
        // debouncedRemoveBlock && debouncedRemoveBlock.cancel();
        return;
      }
    };
    mathsRef.current?.addEventListener("input", listener);
    return () => mathsRef.current?.removeEventListener("input", listener);
  }, [mathsRef.current, onLatexChange, onRemove]);

  return (
    <StyledMathBlock onClick={() => divRef.current?.focus()}>
      <div
        placeholder={"Insert maths"}
        ref={divRef}
        style={{
          fontSize: "default",
          // margin: "5px 0px",
          // padding: "2px",
          display: "flex",
          alignItems: "center",
          whiteSpace: "normal",
          minWidth: "75px",
          borderRadius: "10px 0 0 10px",
          padding: "2px 5px 2px 5px",
          paddingRight: "10px",
          // backgroundColor:
          //   latexString === ""
          //     ? altraResourceBuilderTheme.palette.primary.light
          //     : "white",
          backgroundColor: "white",
        }}
        onFocus={(e) => onFocus && onFocus(e.currentTarget)}
        onBlur={onBlur}
        onKeyDownCapture={(e) => onArrowKeyPress(e.key, mathsRef, divRef)}
      >
        <Typography
          onClick={() => mathsRef.current?.focus()}
          contentEditable={false}
          style={{
            userSelect: "none",
            position: "absolute",
            color: "lightgray",
          }}
          variant={"subtitle1"}
        >
          {latexString === "" && "Insert maths"}
        </Typography>
      </div>

      <StyledDeleteButton
        style={
          {
            // backgroundColor: altraResourceBuilderTheme.palette.primary.light,
          }
        }
      >
        <IconButton onClick={onRemove}>
          <DeleteIcon
            style={{ color: altraResourceBuilderTheme.palette.primary.main }}
          />
        </IconButton>
      </StyledDeleteButton>
    </StyledMathBlock>
  );
};

const StyledMathBlock = styled("div")(({ theme }) => ({
  flexDirection: "row",
  margin: "5px",
  display: "inlineFlex",
  borderRadius: "8px",
  border: "2px solid #fff",

  "&:hover": {
    border: "2px solid #D539641A",
  },
}));

const StyledDeleteButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "0 10px 10px 0",
}));
