import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";

interface LoadingCenteredSpinnerWithTextProps {
  text: string;
}

export const LoadingCenteredSpinnerWithText: FC<
  LoadingCenteredSpinnerWithTextProps
> = ({ text }) => {
  return (
    <StyledCenteredLoaderContainer>
      <CircularProgress />
      <Typography sx={{ marginTop: "1em" }} variant={"h1"}>
        {text}
      </Typography>
    </StyledCenteredLoaderContainer>
  );
};

const StyledCenteredLoaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
  flexDirection: "column",
}));
