import { Checkbox, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  BLOCK_DESCRIPTION_FOR_EXPLORER,
  BLOCK_ICONS_FOR_EXPLORER,
  KeyValue,
} from "../util/custom-types";

interface BlockCardProps {
  keyValue: KeyValue;
  handleCheckboxItemChange: (event, key: number) => void;
  selectedItems: number[];
}
export const BlockCard: FC<BlockCardProps> = ({
  keyValue,
  handleCheckboxItemChange,
  selectedItems,
}) => {
  return (
    <div style={{ marginBottom: "0.5em" }}>
      <div
        style={{
          display: "flex",
          padding: "0.2em 0.5em",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <img
            loading="lazy"
            width="20"
            src={
              BLOCK_ICONS_FOR_EXPLORER.find((b) => b.value === keyValue.value)
                ?.diagram || ""
            }
            alt=""
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "1em",
            }}
          >
            {/*  @ts-expect-error*/}
            <Typography variant={"h7"}> {keyValue.value}</Typography>
            <Typography variant={"body2"}>
              {
                BLOCK_DESCRIPTION_FOR_EXPLORER.find(
                  (b) => b.key === keyValue.key
                )?.description
              }
            </Typography>
          </div>
        </div>
        <Checkbox
          sx={{ justifyContent: "flex-end" }}
          inputProps={{ "aria-label": "controlled" }}
          onChange={(e) => handleCheckboxItemChange(e, keyValue.key)}
          checked={selectedItems.includes(keyValue.key)}
          color={"success"}
          key={`Checkbox-${keyValue.value}`}
        />
      </div>
    </div>
  );
};
