import React, { FC } from "react";
import { Codeline } from "../../util/custom-editor-types";
import { Leaf } from "../resourceBuilderElements/Leaf";

type ViewCodeLineElementProps = {
  block: Codeline;
};

/**
 * View only element for displaying CodeLine blocks
 * @param block
 * @constructor
 */
export const ViewCodeLineElement: FC<ViewCodeLineElementProps> = ({
  block,
}) => {
  return (
    <span>
      {block.children.map((textEl) => (
        <Leaf leaf={textEl}>{textEl.text}</Leaf>
      ))}
    </span>
  );
};
