import { altraProgressManagerTheme } from "@altra-apps/common/src/styling/altra-progress-manager-theme";
import { altraResourceBuilderTheme } from "@altra-apps/common/src/styling/altra-resource-builder-theme";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useIsMobile } from "../../util/useIsMobile";
import { CustomSubmissionToolbar } from "./CustomSubmissionToolbar";

/**
 * Styled wrapper for Slate Editor
 * @param props
 * @constructor
 */
export const CustomSubmissionEditor: FC = (props) => {
  const history = useHistory();
  const isTabletOrMobile = useIsMobile();
  const submissionView = history.location.pathname.includes("/submission")
    ? "SUBMISSION"
    : history.location.pathname.includes("/reviewed")
    ? "REVIEWED"
    : "REVIEW";

  return (
    <CustomEditorContainer
      style={{
        border: `${
          submissionView === "SUBMISSION"
            ? altraResourceBuilderTheme.palette.primary.main
            : altraProgressManagerTheme.palette.primary.main
        } solid 2px`,
        maxHeight: submissionView === "REVIEW" ? "200px" : "auto",
        overflow: submissionView === "REVIEW" ? "scroll" : "auto",
      }}
    >
      <CustomSubmissionToolbar />
      <ChildrenContainer
        style={{
          marginTop: isTabletOrMobile
            ? 0
            : submissionView === "REVIEW"
            ? "50px"
            : 0,
        }}
      >
        {props.children}
      </ChildrenContainer>
    </CustomEditorContainer>
  );
};

const CustomEditorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "0 0 8px 8px",
}));
const ChildrenContainer = styled("div")(({ theme }) => ({
  padding: "10px",
  // minHeight: "200px",
  fontFamily: "Inter",
}));
