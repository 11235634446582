import { COLORS } from "@altra-apps/common/src/util/colors";
import {
  CustomElement,
  LinkElement,
  MathElement,
} from "@altra-apps/common/src/util/custom-editor-types";
import { BLOCK_TYPES } from "@altra-apps/common/src/util/custom-types";
import { getUniqueId } from "@altra-apps/common/src/util/helpers";
import { LIST_TYPES } from "@altra-apps/common/src/util/listTypes";
import { Editor, Element as SlateElement, Range, Transforms } from "slate";

export const FORMAT_TYPE = {
  BOLD: "bold",
  ITALIC: "italic",
  UNDERLINE: "underline",
  CODE: "code",
  MATH: "math",
  LINK: "link",
  BULLETED_LIST: "bulleted-list",
  NUMBERED_LIST: "numbered-list",
};

/**
 * All the custom functions attached to the editor which have been extracted for use in other components
 */
export const CustomSubmissionEditorExtractedLogic = {
  isMarkActive(
    editor: Editor,
    formatType: typeof FORMAT_TYPE[keyof typeof FORMAT_TYPE]
  ) {
    const marks = Editor.marks(editor);
    return marks ? marks[formatType] === true : false;
  },

  toggleMark(
    editor: Editor,
    formatType: typeof FORMAT_TYPE[keyof typeof FORMAT_TYPE]
  ) {
    const isActive = CustomSubmissionEditorExtractedLogic.isMarkActive(
      editor,
      formatType
    );

    if (isActive) {
      Editor.removeMark(editor, formatType);
    } else {
      Editor.addMark(editor, formatType, true);
    }
  },

  toggleColorMark(
    editor: Editor,
    color: keyof typeof COLORS,
    background: boolean
  ) {
    let exitFunction = false;
    const marks = Editor.marks(editor);

    if (marks) {
      //First checks if the current color is an active mark and if it is, removes it
      for (let i = 0; i < Object.keys(marks).length; i++) {
        if (
          Object.keys(marks)[i] === `${color}${background ? "_background" : ""}`
        ) {
          Editor.removeMark(
            editor,
            `${color}${background ? "_background" : ""}`
          );
          exitFunction = true;
          break;
        }
      }

      //If the current mark is not active then removes any other color marks and adds current color mark
      if (!exitFunction) {
        ["", "_background"].map((markSuffix) =>
          Object.keys(COLORS).map((loopColor) => {
            Editor.removeMark(editor, `${loopColor}${markSuffix}`);
          })
        );
        Editor.addMark(
          editor,
          `${color}${background ? "_background" : ""}`,
          true
        );
      }
    }
  },

  isMathActive(editor: Editor) {
    const [link] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "math",
    });
    return !!link;
  },
  isLinkActive(editor: Editor) {
    const [link] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
    return !!link;
  },
  wrapMath(editor: Editor, latex: string) {
    if (CustomSubmissionEditorExtractedLogic.isMathActive(editor)) {
      return;
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const math: MathElement = {
      id: getUniqueId(),
      type: BLOCK_TYPES.MATH,
      latex,
      children: isCollapsed ? [{ text: latex }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, math);
    } else {
      Transforms.wrapNodes(editor, math, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  },
  wrapLink(editor: Editor, url: string) {
    if (CustomSubmissionEditorExtractedLogic.isLinkActive(editor)) {
      return;
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link: LinkElement = {
      id: getUniqueId(),
      type: BLOCK_TYPES.LINK,
      url: url || "",
      children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  },
  unwrapLink(editor: Editor) {
    if (!CustomSubmissionEditorExtractedLogic.isLinkActive(editor)) {
      return;
    }

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
  },
  insertMath(editor: Editor, math: string) {
    if (editor.selection) {
      CustomSubmissionEditorExtractedLogic.wrapMath(editor, math);
    }
  },
  insertLink(editor: Editor, url: string) {
    if (editor.selection) {
      CustomSubmissionEditorExtractedLogic.wrapLink(editor, url);
    }
  },
  isBlockActive(
    editor: Editor,
    formatType: typeof FORMAT_TYPE[keyof typeof FORMAT_TYPE]
  ) {
    const [match] = Editor.nodes(editor, {
      match: (n) => "type" in n && (n.type === formatType || false),
    });
    return !!match;
  },
  toggleBlock(
    editor: Editor,
    formatType: typeof FORMAT_TYPE[keyof typeof FORMAT_TYPE]
  ) {
    const isActive = CustomSubmissionEditorExtractedLogic.isBlockActive(
      editor,
      formatType
    );
    const isList = LIST_TYPES.includes(formatType);

    Transforms.setNodes(
      editor,
      //TODO(JACK): Error caused by answer field being added to types - usnure why
      //@ts-expect-error
      { type: isActive ? "paragraph" : isList ? "list-item" : formatType },
      { match: (n) => Editor.isBlock(editor, n) }
    );

    if (!isActive && isList) {
      const block: CustomElement = {
        id: "LIST-TEST",
        //TODO(JACK): Error caused by answer field being added to types - usnure why
        //@ts-expect-error
        type: formatType,
        children: [],
      };
      Transforms.wrapNodes(editor, block);
    }
  },
};
