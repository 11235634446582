import { LoadingButton } from "@mui/lab";
import { Card, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, styled } from "@mui/system";
import React, { FC, ReactNode } from "react";
import { useIsMobile } from "../util/useIsMobile";

interface InitialFlowContainerCardProps {
  progress: 1 | 2 | 3 | 4;
  onBackClick: () => void;
  children?: ReactNode;
}

export const InitialFlowContainerCard: FC<InitialFlowContainerCardProps> = (
  props
) => {
  const isTabletOrMobile = useIsMobile();
  const theme = useTheme();

  return (
    <Card
      style={{
        display: "flex",
        alignItems: isTabletOrMobile ? "center" : "center",
        flexDirection: isTabletOrMobile ? "column-reverse" : "row",
        width: isTabletOrMobile ? "80%" : "70%",
        height: "600px",
        borderRadius: "20px",
        backgroundColor: "white",
      }}
    >
      <PageContentContainer>
        <StyledTopRow style={{ backgroundColor: theme.palette.primary.main }}>
          <Typography
            sx={{
              color: "white",
              width: "30%",
              fontWeight: 500,
              marginBottom: "0.25em",
            }}
            variant="h2"
          >
            Welcome to <strong> Altra</strong>
          </Typography>
          <Typography
            sx={{
              color: "white",
              width: "30%",
              fontWeight: 300,
              lineHeight: 1.2,
            }}
            variant="button"
          >
            Complete the steps below so we can tailor the experience for you
          </Typography>
          <div style={{ position: "absolute", top: 20, right: 20 }}>
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                sx={{ color: "white" }}
                value={props.progress * 25}
                variant="determinate"
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <Typography variant="caption" component="div" color={"white"}>
                  {props.progress}/4
                </Typography>
              </Box>
            </Box>
          </div>
        </StyledTopRow>
        {props.children}
        <ButtonContainer>
          <LoadingButton
            disabled={props.progress === 1}
            onClick={props.progress === 1 ? () => null : props.onBackClick}
            variant="contained"
            size="large"
          >
            Back
          </LoadingButton>
        </ButtonContainer>
      </PageContentContainer>
    </Card>
  );
};

const StyledTopRow = styled("div")(({ theme }) => ({
  width: "100%",
  height: "10%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "1.5em",
}));

const ImageContainer = styled("div")(({ theme }) => ({
  width: "150px",
  margin: "1em",
  Height: "auto",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    width: "50%",
    Height: "50%",
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  width: "100%",
  alignContent: "flex-start",
  left: 20,
  bottom: 20,
  display: "flex",
  position: "absolute",
}));
const RootTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "7em auto auto auto",
  width: "70%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    marginTop: "12em",
  },
}));

const RootContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "2em auto",
  width: "70%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    marginTop: "12em",
  },
}));

const TypographyContainer = styled(Typography)(({ theme }) => ({
  alignContent: "start",
  width: "90%",
  marginTop: 20,
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    width: "100%",
  },
}));

const PageContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  position: "relative",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    width: "100%",
  },
}));
