import CEHomeBackground from "@altra-apps/common/assets/images/CESelectionBackground.svg";
import { ErrorSnackbar } from "@altra-apps/common/src/atoms/ErrorSnackbar";
import { AppOverviewScreen } from "@altra-apps/common/src/organisms/AppOverviewScreen";
import { AppOverviewScreenMobile } from "@altra-apps/common/src/organisms/AppOverviewScreenMobile";
import { ALTRA_APPS } from "@altra-apps/common/src/util/custom-types";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { styled } from "@mui/material/styles";
import { FC, useState } from "react";

/**
 * The landing page for the Curriculum Explorer app
 * todo(jack): move curriculums out of application context
 * @constructor
 */
export const HomeScreen: FC = () => {
  // use to set runtime error
  const [error, setError] = useState<string>();
  // snackbar state handler to make it open or close
  const [snackbarState, setSnackbarState] = useState<boolean>(false);
  const isTabletOrMobile = useIsMobile();

  return (
    <StyledContainer
      style={{
        backgroundImage: `url(${CEHomeBackground})`,
      }}
    >
      <ErrorSnackbar error={error!} severity={"error"} state={snackbarState} />
      {isTabletOrMobile ? (
        <AppOverviewScreenMobile appName={ALTRA_APPS.CE} />
      ) : (
        <AppOverviewScreen appName={ALTRA_APPS.CE} />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "100vh",
  width: "100vw",
  left: 0,
}));
