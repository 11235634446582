import {
  Button,
  createStyles,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { uniqBy } from "lodash";
import React, { FC, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { SearchBar } from "../molecules/SearchBar";
import { Topic, TopicLabel } from "../redux/applicationContext/types";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import {
  toggledFiltersChangedReadyToSearch,
  userAppInfoCurriculumExplorerTutorial,
  userAppInfoSelectedTopicsId,
} from "../redux/user/actions";
import { CurriculumExplorerTutorialStages } from "../redux/user/types";
import { alterCurriculumExplorerTheme } from "../styling/altra-curriculum-explorer-theme";
import { DRAWER_WIDTH } from "../util/custom-types";
import { getUnionOfValuesForKeys } from "../util/helpers";
import { useIsMobile } from "../util/useIsMobile";
import { FilterDropdownsAndButtons } from "./FilterDropdownsAndButtons";
import { TutorialPopper } from "./TutorialPopper";

export type FilterDrawerProps = {
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  open: boolean;
  fullScreen: boolean;
  searchValue: string;
  updateSearchValue: (s: string) => void;
};

/**
 * Drawer with filters and search bar for explorer
 * - Can either be fixed or hideable
 */
export const FilterDrawer: FC<FilterDrawerProps> = ({
  open,
  handleDrawerClose,
  handleDrawerOpen,
  fullScreen,
}) => {
  const theme = alterCurriculumExplorerTheme;
  const history = useHistory();

  const isTabletOrMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const curriculumExplorerStage: CurriculumExplorerTutorialStages =
    useAppSelector((state) => state.userAppInfo.curriculumExplorerTutorial);
  const topicLabels: { [curriculumId: number]: TopicLabel[] } = useAppSelector(
    (state) => state.applicationContext.topicLabels
  );
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext?.topics
  );
  const selectedCurriculumIds: number[] | undefined = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId!
  );

  const selectedTopicIds: { [topicLabelId: number]: Array<number> } =
    useAppSelector((state) => state.userAppInfo?.selectedTopicsId!);
  const allSelectedTopicIdsArray: Array<Number> =
    getUnionOfValuesForKeys<Number>(selectedTopicIds);

  const handleApplyFilters = () => {
    handleDrawerClose();
    dispatch(toggledFiltersChangedReadyToSearch(true));
  };

  const [tutorialAnchorEl, setTutorialAnchorEl] =
    React.useState<HTMLElement | null>(null);

  useEffect(() => {
    console.log(curriculumExplorerStage);
    let anchorId = "";

    if (curriculumExplorerStage === 1)
      anchorId = "curriculum-explorer-search-bar";
    if (curriculumExplorerStage === 2)
      anchorId = "curriculum-explorer-quick-search";
    if (curriculumExplorerStage === 4)
      anchorId = "curriculum-explorer-apply-filters";

    if (
      (curriculumExplorerStage === 1 ||
        curriculumExplorerStage === 2 ||
        curriculumExplorerStage === 4) &&
      document.getElementById(anchorId)
    ) {
      setTutorialAnchorEl(document.getElementById(anchorId));
      console.log(document.getElementById(anchorId));
    }
  }, [curriculumExplorerStage]);

  // this is quite a heavy component, memoize
  const filters = useMemo(() => <FilterDropdownsAndButtons />, []);

  const renderSuggestedChip = (
    topic: Topic,
    onClick: () => void,
    selected: boolean
  ) => {
    return (
      <Button
        variant={"contained"}
        size={"small"}
        onClick={onClick}
        style={{
          backgroundColor: selected
            ? theme.palette.success.main
            : theme.palette.primary.main,
          borderRadius: "20px",
          marginRight: "1em",
          marginBottom: "0.5em",
        }}
      >
        {topic.title}
      </Button>
    );
  };

  return (
    <StyledSwipeableDrawer
      style={{ width: DRAWER_WIDTH }}
      variant={fullScreen ? "permanent" : "temporary"}
      open={open}
      onClose={handleDrawerClose}
      onOpen={handleDrawerOpen}
      classes={{
        paper: createStyles({
          drawer: {
            width: DRAWER_WIDTH,
          },
          drawerPaper: {
            width: DRAWER_WIDTH,
          },
        }),
      }}
      sx={{
        "& .MuiDrawer-paper": {
          zIndex: 0,
          maxWidth: DRAWER_WIDTH,
        },
      }}
    >
      {fullScreen && <Toolbar />}
      {fullScreen ? (
        <SearchContainer
          id={"curriculum-explorer-search-bar"}
          style={{ padding: isTabletOrMobile ? 0 : "1em" }}
        >
          <Typography sx={{ marginBottom: "0.5em" }} variant={"h2"}>
            Search
          </Typography>
          <SearchBar />
          <TutorialPopper
            open={
              !history.location.pathname.includes("/import") &&
              selectedCurriculumIds &&
              selectedCurriculumIds.length > 0 &&
              curriculumExplorerStage === 1
            }
            onClose={() => {
              console.log("close: 1 to complete");
              dispatch(userAppInfoCurriculumExplorerTutorial("COMPLETE"));
            }}
            onNext={() => {
              console.log("next: 1 to 2");

              dispatch(userAppInfoCurriculumExplorerTutorial(2));
            }}
            anchorEl={tutorialAnchorEl}
            step={1}
            totalSteps={5}
            title={"Search"}
            description={
              "Search by keyword or topic to instantly find learning materials for you"
            }
          />
          <div
            id={"curriculum-explorer-quick-search"}
            style={{ marginTop: "1em" }}
          >
            {renderSuggestedChip(
              {
                title: "All",
                id: 0,
                topic_unit_title: "All learning materials",
              },
              () => {
                dispatch(
                  userAppInfoSelectedTopicsId({
                    topicIds: [],
                    topicLabelId: uniqBy(
                      getUnionOfValuesForKeys<Topic>(topics)?.filter(
                        (t) =>
                          getUnionOfValuesForKeys(topicLabels)
                            ?.find((tl) => tl.id === t.topic_label_id)
                            ?.title?.toLowerCase() === "quick search"
                      ),
                      "id"
                    )[0]?.topic_label_id,
                  })
                );
                dispatch(toggledFiltersChangedReadyToSearch(true));
              },
              !uniqBy(
                getUnionOfValuesForKeys<Topic>(topics)?.filter(
                  (t) =>
                    getUnionOfValuesForKeys(topicLabels)
                      ?.find((tl) => tl.id === t.topic_label_id)
                      ?.title?.toLowerCase() === "quick search"
                ),
                "id"
              ).some((topic2) =>
                getUnionOfValuesForKeys<number>(selectedTopicIds).includes(
                  topic2.id
                )
              )
            )}
            {uniqBy(
              getUnionOfValuesForKeys<Topic>(topics)?.filter(
                (t) =>
                  getUnionOfValuesForKeys(topicLabels)
                    ?.find((tl) => tl.id === t.topic_label_id)
                    ?.title?.toLowerCase() === "quick search"
              ),
              "id"
            )?.map((t) =>
              renderSuggestedChip(
                t,
                getUnionOfValuesForKeys(selectedTopicIds).includes(t.id)
                  ? () => {
                      const currentSelection =
                        selectedTopicIds[t.topic_label_id];
                      dispatch(
                        userAppInfoSelectedTopicsId({
                          topicLabelId: t.topic_label_id,
                          topicIds: currentSelection.filter(
                            (tId) => tId !== t.id
                          ),
                        })
                      );
                      dispatch(toggledFiltersChangedReadyToSearch(true));
                    }
                  : () => {
                      let currentSelection =
                        selectedTopicIds[t.topic_label_id] || [];
                      currentSelection = currentSelection.concat(t.id);
                      dispatch(
                        userAppInfoSelectedTopicsId({
                          topicLabelId: t.topic_label_id,
                          topicIds: currentSelection,
                        })
                      );
                      dispatch(toggledFiltersChangedReadyToSearch(true));
                    },
                getUnionOfValuesForKeys(selectedTopicIds).includes(t.id)
              )
            )}
          </div>
          <TutorialPopper
            open={curriculumExplorerStage === 2}
            onClose={() => {
              console.log("close: 2 to complete");
              dispatch(userAppInfoCurriculumExplorerTutorial("COMPLETE"));
            }}
            onNext={() => {
              console.log("next: 2 to 3");
              dispatch(userAppInfoCurriculumExplorerTutorial(3));
            }}
            anchorEl={tutorialAnchorEl}
            step={2}
            totalSteps={5}
            title={"Quick search"}
            description={
              "Narrow your results using the most common search terms"
            }
          />
        </SearchContainer>
      ) : (
        <Toolbar />
      )}
      {filters}
      <StyledButtonContainer sx={{ backgroundColor: "white" }}>
        {allSelectedTopicIdsArray.length >= 15 && (
          <Typography
            sx={{ marginTop: "10px", width: "90%", margin: "1em 2em" }}
            variant={"subtitle2"}
            color={"error"}
          >
            You can only select 15 or less topics for your search at a time
          </Typography>
        )}
        <Button
          id={"curriculum-explorer-apply-filters"}
          disabled={allSelectedTopicIdsArray.length >= 15}
          style={{
            marginTop: "10px",
            width: "90%",
            margin: "1em 2em",
            backgroundColor: theme.palette.primary.main,
          }}
          variant={"contained"}
          onClick={handleApplyFilters}
          size={"large"}
        >
          Apply filters
        </Button>
        <TutorialPopper
          open={curriculumExplorerStage === 4}
          onClose={() => {
            console.log("close: 4 to complete");

            dispatch(userAppInfoCurriculumExplorerTutorial("COMPLETE"));
          }}
          onNext={() => {
            console.log("next: 4 to 5");
            dispatch(userAppInfoCurriculumExplorerTutorial(5));
          }}
          anchorEl={tutorialAnchorEl}
          step={4}
          totalSteps={5}
          title={"Apply filters"}
          description={"Don’t forget to apply your filters!"}
        />
      </StyledButtonContainer>
    </StyledSwipeableDrawer>
  );
};

const SearchContainer = styled("div")``;

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  //& .MuiDrawer-paper {
  //  position: initial;
  //}
`;
const StyledButtonContainer = styled("div")`
  position: sticky;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
