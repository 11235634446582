import { ReviewedEditor } from "@altra-apps/common/src/molecules/submissionEditor/ReviewedEditor";
import { ReviewEditor } from "@altra-apps/common/src/molecules/submissionEditor/ReviewEditor";
import { SubmissionEditor } from "@altra-apps/common/src/molecules/submissionEditor/SubmissionEditor";
import { Skeleton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import React, { FC, useEffect, useMemo, useState } from "react";
import Question from "../../../assets/blockTypeIcons/Question.svg";
import {
  Block as AwsBlock,
  GetBlocksWithChildrenQueryVariables,
  useGetBlocksWithChildrenLazyQuery,
} from "../../graphql/types";
import { updateLoadingStatus } from "../../redux/applicationContext/actions";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { userAppInfoExplorerBlocksChildren } from "../../redux/user/actions";
import { Block } from "../../redux/user/types";
import { altraTheme } from "../../styling/altra-theme";
import { QuestionTextOnlyElement } from "../../util/custom-editor-types";
import { Status } from "../../util/custom-types";
import { sortChildrenIntoBlocks } from "../../util/helpers";
import { renderViewOnlyBlockForBlockType } from "../../util/render-view-only-block-for-block-type";
import { useIsMobile } from "../../util/useIsMobile";

type ViewQuestionTextOnlyElementProps = {
  block: QuestionTextOnlyElement;
  displayBorder?: boolean;
  viewWatermark?: boolean;
  showPreviewIcon?: boolean;
  doNotLoadChildren?: boolean;
  submissionView?: "SUBMISSION" | "REVIEW" | "REVIEWED";
};

/**
 * View only element for displaying QuestionTextOnly blocks
 * @param block
 * @param doNotLoadChildren
 * @param displayBorder
 * @param viewWatermark
 * @param showPreviewIcon
 * @param submissionView
 * @constructorolva
 */
export const ViewQuestionTextOnlyElement: FC<
  ViewQuestionTextOnlyElementProps
> = ({
  block,
  doNotLoadChildren,
  displayBorder,
  viewWatermark,
  showPreviewIcon,
  submissionView,
}) => {
  const dispatch = useAppDispatch();
  const isTabletOrMobile = useIsMobile();

  const [childrenLoading, setChildrenLoading] = useState(true);
  const userExplorerBlocksChildren: { [parentId: number]: Array<Block> } =
    useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);

  const [
    getBlocksWithChildrenCallback,
    { data: getChildrenData, error: getChildrenError },
  ] = useGetBlocksWithChildrenLazyQuery();

  /**
   * Initial load of all blocks for selected curriculum and topics
   */
  useEffect(() => {
    if (!doNotLoadChildren) {
      setChildrenLoading(true);
      getAllChildren();
    } else {
      setChildrenLoading(false);
    }
  }, []);

  const getAllChildren = async () => {
    let getChildrenInput: GetBlocksWithChildrenQueryVariables = {
      ids: `{${block.id}}`,
    };
    getBlocksWithChildrenCallback({
      variables: getChildrenInput,
    });
  };

  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    if (getChildrenError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get child blocks",
        })
      );
    } else if (getChildrenData && getChildrenData) {
      const childBlocksAll: AwsBlock[] =
        getChildrenData.get_blocks_with_children.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        ) as AwsBlock[];
      const childBlocksForRedux: { id: number; children: AwsBlock[] }[] =
        sortChildrenIntoBlocks(parseInt(block.id), childBlocksAll);

      dispatch(
        userAppInfoExplorerBlocksChildren(
          childBlocksForRedux.map((cb) => ({
            parentId: cb.id,
            children:
              cb.children.map((block) => ({
                id: block.id,
                tags: block.tags.map((tag) => ({
                  id: tag.id,
                  topicId: tag.topic?.id,
                  label: tag.topic?.title || "",
                })),
                active: block.active,
                block: block.block,
                type: block.type,
                position: block.position,
                parent_id: block.parent_id,
                teacherNotes: block.note,
              })) || [],
          }))
        )
      );
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved child blocks",
        })
      );
    }
    setChildrenLoading(false);
  }, [getChildrenData, getChildrenError]);

  const renderChildren = useMemo(
    () => (
      <>
        <StyledQuestionTextOnlyContainer style={altraTheme.typography.body1}>
          {userExplorerBlocksChildren[block.id]
            ?.filter((b) => b.type !== "answer")
            .sort((a, b) => (a?.position || 0) - (b?.position || 0))
            ?.map((childBlock) =>
              renderViewOnlyBlockForBlockType(
                childBlock,
                true,
                viewWatermark,
                showPreviewIcon,
                true,
                submissionView
              )
            )}
          {submissionView !== "SUBMISSION" &&
            userExplorerBlocksChildren[block.id]
              ?.filter((b) => b.type === "answer")
              ?.map((childBlock) =>
                renderViewOnlyBlockForBlockType(
                  childBlock,
                  true,
                  viewWatermark,
                  showPreviewIcon,
                  true,
                  submissionView
                )
              )}
        </StyledQuestionTextOnlyContainer>
      </>
    ),
    [userExplorerBlocksChildren, submissionView]
  );

  const renderEditor = useMemo(
    () => (
      <>
        {submissionView === "SUBMISSION" && (
          <SubmissionEditor blockId={parseInt(block.id)} />
        )}
        {submissionView === "REVIEW" && (
          <ReviewEditor blockId={parseInt(block.id)} />
        )}{" "}
        {submissionView === "REVIEWED" && (
          <ReviewedEditor blockId={parseInt(block.id)} />
        )}
      </>
    ),
    [submissionView]
  );

  return (
    <div style={{ width: "100%" }}>
      {childrenLoading ? (
        <>
          <Skeleton animation="wave" height={20} />
          <Skeleton animation="wave" height={20} />
          <Skeleton animation="wave" height={20} />
          <Skeleton animation="wave" height={20} />
        </>
      ) : (
        <StyledHashLinkContainer id={block.id.toString()}>
          <StyledQuestionContainer isTabletOrMobile={isTabletOrMobile}>
            {displayBorder && (
              <StyledIconColumn>
                {/*TODO: Make column narrow if question is not top level*/}
                <img
                  style={{ maxWidth: "100%", marginTop: "0.8em" }}
                  src={Question}
                  alt={"Question logo"}
                />
              </StyledIconColumn>
            )}
            <StyledQuestionContentColumn
              isTabletOrMobile={isTabletOrMobile}
              sx={{
                width: "100%",
                borderRadius: displayBorder ? "0 10px 10px 0" : 0,
                border: displayBorder ? "1px solid #DBDBDB" : 0,
              }}
            >
              {renderChildren}
              {renderEditor}
            </StyledQuestionContentColumn>
          </StyledQuestionContainer>
        </StyledHashLinkContainer>
      )}
    </div>
  );
};

const StyledQuestionTextOnlyContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  //margin: 1em;
`;

interface StyledTitleDescProps {
  isTabletOrMobile: boolean;
}
const StyledQuestionContainer = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "isTabletOrMobile",
})<StyledTitleDescProps>(({ theme, isTabletOrMobile }) => ({
  display: "flex",
  width: "100%",
  padding: isTabletOrMobile ? "30px 0" : "50px 0",
  "@media print": {
    padding: 0,
    margin: 0,
  },
}));
const StyledIconColumn = styled("div")`
  display: flex;
  background-color: #e2e2e2;
  width: 5%;
  border-radius: 10px 0 0 10px;
  align-items: flex-start;
  @media print {
    display: none;
  }
`;
const StyledQuestionContentColumn = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "isTabletOrMobile",
})<StyledTitleDescProps>(({ theme, isTabletOrMobile }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  padding: "25px",
  "@media print": {
    border: 0,
    padding: 0,
  },
}));

const StyledHashLinkContainer = styled("div")(({ theme }) => ({
  marginTop: "-50px",
  paddingTop: "50px",
  display: "block",
}));
