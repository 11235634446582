import { Skeleton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import React, { FC, useEffect, useState } from "react";
import {
  GetChildrenOfParentWithIdQueryVariables,
  useGetChildrenOfParentWithIdLazyQuery,
} from "../../graphql/types";
import { updateLoadingStatus } from "../../redux/applicationContext/actions";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { userAppInfoExplorerBlockChildren } from "../../redux/user/actions";
import { Block } from "../../redux/user/types";
import { ALTRA_APPS, BLOCK_TYPES, Status } from "../../util/custom-types";
import { renderBoldFirstLine } from "../../util/helpers";
import { QUESTION_TYPE } from "../../util/questionTypes";
import { renderResultCardContentForBlockType } from "../../util/render-result-card-for-block-type";
import { ExplorerResultChildInlineContainer } from "./ExplorerResultChildInlineContainer";

type ExplorerResultChildInlineContentProps = {
  block: Block;
  isSelectable: boolean;
};

/**
 * Content for inline view of result card and logic to retrieve children of block
 * @param block Block of child to be displayed inline
 * @param isSelectable
 * @constructor
 */
export const ExplorerResultChildInlineContent: FC<
  ExplorerResultChildInlineContentProps
> = ({ block, isSelectable }) => {
  const [limit, setLimit] = useState<number | null>(3);
  const [loadingChildren, setLoadingChildren] = useState(true);

  const dispatch = useAppDispatch();

  const userExplorerBlocksChildren: { [parentId: number]: Array<Block> } =
    useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);

  const [
    getChildrenOfParentWithIdCallback,
    { data: getChildrenData, error: getChildrenError },
  ] = useGetChildrenOfParentWithIdLazyQuery();

  /**
   * Initial load of all blocks for selected curriculum and topics
   */
  useEffect(() => {
    setLoadingChildren(true);
    if (!limit || userExplorerBlocksChildren[block.id]?.length < limit) {
      getAllChildrenForCurrentLimit();
    } else {
      setLoadingChildren(false);
    }
  }, [limit]);

  const getAllChildrenForCurrentLimit = async () => {
    let getChildrenInput: GetChildrenOfParentWithIdQueryVariables = {
      limit: limit,
      parentId: block.id,
    };
    setLoadingChildren(true);

    getChildrenOfParentWithIdCallback({
      variables: getChildrenInput,
    });
  };

  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    if (getChildrenError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get child blocks",
        })
      );
    } else if (getChildrenData && getChildrenData.block) {
      dispatch(
        userAppInfoExplorerBlockChildren({
          parentId: block.id,
          children:
            getChildrenData.block.map((block) => ({
              id: block.id,
              tags: block.tags.map((tag) => ({
                id: tag.id,
                topicId: tag.topic?.id,
                label: tag.topic?.title || "",
              })),
              active: block.active,
              block: block.block,
              type: block.type,
              position: block.position,
              parent_id: block.parent_id,
              teacherNotes: block.note,
            })) || [],
        })
      );
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved child blocks",
        })
      );
    }
    setLoadingChildren(false);
  }, [getChildrenData, getChildrenError]);

  return (
    <>
      <StyledChildrenContainer>
        <div style={{ marginBottom: "2em", width: "80%" }}>
          {/*  Display first line of question text as bold text and trim any extra text not needed for display in grid*/}
          {userExplorerBlocksChildren[block.id]
            ?.slice()
            ?.filter((b) => b.type !== "answer")
            .sort((a, b) => (a?.position || 0) - (b?.position || 0))
            ?.map(
              (childBlock, index) =>
                index === 0 &&
                block.type === QUESTION_TYPE.QUESTION_TEXT_ONLY &&
                renderBoldFirstLine(childBlock)
            )}
        </div>
        {block.type === BLOCK_TYPES.QUESTION_TEXT_ONLY ||
        block.type === BLOCK_TYPES.SECTION
          ? userExplorerBlocksChildren[block.id]
              ?.slice()
              ?.filter((b) => b.type !== "answer")
              .sort((a, b) => (a?.position || 0) - (b?.position || 0))
              ?.map(
                (childBlock, index) =>
                  index !== 0 &&
                  (childBlock?.block["children"]?.length === 1 &&
                  childBlock?.block["children"][0]?.text === "" &&
                  childBlock.type === BLOCK_TYPES.PARAGRAPH ? (
                    <span />
                  ) : (
                    <ExplorerResultChildInlineContainer
                      block={childBlock}
                      isSelectable={isSelectable}
                      selected={false}
                      appName={ALTRA_APPS.CE}
                    >
                      {renderResultCardContentForBlockType(
                        childBlock,
                        true,
                        isSelectable
                      )}
                    </ExplorerResultChildInlineContainer>
                  ))
              )
          : userExplorerBlocksChildren[block.id]
              ?.slice()
              ?.filter((b) => b.type !== "answer")
              .sort((a, b) => (a?.position || 0) - (b?.position || 0))
              ?.map((childBlock) => (
                <ExplorerResultChildInlineContainer
                  block={childBlock}
                  isSelectable={isSelectable}
                  selected={false}
                  appName={ALTRA_APPS.CE}
                >
                  {renderResultCardContentForBlockType(
                    childBlock,
                    true,
                    isSelectable
                  )}
                </ExplorerResultChildInlineContainer>
              ))}
        {loadingChildren && (
          <>
            <Skeleton animation="wave" height={40} />
            <Skeleton animation="wave" height={40} />
            <Skeleton animation="wave" height={40} />
            <Skeleton animation="wave" height={40} />
          </>
        )}
      </StyledChildrenContainer>

      {/*{limit && <Button onClick={() => setLimit(null)}>Show all blocks</Button>}*/}
    </>
  );
};

const StyledChildrenContainer = styled("div")`
  margin: 10px 0;
`;
