import { Descendant } from "slate";
import { Tag_Insert_Input } from "../../graphql/types";
import { BLOCK_TYPES, Status } from "../../util/custom-types";

export type UserInfo = {
  active?: boolean;
  avatar_url?: string;
  school?: string;
  bio_data?: string;
  display_name?: string;
  email?: string;
  id?: number | undefined;
  institution_id?: number;
  subscription_id?: number;
  last_edited_time?: number;
  created_time?: number;
  tags?: Array<Tag_Insert_Input>;
  verified?: boolean;
  stripe_id: string | undefined;
};

export enum OPERATION_TYPE {
  insert_text,
  remove_text,
  insert_node,
  remove_node,
  split_node,
  merge_node,
  set_node,
  move_node,
  update_entire_resource,
}

export type BlockBeingDragged =
  | {
      blockType: BLOCK_TYPES | "question";
      id: string;
    }
  | undefined;

export type ManualEditorBlockMove =
  | {
      status: "INACTIVE";
    }
  | { status: "PENDING"; blockId: string; blockType: BLOCK_TYPES }
  | {
      status: "SELECTED";
      blockId: string;
      blockType: BLOCK_TYPES;
      droppableId: string;
    };

export type ManualEditorBlockImport =
  | {
      status: "INACTIVE";
    }
  | { status: "PENDING" }
  | {
      status: "SELECTED";
      droppableId: string;
    };

export type SubmissionIdNameTitle = {
  submissionId: number;
  title?: string;
  requesterName?: string;
  markerName?: string;
  publicly_accessible?: boolean;
};

export type ResourceBeingEdited = {
  title: string;
  description: string;
  id: number;
};

export type CurriculumExplorerTutorialStages = 1 | 2 | 3 | 4 | 5 | "COMPLETE";
export type ResourceBuilderTutorialStages = 1 | 2 | 3 | 4 | 5 | "COMPLETE";
export type ToggleAnswers =
  | "CLOSE_ALL_PENDING"
  | "OPEN_ALL_PENDING"
  | undefined;
/**
 * This is used to manage logged-in and guest user application runtime activities
 */
export type UserAppInfo = {
  teacherOrStudent?: "TEACHER" | "STUDENT";
  blockCurrentlyBeingDragged: BlockBeingDragged;
  manualEditorBlockMove: ManualEditorBlockMove;
  manualEditorBlockImport: ManualEditorBlockImport;
  toggleAnswers: ToggleAnswers;
  triggerResourceSave: boolean;
  firstSearchExecuted: boolean;
  filtersAppliedReadyToSearch: boolean;
  queueForDatabaseUpdates: Array<{ type: OPERATION_TYPE; ids: Array<string> }>;
  selectedCurriculumsId?: Array<number>;
  selectedTopicsId?: { [topicLabelId: number]: Array<number> };
  selectedBlocksId: Array<number>;
  selectedAuthors: Array<{ id: number; name: string }>;
  role?: string;
  userExplorerBlocks: Array<Block>;
  userCreatedResources: Array<Block>;
  userExplorerBlocksChildren: { [parentId: number]: Array<Block> };
  userEditableBlockProps?: Array<EditableBlockProp>;
  searchTerm: string;
  blocksSelectedForAddingToResource: Array<number>;
  onlyShowVerifiedBlocks: boolean;
  authorisedToEditResource: boolean;
  tagModalOpen?: string;
  redirectPath?: string;
  submissions: {
    [blockId: number]: [SubmissionIdNameTitle];
  };
  editableSubmissionBlocks?: Array<EditableSubmissionAnswerFeedbackProp>;
  resourceBeingEdited?: ResourceBeingEdited;
  darkMode: boolean;
  curriculumExplorerTutorial: CurriculumExplorerTutorialStages;
  resourceBuilderTutorial: ResourceBuilderTutorialStages;
};

export type LoadedUserStatus = {
  loadingStatus: Status;
  userInfo: UserInfo;
  error: string;
};

export type EditableSubmissionAnswerFeedbackProp = {
  block_id: number;
  submission_id: number;
  requester_answer?: Array<Descendant>;
  marker_feedback?: Array<Descendant>;
};

export type EditableBlockProp = {
  client_id?: string;
  dbId?: number;
  parent_id?: number;
  has_children?: boolean;
  type?: string;
  position?: number;
  publicly_accessible?: boolean;
  tags?: Tag[];
};

export type Tag = {
  id?: number;
  topicId?: number;
  label: string;
};

export type Block = {
  id: number;
  active: boolean;
  type: string;
  block: any[];
  parent_id: number | undefined | null;
  tags: Tag[];
  position: number | undefined | null;
  teacherNotes: string | null;
  lastEditedDate?: number;
};
