import { Link, Typography } from "@mui/material";
import React, { CSSProperties, FC } from "react";
import styled from "styled-components";

type TextLinkProps = {
  value: string;
  onClick: () => void;
  style?: CSSProperties;
  color?: "primary" | "error";
};

/**
 * Underlined text used throughout the app as a button
 * @param value
 * @param onClick
 * @param style
 * @param color
 * @constructor
 */
export const TextLink: FC<TextLinkProps> = ({
  value,
  onClick,
  style,
  color,
}) => (
  <Link
    style={{ marginTop: "10px", margin: "auto", ...style }}
    onClick={onClick}
  >
    <StyledLinkText
      style={{ userSelect: "none" }}
      contentEditable={false}
      variant={"h5"}
      color={color || "primary"}
    >
      {value}
    </StyledLinkText>
  </Link>
);

const StyledLinkText = styled(Typography)`
  cursor: pointer;
`;
