import { useAppSelector } from "@altra-apps/common/src/redux/hook";
import {
  EditableSubmissionAnswerFeedbackProp,
  SubmissionIdNameTitle,
} from "@altra-apps/common/src/redux/user/types";
import { altraProgressManagerTheme } from "@altra-apps/common/src/styling/altra-progress-manager-theme";
import QuizIcon from "@mui/icons-material/Quiz";
import { Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Descendant } from "slate";
import { alterCurriculumExplorerTheme } from "../../styling/altra-curriculum-explorer-theme";
import { getUnionOfValuesForKeys } from "../../util/helpers";
import { useIsMobile } from "../../util/useIsMobile";
import { ViewSubmissionAnswerElement } from "./ViewSubmissionAnswerElement";

interface SubmissionEditorProps {
  blockId: number;
}
const initialSubmissionValue: Descendant[] = [
  {
    //@ts-expect-error
    type: "paragraph",
    children: [{ text: "" }],
  },
];

/**
 * Simple editor used for answers and feedback for submissions
 * @param blockId
 * @constructor
 */
export const ReviewedEditor: FC<SubmissionEditorProps> = ({ blockId }) => {
  const routerParams = useParams();

  const [markerFeedbackValue, setMarkerFeedbackValue] = useState<
    undefined | Descendant[]
  >(undefined);
  const [requesterAnswer, setRequesterAnswer] = useState<
    undefined | Descendant[]
  >(undefined);

  const reduxSubmissionDetails: SubmissionIdNameTitle | undefined =
    useAppSelector((state) => {
      const submission = getUnionOfValuesForKeys(
        state.userAppInfo.submissions
      ).find(
        //@ts-expect-error
        (b) => b.submissionId === parseInt(routerParams?.id)
      );
      return submission;
    });
  const isTabletOrMobile = useIsMobile();
  const noFeedbackValue: Descendant[] = [
    {
      //@ts-expect-error
      type: "paragraph",
      children: [{ text: "No feedback provided for this question" }],
    },
  ];
  const noAnswerValue: Descendant[] = [
    {
      //@ts-expect-error
      type: "paragraph",
      children: [{ text: "You have not answered this question" }],
    },
  ];
  const submissionAnswerAndFeedbackBlock: EditableSubmissionAnswerFeedbackProp =
    useAppSelector((state) => {
      return (
        state.userAppInfo.editableSubmissionBlocks?.find(
          (b) => b.block_id === blockId
        ) || {
          //@ts-expect-error
          submission_id: routerParams?.id,
          block_id: blockId,
          requester_answer: initialSubmissionValue,
          marker_feedback: initialSubmissionValue,
        }
      );
    });

  /**
   * Set the intial value
   */
  useEffect(() => {
    if (submissionAnswerAndFeedbackBlock && !markerFeedbackValue) {
      setMarkerFeedbackValue(
        submissionAnswerAndFeedbackBlock.marker_feedback &&
          submissionAnswerAndFeedbackBlock.marker_feedback?.length > 0
          ? submissionAnswerAndFeedbackBlock.marker_feedback
          : noFeedbackValue
      );
    }

    if (submissionAnswerAndFeedbackBlock && !requesterAnswer) {
      setRequesterAnswer(
        !submissionAnswerAndFeedbackBlock?.requester_answer ||
          submissionAnswerAndFeedbackBlock?.requester_answer.length === 0 ||
          (submissionAnswerAndFeedbackBlock?.requester_answer.length === 1 &&
            //prettier-ignore
            submissionAnswerAndFeedbackBlock?.requester_answer[0]
                  //@ts-ignore-error
                  ?.children[0]?.text === "")
          ? noAnswerValue
          : submissionAnswerAndFeedbackBlock?.requester_answer
      );
    }
  }, [submissionAnswerAndFeedbackBlock]);

  return (
    <CustomAnswerAndFeedbackContainer
      style={{
        flexDirection: isTabletOrMobile ? "column" : "row",
      }}
    >
      <CustomAnswerContainer
        style={{
          border: `${alterCurriculumExplorerTheme.palette.primary.main} solid 2px`,
          marginTop: isTabletOrMobile ? "1em" : "0",
        }}
      >
        <Typography
          sx={{
            margin: "10px",
            color: alterCurriculumExplorerTheme.palette.primary.main,
          }}
          variant={"h2"}
        >
          Your answer
        </Typography>
        {requesterAnswer && (
          <ViewSubmissionAnswerElement block={requesterAnswer} />
        )}
      </CustomAnswerContainer>

      {!submissionAnswerAndFeedbackBlock?.marker_feedback ||
      submissionAnswerAndFeedbackBlock?.marker_feedback.length === 0 ||
      (submissionAnswerAndFeedbackBlock?.marker_feedback.length === 1 &&
        //prettier-ignore
        submissionAnswerAndFeedbackBlock?.marker_feedback[0]
                    //@ts-ignore-error
                    ?.children[0]?.text === "") ? (
        <></>
      ) : (
        <CustomFeedbackContainer
          style={{
            border: `${altraProgressManagerTheme.palette.primary.main} 2px solid`,
            position: isTabletOrMobile ? "inherit" : "absolute",
            width: isTabletOrMobile ? "100%" : "30%",
            marginTop: isTabletOrMobile ? "1em" : "0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "1em",
              alignItems: "center",
            }}
          >
            <QuizIcon
              sx={{ color: altraProgressManagerTheme.palette.primary.main }}
            />
            <Typography sx={{ marginLeft: "1em" }} variant={"h2"}>
              Feedback{" "}
              {reduxSubmissionDetails?.markerName
                ? `from ${reduxSubmissionDetails.markerName}`
                : ""}
            </Typography>
          </div>
          <Divider sx={{ marginBottom: "1em" }} />
          {markerFeedbackValue && (
            <ViewSubmissionAnswerElement block={markerFeedbackValue} />
          )}
        </CustomFeedbackContainer>
      )}
    </CustomAnswerAndFeedbackContainer>
  );
};

const CustomAnswerAndFeedbackContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "5px",
}));

const CustomAnswerContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  border: "solid 2px black",
  borderRadius: "8px",
}));

const CustomFeedbackContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  width: "35%",
  borderRadius: "8px",
  float: "right",
  right: "5em",
  backgroundColor: "white",
  boxShadow: "0 2px 6px 2px rgb(60 64 67 / 15%)",
  maxHeight: "200px",
  overflow: "auto",
}));
