import PdfIcon from "@altra-apps/common/assets/icons/pdf.svg";
import {
  GetBlockWithIdQueryVariables,
  Subscription,
  useExportResourceMutation,
  useGetBlockWithIdLazyQuery,
} from "@altra-apps/common/src/graphql/types";
import { updateLoadingStatus } from "@altra-apps/common/src/redux/applicationContext/actions";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import {
  userAppInfoExplorerBlocks,
  userAppInfoToggleAnswers,
} from "@altra-apps/common/src/redux/user/actions";
import {
  Block,
  EditableBlockProp,
  ToggleAnswers,
  UserInfo,
} from "@altra-apps/common/src/redux/user/types";
import { ALTRA_APPS, Status } from "@altra-apps/common/src/util/custom-types";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PremiumOptionsModal } from "../../molecules/PremiumOptionsModal";
import { altraTheme } from "../../styling/altra-theme";
import { renderViewOnlyBlockForBlockType } from "../../util/render-view-only-block-for-block-type";
import { useIsMobile } from "../../util/useIsMobile";

export const ExportScreen: FC = () => {
  const routerParams = useParams();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [exporting, setExporting] = useState(false);
  const isTabletOrMobile = useIsMobile();
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<
    | { OPEN: false }
    | {
        OPEN: true;
        title: string;
        description?: string;
        onCancel: () => void;
        cancelText: string;
      }
  >({ OPEN: false });
  const userSubscriptionPlan: Subscription = useAppSelector((state) =>
    state.applicationContext.subscriptions?.find(
      (element) => element.id === user.subscription_id!
    )
  ) as Subscription;

  const editableBlockProp: Array<EditableBlockProp> = useAppSelector(
    (state) => state.userAppInfo.userEditableBlockProps
  ) as Array<EditableBlockProp>;

  const [resourceToExportId, setResourceToExportId] = useState<number>();
  const [resourceToExport, setResourceToExport] = useState<Block>();
  const [exportFileFormat, setExportFileFormat] = useState<
    "WORD" | "POWERPOINT" | "PDF"
  >("PDF");

  const toggleAnswer: ToggleAnswers = useAppSelector(
    (state) => state.userAppInfo?.toggleAnswers
  );

  const [localShowAnswerKey, setLocalShowAnswerKey] = useState(false);

  const updateShowAnswerKey = (value: ToggleAnswers) => {
    if (value === "OPEN_ALL_PENDING") setLocalShowAnswerKey(true);
    if (value === "CLOSE_ALL_PENDING") setLocalShowAnswerKey(false);
    dispatch(userAppInfoToggleAnswers(value));
  };

  useEffect(() => {
    updateShowAnswerKey(toggleAnswer);
  }, [toggleAnswer]);

  useEffect(() => {
    setResourceToExportId(undefined);
    setResourceToExport(undefined);
    //@ts-ignore
    const resourceId: number = routerParams?.id;
    if (resourceId) setResourceToExportId(resourceId);
  }, []);

  useEffect(() => {
    getResource();
  }, [resourceToExportId]);

  const [
    getResourceCallback,
    { data: getResourceData, error: getResourceError },
  ] = useGetBlockWithIdLazyQuery();

  const getResource = async () => {
    let getResourceInput: GetBlockWithIdQueryVariables = {
      blockId: resourceToExportId,
    };
    getResourceCallback({
      variables: getResourceInput,
    });
  };

  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    if (getResourceError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get child blocks",
        })
      );
    } else if (getResourceData && resourceToExportId && getResourceData.block) {
      console.log(getResourceData);
      const explorerPayload: Block = {
        id: getResourceData.block[0].id,
        tags: getResourceData.block[0].tags.map((tag) => ({
          id: tag.id,
          topicId: tag.topic?.id,
          label: tag.topic?.title || "",
        })),
        active: getResourceData.block[0].active,
        block: getResourceData.block[0].block,
        position: getResourceData.block[0].position,
        teacherNotes: getResourceData.block[0].note,
        parent_id: getResourceData.block[0].parent_id,
        type: getResourceData.block[0].type,
      };
      dispatch(userAppInfoExplorerBlocks([explorerPayload]));
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved child blocks",
        })
      );
      setResourceToExport(explorerPayload);
    }
  }, [getResourceData, getResourceError]);

  const [exportResourceMutation] = useExportResourceMutation();

  const exportResouce = async () => {
    dispatch(
      updateLoadingStatus({
        status: Status.LOADING,
        message: "Exporting resource",
      })
    );
    if (resourceToExportId) {
      setExporting(true);
      const result = await exportResourceMutation({
        variables: {
          resourceId: resourceToExportId.toString(),
          userId: user?.id?.toString() || "",
          format: exportFileFormat,
        },
      });

      if (result.data) {
        {
          dispatch(
            updateLoadingStatus({
              status: Status.IDLE,
              message: "Exported resource",
            })
          );
          window.open(result.data.exportResource?.url, "_blank");
        }
      }
      if (result.errors) {
        dispatch(
          updateLoadingStatus({
            status: Status.IDLE,
            message: "Couldn't export resource",
          })
        );
      }
    }
    setExporting(false);
  };

  return (
    <StyledExportScreen
      sx={{ flexDirection: isTabletOrMobile ? "column" : "row" }}
    >
      {isTabletOrMobile && (
        <StyledExportTopBar>
          <StyledTopRow>
            <StyledTopRowContent>
              <IconButton
                onClick={() => {
                  history.goBack();
                }}
                style={{
                  color: theme.palette.error.main,
                  right: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </StyledTopRowContent>
          </StyledTopRow>
          <StyledOptionsMobileSection>
            <FormControl fullWidth={true}>
              <Typography
                sx={{ marginBottom: "20px", marginLeft: "10px" }}
                variant={"h1"}
              >
                Export
              </Typography>

              <Typography
                sx={{ marginBottom: "1.5em", marginLeft: "10px" }}
                variant={"h2"}
              >
                File type
              </Typography>
              <StyledRadioGroup
                value={exportFileFormat}
                //@ts-expect-error
                onChange={(event, value) => setExportFileFormat(value)}
                row={true}
              >
                <StyledRadioButtonWithIcon>
                  <FormControlLabel
                    labelPlacement="top"
                    value="PDF"
                    control={<Radio />}
                    label="PDF"
                  />
                </StyledRadioButtonWithIcon>

                {/*<StyledRadioButtonWithIcon>*/}
                {/*  <FormControlLabel*/}
                {/*    labelPlacement="top"*/}
                {/*    value="WORD"*/}
                {/*    control={<Radio />}*/}
                {/*    label="Word"*/}
                {/*  />*/}
                {/*</StyledRadioButtonWithIcon>*/}

                {/*<StyledRadioButtonWithIcon>*/}
                {/*  <FormControlLabel*/}
                {/*    labelPlacement="top"*/}
                {/*    value="POWERPOINT"*/}
                {/*    control={<Radio />}*/}
                {/*    label="Powerpoint"*/}
                {/*  />*/}
                {/*</StyledRadioButtonWithIcon>*/}
              </StyledRadioGroup>
            </FormControl>
          </StyledOptionsMobileSection>
          <StyledOptionsSectionButton>
            <LoadingButton
              loading={exporting}
              onClick={
                exportFileFormat === "PDF"
                  ? () => window.print()
                  : () => exportResouce()
              }
              size={"large"}
              variant={"contained"}
              fullWidth={true}
            >
              Export
            </LoadingButton>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/*{userSubscriptionPlan?.plan !==*/}
              {/*  "Altra Early bird - Individual" && (*/}
              {/*  <Button*/}
              {/*    fullWidth*/}
              {/*    onClick={() =>*/}
              {/*      setUpgradeModalOpen({*/}
              {/*        OPEN: true,*/}
              {/*        title: "Upgrade to remove watermark on exports",*/}
              {/*        cancelText: "Cancel",*/}
              {/*        onCancel: () => setUpgradeModalOpen({ OPEN: false }),*/}
              {/*      })*/}
              {/*    }*/}
              {/*  >*/}
              {/*    Remove watermark*/}
              {/*  </Button>*/}
              {/*)}*/}
            </div>
          </StyledOptionsSectionButton>
        </StyledExportTopBar>
      )}
      <StyledPreviewColumn
        style={{
          backgroundColor: isTabletOrMobile ? "white" : "#262626",
          height: isTabletOrMobile ? "100%" : "100vh",
        }}
      >
        <StyledResourceContainer
          sx={{
            boxShadow: isTabletOrMobile
              ? "none"
              : "rgb(27 31 35) 10px 11px 12px",
            width: isTabletOrMobile
              ? "100%"
              : exportFileFormat === "POWERPOINT"
              ? "85%"
              : "70%",
          }}
        >
          {resourceToExport ? (
            renderViewOnlyBlockForBlockType(
              resourceToExport,
              true,
              userSubscriptionPlan?.plan !== "Altra Early bird - Individual",
              false
            )
          ) : (
            <CircularProgress />
          )}
        </StyledResourceContainer>
      </StyledPreviewColumn>
      {!isTabletOrMobile && (
        <StyledExportOptionsColumn>
          <StyledTopRow>
            <StyledTopRowContent>
              <IconButton
                onClick={() => {
                  history.goBack();
                }}
                style={{
                  color: theme.palette.error.main,
                  right: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </StyledTopRowContent>
          </StyledTopRow>
          <StyledExportOptionsContents>
            <Typography variant={"h1"}>Export</Typography>
            <StyledOptionsSection>
              <FormControl fullWidth={true}>
                <Typography sx={{ marginBottom: "1.5em" }} variant={"h2"}>
                  File type
                </Typography>
                <StyledRadioGroup
                  value={exportFileFormat}
                  //@ts-expect-error
                  onChange={(event, value) => setExportFileFormat(value)}
                  row={true}
                >
                  <StyledRadioButtonWithIcon>
                    <img
                      style={{
                        height: "40px",
                        marginBottom: 10,
                        marginRight: "10px",
                        display: "inline-flex",
                      }}
                      src={PdfIcon}
                      alt={"Heading Block"}
                    />
                    <FormControlLabel
                      labelPlacement="top"
                      value="PDF"
                      control={<Radio />}
                      label="PDF"
                    />
                  </StyledRadioButtonWithIcon>

                  {/*<StyledRadioButtonWithIcon>*/}
                  {/*  <img*/}
                  {/*    style={{*/}
                  {/*      // margin: "auto",*/}
                  {/*      height: "40px",*/}
                  {/*      marginBottom: 10,*/}
                  {/*      marginRight: "10px",*/}
                  {/*      display: "inline-flex",*/}
                  {/*    }}*/}
                  {/*    src={WordIcon}*/}
                  {/*  />*/}
                  {/*  <FormControlLabel*/}
                  {/*    labelPlacement="top"*/}
                  {/*    value="WORD"*/}
                  {/*    control={<Radio />}*/}
                  {/*    label="Word"*/}
                  {/*  />*/}
                  {/*</StyledRadioButtonWithIcon>*/}

                  {/*<StyledRadioButtonWithIcon>*/}
                  {/*  <img*/}
                  {/*    style={{*/}
                  {/*      // margin: "auto",*/}
                  {/*      height: "40px",*/}
                  {/*      marginBottom: 10,*/}
                  {/*      marginRight: "10px",*/}
                  {/*      display: "inline-flex",*/}
                  {/*    }}*/}
                  {/*    src={PowerpointIcon}*/}
                  {/*  />*/}
                  {/*  <FormControlLabel*/}
                  {/*    labelPlacement="top"*/}
                  {/*    value="POWERPOINT"*/}
                  {/*    control={<Radio />}*/}
                  {/*    label="Powerpoint"*/}
                  {/*  />*/}
                  {/*</StyledRadioButtonWithIcon>*/}
                </StyledRadioGroup>
              </FormControl>
            </StyledOptionsSection>
            <StyledOptionsSection>
              <FormControl fullWidth={true}>
                <Typography sx={{ marginBottom: "1.5em" }} variant={"h2"}>
                  Display
                </Typography>
                <FormControlLabel
                  contentEditable={false}
                  sx={{
                    "& .MuiFormControlLabel-label":
                      altraTheme.typography.subtitle2,
                  }}
                  value="start"
                  control={
                    <Switch
                      onChange={(event, checked) =>
                        updateShowAnswerKey(
                          checked ? "OPEN_ALL_PENDING" : "CLOSE_ALL_PENDING"
                        )
                      }
                      checked={localShowAnswerKey}
                      color="primary"
                    />
                  }
                  label="Show all answers"
                  labelPlacement="end"
                />
              </FormControl>
            </StyledOptionsSection>
            <StyledOptionsSectionButton>
              <LoadingButton
                loading={exporting}
                onClick={
                  exportFileFormat === "PDF"
                    ? () => window.print()
                    : () => exportResouce()
                }
                size={"large"}
                variant={"contained"}
                fullWidth={true}
              >
                Export
              </LoadingButton>
              {/*{userSubscriptionPlan?.plan !==*/}
              {/*  "Altra Early bird - Individual" && (*/}
              {/*  <Button*/}
              {/*    fullWidth*/}
              {/*    onClick={() =>*/}
              {/*      setUpgradeModalOpen({*/}
              {/*        OPEN: true,*/}
              {/*        title: "Upgrade to remove watermark on exports",*/}
              {/*        cancelText: "Cancel",*/}
              {/*        onCancel: () => setUpgradeModalOpen({ OPEN: false }),*/}
              {/*      })*/}
              {/*    }*/}
              {/*  >*/}
              {/*    Remove watermark*/}
              {/*  </Button>*/}
              {/*)}*/}
            </StyledOptionsSectionButton>
          </StyledExportOptionsContents>
        </StyledExportOptionsColumn>
      )}
      <PremiumOptionsModal
        open={upgradeModalOpen.OPEN}
        title={(upgradeModalOpen.OPEN && upgradeModalOpen.title) || "Upgrade"}
        subtitle={(upgradeModalOpen.OPEN && upgradeModalOpen.description) || ""}
        appName={ALTRA_APPS.CE}
        cancelText={
          (upgradeModalOpen.OPEN && upgradeModalOpen.cancelText) || "Cancel"
        }
        onCancel={
          (upgradeModalOpen.OPEN && upgradeModalOpen.onCancel) ||
          (() => setUpgradeModalOpen({ OPEN: false }))
        }
        onClose={() => setUpgradeModalOpen({ OPEN: false })}
      />
    </StyledExportScreen>
  );
};

const StyledExportTopBar = styled("div")`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  background-color: #f0f0f0;
  border-radius: 8px;
  flex-direction: column;
  padding: 10px;
  width: 90%;
  @media print {
    display: none;
  }
`;
const StyledExportScreen = styled("div")`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @media print {
    position: relative;
  }
`;

const StyledPreviewColumn = styled("div")(({ theme }) => ({
  "@media print": {
    backgroundColor: "white !important",
    width: "100%",
    overflow: "visible",
  },
  justifyContent: "center",
  alignItems: "flexStart",
  width: "70%",
  overflow: "hidden",
  display: "flex",
}));

const StyledExportOptionsColumn = styled("div")`
  align-items: center;
  width: 30%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  @media print {
    display: none;
  }
`;
const StyledResourceContainer = styled("div")`
  display: flex;
  min-height: 90%;
  padding: 1em;
  background-color: white;
  flex-direction: column;
  border-radius: 8px;
  margin: 2em;
  overflow: auto;

  @media print {
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    width: 100%;
    overflow: visible;
  }
`;
const StyledOptionsSection = styled("div")`
  display: flex;
  width: 100%;
  margin: 2em 0;
  justify-content: space-between;
`;
const StyledOptionsSectionButton = styled("div")`
  display: flex;
  width: 100%;
  margin: 2em 0;
  justify-content: space-between;
  flex-direction: column;
`;
const StyledOptionsMobileSection = styled("div")`
  display: flex;
  width: 100%;
  margin: 0 0 1em 0;
  justify-content: space-between;
`;
const StyledExportOptionsContents = styled("div")`
  display: flex;
  width: 80%;
  flex-direction: column;
  margin-top: 2em;
`;
const StyledRadioButtonWithIcon = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;

const StyledRadioGroup = styled(RadioGroup)`
& .MuiFormGroup-root {
  width: 100%;
  justify-content: space-between;
},
`;
const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  height: "20px",
  width: "90%",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
  padding: "5%",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  height: "20px",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
}));
