import { LoadingCenteredSpinnerWithText } from "@altra-apps/common/src/atoms/LoadingCenteredSpinnerWithText";
import {
  GetBlockWithIdQueryVariables,
  Submission,
  useGetBlockWithIdLazyQuery,
  useGetSubmissionLazyQuery,
} from "@altra-apps/common/src/graphql/types";
import { AltraTopAppBar } from "@altra-apps/common/src/molecules/AltraTopAppBar";
import { ShareSubmissionDialog } from "@altra-apps/common/src/organisms/ShareSubmissionDialog";
import { updateLoadingStatus } from "@altra-apps/common/src/redux/applicationContext/actions";
import { LoadingStatus } from "@altra-apps/common/src/redux/applicationContext/types";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import {
  userAppInfoCreateSubmissionAnswerFeedbackBlocks,
  userAppInfoExplorerBlocks,
  userAppInfoUpdateSubmission,
} from "@altra-apps/common/src/redux/user/actions";
import { Block } from "@altra-apps/common/src/redux/user/types";
import { altraProgressManagerTheme } from "@altra-apps/common/src/styling/altra-progress-manager-theme";
import { ALTRA_APPS, Status } from "@altra-apps/common/src/util/custom-types";
import { renderContentNavigationForBlockType } from "@altra-apps/common/src/util/render-content-navigation-for-block-type";
import { renderViewOnlyBlockForBlockType } from "@altra-apps/common/src/util/render-view-only-block-for-block-type";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import QuizIcon from "@mui/icons-material/Quiz";
import {
  Button,
  CircularProgress,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SubmissionSidebar } from "../../molecules/SubmissionSidebar";

/**
 * Screen to view submissions, users can create a submission with stores answers and feedback for questions
 * @constructor
 */
export const SubmissionScreen: FC = () => {
  const routerParams = useParams();
  const history = useHistory();

  const submissionView = history.location.pathname.includes("/submission")
    ? "SUBMISSION"
    : history.location.pathname.includes("/reviewed")
    ? "REVIEWED"
    : "REVIEW";

  const dispatch = useAppDispatch();
  const darkMode = false;
  const userExplorerBlocksChildren: { [parentId: number]: Array<Block> } =
    useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);

  const [submissionToView, setSubmissionToView] = useState<Submission | null>(
    null
  );
  const [blockForSubmission, setBlockForSubmission] = useState<Block>();
  const [hasFeedback, setHasFeedback] = useState(false);
  const isTabletOrMobile = useIsMobile();

  const [
    getResourceCallback,
    { data: getResourceData, error: getResourceError },
  ] = useGetBlockWithIdLazyQuery();

  const [
    getSubmissionCallback,
    { data: getSubmissionData, error: getSubmissionError },
  ] = useGetSubmissionLazyQuery();
  const loadingStatus: LoadingStatus = useAppSelector(
    (state) => state.applicationContext.loadingStatus
  );

  /**
   * Load the submission for the ID in URL
   */
  useEffect(() => {
    console.log("Getting submission from DB");
    dispatch(
      updateLoadingStatus({
        status: Status.IDLE,
        message: "Retrieved submission",
      })
    );
    //@ts-expect-error
    if (routerParams?.id && !submissionToView) {
      dispatch(
        updateLoadingStatus({
          status: Status.LOADING,
          message: "Retrieved submission",
        })
      );
      //@ts-expect-error
      getSubmissionCallback({ variables: { id: parseInt(routerParams?.id) } });
    }
  }, [routerParams]);

  useEffect(() => {
    document
      ?.getElementById("revision-resource-column")
      ?.scrollTo({ top: 0, behavior: "smooth" });
    document
      ?.getElementById("submission-sidebar")
      ?.scrollTo({ top: 0, behavior: "smooth" });
  }, [submissionView]);

  /**
   * If successfully loaded in submission, store the submission in redux
   */
  useEffect(() => {
    console.log("got submission");
    if (getSubmissionError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get submission",
        })
      );
    } else if (getSubmissionData && getSubmissionData.submission_by_pk) {
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved submission",
        })
      );
      const answers =
        getSubmissionData.submission_by_pk.submission_answers || {};
      const feedback =
        getSubmissionData.submission_by_pk.questions_feedback || {};

      if (answers) {
        dispatch(
          userAppInfoCreateSubmissionAnswerFeedbackBlocks(
            Object.keys(answers).map((blockId) => ({
              block_id: parseInt(blockId),
              requester_answer:
                answers && answers[blockId] ? answers[blockId] : [],
              marker_feedback:
                feedback && feedback[blockId] ? feedback[blockId] : [],
              //@ts-expect-error
              submission_id: routerParams?.id,
            }))
          )
        );
      }
      setSubmissionToView(getSubmissionData.submission_by_pk);
      dispatch(
        userAppInfoUpdateSubmission({
          submissionId: getSubmissionData.submission_by_pk.id,
          requesterName:
            getSubmissionData.submission_by_pk.requester_name || "",
          title: getSubmissionData.submission_by_pk.title || "",
          markerName: getSubmissionData.submission_by_pk.marker_name || "",
        })
      );
    }
  }, [getSubmissionData, getSubmissionError]);

  /**
   * Once submission is loaded in, get the block associated with it
   */
  useEffect(() => {
    console.log("Get blocks for submission");
    if (
      submissionToView?.block_id &&
      // @ts-expect-error
      routerParams?.id !== submissionToView?.block_id
    ) {
      getResource();
    }
  }, [submissionToView]);

  /**
   * Get resource associated with submission
   */
  const getResource = async () => {
    console.log("getting resource");

    if (submissionToView?.block_id) {
      let getResourceInput: GetBlockWithIdQueryVariables = {
        blockId: submissionToView?.block_id,
      };
      getResourceCallback({
        variables: getResourceInput,
      });
    }
  };

  /**
   * On retrieval of resource associated with submission, store blocks in redux
   */
  useEffect(() => {
    if (getResourceError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get child blocks",
        })
      );
    } else if (getResourceData && getResourceData.block) {
      console.log("got resource", getResourceData);
      const explorerPayload: Block = {
        id: getResourceData.block[0].id,
        tags: getResourceData.block[0].tags.map((tag) => ({
          id: tag.id,
          topicId: tag.topic?.id,
          label: tag.topic?.title || "",
        })),
        active: getResourceData.block[0].active,
        block: getResourceData.block[0].block,
        position: getResourceData.block[0].position,
        teacherNotes: getResourceData.block[0].note,
        parent_id: getResourceData.block[0].parent_id,
        type: getResourceData.block[0].type,
      };
      dispatch(userAppInfoExplorerBlocks([explorerPayload]));
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved child blocks",
        })
      );
      setBlockForSubmission(explorerPayload);
    }
  }, [getResourceData, getResourceError]);

  /**
   * When submission changed, update feedback boolean to toggle view between submission and feedback
   */
  useEffect(() => {
    console.log("Update has feedback");

    const localHasFeedback = Object.keys(
      submissionToView?.questions_feedback || {}
    ).some((f) => {
      if (
        submissionToView?.questions_feedback[f] &&
        submissionToView?.questions_feedback[f]?.length === 1 &&
        submissionToView?.questions_feedback[f][0]?.children?.length === 1 &&
        submissionToView?.questions_feedback[f][0]?.children[0]?.text === ""
      ) {
        return false;
      } else if (
        submissionToView?.questions_feedback[f] &&
        submissionToView?.questions_feedback[f]?.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    });
    setHasFeedback(localHasFeedback);
  }, [submissionToView]);

  const ContentMenu = useMemo(() => {
    return (
      blockForSubmission &&
      renderContentNavigationForBlockType(
        blockForSubmission,
        userExplorerBlocksChildren,
        blockForSubmission?.id,
        submissionView,
        //@ts-expect-error
        routerParams?.id
      )
    );
  }, [blockForSubmission, submissionView]);

  const renderChildren = useMemo(
    () =>
      blockForSubmission ? (
        <>
          {renderViewOnlyBlockForBlockType(
            blockForSubmission,
            true,
            false,
            true,
            true,
            submissionView
          )}
        </>
      ) : (
        <CircularProgress />
      ),
    [blockForSubmission, submissionView]
  );

  const renderInfoBox = () => (
    <>
      {submissionView === "REVIEWED" && (
        <StyledDarkInfoBox isTabletOrMobile={isTabletOrMobile}>
          <StyledRow>
            <Typography
              sx={{
                color: "white",
              }}
              style={{ marginBottom: "0.5em" }}
              variant={"h1"}
            >
              {hasFeedback
                ? "You have feedback"
                : "Submit your answers for feedback"}
            </Typography>
            <QuizIcon
              sx={{
                color: "white",
              }}
            />
          </StyledRow>
          {!hasFeedback && <ShareSubmissionDialog />}
          {hasFeedback && (
            <Button
              disabled={loadingStatus.status === Status.LOADING}
              sx={{ width: "100%", marginTop: "1em" }}
              size="large"
              variant={"contained"}
              onClick={() =>
                document
                  ?.getElementById("check-answers-button")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Scroll to bottom
            </Button>
          )}
        </StyledDarkInfoBox>
      )}
      {submissionView === "REVIEW" && (
        <StyledDarkInfoBox isTabletOrMobile={isTabletOrMobile}>
          <StyledRow>
            <Typography
              sx={{
                color: "white",
              }}
              style={{ marginBottom: "0.5em" }}
              variant={"h1"}
            >
              {submissionToView?.requester_name} has requested your feedback
            </Typography>
            <QuizIcon
              sx={{
                color: altraProgressManagerTheme.palette.primary.main,
              }}
            />
          </StyledRow>
          <ShareSubmissionDialog />
        </StyledDarkInfoBox>
      )}
      {submissionView === "SUBMISSION" && (
        <StyledDarkInfoBox isTabletOrMobile={isTabletOrMobile}>
          <StyledRow>
            <Typography
              sx={{
                color: "white",
              }}
              variant={"h1"}
            >
              Test yourself then get feedback
            </Typography>
            <QuizIcon
              sx={{
                color: "white",
              }}
            />
          </StyledRow>
          <Button
            sx={{ width: "100%", marginTop: "1em" }}
            size="large"
            variant={"contained"}
            onClick={() =>
              document
                ?.getElementById("check-answers-button")
                ?.scrollIntoView({ behavior: "smooth" })
            }
            disabled={loadingStatus.status === Status.LOADING}
          >
            Scroll to bottom
          </Button>
        </StyledDarkInfoBox>
      )}
    </>
  );

  if (blockForSubmission === undefined) {
    return <LoadingCenteredSpinnerWithText text={"Loading your submission"} />;
  }

  return (
    <>
      <AltraTopAppBar displaySubCompoments appName={ALTRA_APPS.CE} />
      <>
        <Toolbar />
        <div
          style={{
            height: "95%",
            width: "100vw",
            overflow: "hidden",
            display: "flex",
            position: "absolute",
            flexDirection: isTabletOrMobile ? "column" : "row",
            left: 0,
            backgroundColor: darkMode ? "#262626" : "rgb(248, 248, 248)",
          }}
        >
          {!isTabletOrMobile && (
            <SubmissionSidebar>
              {renderInfoBox()}
              {ContentMenu}
            </SubmissionSidebar>
          )}
          {isTabletOrMobile && renderInfoBox()}
          <StyledPreviewColumn
            id={"revision-resource-column"}
            style={{
              position: "relative",

              overflow: "auto",
              justifyContent: "flex-start",
              alignItems:
                submissionView === "SUBMISSION"
                  ? "center"
                  : submissionView === "REVIEWED" && !hasFeedback
                  ? "center"
                  : "flex-start",
              width: isTabletOrMobile ? "100%" : "80%",
            }}
          >
            <StyledBorderedResource
              style={{
                borderRadius: isTabletOrMobile ? 0 : "8px",
                border: isTabletOrMobile ? "none" : "1px solid #606060",
                width: isTabletOrMobile
                  ? "100%"
                  : submissionView === "SUBMISSION"
                  ? "80%"
                  : submissionView === "REVIEWED" && !hasFeedback
                  ? "80%"
                  : "50%",
                margin: isTabletOrMobile ? 0 : "2em",
              }}
            >
              {renderChildren}
            </StyledBorderedResource>

            {submissionView === "SUBMISSION" && (
              <Tooltip title={"Show the answers to all the questions"}>
                <Button
                  id={"check-answers-button"}
                  sx={{ marginBottom: "5em", width: "80%" }}
                  size="large"
                  variant={"contained"}
                  onClick={() =>
                    history.push(
                      history.location.pathname.includes("submission/")
                        ? //@ts-expect-error
                          `/reviewed/${routerParams?.id}`
                        : //@ts-expect-error
                          `/submission/${routerParams?.id}`
                    )
                  }
                  disabled={loadingStatus.status === Status.LOADING}
                >
                  Check answers
                </Button>
              </Tooltip>
            )}
            {submissionView === "REVIEW" && (
              <Tooltip title={"Send your feedback to the requester"}>
                <ShareSubmissionDialog />
              </Tooltip>
            )}
            {submissionView === "REVIEWED" && hasFeedback && (
              <Tooltip
                title={
                  "Find more resources to revise in the Altra Curriculum Explorer app"
                }
              >
                <Button
                  id={"check-answers-button"}
                  sx={{ marginBottom: "5em", width: "80%" }}
                  size="large"
                  variant={"contained"}
                  onClick={() =>
                    //@ts-expect-error
                    routerParams?.id && history.push(`/explorer`)
                  }
                  disabled={loadingStatus.status === Status.LOADING}
                >
                  Find more resources to revise
                </Button>
              </Tooltip>
            )}
          </StyledPreviewColumn>
        </div>
      </>
    </>
  );
};

const StyledPreviewColumn = styled("div")`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media print {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
  @media print {
    background-color: white;
    width: 100%;
    overflow: visible;
  }
`;

const StyledResourceContainer = styled("div")`
  display: flex;
  min-height: 90%;
  flex-direction: column;
  border-radius: 8px;
  @media print {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
`;

interface StyledTitleDescProps {
  isTabletOrMobile: boolean;
}
const StyledDarkInfoBox = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "isTabletOrMobile",
})<StyledTitleDescProps>(({ theme, isTabletOrMobile }) => ({
  backgroundColor: "#262626",
  display: "flex",
  flexDirection: "column",

  padding: "1em",
  borderRadius: isTabletOrMobile ? 0 : "20px",
  width: isTabletOrMobile ? "auto" : "80%",
  marginBottom: isTabletOrMobile ? 0 : "1em",
}));

const StyledRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));
const StyledBorderedResource = styled("div")`
  background-color: white;
`;
