import { LoadingButton } from "@mui/lab";
import { Portal, Toolbar, Typography } from "@mui/material";
import React, { FC, useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToolbarContext } from "../molecules/AltraTopAppBar";
import { SearchBar } from "../molecules/SearchBar";
import { ALTRA_APPS } from "../util/custom-types";
import { useIsMobile } from "../util/useIsMobile";
import { FilterDrawer } from "./FilterDrawer";
import { SearchResults } from "./SearchResults";

interface ExplorerContainerProps {
  fullScreen: boolean;
  displayInTab: boolean;
  title?: string;
  appName: ALTRA_APPS;
}

/**
 * Generic explorer component for searching, filtering and displaying results
 * - Used across the explorer and builder applications
 * - Can be displayed as full screen or mobile view and within or outwith a side menu
 * @param fullScreen
 * @param displayInTab
 * @param title
 * @param onInsertBlocks
 * @param appName
 * @constructor
 */

export const ExplorerContainer: FC<ExplorerContainerProps> = ({
  fullScreen,
  displayInTab,
  title,
  appName,
}) => {
  const isTabletOrMobile = useIsMobile();
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const setOpen = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);
  const setClosed = useCallback(() => setDrawerOpen(false), [setDrawerOpen]);
  const toolbar = useContext(ToolbarContext);

  /**
   * Bar shown when explorer is within a side menu
   * - Consists of filter button and search bar
   */
  const SideMenuSearchAndFilterToolbar = (
    <Toolbar
      style={{
        display: "grid",
        gap: "1em",
        gridAutoFlow: "column",
      }}
    >
      <LoadingButton
        style={{
          flex: 1,
        }}
        loading={drawerOpen}
        variant={"contained"}
        color={"primary"}
        size={"large"}
        onClick={() => {
          setDrawerOpen(!drawerOpen);
        }}
      >
        Filters
      </LoadingButton>

      <SearchBar small={true} />
    </Toolbar>
  );

  return (
    <div
      style={{
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: "#F8F8F8",
        display: "flex",
        height: "100%",
      }}
    >
      <FilterDrawer
        fullScreen={fullScreen}
        open={drawerOpen}
        handleDrawerClose={() => setClosed()}
        handleDrawerOpen={() => setOpen()}
        searchValue={""}
        updateSearchValue={() => console.log("search")}
      />
      <main style={{ flex: 1, width: "100%", minHeight: "100%" }}>
        {!displayInTab && <Toolbar />}
        {!displayInTab && !fullScreen && <Toolbar />}
        {!fullScreen && toolbar.current && (
          <Portal container={toolbar.current}>
            {SideMenuSearchAndFilterToolbar}
          </Portal>
        )}
        {!fullScreen && !toolbar.current && SideMenuSearchAndFilterToolbar}
        {title && (
          <div style={{ margin: "1em 1em 0 1em", marginBottom: 0 }}>
            <Typography variant={"h2"} color={"secondary"}>
              {title}
            </Typography>
          </div>
        )}
        <SearchResults
          fullScreen={fullScreen}
          appName={appName}
          isSelectable={appName === ALTRA_APPS.RB}
          showFilters={setOpen}
        />
      </main>
    </div>
  );
};
