import MessageIcon from "@mui/icons-material/Message";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FC } from "react";
import { useAppSelector } from "../redux/hook";
import { UserAppInfo, UserInfo } from "../redux/user/types";
import { openInNewTab } from "../util/helpers";

export const AltraSpeedDial: FC = () => {
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  const userAppInfo: UserAppInfo = useAppSelector((state) => state.userAppInfo);

  const actions = [
    {
      icon: <QuestionMarkIcon />,
      name: "Tutorials",
      link: `https://www.notion.so/jackow98/Altra-Tutorials-021e965791ba46e69267a19fa72a800a`,
    },
    {
      icon: <MessageIcon />,
      name: "Contact",
      link: `https://2uluvvlx386.typeform.com/to/CxLRLlOf#email=${user.email}&name=${user.display_name}&block=xxxxx&accountid=${user.id}`,
    },
    {
      icon: <PriorityHighIcon />,
      name: "Issue",
      link: `https://2uluvvlx386.typeform.com/to/rhLlAWSr#name=${user.display_name}&block=xxxxx&email=${user.email}`,
    },
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <StyledContainer>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<QuestionMarkIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => {
              openInNewTab(action.link);
              handleClose();
            }}
          />
        ))}
      </SpeedDial>
    </StyledContainer>
  );
};

const StyledContainer = styled("div")`
  @media print {
    display: none;
  }
`;
