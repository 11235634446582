import { styled } from "@mui/material/styles";
import React, { FC, useState } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { BaseEditor } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor } from "slate-react";
import { CUSTOM_HEADER_THEME_FOR_RESOURCE } from "../../styling/constants";
import { CustomElement } from "../../util/custom-editor-types";
import { getStyleForDroppable } from "../../util/helpers";
import { DraggingBlock } from "../DraggingBlock";
import { EditableBlockContainer } from "./EditableBlockContainer";

interface HeadingElementProps {
  type: "ONE" | "TWO";
  attributes: any;
  element: CustomElement;
  editor: BaseEditor & ReactEditor & HistoryEditor;
}

/**
 * A element that allows editable header
 * - Header can be one of two sizes
 * @param element
 * @param type
 * @param attributes
 * @param children
 * @param editor
 * @constructor
 */
export const HeadingElement: FC<HeadingElementProps> = ({
  element,
  type,
  attributes,
  children,
  editor,
}) => {
  // const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);

  const [isDragging, setIsDragging] = useState(false);

  const renderStaticBlock = (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) => (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={getStyleForDroppable(provided.draggableProps.style, snapshot)}
    >
      {setIsDragging(snapshot.isDragging)}

      <EditableBlockContainer
        element={element}
        dragHandleProps={provided.dragHandleProps}
        {...attributes}
        editor={editor}
      >
        {/*<ClickAwayListener onClickAway={handleClose}>*/}
        <StyledParagraph
          {...attributes}
          style={CUSTOM_HEADER_THEME_FOR_RESOURCE[type]}
        >
          {!children ||
            //  @ts-expect-error
            (children.length === 1 && children[0].props.text.text === "" && (
              <span
                style={{
                  opacity: 0.3,
                  position: "absolute",
                  margin: "auto",
                  cursor: "text",
                  userSelect: "none",
                  pointerEvents: "none",
                  fontWeight: CUSTOM_HEADER_THEME_FOR_RESOURCE[type].fontWeight,
                  fontSize: CUSTOM_HEADER_THEME_FOR_RESOURCE[type].fontSize,
                  letterSpacing:
                    CUSTOM_HEADER_THEME_FOR_RESOURCE[type].letterSpacing,
                }}
                contentEditable={false}
              >
                Type to edit heading {type === "ONE" ? "1" : "2"}
              </span>
            ))}
          {children}
        </StyledParagraph>
      </EditableBlockContainer>
    </div>
  );

  const renderDraggingMenuCard = (provided, snapshot) => (
    <DraggingBlock
      provided={provided}
      snapshot={snapshot}
      setIsDragging={setIsDragging}
      label={`Heading ${type.toLocaleLowerCase()}`}
    />
  );

  return (
    <Draggable
      key={element.id}
      draggableId={element.id}
      index={path[path.length - 1]}
    >
      {(provided, snapshot) =>
        isDragging
          ? renderDraggingMenuCard(provided, snapshot)
          : renderStaticBlock(provided, snapshot)
      }
    </Draggable>
  );
};

const StyledParagraph = styled("p")(({ theme }) => ({
  display: "inline",
  cursor: "text",
}));
