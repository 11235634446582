import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useState } from "react";
import { useIsMobile } from "../util/useIsMobile";

interface LinkForCopyingBoxProps {
  link: string;
  disabled?: boolean;
}

export const LinkForCopyingBox: FC<LinkForCopyingBoxProps> = ({
  link,
  disabled,
}) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const isTabletOrMobile = useIsMobile();

  return (
    <StyledLinkForCopying>
      {isTabletOrMobile ? (
        <Button
          disabled={disabled}
          variant={linkCopied ? "outlined" : "contained"}
          color={linkCopied ? "success" : "primary"}
          sx={{ marginLeft: "20px" }}
          onClick={() => {
            navigator.clipboard.writeText(link);
            setLinkCopied(true);
          }}
        >
          {linkCopied ? "Copied" : "Copy link"}
        </Button>
      ) : (
        <StyledLinkForCopyingBox>
          <Typography sx={{ overflow: "scroll" }}>{link}</Typography>
          <Button
            disabled={disabled}
            variant={linkCopied ? "outlined" : "contained"}
            color={linkCopied ? "success" : "primary"}
            sx={{ marginLeft: "20px" }}
            onClick={() => {
              navigator.clipboard.writeText(link);
              setLinkCopied(true);
            }}
          >
            {linkCopied ? "Copied" : "Copy link"}
          </Button>
        </StyledLinkForCopyingBox>
      )}
    </StyledLinkForCopying>
  );
};

const StyledLinkForCopying = styled("div")`
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
`;

const StyledLinkForCopyingBox = styled("div")`
  display: flex;
  border-radius: 8px;
  padding: 0.2em 0.5em;
  background-color: #51515126;
  align-items: center;
  max-width: 100%;
`;
