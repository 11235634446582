// Define our own custom set of helpers.
import { OPERATION_TYPE } from "@altra-apps/common/src/redux/user/types";
import { COLORS } from "@altra-apps/common/src/util/colors";
import {
  CustomElement,
  LinkElement,
  MathElement,
} from "@altra-apps/common/src/util/custom-editor-types";
import { BLOCK_TYPES } from "@altra-apps/common/src/util/custom-types";
import {
  getUniqueId,
  objectWithoutKey,
} from "@altra-apps/common/src/util/helpers";
import { LIST_TYPES } from "@altra-apps/common/src/util/listTypes";
import {
  Descendant,
  Editor,
  Element as SlateElement,
  Node,
  Operation,
  Path,
  Range,
  Transforms,
} from "slate";

export const FORMAT_TYPE = {
  BOLD: "bold",
  ITALIC: "italic",
  UNDERLINE: "underline",
  CODE: "code",
  MATH: "math",
  LINK: "link",
  BULLETED_LIST: "bulleted-list",
  NUMBERED_LIST: "numbered-list",
};

/**
 * Retrieves a node from a given path within the provided resource
 * @param resource
 * @param index
 * @param includeTextNode
 */
export const getNodeFromPath = (
  resource: CustomElement[],
  index: number[],
  includeTextNode?: boolean
) => {
  let curr: CustomElement | undefined = Array.from(resource)[0];

  const limit = includeTextNode ? index.length : index.length - 1;

  for (let i = 1; i < limit; i++) {
    try {
      //@ts-expect-error
      curr = curr.children[index[i]];
    } catch (e) {
      return undefined;
    }
  }
  return curr;
};

/**
 * Gets the type of an element
 * @param resource
 * @param index
 */
const getTypeOfElementFromPath = (
  resource: CustomElement[],
  index: number[]
) => {
  return getNodeFromPath(resource, index)?.type;
};

/**
 * Removes any sections from anything other than a resource node
 * @param editor
 * @param node
 * @param path
 */
const removeSectionsFromNodes = (editor: Editor, node: Node, path: Path) => {
  try {
    // If the element is not a resource node, remove any section nodes.
    if (SlateElement.isElement(node) && node.type !== BLOCK_TYPES.RESOURCE) {
      if (node.children.some((child) => child.type === BLOCK_TYPES.SECTION)) {
        Transforms.removeNodes(editor, {
          at: path,
          // @ts-expect-error
          match: (node1, path1) => node1.type === BLOCK_TYPES.SECTION,
        });
      }
    }
  } catch (e) {
    // console.log("Custom normalising - Could not remove section from node", e);
  }
};

/**
 * Removes any resources from anything other than a resource node
 * @param editor
 * @param node
 * @param path
 */
const removeResourcesFromNodes = (editor: Editor, node: Node, path: Path) => {
  try {
    Transforms.removeNodes(editor, {
      at: path,
      match: (node1, path1) =>
        // @ts-expect-error
        node1.type === BLOCK_TYPES.RESOURCE && path1.length !== 1,
    });
  } catch (e) {
    // console.log("Custom normalising - Could not remove section from node", e);
  }
};

/**
 * Removes passages from nodes that are not sections
 * @param editor
 * @param node
 * @param path
 */
const removePassagesFromNodes = (editor: Editor, node: Node, path: Path) => {
  try {
    // If the element is not a section node or a question node, remove any passage nodes.
    if (
      SlateElement.isElement(node) &&
      node.type !== BLOCK_TYPES.SECTION &&
      SlateElement.isElement(node) &&
      node.type !== BLOCK_TYPES.QUESTION_TEXT_ONLY
    ) {
      if (node.children.some((child) => child.type === BLOCK_TYPES.PASSAGE)) {
        Transforms.removeNodes(editor, {
          at: path,
          // @ts-expect-error
          match: (node1, path1) => node1.type === BLOCK_TYPES.PASSAGE,
        });
      }
    }
  } catch (e) {
    // console.log("Custom normalising - Could not remove passage from node", e);
  }
};

/**
 * Removes question nodes from passages
 * @param editor
 * @param node
 * @param path
 */
const removeQuestionsFromPassages = (
  editor: Editor,
  node: Node,
  path: Path
) => {
  try {
    // If the element is a passage node, remove any question nodes.
    if (SlateElement.isElement(node) && node.type === BLOCK_TYPES.PASSAGE) {
      if (
        node.children.some(
          (child) =>
            // @ts-expect-error
            child.type === BLOCK_TYPES.QUESTION_TEXT_ONLY ||
            // @ts-expect-error
            child.type === BLOCK_TYPES.QUESTION_WITH_SUB_PART
        )
      ) {
        Transforms.removeNodes(editor, {
          at: path,
          match: (node1, path1) =>
            // @ts-expect-error
            node1.type === BLOCK_TYPES.QUESTION_WITH_SUB_PART ||
            // @ts-expect-error
            node1.type === BLOCK_TYPES.QUESTION_TEXT_ONLY,
        });
      }
    }
  } catch (e) {
    // console.log(
    //   "Custom normalising - Could not remove question from passage",
    //   e
    // );
  }
};

/**
 * Removes question nodes from questions
 * @param editor
 * @param node
 * @param path
 */
const removeQuestionsFromQuestions = (
  editor: Editor,
  node: Node,
  path: Path
) => {
  try {
    // If the element is a passage node, remove any question nodes.
    if (
      SlateElement.isElement(node) &&
      (node.type === BLOCK_TYPES.QUESTION_TEXT_ONLY ||
        node.type === BLOCK_TYPES.QUESTION_WITH_SUB_PART)
    ) {
      if (
        node.children.some(
          (child) =>
            child.type === BLOCK_TYPES.QUESTION_TEXT_ONLY ||
            child.type === BLOCK_TYPES.QUESTION_WITH_SUB_PART
        )
      ) {
        Transforms.removeNodes(editor, {
          at: path,
          match: (node1, path1) =>
            // @ts-expect-error
            node1.type === BLOCK_TYPES.QUESTION_WITH_SUB_PART ||
            // @ts-expect-error
            node1.type === BLOCK_TYPES.QUESTION_TEXT_ONLY,
        });
      }
    }
  } catch (e) {
    // console.log(
    //   "Custom normalising - Could not remove question from passage",
    //   e
    // );
  }
};

/**
 * Custom normaliser for the editor
 * @param editor
 * @param toggleToolBar
 * @param appendToQueueForDatabaseUpdates
 */
export const withCustomNormaliser = (
  editor,
  toggleToolBar: (elementType: string | undefined) => void,
  appendToQueueForDatabaseUpdates: (type: OPERATION_TYPE, ids: string[]) => void
) => {
  const { insertData, normalizeNode, apply } = editor;

  editor.apply = (operation: Operation) => {
    // console.log("NORMALISER APPLY", operation);

    //On selection of a node, update redux to ensure correct toolbar is shown for block
    if (
      operation.type === "set_selection" &&
      operation!.newProperties!.focus?.path
    ) {
      toggleToolBar(
        getTypeOfElementFromPath(
          editor.children,
          operation!.newProperties!.focus?.path
        )
      );
    }
    //On splitting of two text lines, ensures new paragraph has unique ID triggering a save in the database
    //@ts-expect-error
    else if (operation.type === "split_node" && operation.properties.id) {
      operation.properties = JSON.parse(
        JSON.stringify(
          objectWithoutKey(operation.properties, ["doNotCreateInDb"])
        )
      );
      const nodeId = getNodeFromPath(editor.children, operation.path)?.id;
      nodeId &&
        appendToQueueForDatabaseUpdates(OPERATION_TYPE.split_node, [nodeId]);
      const newId = getUniqueId();
      Transforms.setNodes(
        editor,
        { id: newId, teacherNotes: "" },
        { at: operation.path }
      );
    } else if (operation.type === "merge_node") {
      const nodeId = getNodeFromPath(editor.children, operation.path)?.id;
      nodeId &&
        appendToQueueForDatabaseUpdates(OPERATION_TYPE.merge_node, [nodeId]);
      // if (!getNodeFromPath(editor, operation.path, true)) return;
    } else if (operation.type === "set_node") {
      //Trying to prevent nodes from merging when not same type

      if (
        //@ts-expect-error
        !operation.properties.text &&
        //@ts-expect-error
        operation.newProperties.type !== operation.properties.type
      )
        return;

      let includeTextNode = false;
      if (
        //@ts-expect-error
        operation.newProperties.title ||
        //@ts-expect-error
        operation.newProperties.description ||
        //@ts-expect-error
        operation.newProperties.whiteboardImageS3Url ||
        //@ts-expect-error
        operation.newProperties.width
      ) {
        includeTextNode = true;
      }

      const nodeId = getNodeFromPath(
        editor.children,
        operation.path,
        includeTextNode
      )?.id;
      // console.log(nodeId);
      nodeId &&
        appendToQueueForDatabaseUpdates(OPERATION_TYPE.set_node, [nodeId]);
      try {
        //@ts-expect-error
        const ogNodeId = operation.properties.id;
        //@ts-expect-error
        const newNodeId = operation.newProperties.id;
        nodeId &&
          appendToQueueForDatabaseUpdates(OPERATION_TYPE.set_node, [
            ogNodeId,
            newNodeId,
          ]);
      } catch (e) {
        console.log(e);
      }
    } else if (operation.type === "insert_text") {
      // console.log(getNodeFromPath(editor.children, operation.path)?.id);
      const nodeId = getNodeFromPath(editor.children, operation.path)?.id;
      nodeId &&
        appendToQueueForDatabaseUpdates(OPERATION_TYPE.insert_text, [nodeId]);
    } else if (operation.type === "remove_text") {
      // console.log(getNodeFromPath(editor.children, operation.path)?.id);
      const nodeId = getNodeFromPath(editor.children, operation.path)?.id;
      nodeId &&
        appendToQueueForDatabaseUpdates(OPERATION_TYPE.remove_text, [nodeId]);
    } else if (operation.type === "insert_node") {
      // console.log(operation);
      try {
        //@ts-expect-error
        operation.node.id &&
          appendToQueueForDatabaseUpdates(OPERATION_TYPE.insert_node, [
            //@ts-expect-error
            operation.node.id,
          ]);
      } catch (e) {
        console.log(e);
      }
    } else if (operation.type === "remove_node") {
      // console.log(operation);
      try {
        //@ts-expect-error
        operation.node.id &&
          appendToQueueForDatabaseUpdates(OPERATION_TYPE.remove_node, [
            //@ts-expect-error
            operation.node.id,
          ]);
      } catch (e) {
        console.log(e);
      }
    } else if (operation.type === "move_node") {
      // console.log(operation);
      try {
        const nodeId = getNodeFromPath(editor.children, operation.path)?.id;
        nodeId &&
          appendToQueueForDatabaseUpdates(OPERATION_TYPE.move_node, [nodeId]);
      } catch (e) {
        console.log(e);
      }
    }

    return apply(operation);
  };

  /**
   * Prevents certain nodes being added to the wrong place
   * - Prevents section nodes being added as a child to anything other than a resource
   * @param entry
   */
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // console.log("NORMALISER NORMALISE", entry);

    removeResourcesFromNodes(editor, node, path);
    removeSectionsFromNodes(editor, node, path);
    removePassagesFromNodes(editor, node, path);
    removeQuestionsFromPassages(editor, node, path);
    removeQuestionsFromQuestions(editor, node, path);

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  return editor;
};

/**
 * This is wrapped around the slate react editor to support inline blocks
 * - We currently use maths and links as our inline blocks
 * - Inline blocks are more complex than leaves yet displayed inline unlike blocks
 * - See an example here: https://www.slatejs.org/examples/inlines
 * @param editor
 */
export const withInlines = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: CustomElement) =>
    ["link", "math"].includes(element.type) || isInline(element);

  editor.insertText = (text: string) => {
    insertText(text);
  };

  editor.insertData = (data: any) => {
    insertData(data);
  };

  return editor;
};

/**
 * All the custom functions attached to the editor which have been extracted for use in other components
 */
export const CustomEditorExtractedLogic = {
  isMarkActive(
    editor: Editor,
    formatType: typeof FORMAT_TYPE[keyof typeof FORMAT_TYPE]
  ) {
    const marks = Editor.marks(editor);
    return marks ? marks[formatType] === true : false;
  },
  isAnyColorActive(editor: Editor) {
    const marks = Editor.marks(editor);
    return (
      marks && Object.keys(marks)?.some((k) => Object.keys(COLORS).includes(k))
    );
  },
  activeColor(editor: Editor) {
    const marks = Editor.marks(editor);
    const isColor =
      marks && Object.keys(marks)?.some((k) => Object.keys(COLORS).includes(k));
    return isColor
      ? Object.keys(marks)?.find((k) => Object.keys(COLORS).includes(k))
      : "BLACK";
  },
  activeHighlight(editor: Editor) {
    const marks = Editor.marks(editor);
    const highlightColors = Object.keys(COLORS).map((c) => `${c}_background`);
    const isColor =
      marks && Object.keys(marks)?.some((k) => highlightColors.includes(k));
    return isColor
      ? Object.keys(marks)?.find((k) => highlightColors.includes(k))
      : "BLACK";
  },
  toggleMark(
    editor: Editor,
    formatType: typeof FORMAT_TYPE[keyof typeof FORMAT_TYPE]
  ) {
    const isActive = CustomEditorExtractedLogic.isMarkActive(
      editor,
      formatType
    );

    if (isActive) {
      Editor.removeMark(editor, formatType);
    } else {
      Editor.addMark(editor, formatType, true);
    }
  },

  toggleColorMark(
    editor: Editor,
    color: keyof typeof COLORS,
    background: boolean
  ) {
    let exitFunction = false;
    const marks = Editor.marks(editor);

    if (marks) {
      //First checks if the current color is an active mark and if it is, removes it
      for (let i = 0; i < Object.keys(marks).length; i++) {
        if (
          Object.keys(marks)[i] === `${color}${background ? "_background" : ""}`
        ) {
          Editor.removeMark(
            editor,
            `${color}${background ? "_background" : ""}`
          );
          exitFunction = true;
          break;
        }
      }

      //If the current mark is not active then removes any other color marks and adds current color mark
      if (!exitFunction) {
        ["", "_background"].map((markSuffix) =>
          Object.keys(COLORS).map((loopColor) => {
            Editor.removeMark(editor, `${loopColor}${markSuffix}`);
          })
        );
        Editor.addMark(
          editor,
          `${color}${background ? "_background" : ""}`,
          true
        );
      }
    }
  },

  isMathActive(editor: Editor) {
    const [link] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "math",
    });
    return !!link;
  },
  isLinkActive(editor: Editor) {
    const [link] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
    return !!link;
  },
  wrapMath(editor: Editor, latex: string) {
    if (CustomEditorExtractedLogic.isMathActive(editor)) {
      return;
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const math: MathElement = {
      id: getUniqueId(),
      type: BLOCK_TYPES.MATH,
      latex,
      children: isCollapsed ? [{ text: latex }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, math);
    } else {
      Transforms.wrapNodes(editor, math, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  },
  wrapLink(editor: Editor, url: string) {
    if (CustomEditorExtractedLogic.isLinkActive(editor)) {
      return;
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link: LinkElement = {
      id: getUniqueId(),
      type: BLOCK_TYPES.LINK,
      url: url || "",
      children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  },
  unwrapLink(editor: Editor) {
    if (!CustomEditorExtractedLogic.isLinkActive(editor)) {
      return;
    }

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
  },
  insertMath(editor: Editor, math: string) {
    if (editor.selection) {
      CustomEditorExtractedLogic.wrapMath(editor, math);
    }
  },
  insertLink(editor: Editor, url: string) {
    if (editor.selection) {
      CustomEditorExtractedLogic.wrapLink(editor, url);
    }
  },
  isBlockActive(
    editor: Editor,
    formatType: typeof FORMAT_TYPE[keyof typeof FORMAT_TYPE]
  ) {
    const [match] = Editor.nodes(editor, {
      match: (n) => "type" in n && (n.type === formatType || false),
    });
    return !!match;
  },
  toggleBlock(
    editor: Editor,
    formatType: typeof FORMAT_TYPE[keyof typeof FORMAT_TYPE]
  ) {
    const isActive = CustomEditorExtractedLogic.isBlockActive(
      editor,
      formatType
    );
    const isList = LIST_TYPES.includes(formatType);

    Transforms.setNodes(
      editor,
      //TODO(JACK): Error caused by answer field being added to types - usnure why
      //@ts-expect-error
      { type: isActive ? "paragraph" : isList ? "list-item" : formatType },
      { match: (n) => Editor.isBlock(editor, n) }
    );

    if (!isActive && isList) {
      const block: CustomElement = {
        id: "LIST-TEST",
        //TODO(JACK): Error caused by answer field being added to types - usnure why
        //@ts-expect-error
        type: formatType,
        children: [],
      };
      Transforms.wrapNodes(editor, block);
    }
  },
};

export const withCustomSubmissionNormaliser = (editor) => {
  const { insertData, normalizeNode, apply } = editor;

  editor.apply = (operation: Operation) => {
    if (operation.type === "remove_node") {
      try {
        return apply(operation);
      } catch (e) {
        console.log(e);
      }
    }
    if (operation.type === "merge_node") {
      try {
        if (!getNodeFromPath(editor.children, [0].concat(operation.path)))
          return;
      } catch (e) {
        console.log(e);
      }
    }
    if (operation.type === "remove_text") {
      try {
        return apply(operation);
      } catch (e) {
        console.log(e);
      }
    }
    if (operation.type === "split_node") {
      try {
        Transforms.select(editor, {
          path: [operation.path[0] + 1],
          offset: 0,
        });
      } catch (e) {}
    }
    return apply(operation);
  };

  /**
   * Prevents certain nodes being added to the wrong place
   * - Prevents section nodes being added as a child to anything other than a resource
   * @param entry
   */
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // console.log("NORMALISER NORMALISE", entry);

    removeResourcesFromNodes(editor, node, path);
    removeSectionsFromNodes(editor, node, path);
    removePassagesFromNodes(editor, node, path);
    removeQuestionsFromPassages(editor, node, path);
    removeQuestionsFromQuestions(editor, node, path);

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  return editor;
};

/**
 * Ensures all nodes within initial value have a child paragraph node
 * @param value
 */
export const normaliseInitialValue = (value: Descendant[]): Descendant[] => {
  const resource = value[0];

  //@ts-expect-error
  resource.children.map((childSection) => {
    normaliseChild(childSection);
  });

  return value;
};

export const normaliseChild = (value: Descendant[]): Descendant[] => {
  if (
    //@ts-expect-error
    (!value.children || value.children.length === 0) &&
    !Object.keys(value).some((key) => key === "text")
  ) {
    //@ts-expect-error
    value.children = [
      {
        type: BLOCK_TYPES.PARAGRAPH,
        id: getUniqueId(),
        children: [{ text: "" }],
      },
    ];
    return value;
  }
  //@ts-expect-error
  value.children?.map((child) => normaliseChild(child));

  return value;
};

export const onKeyDownSubmission = (event: any, editor: Editor) => {
  const { selection } = editor;

  if (!event.ctrlKey && !event.metaKey) {
    return;
  }
  switch (event.key) {
    // When "B" is pressed, bold the text in the selection.
    case "b": {
      event.preventDefault();
      CustomEditorExtractedLogic.toggleMark(editor, FORMAT_TYPE.BOLD);
      break;
    } // When "B" is pressed, bold the text in the selection.
    case "u": {
      event.preventDefault();
      CustomEditorExtractedLogic.toggleMark(editor, FORMAT_TYPE.UNDERLINE);
      break;
    }
    case "i": {
      event.preventDefault();
      CustomEditorExtractedLogic.toggleMark(editor, FORMAT_TYPE.ITALIC);
      break;
    }
    case "m": {
      event.preventDefault();
      CustomEditorExtractedLogic.insertMath(editor, "");
      break;
    }
  }
};
