import { FormControlLabel, Switch, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Block as AwsBlock,
  GetBlocksWithChildrenQueryVariables,
  useGetBlocksWithChildrenLazyQuery,
} from "../../graphql/types";
import { updateLoadingStatus } from "../../redux/applicationContext/actions";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { userAppInfoExplorerBlocksChildren } from "../../redux/user/actions";
import { Block, ToggleAnswers } from "../../redux/user/types";
import { FONT_WEIGHTS } from "../../styling/altra-theme";
import { AnswerElement } from "../../util/custom-editor-types";
import { Status } from "../../util/custom-types";
import { sortChildrenIntoBlocks } from "../../util/helpers";
import { renderViewOnlyBlockForBlockType } from "../../util/render-view-only-block-for-block-type";

type ViewAnswerElementProps = {
  block: AnswerElement;
  viewWatermark?: boolean;
  displayBorder?: boolean;
  showPreviewIcon?: boolean;
  doNotLoadChildren?: boolean;
  submissionView?: "SUBMISSION" | "REVIEW" | "REVIEWED";
};

/**
 * View only element for displaying Answer blocks
 * @param block
 * @param viewWatermark
 * @param displayBorder
 * @param showPreviewIcon
 * @param doNotLoadChildren
 * @param submissionView
 * @constructor
 */
export const ViewAnswerElement: FC<ViewAnswerElementProps> = ({
  block,
  viewWatermark,
  displayBorder,
  showPreviewIcon,
  doNotLoadChildren,
  submissionView,
}) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const teacherOrStudent = useAppSelector(
    (state) => state.userAppInfo.teacherOrStudent
  );
  const toggleAnswer: ToggleAnswers = useAppSelector(
    (state) => state.userAppInfo?.toggleAnswers
  );
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const history = useHistory();
  const isSubmissionScreen = history.location.pathname.includes("/reviewed");

  useEffect(() => {
    if (isSubmissionScreen) {
      setShowAnswer(true);
    } else if (toggleAnswer === "OPEN_ALL_PENDING") {
      setShowAnswer(true);
    } else if (toggleAnswer === "CLOSE_ALL_PENDING") {
      setShowAnswer(false);
    } else if (teacherOrStudent === "TEACHER") {
      setShowAnswer(true);
    }
  }, [teacherOrStudent, toggleAnswer, isSubmissionScreen]);

  const [childrenLoading, setChildrenLoading] = useState(true);
  const userExplorerBlocksChildren: { [parentId: number]: Array<Block> } =
    useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);

  const [
    getBlocksWithChildrenCallback,
    { data: getChildrenData, error: getChildrenError },
  ] = useGetBlocksWithChildrenLazyQuery();

  /**
   * Initial load of all blocks for selected curriculum and topics
   */
  useEffect(() => {
    if (!doNotLoadChildren) {
      setChildrenLoading(true);
      getAllChildren();
    } else {
      setChildrenLoading(false);
    }
  }, []);

  const getAllChildren = async () => {
    let getChildrenInput: GetBlocksWithChildrenQueryVariables = {
      ids: `{${block.id}}`,
    };
    getBlocksWithChildrenCallback({
      variables: getChildrenInput,
    });
  };

  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    if (getChildrenError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get child blocks",
        })
      );
    } else if (getChildrenData && getChildrenData) {
      const childBlocksAll: AwsBlock[] =
        getChildrenData.get_blocks_with_children.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        ) as AwsBlock[];
      const childBlocksForRedux: { id: number; children: AwsBlock[] }[] =
        sortChildrenIntoBlocks(parseInt(block.id), childBlocksAll);

      console.log(block);
      console.log(childBlocksForRedux);

      dispatch(
        userAppInfoExplorerBlocksChildren(
          childBlocksForRedux.map((cb) => ({
            parentId: cb.id,
            children:
              cb.children.map((block) => ({
                id: block.id,
                tags: block.tags.map((tag) => ({
                  id: tag.id,
                  topicId: tag.topic?.id,
                  label: tag.topic?.title || "",
                })),
                active: block.active,
                block: block.block,
                type: block.type,
                position: block.position,
                parent_id: block.parent_id,
                teacherNotes: block.note,
              })) || [],
          }))
        )
      );
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved child blocks",
        })
      );
    }
    setChildrenLoading(false);
  }, [getChildrenData, getChildrenError]);
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <StyledFormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              fontWeight: FONT_WEIGHTS.REGULAR_600,
              fontSize: 13,
              color: theme.palette.primary.main,
            },
          }}
          value="start"
          color={"primary"}
          control={
            <Switch
              onChange={(event, checked) => setShowAnswer(checked)}
              checked={showAnswer}
              color="primary"
            />
          }
          label="Show answer"
          labelPlacement="start"
        />
      </div>
      <StyledAnswerSection sx={{ display: showAnswer ? "flex" : "none" }}>
        <StyledLabel variant={"subtitle2"}>Answer</StyledLabel>
        <StyledAnswerFieldContainer>
          {userExplorerBlocksChildren[block.id]
            ?.filter((child) => child.block["type"] === "answer-text")
            .map((b) =>
              renderViewOnlyBlockForBlockType(
                b,
                displayBorder,
                viewWatermark,
                showPreviewIcon,
                true,
                submissionView
              )
            )}
        </StyledAnswerFieldContainer>
        <StyledLabel variant={"subtitle2"}>Marks</StyledLabel>
        <StyledMarksContainer>
          <Typography sx={{ margin: "20px" }}>{block.marks}</Typography>
        </StyledMarksContainer>

        <StyledLabel variant={"subtitle2"}>Marking Instructions</StyledLabel>
        <StyledLabel variant={"body2"}>Generic Instructions</StyledLabel>
        <StyledAnswerFieldContainer>
          {userExplorerBlocksChildren[block.id]
            ?.filter((child) => child.block["type"] === "generic-guidance")
            .map((b) =>
              renderViewOnlyBlockForBlockType(
                b,
                displayBorder,
                viewWatermark,
                showPreviewIcon,
                true,
                submissionView
              )
            )}
        </StyledAnswerFieldContainer>
        <StyledLabel variant={"body2"}>Additional Instructions</StyledLabel>
        <StyledAnswerFieldContainer>
          {userExplorerBlocksChildren[block.id]
            ?.filter((child) => child.block["type"] === "additional-guidance")
            .map((b) =>
              renderViewOnlyBlockForBlockType(
                b,
                displayBorder,
                viewWatermark,
                showPreviewIcon,
                true
              )
            )}
        </StyledAnswerFieldContainer>
      </StyledAnswerSection>
    </div>
  );
};

const StyledAnswerSection = styled("div")(({ theme }) => ({
  display: "flex",
  backgroundColor: "#F5F5F5",
  flexDirection: "column",
  borderRadius: "8px",
  margin: "10px",
  padding: "10px",
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  userSelect: "none",
  marginLeft: "0.5em",
  marginBottom: "0.2em",
  marginTop: "1em",
}));
const StyledAnswerFieldContainer = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "white",
  minHeight: "1em",
  display: "flex",
  padding: "1em",
}));
const StyledMarksContainer = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "white",
  minHeight: "1em",
  display: "flex",
}));
const StyledFormControlLabel = styled(FormControlLabel)`
  @media print {
    display: none;
  }
`;
