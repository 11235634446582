import {
  useGetSubmissionLazyQuery,
  useUpdateSubmissionMutation,
} from "@altra-apps/common/src/graphql/types";
import { updateLoadingStatus } from "@altra-apps/common/src/redux/applicationContext/actions";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import { SubmissionIdNameTitle } from "@altra-apps/common/src/redux/user/types";
import { APP_URL } from "@altra-apps/common/src/styling/constants";
import { Status } from "@altra-apps/common/src/util/custom-types";
import CloseIcon from "@mui/icons-material/Close";
import { Skeleton } from "@mui/lab";
import {
  Button,
  CardContent,
  Dialog,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LinkForCopyingBox } from "../atoms/StyledLinkForCopying";
import { LoadingStatus } from "../redux/applicationContext/types";
import { userAppInfoUpdateSubmission } from "../redux/user/actions";
import { altraProgressManagerTheme } from "../styling/altra-progress-manager-theme";
import { altraResourceBuilderTheme } from "../styling/altra-resource-builder-theme";
import { getUnionOfValuesForKeys } from "../util/helpers";
import { useDebounce } from "../util/use-debounce";
import { useIsMobile } from "../util/useIsMobile";
enum TitleStatus {
  LOADING,
}

type TextForPopper = {
  PUBLISHED_BUTTON_TEXT: string;
  UNPUBLISHED_BUTTON_TEXT: string;
  BUTTON_TOOLTIP: string;
  POPPER_TITLE: string;
  SHARE_TEXT: string;
  URL_TEXT: string;
};
enum SubmissionType {
  "PROVIDING_FEEDBACK",
  "ASKING_FOR_FEEDBACK",
}

interface ShareSubmissionDialogProps {
  disabled?: boolean;
}

/**
 * Screen to toggle whether or not resource is public
 * @constructor
 */
export const ShareSubmissionDialog: FC<ShareSubmissionDialogProps> = ({
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const routerParams = useParams();
  const history = useHistory();

  const reduxSubmissionDetails: SubmissionIdNameTitle | undefined =
    useAppSelector((state) => {
      const submission = getUnionOfValuesForKeys(
        state.userAppInfo.submissions
      ).find(
        //@ts-expect-error
        (b) => b.submissionId === parseInt(routerParams?.id)
      );
      return submission;
    });

  const submissionType: SubmissionType = history.location.pathname.includes(
    "/review/"
  )
    ? SubmissionType.PROVIDING_FEEDBACK
    : SubmissionType.ASKING_FOR_FEEDBACK;

  const theme = useTheme();
  const [
    getSubmissionCallback,
    { data: getSubmissionData, error: getSubmissionError },
  ] = useGetSubmissionLazyQuery();

  const [linkCopied, setLinkCopied] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const loadingStatus: LoadingStatus = useAppSelector(
    (state) => state.applicationContext.loadingStatus
  );
  const [localRequesterName, setLocalRequesterName] = useState<
    string | TitleStatus.LOADING
  >(reduxSubmissionDetails?.requesterName || TitleStatus.LOADING);
  const [localMarkerName, setLocalMarkerName] = useState<
    string | TitleStatus.LOADING
  >(reduxSubmissionDetails?.markerName || TitleStatus.LOADING);
  const [localTitle, setLocalTitle] = useState<string | TitleStatus.LOADING>(
    reduxSubmissionDetails?.title || TitleStatus.LOADING
  );

  const [updateSubmissionMutation] = useUpdateSubmissionMutation();
  const [publishing, setPublishing] = useState<boolean>(false);

  const debouncedSubmission = useDebounce(reduxSubmissionDetails, 1500);

  /**
   * Updates the submission in the backend when changed in redux
   */
  const updateNameTitleInDb = async () => {
    dispatch(
      updateLoadingStatus({
        status: Status.LOADING,
        message: "Saving submission",
      })
    );
    //@ts-expect-error
    if (routerParams?.id && debouncedSubmission) {
      const result = await updateSubmissionMutation({
        variables: {
          //@ts-expect-error
          submission_id: routerParams?.id,
          submission_spec: {
            title: debouncedSubmission.title,
            requester_name: debouncedSubmission.requesterName,
            marker_name: debouncedSubmission.markerName,
          },
        },
      });
      if (
        result.data?.update_submission_by_pk &&
        result.data?.update_submission_by_pk.id &&
        result.data?.update_submission_by_pk.block_id
      ) {
        dispatch(
          userAppInfoUpdateSubmission({
            submissionId: result.data?.update_submission_by_pk.id,
            requesterName:
              result.data?.update_submission_by_pk.requester_name || "",
            markerName: result.data?.update_submission_by_pk.marker_name || "",
            title: result.data?.update_submission_by_pk.title || "",
          })
        );
      }
    }
    dispatch(
      updateLoadingStatus({
        status: Status.IDLE,
        message: "Saved submission",
      })
    );
  };

  useEffect(() => {
    console.log("Update submission details after debounce");
    updateNameTitleInDb();
  }, [debouncedSubmission]);

  const updateTitle = (newTitle: string) => {
    if (reduxSubmissionDetails) {
      dispatch(
        userAppInfoUpdateSubmission({
          ...reduxSubmissionDetails,
          title: newTitle,
        })
      );
    }
  };
  const updateRequesterName = (newName: string) => {
    console.log(reduxSubmissionDetails);
    if (reduxSubmissionDetails) {
      console.log("updating requester name");
      dispatch(
        userAppInfoUpdateSubmission({
          ...reduxSubmissionDetails,
          requesterName: newName,
        })
      );
    }
  };
  const updateMarkerName = (newName: string) => {
    if (reduxSubmissionDetails) {
      dispatch(
        userAppInfoUpdateSubmission({
          ...reduxSubmissionDetails,
          markerName: newName,
        })
      );
    }
  };

  useEffect(() => {
    setLocalTitle(reduxSubmissionDetails?.title || "");
    setLocalRequesterName(reduxSubmissionDetails?.requesterName || "");
    setLocalMarkerName(reduxSubmissionDetails?.markerName || "");
  }, [reduxSubmissionDetails]);

  useEffect(() => {
    //@ts-expect-error
    if (routerParams.id && open) {
      getSubmissionCallback({
        //@ts-expect-error
        variables: { id: parseInt(routerParams?.id) },
      });
    }
  }, [open]);
  useEffect(() => {
    //@ts-expect-error
    if (routerParams.id && open) {
      getSubmissionCallback({
        //@ts-expect-error
        variables: { id: parseInt(routerParams?.id) },
      });
    }
  }, []);

  useEffect(() => {
    if (getSubmissionError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get block",
        })
      );
    } else if (
      getSubmissionData &&
      getSubmissionData.submission_by_pk?.id &&
      getSubmissionData.submission_by_pk?.block_id
    ) {
      updateTitle(getSubmissionData.submission_by_pk?.title || "");
      updateRequesterName(
        getSubmissionData.submission_by_pk?.requester_name || ""
      );
      updateMarkerName(getSubmissionData.submission_by_pk?.marker_name || "");

      setPublished(
        getSubmissionData.submission_by_pk?.publicly_accessible || false
      );
      dispatch(
        userAppInfoUpdateSubmission({
          submissionId: getSubmissionData.submission_by_pk?.id,
          requesterName:
            getSubmissionData.submission_by_pk?.requester_name || "",
          markerName: getSubmissionData.submission_by_pk?.marker_name || "",
          title: getSubmissionData.submission_by_pk?.title || "",
        })
      );
    }
  }, [getSubmissionData, getSubmissionError, dispatch]);

  const [published, setPublished] = useState<boolean>(false);
  const isTabletOrMobile = useIsMobile();
  useEffect(() => {
    if (publishing) {
      dispatch(
        updateLoadingStatus({
          status: Status.LOADING,
          message: "Publishing",
        })
      );
    } else {
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Published",
        })
      );
    }
  }, [publishing]);

  useEffect(() => {
    onUpdateSubmission(true);
  }, []);

  /**
   *Update resource with published or not
   */
  const onUpdateSubmission = async (checked: boolean) => {
    setPublishing(true);

    if (
      //@ts-expect-error
      routerParams?.id &&
      localRequesterName !== TitleStatus.LOADING &&
      localTitle !== TitleStatus.LOADING
    ) {
      const { data, errors } = await updateSubmissionMutation({
        variables: {
          //@ts-expect-error
          submission_id: routerParams?.id,
          submission_spec: {
            title: localTitle || "",
            requester_name: localRequesterName || "",
            marker_name: localMarkerName || "",
            publicly_accessible: checked,
          },
        },
      });

      if (
        data &&
        data.update_submission_by_pk?.id &&
        data.update_submission_by_pk?.block_id
      ) {
        dispatch(
          userAppInfoUpdateSubmission({
            submissionId: data.update_submission_by_pk?.id,
            requesterName: data.update_submission_by_pk?.requester_name || "",
            markerName: data.update_submission_by_pk?.marker_name || "",
            title: data.update_submission_by_pk?.title || "",
          })
        );
      }
    }

    setPublishing(false);
    setPublished(checked);
  };

  return (
    <>
      {submissionType === SubmissionType.ASKING_FOR_FEEDBACK ? (
        <Tooltip title={"Request feedback using this link"}>
          <Button
            size="small"
            variant={"contained"}
            sx={{
              "&:hover": {
                backgroundColor: altraResourceBuilderTheme.palette.primary.main,
              },
              backgroundColor: altraResourceBuilderTheme.palette.primary.dark,
              color: altraResourceBuilderTheme.palette.primary.contrastText,
            }}
            onClick={() => setShareModalOpen(true)}
            disabled={loadingStatus.status === Status.LOADING || disabled}
          >
            Submit my answers
          </Button>
        </Tooltip>
      ) : (
        <Button
          sx={{
            "&:hover": {
              backgroundColor: altraProgressManagerTheme.palette.primary.main,
            },
            backgroundColor: altraProgressManagerTheme.palette.primary.dark,
            color: altraProgressManagerTheme.palette.primary.contrastText,
          }}
          variant={"contained"}
          onClick={() => setShareModalOpen(true)}
        >
          Submit feedback
        </Button>
      )}
      <Dialog fullScreen={isTabletOrMobile} open={shareModalOpen}>
        <StyledTopRow>
          <StyledTopRowContent>
            <IconButton
              onClick={() => setShareModalOpen(false)}
              style={{
                color: theme.palette.error.main,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </StyledTopRowContent>
        </StyledTopRow>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: isTabletOrMobile ? "none" : "500px",
            minHeight: "200px",
            justifyContent: "space-between",
          }}
        >
          <Typography id="modal-modal-title" variant="h1">
            {submissionType === SubmissionType.ASKING_FOR_FEEDBACK
              ? "Submit answers for feedback"
              : "Submit feedback"}
          </Typography>
          <div
            style={{
              marginTop: "1em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                color:
                  submissionType === SubmissionType.PROVIDING_FEEDBACK
                    ? altraProgressManagerTheme.palette.primary.main
                    : altraResourceBuilderTheme.palette.primary.main,
                marginBottom: "0.5em",
              }}
              variant="h2"
            >
              1. What is your name?
            </Typography>
            {submissionType === SubmissionType.ASKING_FOR_FEEDBACK && (
              <>
                {localRequesterName === TitleStatus.LOADING ? (
                  <Skeleton height={20} />
                ) : (
                  <TextField
                    sx={{ marginBottom: "20px" }}
                    error={!localRequesterName}
                    helperText={
                      !localRequesterName && "Submission must have a name"
                    }
                    value={localRequesterName}
                    onChange={(e) => updateRequesterName(e.target.value)}
                    label={"Your name"}
                  />
                )}
              </>
            )}
            {submissionType === SubmissionType.PROVIDING_FEEDBACK && (
              <>
                {localMarkerName === TitleStatus.LOADING ? (
                  <Skeleton height={20} />
                ) : (
                  <TextField
                    sx={{
                      marginBottom: "20px",
                      borderColor:
                        submissionType === SubmissionType.PROVIDING_FEEDBACK
                          ? altraProgressManagerTheme.palette.primary.main
                          : altraResourceBuilderTheme.palette.primary.main,
                    }}
                    error={!localMarkerName}
                    helperText={!localMarkerName && "Review must have a name"}
                    value={localMarkerName}
                    onChange={(e) => updateMarkerName(e.target.value)}
                    label={"Your name"}
                  />
                )}
              </>
            )}
          </div>

          <Typography
            id="modal-modal-title"
            sx={{
              color:
                submissionType === SubmissionType.PROVIDING_FEEDBACK
                  ? altraProgressManagerTheme.palette.primary.main
                  : altraResourceBuilderTheme.palette.primary.main,
              marginBottom: "0.5em",
            }}
            variant="h2"
          >
            2. Send this link to your teacher to request feedback
          </Typography>

          <LinkForCopyingBox
            link={`${process.env[APP_URL["Curriculum Explorer"]]}/
              ${
                submissionType === SubmissionType.ASKING_FOR_FEEDBACK
                  ? "review"
                  : "reviewed"
                //@ts-expect-error
              }/${routerParams?.id}`}
            disabled={
              (submissionType === SubmissionType.ASKING_FOR_FEEDBACK &&
                !localRequesterName) ||
              (submissionType === SubmissionType.PROVIDING_FEEDBACK &&
                !localMarkerName)
            }
          />
        </CardContent>
      </Dialog>
    </>
  );
};

const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
}));
