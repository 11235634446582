import { useAppDispatch } from "@altra-apps/common/src/redux/hook";
import { ToggleAnswers } from "@altra-apps/common/src/redux/user/types";
import CloseIcon from "@mui/icons-material/Close";
import {
  CardContent,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { userAppInfoToggleAnswers } from "../redux/user/actions";
import { altraTheme } from "../styling/altra-theme";
import { useIsMobile } from "../util/useIsMobile";

interface ResourceSettingsDialogProps {
  settingsModalOpen: boolean;
  setSettingsModalOpen: (open: boolean) => void;
}

/**
 * Screen to toggle whether or not resource is public
 * @constructor
 */
export const ResourceSettingsDialog: FC<ResourceSettingsDialogProps> = ({
  setSettingsModalOpen,
  settingsModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const routerParams = useParams();
  const history = useHistory();
  const theme = useTheme();
  const isTabletOrMobile = useIsMobile();

  const [localShowAnswerKey, setLocalShowAnswerKey] = useState(false);

  const updateShowAnswerKey = (value: ToggleAnswers) => {
    if (value === "OPEN_ALL_PENDING") setLocalShowAnswerKey(true);
    if (value === "CLOSE_ALL_PENDING") setLocalShowAnswerKey(false);
    dispatch(userAppInfoToggleAnswers(value));
  };

  return (
    <Dialog fullScreen={isTabletOrMobile} open={settingsModalOpen}>
      <StyledTopRow>
        <StyledTopRowContent>
          <IconButton
            onClick={() => setSettingsModalOpen(false)}
            style={{
              color: theme.palette.error.main,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledTopRowContent>
      </StyledTopRow>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: isTabletOrMobile ? "none" : "500px",
          justifyContent: "space-between",
        }}
      >
        <Typography id="modal-modal-title" variant="h1">
          Settings
        </Typography>
        <div
          style={{
            marginTop: "1em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <FormControl fullWidth={true}>
            <FormControlLabel
              contentEditable={false}
              sx={{
                "& .MuiFormControlLabel-label": altraTheme.typography.subtitle2,
              }}
              value="start"
              control={
                <Switch
                  onChange={(event, checked) =>
                    updateShowAnswerKey(
                      checked ? "OPEN_ALL_PENDING" : "CLOSE_ALL_PENDING"
                    )
                  }
                  checked={localShowAnswerKey}
                  color="primary"
                />
              }
              label="Show all answers"
              labelPlacement="end"
            />
          </FormControl>
        </div>
      </CardContent>
    </Dialog>
  );
};

const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
}));
