import CloseIcon from "@mui/icons-material/Close";
import { CardContent, Dialog, IconButton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC } from "react";
import QRCode from "react-qr-code";
import { LinkForCopyingBox } from "../atoms/StyledLinkForCopying";
import { useIsMobile } from "../util/useIsMobile";

interface ShareLinkAndQRModalProps {
  open: boolean;
  toggleOpen: (open: boolean) => void;
  id: number;
  type?: undefined | "linkOnly";
}

export const ShareLinkAndQRModal: FC<ShareLinkAndQRModalProps> = ({
  id,
  open,
  toggleOpen,
  type,
}) => {
  const theme = useTheme();
  const isTabletOrMobile = useIsMobile();

  return (
    <Dialog open={open}>
      <StyledTopRow>
        <StyledTopRowContent>
          <IconButton
            onClick={() => toggleOpen(false)}
            style={{
              color: theme.palette.error.main,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledTopRowContent>
      </StyledTopRow>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: isTabletOrMobile ? 0 : "500px",
          minHeight: type === "linkOnly" ? "inherit" : "200px",
          justifyContent: type === "linkOnly" ? "center" : "space-between",
        }}
      >
        <Typography id="modal-modal-title" variant="h2">
          Share Resource
        </Typography>
        <Typography sx={{ marginBottom: "30px" }} variant={"subtitle1"}>
          Share the link below with anyone to view this resource.
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <LinkForCopyingBox
            link={`${process.env.REACT_APP_CURRICULUM_EXPLORER_URL}/explorer/${id}`}
          />
          {type !== "linkOnly" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "auto",
                margin: "0 auto",
                width: "100%",
                marginBottom: "1em",
              }}
            >
              <QRCode
                value={`${process.env.REACT_APP_CURRICULUM_EXPLORER_URL}/explorer/${id}`}
              />
            </div>
          )}
        </div>
      </CardContent>
    </Dialog>
  );
};

const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
}));
