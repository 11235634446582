import { createTheme } from "@mui/material/styles";
import { altraTheme } from "./altra-theme";

export const altraResourceBuilderTheme = createTheme({
  ...altraTheme,
  ...{
    palette: {
      primary: {
        main: "#EC3F68",
        dark: "#D53964",
        light: "#D539641A",
        contrastText: "#FFF",
      },
    },
  },
});
