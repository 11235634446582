import { OpenInFull } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SettingsIcon from "@mui/icons-material/Settings";
import ShareIcon from "@mui/icons-material/Share";
import {
  Button,
  CardActions,
  CardContent,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Block_Insert_Input,
  CreateSubmissionMutationVariables,
  InsertTagsMutationVariables,
  useCreateBlockMutation,
  useCreateSubmissionMutation,
  useInsertTagsMutation,
} from "../../graphql/types";
import { ResourceSettingsDialog } from "../../organisms/ResourceSettingsDialog";
import { Topic } from "../../redux/applicationContext/types";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { userAppInfoCreateSubmission } from "../../redux/user/actions";
import {
  Block,
  ManualEditorBlockImport,
  UserInfo,
} from "../../redux/user/types";
import { ALTRA_APPS, BLOCK_TYPES } from "../../util/custom-types";
import {
  getTopicLabelIdsForTier,
  getUnionOfValuesForKeys,
  openInNewTab,
} from "../../util/helpers";
import { prettyBlockType } from "../../util/pretty-text";
import { useIsMobile } from "../../util/useIsMobile";
import { ShareAssignmentLink } from "../ShareAssignmentLink";
import { ShareLinkAndQRModal } from "../ShareLinkAndQRModal";
import { TextLink } from "../TextLink";

type ExplorerPreviewModalContainerProps = {
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  appName: ALTRA_APPS;
  block: Block;
};

/**
 * Modal container for a preview of the Block along with buttons to open in resource builder
 * @param open
 * @param handleClose
 * @param handleOpen
 * @constructor
 */
export const ExplorerPreviewModalContainer: FC<
  ExplorerPreviewModalContainerProps
> = ({ open, handleClose, block, children }) => {
  const isTabletOrMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext?.topics
  );
  const [creatingBlock, setCreatingBlock] = useState<number | null>(null);
  const verified = useAppSelector((state) => state.user.userInfo.verified);
  const history = useHistory();
  const theme = useTheme();
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  const routerParams = useParams();
  const teacherOrStudent = useAppSelector(
    (state) => state.userAppInfo.teacherOrStudent
  );
  const userExplorerBlocks: Array<Block> = useAppSelector(
    (state) => state.userAppInfo.userExplorerBlocks
  );
  const userExplorerBlocksChildren: { [parentId: number]: Array<Block> } =
    useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);
  const userId = useAppSelector((state) => state.user.userInfo.id);
  const selectedCurriculumsId: number[] | undefined = useAppSelector(
    (state) => state.userAppInfo.selectedCurriculumsId
  );
  const [creatingSubmission, setCreatingSubmission] = useState<boolean>(false);

  const [createSubmissionMutation] = useCreateSubmissionMutation();
  const manualEditorBlockImport: ManualEditorBlockImport = useAppSelector(
    (state) => state.userAppInfo.manualEditorBlockImport
  ) || {
    status: "INACTIVE",
  };
  const createSubmissionAndNavigate = async () => {
    setCreatingSubmission(true);
    const createVariables: CreateSubmissionMutationVariables = {
      submission_spec: {
        block_id: block.id,
        requester_user_id: user && user?.id ? user?.id : undefined,
        publicly_accessible: false,
      },
    };

    const { data, errors } = await createSubmissionMutation({
      variables: createVariables,
    });

    if (data && data?.insert_submission_one?.id) {
      dispatch(userAppInfoCreateSubmission(data?.insert_submission_one));
      history.push(`/submission/${data?.insert_submission_one?.id}`);
    }
    setCreatingSubmission(false);
  };

  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false);
  const [shareQrModalOpen, setShareQrModalOpen] = useState(false);
  const [resourceSettingsModalOpen, setResourceSettingsModalOpen] =
    useState(false);
  const [shareAssignmentModalOpen, setShareAssignmentModalOpen] =
    useState(false);

  const selectedSubject: number | undefined = useAppSelector((state) => {
    const topTierTopicLevelIds: number[] = getTopicLabelIdsForTier(
      state.userAppInfo.selectedCurriculumsId || [],
      state.applicationContext.topicLabels,
      1
    ).filter((tl) =>
      getUnionOfValuesForKeys(state.applicationContext.topicLabels).find(
        (tl2) => tl2.id == tl && tl2.title?.toLowerCase() !== "quick search"
      )
    );

    const topTierSelectedTopics = state?.userAppInfo?.selectedTopicsId
      ? state?.userAppInfo?.selectedTopicsId[topTierTopicLevelIds[0]]
      : [] || [];

    return topTierSelectedTopics ? topTierSelectedTopics[0] : undefined;
  });
  const [createBlockMutation] = useCreateBlockMutation();
  const [insertTagMutation] = useInsertTagsMutation();
  const insertTags = async (tags: InsertTagsMutationVariables) => {
    await insertTagMutation({
      variables: tags,
    });
  };

  /**
   * Created child blocks in the backend and iterates to create nested children
   * @param childBlock
   * @param parentId
   */
  const createChildBlockInBackend = async (
    childBlock: Block,
    parentId: number
  ) => {
    const hasChildren = userExplorerBlocksChildren[childBlock.id]?.length > 0;
    let blockInsertInput: Block_Insert_Input = {
      archived: false,
      active: true,
      block: childBlock.block,
      //@ts-expect-error
      type: childBlock.block.type,
      note: block.teacherNotes,
      created_time: Date.now(),
      has_children: hasChildren,
      publicly_accessible: false,
      last_edited_time: Date.now(),
      position: childBlock.position,
      parent_id: parentId,
      created_by_id: userId || null,
      verified: verified,
    };
    const response = await createBlockMutation({
      variables: {
        block_spec: blockInsertInput,
      },
    });
    if (response.errors) console.log(response.errors);
    if (response.data?.insert_block_one && hasChildren) {
      await insertTags({
        tags:
          childBlock.tags
            .filter((tag) => typeof tag.topicId === "number")
            .map((tag) => ({
              //@ts-expect-error
              block_id: response.data?.insert_block_one.id,
              topic_id: tag.topicId,
            })) || [],
      });
      await Promise.all(
        userExplorerBlocksChildren[childBlock.id]?.map(async (b1) => {
          if (response.data?.insert_block_one?.id) {
            await createChildBlockInBackend(
              b1,
              response.data?.insert_block_one?.id
            );
          }
        })
      );
    }
    return response;
  };

  /**
   * Create a copy of a block in the backend
   */
  const createExplorerBlockInBackend = async () => {
    let resourceDbId: number | undefined = undefined;
    let sectionDbId: number | undefined = undefined;

    //Create resource
    // - If block being duplicated is not a resource block, create an empty resource block to contain children
    // -  If block being duplicated is a resource block, duplicate it and return
    let blockInsertInputResource: Block_Insert_Input = {
      archived: false,
      active: true,
      //@ts-expect-error
      block: block.block.type === BLOCK_TYPES.RESOURCE ? block.block : {},
      type: BLOCK_TYPES.RESOURCE,
      created_time: Date.now(),
      has_children: true,
      publicly_accessible: false,
      last_edited_time: Date.now(),
      position: block.position,
      parent_id: null,
      created_by_id: userId || null,
      note:
        //@ts-expect-error
        block.block.type === BLOCK_TYPES.RESOURCE ? block.teacherNotes : null,
      verified: verified,
    };
    const responseResource = await createBlockMutation({
      variables: {
        block_spec: blockInsertInputResource,
      },
    });
    if (responseResource.errors) console.log(responseResource.errors);
    if (responseResource.data?.insert_block_one) {
      resourceDbId = responseResource.data.insert_block_one.id;
      if (
        //@ts-expect-error
        block.block.type === BLOCK_TYPES.RESOURCE &&
        responseResource.data?.insert_block_one?.id !== undefined
      ) {
        // console.log("DUPLICATING TAGS");
        await insertTags({
          tags:
            block.tags
              .filter((tag) => typeof tag.topicId === "number")
              .map((tag) => ({
                //@ts-expect-error
                block_id: responseResource.data?.insert_block_one.id,
                topic_id: tag.topicId,
              })) || [],
        });

        await Promise.all(
          userExplorerBlocksChildren[block.id].map(async (b1) => {
            if (responseResource?.data?.insert_block_one?.id) {
              await createChildBlockInBackend(
                b1,
                responseResource.data?.insert_block_one.id
              );
            }
          })
        );
        return resourceDbId;
      }
    }

    //If not a section block, create a section block to contain all child blocks and store ID
    if (
      //@ts-expect-error
      block.block.type !== BLOCK_TYPES.SECTION &&
      //@ts-expect-error
      block.block.type !== BLOCK_TYPES.RESOURCE
    ) {
      let blockInsertInputSection: Block_Insert_Input = {
        archived: false,
        active: true,
        block: {},
        type: BLOCK_TYPES.SECTION,
        created_time: Date.now(),
        has_children: true,
        publicly_accessible: false,
        last_edited_time: Date.now(),
        position: block.position,
        parent_id: resourceDbId,
        created_by_id: userId || null,
        verified: verified,
      };
      const responseSection = await createBlockMutation({
        variables: {
          block_spec: blockInsertInputSection,
        },
      });
      if (responseSection.errors) console.log(responseSection.errors);
      if (responseSection.data?.insert_block_one)
        sectionDbId = responseSection.data.insert_block_one.id;
    }

    const hasChildren = userExplorerBlocksChildren[block.id].length > 0;

    //Create explorer block
    let blockInsertInput: Block_Insert_Input = {
      archived: false,
      active: true,
      block: block.block,
      type: block.type,
      created_time: Date.now(),
      has_children: hasChildren,
      publicly_accessible: false,
      last_edited_time: Date.now(),
      position: block.position,
      parent_id:
        //@ts-expect-error
        block.block.type === BLOCK_TYPES.SECTION ? resourceDbId : sectionDbId,
      created_by_id: userId || null,
      note: block.teacherNotes,
      verified: verified,
    };
    const response = await createBlockMutation({
      variables: {
        block_spec: blockInsertInput,
      },
    });
    if (response.errors) console.log(response.errors);

    if (response.data?.insert_block_one && hasChildren) {
      await insertTags({
        tags:
          block.tags
            .filter((tag) => typeof tag.topicId === "number")
            .map((tag) => ({
              //@ts-expect-error
              block_id: response.data?.insert_block_one.id,
              topic_id: tag.topicId,
            })) || [],
      });

      await Promise.all(
        userExplorerBlocksChildren[block.id].map(async (b1) => {
          response.data?.insert_block_one?.id &&
            (await createChildBlockInBackend(
              b1,
              response.data?.insert_block_one?.id
            ));
        })
      );
    }

    return resourceDbId;
  };

  const createBlockFromExplorerBlock = async () => {
    setCreatingBlock(block.id);
    const dbResourceId = await createExplorerBlockInBackend();
    if (
      selectedCurriculumsId &&
      selectedCurriculumsId?.length > 0 &&
      selectedSubject
    ) {
      openInNewTab(
        `${process.env.REACT_APP_RESOURCE_BUILDER_URL}/editor/${dbResourceId}/${selectedCurriculumsId[0]}/${selectedSubject}`
      );
    } else {
      openInNewTab(
        `${process.env.REACT_APP_RESOURCE_BUILDER_URL}/editor/${dbResourceId}`
      );
    }

    setCreatingBlock(null);
  };

  const renderMobileContent = () => (
    <StyledPreviewBody style={{ flexDirection: "column" }}>
      <div style={{ overflow: "auto" }}>{children}</div>

      <div
        style={{
          position: "absolute",
          bottom: "1.5em",
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "row",
          marginTop: "2em",
          left: 0,
          width: "100%",
        }}
      >
        <StyledIconLabelButton>
          <IconButton
            onClick={() => history.push(`/export/${block.id}`)}
            sx={{
              marginTop: "0.5em",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <FileDownloadIcon sx={{ color: "white" }} />
          </IconButton>
        </StyledIconLabelButton>
        <StyledIconLabelButton>
          <IconButton
            onClick={() => setShareLinkModalOpen(true)}
            sx={{
              marginTop: "0.5em",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <ShareIcon sx={{ color: "white" }} />
          </IconButton>
        </StyledIconLabelButton>
        <StyledIconLabelButton>
          <IconButton
            onClick={() => setShareQrModalOpen(true)}
            sx={{
              marginTop: "0.5em",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <QrCodeScannerIcon sx={{ color: "white" }} />
          </IconButton>
        </StyledIconLabelButton>
        <StyledIconLabelButton>
          <IconButton
            onClick={() => setResourceSettingsModalOpen(true)}
            sx={{
              marginTop: "0.5em",

              backgroundColor: theme.palette.primary.main,
            }}
          >
            <SettingsIcon sx={{ color: "white" }} />
          </IconButton>
        </StyledIconLabelButton>
      </div>
    </StyledPreviewBody>
  );

  return (
    <Dialog
      fullScreen={isTabletOrMobile}
      open={open}
      onClose={() => {
        handleClose();
      }}
      maxWidth={"lg"}
    >
      <StyledCardContent
        style={{
          backgroundColor: isTabletOrMobile ? "white" : "#f8f8f8",
          flex: 1,
        }}
      >
        <StyledTopRow>
          <StyledTopRowContent>
            <IconButton
              onClick={handleClose}
              style={{
                color: theme.palette.error.main,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </StyledTopRowContent>
        </StyledTopRow>
        {isTabletOrMobile ? (
          renderMobileContent()
        ) : (
          <StyledPreviewBody style={{ flexDirection: "row" }}>
            <StyledBorderedResource
              style={{
                width: history.location.pathname.includes("import")
                  ? "100%"
                  : "60%",
              }}
            >
              {children}
            </StyledBorderedResource>
            {!history.location.pathname.includes("import") && (
              <StyledCardButtons>
                <div>
                  <Typography
                    sx={{ color: "white", marginBottom: "0.5em" }}
                    variant={"h1"}
                  >
                    View this {prettyBlockType(block.type)}
                  </Typography>
                  {!isTabletOrMobile && (
                    <Button
                      endIcon={<OpenInFull />}
                      style={{
                        padding: "0.5em 1em",
                        whiteSpace: "nowrap",
                      }}
                      variant={"contained"}
                      size={"large"}
                      onClick={() => history.push(`/explorer/${block.id}`)}
                    >
                      Full screen
                    </Button>
                  )}

                  {/*{teacherOrStudent === "TEACHER" ? (*/}
                  {/*  <>*/}
                  {/*<Typography*/}
                  {/*  sx={{*/}
                  {/*    color: "white",*/}
                  {/*    marginTop: "1em",*/}
                  {/*    marginBottom: "0.5em",*/}
                  {/*  }}*/}
                  {/*  variant={"h1"}*/}
                  {/*>*/}
                  {/*  Adapt*/}
                  {/*</Typography>*/}
                  {/*<Typography*/}
                  {/*  sx={{ color: "white", marginBottom: "0.5em" }}*/}
                  {/*>*/}
                  {/*  Make changes to this 100% Customisable{" "}*/}
                  {/*  {prettyBlockType(block?.type)}, cherry-pick from other*/}
                  {/*  teachers’ resources and share with your students*/}
                  {/*</Typography>*/}

                  {/*<LoadingButton*/}
                  {/*  loading={creatingBlock !== null}*/}
                  {/*  style={{*/}
                  {/*    padding: "0.5em 1em",*/}
                  {/*  }}*/}
                  {/*  variant={"contained"}*/}
                  {/*  endIcon={<EditIcon />}*/}
                  {/*  size={"large"}*/}
                  {/*  onClick={createBlockFromExplorerBlock}*/}
                  {/*  sx={{*/}
                  {/*    "&:hover": {*/}
                  {/*      backgroundColor:*/}
                  {/*        altraResourceBuilderTheme.palette.primary.main,*/}
                  {/*    },*/}
                  {/*    backgroundColor:*/}
                  {/*      altraResourceBuilderTheme.palette.primary.dark,*/}
                  {/*    color:*/}
                  {/*      altraResourceBuilderTheme.palette.primary*/}
                  {/*        .contrastText,*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Create my own copy to adapt*/}
                  {/*</LoadingButton>*/}
                  {/*<Typography*/}
                  {/*  sx={{*/}
                  {/*    color: "white",*/}
                  {/*    marginTop: "1em",*/}
                  {/*    marginBottom: "0.5em",*/}
                  {/*  }}*/}
                  {/*  variant={"h1"}*/}
                  {/*>*/}
                  {/*  Create assignment*/}
                  {/*</Typography>*/}
                  {/*<Typography*/}
                  {/*  sx={{ color: "white", marginBottom: "0.5em" }}*/}
                  {/*>*/}
                  {/*  Send this resource to your students for them to revise.*/}
                  {/*  Students can then send their submissions to you for*/}
                  {/*  feedback*/}
                  {/*</Typography>*/}

                  {/*<Button*/}
                  {/*  style={{*/}
                  {/*    padding: "0.5em 1em",*/}
                  {/*  }}*/}
                  {/*  variant={"contained"}*/}
                  {/*  endIcon={<QuizIcon />}*/}
                  {/*  size={"large"}*/}
                  {/*  sx={{*/}
                  {/*    "&:hover": {*/}
                  {/*      backgroundColor:*/}
                  {/*        altraProgressManagerTheme.palette.primary.main,*/}
                  {/*    },*/}
                  {/*    backgroundColor:*/}
                  {/*      altraProgressManagerTheme.palette.primary.dark,*/}
                  {/*    color:*/}
                  {/*      altraProgressManagerTheme.palette.primary*/}
                  {/*        .contrastText,*/}
                  {/*  }}*/}
                  {/*  onClick={() => setShareAssignmentModalOpen(true)}*/}
                  {/*>*/}
                  {/*  Create assignment*/}
                  {/*</Button>*/}
                  {/*  </>*/}
                  {/*) : (*/}
                  {/*  <>*/}
                  {/*    <Typography*/}
                  {/*      sx={{*/}
                  {/*        color: "white",*/}
                  {/*        marginTop: "1em",*/}
                  {/*        marginBottom: "0.5em",*/}
                  {/*      }}*/}
                  {/*      variant={"h1"}*/}
                  {/*    >*/}
                  {/*      Revise*/}
                  {/*    </Typography>*/}
                  {/*    <Typography*/}
                  {/*      sx={{ color: "white", marginBottom: "0.5em" }}*/}
                  {/*    >*/}
                  {/*      Practise all the questions within this resource, prepare*/}
                  {/*      for exam conditions, and send your answers to your*/}
                  {/*      teacher for feedback*/}
                  {/*    </Typography>*/}
                  {/*    <LoadingButton*/}
                  {/*      loading={creatingSubmission}*/}
                  {/*      onClick={createSubmissionAndNavigate}*/}
                  {/*      sx={{*/}
                  {/*        margin: "10px 0",*/}
                  {/*        color: "white",*/}
                  {/*        "&:hover": {*/}
                  {/*          backgroundColor:*/}
                  {/*            altraResourceBuilderTheme.palette.primary.main,*/}
                  {/*        },*/}
                  {/*        backgroundColor:*/}
                  {/*          altraResourceBuilderTheme.palette.primary.dark,*/}
                  {/*      }}*/}
                  {/*      size={"large"}*/}
                  {/*      color={"primary"}*/}
                  {/*      variant="contained"*/}
                  {/*      endIcon={<QuizIcon />}*/}
                  {/*    >*/}
                  {/*      Revise*/}
                  {/*    </LoadingButton>*/}
                  {/*  </>*/}
                  {/*)}*/}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <StyledButtonRow>
                    <StyledIconLabelButton>
                      <IconButton
                        onClick={() => history.push(`/export/${block.id}`)}
                        sx={{
                          marginTop: "0.5em",
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        <FileDownloadIcon sx={{ color: "white" }} />
                      </IconButton>
                      {/*@ts-expect-error*/}
                      <Typography
                        sx={{ color: "white", marginTop: "0.5em" }}
                        vairant={"subtitle1"}
                      >
                        Download
                      </Typography>
                    </StyledIconLabelButton>
                    <StyledIconLabelButton>
                      <IconButton
                        onClick={() => setShareLinkModalOpen(true)}
                        sx={{
                          marginTop: "0.5em",
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        <ShareIcon sx={{ color: "white" }} />
                      </IconButton>
                      {/*@ts-expect-error*/}
                      <Typography
                        sx={{ color: "white", marginTop: "0.5em" }}
                        vairant={"subtitle1"}
                      >
                        Share link
                      </Typography>
                    </StyledIconLabelButton>
                    <StyledIconLabelButton>
                      <IconButton
                        onClick={() => setShareQrModalOpen(true)}
                        sx={{
                          marginTop: "0.5em",
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        <QrCodeScannerIcon sx={{ color: "white" }} />
                      </IconButton>
                      {/*@ts-expect-error*/}
                      <Typography
                        sx={{ color: "white", marginTop: "0.5em" }}
                        vairant={"subtitle1"}
                      >
                        QR Code
                      </Typography>
                    </StyledIconLabelButton>
                  </StyledButtonRow>
                  <TextLink
                    style={{ color: "#F32013", marginTop: "0.5em" }}
                    color={"error"}
                    value={"Report an issue"}
                    onClick={() =>
                      openInNewTab(
                        `https://2uluvvlx386.typeform.com/to/rhLlAWSr#name=${user.display_name}&block=${block.id}&email=${user.email}`
                      )
                    }
                  />
                </div>
              </StyledCardButtons>
            )}
          </StyledPreviewBody>
        )}
      </StyledCardContent>
      <ShareLinkAndQRModal
        open={shareLinkModalOpen}
        id={block.id}
        toggleOpen={setShareLinkModalOpen}
        type={"linkOnly"}
      />
      <ShareLinkAndQRModal
        open={shareQrModalOpen}
        id={block.id}
        toggleOpen={setShareQrModalOpen}
      />
      <ShareAssignmentLink
        open={shareAssignmentModalOpen}
        id={block.id}
        toggleOpen={setShareAssignmentModalOpen}
      />
      <ResourceSettingsDialog
        settingsModalOpen={resourceSettingsModalOpen}
        setSettingsModalOpen={setResourceSettingsModalOpen}
      />
    </Dialog>
  );
};

const StyledCardActions = styled(CardActions)`
  border-top: 1px solid #eee;
  display: flex;
  justify-content: left;
  padding: 0 !important;
  position: sticky;
  background-color: white;
  bottom: 0;
  flex-direction: column;
`;
const StyledButtonRow = styled(CardActions)`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 2em;
`;

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  minWidth: "800px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
  },
}));
const StyledPreviewBody = styled("div")(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  width: "100%",
  justifyContent: "space-between",
}));
const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
}));
const StyledBorderedResource = styled("div")(({ theme }) => ({
  display: "flex",
  border: "1px solid #606060",
  borderRadius: "10px",
  backgroundColor: "white",
  overflow: "auto",
}));
const StyledCardButtons = styled("div")(({ theme }) => ({
  backgroundColor: "#262626",
  width: "35%",
  borderRadius: "10px",
  padding: "1em",
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
}));
const StyledIconLabelButton = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
