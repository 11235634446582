import CloseIcon from "@mui/icons-material/Close";
import { CardContent, Dialog, IconButton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC, useState } from "react";
import { LinkForCopyingBox } from "../atoms/StyledLinkForCopying";
import { APP_URL } from "../styling/constants";
import { useIsMobile } from "../util/useIsMobile";

interface ShareLinkAndQRModalProps {
  open: boolean;
  toggleOpen: (open: boolean) => void;
  id: number;
}

export const ShareAssignmentLink: FC<ShareLinkAndQRModalProps> = ({
  id,
  open,
  toggleOpen,
}) => {
  const theme = useTheme();
  const [linkCopied, setLinkCopied] = useState(false);
  const isTabletOrMobile = useIsMobile();

  return (
    <Dialog open={open}>
      <StyledTopRow>
        <StyledTopRowContent>
          <IconButton
            onClick={() => toggleOpen(false)}
            style={{
              color: theme.palette.error.main,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledTopRowContent>
      </StyledTopRow>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: isTabletOrMobile ? 0 : "500px",
          minHeight: "200px",
          justifyContent: "space-between",
        }}
      >
        <Typography id="modal-modal-title" variant="h2">
          Share Assignment
        </Typography>
        <Typography sx={{ marginBottom: "30px" }} variant={"subtitle1"}>
          Share this link with your students so they can practise the questions
          and request feedback
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <LinkForCopyingBox
            link={`${
              process.env[APP_URL["Curriculum Explorer"]]
            }/assignment/${id}`}
          />
        </div>
      </CardContent>
    </Dialog>
  );
};
const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
}));
