import {
  DocumentNode,
  gql,
  OperationVariables,
  useApolloClient,
} from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import { Skeleton } from "@mui/lab";
import { Button, Chip, IconButton, Link, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { uniqBy } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { filterBlocks, GetBlocksWithChildren } from "../graphql/queries";
import {
  Block as AwsBlock,
  FilterBlocksQuery,
  FilterBlocksQueryVariables,
  GetBlocksWithChildrenQuery,
  GetBlocksWithChildrenQueryVariables,
} from "../graphql/types";
import { ExplorerResultCardContainer } from "../molecules/explorerResultCards/ExplorerResultCardContainer";
import { updateLoadingStatus } from "../redux/applicationContext/actions";
import { Topic, TopicLabel } from "../redux/applicationContext/types";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import {
  blocksSelectedForAddingToResource,
  resetChildBlocks,
  toggledFiltersChangedReadyToSearch,
  userAppInfoCurriculumExplorerTutorial,
  userAppInfoExplorerBlocks,
  userAppInfoExplorerBlocksChildren,
  userAppInfoFirstSearchExecuted,
  userAppInfoManualBlockImport,
  userAppInfoSelectedTopicsId,
} from "../redux/user/actions";
import {
  Block,
  CurriculumExplorerTutorialStages,
  UserInfo,
} from "../redux/user/types";
import { altraResourceBuilderTheme } from "../styling/altra-resource-builder-theme";
import { ALTRA_APPS, BLOCK_TYPES, Status } from "../util/custom-types";
import {
  getTopicLabelIdsForTier,
  getUnionOfValuesForKeys,
  mapEnum,
  openInNewTab,
  renderBoldFirstLine,
} from "../util/helpers";
import { prettyBlockType } from "../util/pretty-text";
import { QUESTION_TYPE } from "../util/questionTypes";
import { renderResultCardContentForBlockType } from "../util/render-result-card-for-block-type";
import { useIsMobile } from "../util/useIsMobile";
import { SearchResultsGrid } from "./SearchResultsGrid";
import { TutorialPopper } from "./TutorialPopper";

type SearchResultsProps = {
  onMobileExit?: () => void;
  showFilters?: () => void;
  fullScreen: boolean;
  isSelectable: boolean;
  appName: ALTRA_APPS;
};

function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode
) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables,
      }),
    [client]
  );
}

/**
 * Search results within Explorer application displaying compact cards
 * - On mobile, user is able to select cards and add, on desktop they are draggable
 * @param loading
 */
export const SearchResults: FC<SearchResultsProps> = ({
  appName,
  showFilters,
  fullScreen,
  isSelectable,
}) => {
  const theme = useTheme();
  const isTabletOrMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  const redirectPath: string | undefined = useAppSelector(
    (state) => state.userAppInfo.redirectPath
  );

  const [resultPage, setResultPage] = useState(1);
  const [retrievingBlocks, setRetrievingBlocks] = useState(true);
  const teacherOrStudent = useAppSelector(
    (state) => state.userAppInfo.teacherOrStudent
  );
  const curriculumExplorerStage: CurriculumExplorerTutorialStages =
    useAppSelector((state) => state.userAppInfo.curriculumExplorerTutorial);
  const [tutorialAnchorEl, setTutorialAnchorEl] =
    React.useState<HTMLElement | null>(null);

  useEffect(() => {
    if (curriculumExplorerStage === 5) {
      const anchorId = "curriculum-explorer-preview";
      if (document.getElementById(anchorId)) {
        setTutorialAnchorEl(document.getElementById(anchorId));
      }
    }
  }, [curriculumExplorerStage]);

  const firstSearchExecuted: boolean = useAppSelector(
    (state) => state.userAppInfo?.firstSearchExecuted
  );
  const searchTerm: string = useAppSelector(
    (state) => state.userAppInfo?.searchTerm
  );
  const filtersAppliedReadyToSearch: boolean = useAppSelector(
    (state) => state.userAppInfo?.filtersAppliedReadyToSearch
  );
  const userExplorerBlocks: Array<Block> = useAppSelector(
    (state) => state.userAppInfo.userExplorerBlocks
  );
  const userExplorerBlocksChildren: {
    [parentId: number]: Array<Block>;
  } = useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);
  const verifiedOnly: boolean = useAppSelector(
    (state) => state.userAppInfo?.onlyShowVerifiedBlocks
  );
  const topicLabels: { [curriculumId: number]: TopicLabel[] } = useAppSelector(
    (state) => state.applicationContext.topicLabels
  );
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext?.topics
  );

  //Selected topics within dropdown menus sorted by topic label id
  const selectedTopicIds: { [topicLabelId: number]: Array<number> } =
    useAppSelector((state) => state.userAppInfo?.selectedTopicsId || {});

  const selectedBlockIdsFromFilters: Array<number> = useAppSelector(
    (state) => state.userAppInfo?.selectedBlocksId
  );
  const selectedAuthorsFromFilters: Array<{ id: number; name: string }> =
    useAppSelector((state) => state.userAppInfo?.selectedAuthors);

  const selectedBlockResults: Array<number> = useAppSelector(
    (state) => state.userAppInfo?.blocksSelectedForAddingToResource
  );

  const GetBlocksWithChildrenCallback = useLazyQuery<
    GetBlocksWithChildrenQuery,
    GetBlocksWithChildrenQueryVariables
  >(
    gql`
      ${GetBlocksWithChildren}
    `
  );

  /**
   * TODO: is remove need for multiple queries here and just use one
   */
  const getAllChildrenForBlocks = async () => {
    setRetrievingBlocks(true);
    const { data: getChildrenData, error: getChildrenError } =
      await GetBlocksWithChildrenCallback({
        ids: `{${userExplorerBlocks.map((b) => b.id)?.join(", ")}}`,
      });
    if (getChildrenError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get child blocks",
        })
      );
    } else if (getChildrenData && getChildrenData) {
      const childBlocksAll: AwsBlock[] =
        getChildrenData.get_blocks_with_children.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        ) as AwsBlock[];

      let childBlocksForRedux: { id: number; children: AwsBlock[] }[] = [];
      userExplorerBlocks.map((b) => {
        childBlocksForRedux.push({
          id: b.id,
          children: childBlocksAll.filter((b1) => b1.parent_id === b.id),
        });
      });

      dispatch(
        userAppInfoExplorerBlocksChildren(
          childBlocksForRedux.map((cb) => ({
            parentId: cb.id,
            children:
              cb.children.map((block) => ({
                id: block.id,
                tags: block.tags.map((tag) => ({
                  id: tag.id,
                  topicId: tag.topic?.id,
                  label: tag.topic?.title || "",
                })),
                active: block.active,
                block: block.block,
                type: block.type,
                position: block.position,
                parent_id: block.parent_id,
                teacherNotes: block.note,
              })) || [],
          }))
        )
      );
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved child blocks",
        })
      );
    }
    setRetrievingBlocks(false);
    dispatch(toggledFiltersChangedReadyToSearch(false));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [resultPage]);

  //Reset selected blocks on initial load
  useEffect(() => {
    dispatch(blocksSelectedForAddingToResource([]));
  }, []);

  const selectBlock = (id: number) => {
    dispatch(blocksSelectedForAddingToResource([...selectedBlockResults, id]));
  };
  const deSelectBlock = (id: number) => {
    const localBlocks = Array.from(selectedBlockResults).filter(
      (b) => b !== id
    );
    dispatch(blocksSelectedForAddingToResource(localBlocks));
  };

  const [selectedTopicIdsForQuery, setSelectedTopicIdsForQuery] = useState<
    Array<Array<number>>
  >([]);

  const [selectedBlockIdsForQuery, setSelectedBlockIdsForQuery] = useState<
    Array<string>
  >([]);

  const [displaySize, setDisplaySize] = useState<number>(3);

  const filterBlocksCallback = useLazyQuery<
    FilterBlocksQuery,
    FilterBlocksQueryVariables
  >(
    gql`
      ${filterBlocks}
    `
  );

  /**
   * If the type of the block is changed, update the block ids and trigger a new query
   */
  useEffect(() => {
    getBlockIdsForQuery();
  }, [selectedBlockIdsFromFilters]);

  /**
   * Converts user friendly dropdown option to IDs used for graphql query
   * - e.g. converting question to question-with-sub-parts and question-text-only
   */
  const getBlockIdsForQuery = () => {
    const selectedIdsForQuery: Array<string> = [];
    selectedBlockIdsFromFilters?.map((id) => {
      switch (id) {
        case 0:
          selectedIdsForQuery.push("question-with-sub-parts");
          selectedIdsForQuery.push("question-text-only");
          break;
        case 1:
          selectedIdsForQuery.push("resource");
          break;
        case 2:
          selectedIdsForQuery.push("section");
          break;
        case 3:
          selectedIdsForQuery.push("passage");
          break;
        case 6:
          selectedIdsForQuery.push("diagram");
          break;
        case 7:
          selectedIdsForQuery.push("block-quote");
          break;
        case 8:
          selectedIdsForQuery.push("code");
          break;
      }
    });

    setSelectedBlockIdsForQuery(selectedIdsForQuery);
  };

  /**
   * Get top tier topics by finding selected topics with topic label with order_id 1
   * todo: optimise this
   */
  const selectedTopTierTopicsForSelectedCurriculums: boolean = useAppSelector(
    (state) => {
      const topTierTopicLevelIds: number[] = getTopicLabelIdsForTier(
        state.userAppInfo.selectedCurriculumsId || [],
        state.applicationContext.topicLabels,
        1
      );

      let empty = true;

      topTierTopicLevelIds.map((topicLevelId) => {
        if (!selectedTopicIds || selectedTopicIds[topicLevelId]?.length > 0)
          empty = false;
      });

      return empty;
    }
  );

  /**
   * Gets child ids of topic with given id
   * @param id
   */
  const getChildTopics = (id: number): Array<Topic> => {
    let childTopics: Array<Topic> = [];

    getUnionOfValuesForKeys<Topic>(topics).map((topic) => {
      if (topic.parent_id === id) {
        childTopics.push(topic);
        childTopics = childTopics.concat(getChildTopics(topic.id));
      }
    });
    return childTopics;
  };

  /**
   * On item change updates selected ids with selected id and all its children
   */
  const updateSelectedTopicsWithChildren = () => {
    // console.log("getting children for selected topics");
    const allTopicsArray: Array<Topic> = getUnionOfValuesForKeys<Topic>(topics);
    const allSelectedTopicIdsArray: Array<Number> =
      getUnionOfValuesForKeys<Number>(selectedTopicIds);

    let localSelectedIdsForQuery: Array<Array<number>> = [];
    const allSelectedTopicsArray: Array<Topic> = allTopicsArray.filter((T) =>
      allSelectedTopicIdsArray.includes(T.id)
    );

    //For every topic label, get selected topics
    getUnionOfValuesForKeys<TopicLabel>(topicLabels).map((topicLabel) => {
      const selectedTopicIdsForLabel: Array<number> =
        selectedTopicIds[topicLabel.id] || [];
      const selectedTopicsForLabel: Array<Topic> =
        selectedTopicIdsForLabel?.length > 0
          ? allTopicsArray.filter((t1) =>
              selectedTopicIdsForLabel?.includes(t1.id)
            )
          : [];

      let parentsToAddToQuery: { [topicId: number]: boolean } = {};
      selectedTopicIdsForLabel.map((ST) => {
        parentsToAddToQuery[ST] = true;
      });

      let addChildrenToQueryArray = true;
      const childTopicIdsToAdd: Array<number> = [];

      let localSelectedIdsForLabel: Array<number> = [];

      //If there are selected topics for label
      selectedTopicsForLabel.map((ST) => {
        const childTopics = getChildTopics(ST.id);

        // For each child topic (CT)
        childTopics.forEach((CT) => {
          //If the user has selected a child topic
          if (
            allSelectedTopicIdsArray.includes(CT.id) &&
            addChildrenToQueryArray
          ) {
            parentsToAddToQuery[ST.id] = false;
            addChildrenToQueryArray = false;
            //  If there is another selected topic (CST) with matching [CT - topic label]
            //Next selected topic
          } else if (
            allSelectedTopicsArray.some(
              (CST) => CST.topic_label_id === topicLabel
            ) ||
            !addChildrenToQueryArray
          ) {
            addChildrenToQueryArray = false;
          } else {
            //Add child topic to array and repeat
            childTopicIdsToAdd.push(CT.id);
          }
        });
      });

      //If there are no children selected, add selected topic id
      Object.keys(parentsToAddToQuery).map((PST) => {
        if (parentsToAddToQuery[PST]) {
          localSelectedIdsForLabel.push(parseInt(PST));
        }
      });

      if (addChildrenToQueryArray)
        localSelectedIdsForLabel =
          localSelectedIdsForLabel.concat(childTopicIdsToAdd);

      if (localSelectedIdsForLabel && localSelectedIdsForLabel.length > 0) {
        localSelectedIdsForQuery.push(localSelectedIdsForLabel);
      }
    });

    setSelectedTopicIdsForQuery(localSelectedIdsForQuery);
  };

  useEffect(() => {
    if (
      selectedTopicIdsForQuery.length > 0 &&
      selectedTopicIdsForQuery.length <= 15
    ) {
      executeSearchBasedOnFilters();
    }
  }, [selectedTopicIdsForQuery]);

  const executeSearchBasedOnFilters = async () => {
    setRetrievingBlocks(true);
    dispatch(userAppInfoFirstSearchExecuted(true));

    const blockIds =
      !selectedBlockIdsForQuery || selectedBlockIdsForQuery.length === 0
        ? mapEnum(BLOCK_TYPES, (type) => type)
        : selectedBlockIdsForQuery;

    const authorIds: Array<number> = selectedAuthorsFromFilters.map(
      (a) => a.id
    );

    let getBlocksInput: FilterBlocksQueryVariables = {
      searchterm: searchTerm || "",
      blockIds: blockIds.length < 1 ? null : blockIds.join(),
      verified: verifiedOnly ? "1" : "0",
      limit: 12,
      offset: (resultPage - 1) * 12,
      // blockauthorids: authorIds.length < 1 ? null : `{${authorIds.join(", ")}}`,
      blockauthorids: null,
    };

    const numberWords = [
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
    ];

    selectedTopicIdsForQuery.map((arrayOfIds, index) => {
      const key = `topiclist${numberWords[index]}`;
      getBlocksInput[key] = `{${arrayOfIds.join(", ")}}`;
    });

    const { data: filterBlocksData, error: filterBlocksError } =
      await filterBlocksCallback(getBlocksInput);

    if (filterBlocksError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get blocks from search",
        })
      );
    } else if (filterBlocksData && filterBlocksData.filter_blocks) {
      // console.log("ELSE", searchAndFilterData);
      //TODO: Only do this after got children
      dispatch(
        userAppInfoExplorerBlocks(
          filterBlocksData.filter_blocks.map((block) => ({
            id: block.id,
            tags: block.tags.map((tag) => ({
              id: tag.id,
              topicId: tag.topic?.id,
              label: tag.topic?.title || "",
            })),
            active: block.active,
            block: { ...block.block, type: block.type },
            parent_id: block.parent_id,
            position: block.position,
            teacherNotes: block.note,
            type: block.type,
          })) || []
        )
      );
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved blocks",
        })
      );
    }
  };

  /**
   * Depending on whether filters are selected and search term is present, executes corresponding query
   */
  useEffect(() => {
    if (firstSearchExecuted) {
      // console.log("RESULT PAGE CHANGED, EXECUTING SEARCH");
      updateSelectedTopicsWithChildren();
    }
  }, [resultPage]);

  useEffect(() => {
    if (!firstSearchExecuted) {
      if (resultPage === 1) {
        // console.log("SELECTED TOPIC CHANGED, EXECUTING SEARCH");
        updateSelectedTopicsWithChildren();
      } else {
        setResultPage(1);
      }
    }
  }, [selectedTopicIds]);

  useEffect(() => {
    if (firstSearchExecuted) {
      if (resultPage === 1) {
        // console.log("SEARCH CHANGED, EXECUTING SEARCH");
        updateSelectedTopicsWithChildren();
      } else {
        setResultPage(1);
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    if (firstSearchExecuted && filtersAppliedReadyToSearch) {
      if (resultPage === 1) {
        // console.log("FILTER PARAMS APPLIED, EXECUTING SEARCH");
        updateSelectedTopicsWithChildren();
      } else {
        setResultPage(1);
      }
    }
  }, [filtersAppliedReadyToSearch]);

  useEffect(() => {
    if (firstSearchExecuted) {
      // console.log("explorer blocks changed, getting children");
      dispatch(resetChildBlocks());
      getAllChildrenForBlocks();
    }
  }, [userExplorerBlocks, firstSearchExecuted]);

  /**
   * Adds all selected blocks to resource editor and switches tab
   */
  const onAddBlocks = () => {
    dispatch(userAppInfoManualBlockImport({ status: "PENDING" }));
    redirectPath && history.push(redirectPath);
  };
  const onCancelAddBlocks = () => {
    dispatch(userAppInfoManualBlockImport({ status: "INACTIVE" }));
    redirectPath && history.push(redirectPath);
  };

  /**
   * If no results after initial load, display message to user
   */
  const renderNoResults = () => {
    if (userExplorerBlocks.length === 0) {
      return (
        <>
          <StyledExplorerCardContainer
            style={{
              maxHeight: "400px",
              position: "relative",
              backgroundColor: "#FFF",
              // cursor: isSelectable ? "pointer" : "default",
              display: "flex",
              border: `2px solid ${altraResourceBuilderTheme.palette.error.main}`,
            }}
          >
            <StyledCardContent>
              <Typography
                color={"error"}
                variant={"h1"}
                sx={{ marginBottom: "1em" }}
              >
                No results
              </Typography>
              <Typography variant={"h3"} sx={{ marginBottom: "1em" }}>
                Try using a different search term or changing the filter values
                in the side menu on the left
              </Typography>{" "}
              <Typography variant={"h3"} sx={{ marginBottom: "1em" }}>
                Get in touch to let us know what resources you would like to see
              </Typography>
            </StyledCardContent>
            {isTabletOrMobile && (
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "white",
                }}
              >
                <StyledButtonContainer>
                  <Button
                    size={"large"}
                    onClick={() =>
                      openInNewTab(
                        `https://2uluvvlx386.typeform.com/to/CxLRLlOf#email=${user.email}&name=${user.display_name}&block=xxxxx&accountid=${user.id}`
                      )
                    }
                    sx={{
                      margin: "10px",
                      width: "90%",
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    Request a resource
                  </Button>
                </StyledButtonContainer>
              </div>
            )}
          </StyledExplorerCardContainer>
          {/*{!isTabletOrMobile &&*/}
          {/*  !history.location.pathname.includes("import") && (*/}
          {/*    <CreateYourOwnResourceCard />*/}
          {/*  )}*/}
        </>
      );
    }
  };

  /**
   * Renders a count of results with current search term and link to filters if applied
   */
  const renderResultCount = useMemo(
    () => (
      <div
        style={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        <Typography variant={"h3"} style={{ display: "inline" }}>
          <>
            Top results{" "}
            <Typography
              variant={"h3"}
              color={"primary"}
              sx={{ display: "inline", fontWeight: 700 }}
            >
              ({userExplorerBlocks.length})
            </Typography>
            {searchTerm ? ` for search term "${searchTerm}"` : ""}
          </>
        </Typography>
      </div>
    ),
    [searchTerm, userExplorerBlocks]
  );

  const renderSuggestedChip = (
    topic: Topic,
    onClick: () => void,
    selected: boolean
  ) => {
    return (
      <Button
        variant={"contained"}
        size={"large"}
        color={selected ? "success" : "primary"}
        onClick={onClick}
        style={{
          borderRadius: "20px",
          marginRight: "1em",
          marginBottom: "0.5em",
        }}
      >
        {topic.title}
      </Button>
    );
  };

  /**
   * Renders a count of results with current search term and link to filters if applied
   */
  const renderSuggestedSearches = useMemo(
    () => (
      <div
        style={{
          padding: "1em",
          borderRadius: "8px",
          marginBottom: "1em",
          width: "100%",
        }}
      >
        <div>
          {renderSuggestedChip(
            {
              title: "All",
              id: 0,
              topic_unit_title: "All learning materials",
            },
            () => {
              dispatch(
                userAppInfoSelectedTopicsId({
                  topicIds: [],
                  topicLabelId: uniqBy(
                    getUnionOfValuesForKeys<Topic>(topics)?.filter(
                      (t) =>
                        getUnionOfValuesForKeys(topicLabels)
                          ?.find((tl) => tl.id === t.topic_label_id)
                          ?.title?.toLowerCase() === "quick search"
                    ),
                    "id"
                  )[0]?.topic_label_id,
                })
              );
              dispatch(toggledFiltersChangedReadyToSearch(true));
            },
            !uniqBy(
              getUnionOfValuesForKeys<Topic>(topics)?.filter(
                (t) =>
                  getUnionOfValuesForKeys(topicLabels)
                    ?.find((tl) => tl.id === t.topic_label_id)
                    ?.title?.toLowerCase() === "quick search"
              ),
              "id"
            ).some((topic2) =>
              getUnionOfValuesForKeys<number>(selectedTopicIds).includes(
                topic2.id
              )
            )
          )}
          {uniqBy(
            getUnionOfValuesForKeys<Topic>(topics)?.filter(
              (t) =>
                getUnionOfValuesForKeys(topicLabels)
                  ?.find((tl) => tl.id === t.topic_label_id)
                  ?.title?.toLowerCase() === "quick search"
            ),
            "id"
          )?.map((t) =>
            renderSuggestedChip(
              t,
              getUnionOfValuesForKeys(selectedTopicIds).includes(t.id)
                ? () => {
                    const currentSelection = selectedTopicIds[t.topic_label_id];
                    dispatch(
                      userAppInfoSelectedTopicsId({
                        topicLabelId: t.topic_label_id,
                        topicIds: currentSelection.filter(
                          (tId) => tId !== t.id
                        ),
                      })
                    );
                    dispatch(toggledFiltersChangedReadyToSearch(true));
                  }
                : () => {
                    let currentSelection =
                      selectedTopicIds[t.topic_label_id] || [];
                    currentSelection = currentSelection.concat(t.id);
                    dispatch(
                      userAppInfoSelectedTopicsId({
                        topicLabelId: t.topic_label_id,
                        topicIds: currentSelection,
                      })
                    );
                    dispatch(toggledFiltersChangedReadyToSearch(true));
                  },
              getUnionOfValuesForKeys(selectedTopicIds).includes(t.id)
            )
          )}
        </div>
      </div>
    ),
    [selectedTopicIds]
  );

  /**
   * Renders button to display more results if available
   */
  const renderShowMoreButton = useMemo(
    () => (
      <StyledShowMoreContainer>
        {resultPage > 1 && (
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              setResultPage(resultPage - 1);
            }}
          >
            <Typography color={"primary"}>Previous Page</Typography>
          </Link>
        )}
        <Typography sx={{ margin: "0 2em" }} color={"primary"}>
          Page {resultPage}
        </Typography>
        {userExplorerBlocks.length >= 12 && (
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              setResultPage(resultPage + 1);
            }}
          >
            <Typography color={"primary"}>Next Page</Typography>
          </Link>
        )}
      </StyledShowMoreContainer>
    ),
    [userExplorerBlocks, resultPage]
  );

  /**
   * Resource builder buttons to select question
   * TODO(Jack): Implement
   */
  const renderAddSelectedQuestionsButtons = () => {
    if (isSelectable) {
      return (
        <StyledImportBannerOuterContainer>
          <StyledImportBannerContainer>
            <Typography sx={{ color: "white" }} variant={"h1"}>
              Selected blocks to import
            </Typography>
            <Button
              size={"large"}
              style={{
                textTransform: "none",
                margin: "1.5em 1em",
              }}
              onClick={onAddBlocks}
              disabled={selectedBlockResults.length === 0}
              variant="contained"
            >
              Add {selectedBlockResults.length} Selected Block
              {selectedBlockResults.length !== 1 && "s"}
            </Button>
            <StyledSelectedBlockRow>
              {selectedBlockResults.map((bId) => {
                const block: Block | undefined =
                  userExplorerBlocks.find((b) => b.id === bId) ||
                  getUnionOfValuesForKeys(userExplorerBlocksChildren).find(
                    (b) => b.id === bId
                  );
                return (
                  <StyledSelectedBlock>
                    <Typography variant={"h2"}>
                      {prettyBlockType(block?.type) || ""}
                    </Typography>
                    <Typography
                      sx={{ width: "100%", height: "100%", overflow: "hidden" }}
                      variant={"subtitle2"}
                    >
                      {block?.block["title"] || ""}
                      {block?.id &&
                        userExplorerBlocksChildren[block.id]
                          ?.slice()
                          ?.filter((b) => b.type !== "answer")
                          .sort(
                            (a, b) => (a?.position || 0) - (b?.position || 0)
                          )
                          ?.map(
                            (childBlock, index) =>
                              index === 0 &&
                              block?.type ===
                                QUESTION_TYPE.QUESTION_TEXT_ONLY &&
                              renderBoldFirstLine(childBlock)
                          )}
                    </Typography>
                    {block?.id && (
                      <IconButton
                        sx={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() => deSelectBlock(block.id)}
                        color={"error"}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </StyledSelectedBlock>
                );
              })}
            </StyledSelectedBlockRow>
          </StyledImportBannerContainer>
        </StyledImportBannerOuterContainer>
      );
    }
  };

  /**
   * Resets max cards to display when filtered questions change
   */
  useEffect(() => {
    setResultPage(1);
  }, []);

  const Wrapper = StyledStickyDesktopButtons;

  /**
   * Display results as Grid of cards
   */
  const renderResults = useMemo(
    () => (
      <div style={{ marginBottom: 100, width: isSelectable ? "70%" : "100%" }}>
        <SearchResultsGrid
          displaySize={displaySize}
          setDisplaySize={setDisplaySize}
          renderResultCount={renderResultCount}
          renderNoResults={renderNoResults}
          displayGrid={fullScreen}
          renderSuggestedSearches={renderSuggestedSearches}
          isSelectable={isSelectable}
        >
          {!retrievingBlocks
            ? userExplorerBlocks?.map((block) => (
                <ExplorerResultCardContainer
                  selectQuestion={() => selectBlock(block.id)}
                  deSelectQuestion={() => deSelectBlock(block.id)}
                  isSelectable={isSelectable}
                  selected={selectedBlockResults?.includes(block.id)}
                  appName={appName}
                  block={block}
                >
                  {renderResultCardContentForBlockType(
                    block,
                    false,
                    isSelectable
                  )}
                </ExplorerResultCardContainer>
              ))
            : Array(12)
                .fill(undefined)
                .map(Math.random)
                .map((e) => (
                  <StyledExplorerCardContainer
                    style={{
                      height: "400px",
                      position: "relative",
                      backgroundColor: "#FFF",
                      padding: "20px",
                      // cursor: isSelectable ? "pointer" : "default",
                      display: "flex",
                      border: `2px solid ${theme.palette.text.primary}`,
                    }}
                  >
                    <>
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                      <Skeleton animation="wave" height={40} />
                    </>
                  </StyledExplorerCardContainer>
                ))}
        </SearchResultsGrid>
        {renderShowMoreButton}
      </div>
    ),
    [userExplorerBlocks, !retrievingBlocks, displaySize, selectedBlockResults]
  );

  return useMemo(
    () => (
      <div>
        {selectedTopTierTopicsForSelectedCurriculums ? (
          <div
            style={{
              padding: "0 1em",
              display: "grid",
              gap: "1em",
              marginBottom: "20px",
              gridTemplateColumns: `repeat(${
                isTabletOrMobile ? 1 : isSelectable ? 2 : displaySize
              }, 1fr)`,
            }}
          >
            <StyledExplorerCardContainer
              style={{
                maxHeight: "400px",
                position: "relative",
                backgroundColor: "#FFF",
                // cursor: isSelectable ? "pointer" : "default",
                display: "flex",
                border: `2px solid ${theme.palette.primary.main}`,
              }}
            >
              <StyledCardContent>
                <Typography
                  color={"primary"}
                  variant={"h1"}
                  sx={{ marginBottom: "1em" }}
                >
                  Choose a Curriculum and Subject
                </Typography>
                <Typography variant={"h3"} sx={{ marginBottom: "1em" }}>
                  Get started by selecting the curriculum and subject you would
                  like to see learning materials for
                </Typography>{" "}
              </StyledCardContent>
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "white",
                }}
              >
                <StyledButtonContainer>
                  <Button
                    size={"large"}
                    onClick={() => history.push("/")}
                    sx={{
                      margin: "10px",
                      width: "90%",
                    }}
                    variant="contained"
                    fullWidth
                  >
                    Choose a Curriculum and Subject
                  </Button>
                </StyledButtonContainer>
              </div>
            </StyledExplorerCardContainer>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <div
              id={"curriculum-explorer-preview"}
              style={{ position: "absolute", left: 0 }}
            />
            <div style={{ minHeight: "100vh", width: "100%" }}>
              {renderResults}
            </div>
            {isSelectable && renderAddSelectedQuestionsButtons()}
          </div>
        )}
        <TutorialPopper
          open={curriculumExplorerStage === 5}
          onClose={() => {
            console.log("close: 5 to complete");
            dispatch(userAppInfoCurriculumExplorerTutorial("COMPLETE"));
          }}
          onNext={() => {
            console.log("next: 5 to finish");

            dispatch(userAppInfoCurriculumExplorerTutorial("COMPLETE"));
          }}
          anchorEl={tutorialAnchorEl}
          step={5}
          totalSteps={5}
          title={"Get in touch"}
          description={`Get in touch with us if you have any questions or feedback`}
        />
      </div>
    ),
    [
      selectedTopTierTopicsForSelectedCurriculums,
      renderResults,
      curriculumExplorerStage,
      tutorialAnchorEl,
    ]
  );
};

const StyledShowMoreContainer = styled("div")`
  display: flex;
  justify-content: center;
  margin: 2em 0;
`;

const StyledStickyDesktopButtons = styled("div")`
  position: sticky;
  bottom: 5%;
  height: 64px;
  padding: 1em;
`;

const StyledLinkText = styled(Typography)`
  display: inline;
  cursor: pointer;
  text-decoration: underline;
`;
const StyledChip = styled(Chip)`
  border: solid 1px #979797;
`;
const StyledExplorerCardContainer = styled("div")`
  flex-direction: column;
  border-radius: 10px;
  overflow: auto;
`;

const StyledCardContent = styled("div")`
  display: flex;
  padding: 1em;
  flex: 1;
  flex-direction: column;
`;
const StyledButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const StyledImportBannerContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  width: 90%;
  flex-direction: column;
  background-color: #262626;
  padding: 1em;
  border-radius: 8px;
  height: 85vh;
`;
const StyledImportButtonContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;
const StyledImportBannerOuterContainer = styled("div")`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1em;
  width: 20%;
  right: 0;
  position: fixed;
`;

const StyledSelectedBlock = styled("div")`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100px;
  width: 100px;
  padding: 1em;
  margin: 1em;
  border-radius: 8px;
  background-color: white;
  position: relative;
`;
const StyledSelectedBlockRow = styled("div")`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 1em 0;
  align-items: center;
  overflow-y: auto;
`;
