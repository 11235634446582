import {
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { uniq, uniqBy } from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { AutocompleteAuthorMultiSelectDropDown } from "../atoms/AutocompleteAuthorMultiSelectDropDown";
import { AutocompleteBlockMultiSelectDropDown } from "../atoms/AutocompleteBlockMultiSelectDropDown";
import { AutocompleteTopicCurriculumMultiSelectDropDown } from "../atoms/AutocompleteTopicCurriculumMultiSelectDropDown";
import {
  Curriculum,
  Topic,
  TopicLabel,
} from "../redux/applicationContext/types";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import {
  userAppInfoCurriculumExplorerTutorial,
  userAppInfoOnlyShowVerifiedBlocks,
  userAppInfoSelectedBlocksId,
} from "../redux/user/actions";
import { CurriculumExplorerTutorialStages } from "../redux/user/types";
import { alterCurriculumExplorerTheme } from "../styling/altra-curriculum-explorer-theme";
import {
  BLOCK_TYPES_FOR_EXPLORER,
  MultiSelectDropDownMode,
} from "../util/custom-types";
import {
  getTopicLabelIdsForTier,
  getUnionOfValuesForKeys,
} from "../util/helpers";
import { TutorialPopper } from "./TutorialPopper";

export type DropdownFilterInputsProps = {};

/**
 * Group of dropdowns used within side menu for the explorer
 * - Displays dropdowns based on selected parent curriculum and topic
 * - Each tier is re rendered when the parent topic selection updates
 */
export const FilterDropdownsAndButtons: FC<DropdownFilterInputsProps> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = alterCurriculumExplorerTheme;
  const curriculumExplorerStage: CurriculumExplorerTutorialStages =
    useAppSelector((state) => state.userAppInfo.curriculumExplorerTutorial);

  /**
   * Get top tier topics by finding topic label with order_id 1
   */
  const topTierTopicsForSelectedCurriculums: Array<Topic> = useAppSelector(
    (state) => {
      const topTierTopicLevelIds: number[] = getTopicLabelIdsForTier(
        state.userAppInfo.selectedCurriculumsId || [],
        state.applicationContext.topicLabels,
        1
      );

      return getUnionOfValuesForKeys<Topic>(
        state.applicationContext.topics,
        state.userAppInfo?.selectedCurriculumsId
      ).filter((t) => topTierTopicLevelIds.includes(t.topic_label_id));
    }
  );

  const selectedTopTierTopicIds: Array<number> =
    getUnionOfValuesForKeys<number>(
      useAppSelector((state) => state.userAppInfo?.selectedTopicsId) || {},
      topTierTopicsForSelectedCurriculums.map((t) => t.topic_label_id)
    );

  // Get all curriculums, topics and labels from redux
  const curriculums: Curriculum[] | undefined = useAppSelector(
    (state) => state.applicationContext?.curriculums!
  );
  const selectedCurriculumIds: number[] | undefined = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId!
  );
  const verifiedOnly: boolean = useAppSelector(
    (state) => state.userAppInfo?.onlyShowVerifiedBlocks!
  );
  const topicLabels: { [curriculumId: number]: Array<TopicLabel> } =
    useAppSelector((state) => state.applicationContext.topicLabels!);
  //Selected topics within dropdown menus sorted by topic label id
  const selectedTopicIds: { [topicLabelId: number]: Array<number> } =
    useAppSelector((state) => state.userAppInfo?.selectedTopicsId!);
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext.topics
  );
  const topicsForCurriculum: Topic[] = useAppSelector(
    (state) => state.applicationContext.topics[selectedCurriculumIds[0]]
  );
  const topicLabelsForCurriculum: TopicLabel[] = useAppSelector(
    (state) => state.applicationContext.topicLabels[selectedCurriculumIds[0]]
  );
  const selectedTopicsArray: Array<Topic> = useAppSelector((state) =>
    topicsForCurriculum?.filter((t) =>
      getUnionOfValuesForKeys<number>(selectedTopicIds)?.some(
        (tid) => tid === t.id
      )
    )
  );

  const [tutorialAnchorEl, setTutorialAnchorEl] =
    React.useState<HTMLElement | null>(null);

  useEffect(() => {
    if (curriculumExplorerStage === 3) {
      const anchorId = "curriculum-explorer-filters";
      // console.log(anchorId);
      // console.log(document.getElementById(anchorId));
      if (document.getElementById(anchorId)) {
        setTutorialAnchorEl(document.getElementById(anchorId));
      }
    }
  }, [curriculumExplorerStage]);

  const handleVerifyChange = (checked: boolean) => {
    dispatch(userAppInfoOnlyShowVerifiedBlocks(checked));
  };

  const [
    topicsBrokenDownByTopicLabelForSelectedTopics,
    setTopicsBrokenDownByTopicLabelForSelectedTopics,
  ] = useState<{ [topicLabelId: number]: Array<Topic> }>({});

  /**
   * Get topics for each topic label by finding topic label with corresponding id
   */
  const getTopicsBrokenDownByTopicLabelForSelectedTopics = () => {
    let returnVal: { [topicLabelId: number]: Array<Topic> } = {};

    const selectedTopicsArrayNotInclTopTier: Array<Topic> =
      selectedTopicsArray?.filter((t) => t.topic_label_order_id !== 1);
    let topicsForCurriculumFilteredForDropdown: Topic[] = topicsForCurriculum;

    const selectedTopicsByOrderIdNotIncTopTier: { number?: number[] } = {};

    let topicsForCurriculumFilteredForDropdownTopicLabelIds: number[] = [];
    // console.log(
    //   "SELECTED TOPICS NOT INCLUDING TOP TIER",
    //   selectedTopicsArrayNotInclTopTier
    // );
    //If a topic has been selected, filter the options
    if (selectedTopicsArrayNotInclTopTier?.length > 0) {
      selectedTopicsArrayNotInclTopTier?.map((topic) => {
        if (
          topic?.topic_label_order_id &&
          topic?.topic_label_order_id > 1 &&
          selectedTopicsByOrderIdNotIncTopTier[topic?.topic_label_order_id]
        )
          selectedTopicsByOrderIdNotIncTopTier[
            topic?.topic_label_order_id
          ].push(topic);
        else if (topic?.topic_label_order_id && topic?.topic_label_order_id > 1)
          selectedTopicsByOrderIdNotIncTopTier[topic?.topic_label_order_id] = [
            topic,
          ];
      });

      // console.log(
      //   `SELECTED  TOPIC LABEL ORDER ID`,
      //   selectedTopicsByOrderIdNotIncTopTier
      // );

      //Get the children for each selected topic if they are of the highest topic label order of the selected parents
      topicsForCurriculumFilteredForDropdown = [];
      selectedTopicsArrayNotInclTopTier.map((topic1) => {
        let childTopics: Topic[] = [];
        const topicParentIds: number[] =
          topic1?.parent_topics?.map((pt) => pt.key) || [];
        let allChildrenForTopic: Topic[] = [];

        allChildrenForTopic = topicsForCurriculum.filter((t) =>
          t?.parent_topics?.some((pt) => pt.key === topic1.id)
        );
        const selectedParentsForTopicNotInclTopTier =
          selectedTopicsArrayNotInclTopTier.filter((t) =>
            topicParentIds.includes(t.id)
          ) || [];
        if (
          selectedParentsForTopicNotInclTopTier.length === 0 &&
          !selectedTopicsArrayNotInclTopTier.some((st) =>
            allChildrenForTopic.includes(st)
          )
        ) {
          //Must now also check that there is no topics with this as one of their parents selected
          // console.log(
          //   `NO SELECTED PARENTS FOR ${topic1.title} AND NO CHILDREN SELECTED, adding`,
          //   allChildrenForTopic
          // );
          childTopics = childTopics.concat(allChildrenForTopic || []);
        }
        if (selectedParentsForTopicNotInclTopTier.length > 0) {
          // console.log(
          //   `SELECTED PARENTS FOR ${topic1.title}`,
          //   selectedParentsForTopicNotInclTopTier
          // );
          const highestTopicLabelOrderKey = Math.max(
            ...Object.keys(selectedTopicsByOrderIdNotIncTopTier).map((k) =>
              parseInt(k)
            )
          );
          // console.log("HIGHEST ORDER KEY", highestTopicLabelOrderKey);
          // console.log("CURRENT TOPIC ORDER KEY", topic1.topic_label_order_id);
          if (topic1.topic_label_order_id === highestTopicLabelOrderKey) {
            // console.log(
            //   ` SELECTED PARENTS FOR ${topic1.title}, adding order${topic1.topic_label_order_id}`,
            //   allChildrenForTopic
            // );
            childTopics = childTopics.concat(allChildrenForTopic || []);
          }
        }
        topicsForCurriculumFilteredForDropdown =
          topicsForCurriculumFilteredForDropdown.concat(
            uniq(childTopics) || []
          );
      });

      // Add all the selected topics to results
      selectedTopicsArrayNotInclTopTier.map((t) => {
        topicsForCurriculumFilteredForDropdown.push(t);
      });

      // Get topic label IDs for all the dropdown option topics
      topicsForCurriculumFilteredForDropdown.map((t) => {
        topicsForCurriculumFilteredForDropdownTopicLabelIds.push(
          t.topic_label_id
        );
      });

      // console.log(
      //   "TOPIC LABEL IDS FOR DROPDOWN OPTIONS",
      //   topicsForCurriculumFilteredForDropdownTopicLabelIds
      // );

      //Add any topics for a topic label if there are no dropdown options for that label
      topicsForCurriculum.map((t) => {
        if (
          !topicsForCurriculumFilteredForDropdownTopicLabelIds.includes(
            t.topic_label_id
          )
        ) {
          // console.log(`ADDING TOPIC FOR LABEL ID ${t.topic_label_id}`, t);
          topicsForCurriculumFilteredForDropdown.push(t);
        }
      });

      //Add all topics with same parent id as selected
      topicsForCurriculum.map((t) => {
        if (selectedTopicsArray.some((st) => st.parent_id === t.parent_id)) {
          console.log(`ADDING TOPIC FOR PARENT ${t.parent_id}`, t);
          topicsForCurriculumFilteredForDropdown.push(t);
        }
      });

      // console.log(
      //   "ALL SELECTED TOPICS CHILDREN",
      //   allSelectedTopicsChildrenNotIncTopTier
      // );
    }
    // console.log("ALL TOPICS FOR CURRICULUM", topicsForCurriculum);
    // console.log(
    //   "SELECTED TOPICS CHILDREN",
    //   selectedTopicsChildrenNotIncTopTier
    // );

    // console.log(
    //   "TOPICS FILTERED TO SHOW IN DROPDOWNS",
    //   topicsForCurriculumFilteredForDropdown
    // );

    topicsForCurriculumFilteredForDropdown = uniqBy(
      topicsForCurriculumFilteredForDropdown,
      "id"
    );

    topicsForCurriculumFilteredForDropdown.map((t) => {
      if (returnVal[t.topic_label_id]) returnVal[t.topic_label_id].push(t);
      else returnVal[t.topic_label_id] = [t];
    });

    // console.log("RETURN VAL", returnVal);
    // console.log("\n");

    setTopicsBrokenDownByTopicLabelForSelectedTopics(returnVal);
  };

  useEffect(() => {
    // console.log(selectedTopicIds);
    getTopicsBrokenDownByTopicLabelForSelectedTopics();
  }, [selectedTopicIds]);

  const clearBlockFilters = () => {
    dispatch(
      userAppInfoSelectedBlocksId({
        blockIds: [],
      })
    );
  };

  const renderVerifiedSwitch = useMemo(() => {
    return (
      <StyledVerifiedContainer sx={{ padding: "5px" }}>
        <FormControlLabel
          sx={{
            //@ts-expect-error
            "& .MuiFormControlLabel-label": (theme) => theme.typography.h7,
          }}
          control={
            <Switch
              onChange={(event, checked) => handleVerifyChange(checked)}
              checked={verifiedOnly}
            />
          }
          label="Show peer reviewed content"
        />
      </StyledVerifiedContainer>
    );
  }, [verifiedOnly]);

  const renderBlockDropdowns = useMemo(() => {
    return (
      <AutocompleteBlockMultiSelectDropDown
        inputLabel={"Type"}
        items={BLOCK_TYPES_FOR_EXPLORER}
      />
    );
  }, []);

  const renderAuthorsDropdowns = useMemo(() => {
    return <AutocompleteAuthorMultiSelectDropDown inputLabel={"Author"} />;
  }, []);

  /**
   * Displays the dropdown filter menus for the given curriculum id
   * - Renders a dropdown for each topic label ID
   * @param curriculumId
   */
  const renderDropdownsForCurriculum = (curriculumId: number) => {
    const curriculum = curriculums?.find((curr) => curr.id === curriculumId);

    const renderAutocompleteMenu = (topicLabel: TopicLabel) => {
      return (
        <AutocompleteTopicCurriculumMultiSelectDropDown
          inputLabel={topicLabel.title || `Topic ${topicLabel.id}`}
          selectedItems={selectedTopicIds[topicLabel.id]}
          items={topicsBrokenDownByTopicLabelForSelectedTopics[
            topicLabel.id
          ]?.map((sub) => {
            return {
              key: sub.id,
              value: sub.title,
            };
          })}
          type={MultiSelectDropDownMode.TOPIC}
          topicLabelIds={[topicLabel.id]}
        />
      );
    };

    return (
      <>
        <StyledFilterTitleRow>
          <Typography variant={"h2"}>
            {" "}
            {
              selectedTopTierTopicIds
                ?.filter(
                  (tId) =>
                    getUnionOfValuesForKeys(topicLabels)
                      ?.find(
                        (tl) =>
                          tl.id ===
                          getUnionOfValuesForKeys(topics).find(
                            (t) => t.id === tId
                          )?.topic_label_id
                      )
                      ?.title?.toLowerCase() !== "quick search"
                )
                ?.map(
                  (scId) =>
                    topTierTopicsForSelectedCurriculums.find(
                      (c) => c.id === scId
                    )?.title || ""
                )[0]
            }{" "}
            Filters
          </Typography>

          <Button
            sx={{ color: theme.palette.primary.main }}
            size={"small"}
            variant={"text"}
            onClick={() => history.push("/topicSelection")}
          >
            Change Subject
          </Button>
        </StyledFilterTitleRow>

        {selectedCurriculumIds[0] &&
          topicLabels[selectedCurriculumIds[0]] &&
          [...uniqBy(topicLabels[selectedCurriculumIds[0]], "id")]
            ?.sort((a, b) => (a.order_id || 0) - (b.order_id || 0))
            //Filters out any top tiered topics as they are already used in header bar
            ?.filter(
              (topicLabel) =>
                topicLabel.order_id !== 1 &&
                topicsBrokenDownByTopicLabelForSelectedTopics[topicLabel.id]
                  ?.length > 0
            )
            ?.map((topicLabel) => renderAutocompleteMenu(topicLabel))}
      </>
    );
  };

  return useMemo(
    () => (
      <div
        id={"curriculum-explorer-filters"}
        style={{ margin: "0 1em 5em 1em" }}
      >
        <div
          style={{
            display: "grid",
            gap: "0.5em",
            maxWidth: "250px",
          }}
        >
          <FormControl>
            <div style={{ marginTop: "1em" }}>
              {renderDropdownsForCurriculum(selectedCurriculumIds[0])}
            </div>
            <div style={{ marginTop: "1em" }}>
              <StyledFilterTitleRow>
                <Typography sx={{ marginTop: "0.5em" }} variant={"h2"}>
                  Content Filters
                </Typography>
                <Button
                  size={"small"}
                  variant={"text"}
                  onClick={clearBlockFilters}
                  sx={{ color: theme.palette.primary.main }}
                >
                  Clear
                </Button>
              </StyledFilterTitleRow>
              {renderBlockDropdowns}
            </div>

            {renderVerifiedSwitch}
          </FormControl>
        </div>
        <TutorialPopper
          open={curriculumExplorerStage === 3}
          onClose={() => {
            console.log("close: 3 to complete");
            dispatch(userAppInfoCurriculumExplorerTutorial("COMPLETE"));
          }}
          onNext={() => {
            console.log("next: 3 to 4");
            dispatch(userAppInfoCurriculumExplorerTutorial(4));
          }}
          anchorEl={tutorialAnchorEl}
          step={3}
          totalSteps={5}
          title={"Filter"}
          description={`Filter by ${
            selectedTopTierTopicIds
              ?.filter(
                (tId) =>
                  getUnionOfValuesForKeys(topicLabels)
                    ?.find(
                      (tl) =>
                        tl.id ===
                        getUnionOfValuesForKeys(topics).find(
                          (t) => t.id === tId
                        )?.topic_label_id
                    )
                    ?.title?.toLowerCase() !== "quick search"
              )
              ?.map(
                (scId) =>
                  topTierTopicsForSelectedCurriculums.find((c) => c.id === scId)
                    ?.title || ""
              )[0]
          } learning outcomes, topics and levels`}
        />
      </div>
    ),
    [
      selectedCurriculumIds,
      verifiedOnly,
      topicsBrokenDownByTopicLabelForSelectedTopics,
      curriculumExplorerStage,
      tutorialAnchorEl,
    ]
  );
};

const StyledVerifiedContainer = styled("div")(({ theme }) => ({
  justifyContent: "center",
  display: "flex",
  backgroundColor: "#e2e2e2",
  borderRadius: "8px",
  margin: "0.6em 0",
}));

const StyledFilterTitleRow = styled("div")(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
}));
