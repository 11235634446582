import PassageBlockIcon from "@altra-apps/common/assets/blockTypeIcons/Passage.svg";
import { Tooltip, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import {
  AdditionalFieldsElement,
  PassageElement,
} from "../../util/custom-editor-types";
import { ExplorerResultChildInlineContent } from "./ExplorerResultChildInlineContent";

type PassageElementResultCardContentsProps = {
  block: PassageElement & AdditionalFieldsElement;
  tags: Tag[];
  inline?: boolean;
  position: number | null | undefined;
  isSelectable: boolean;
};

/**
 * Passage Block result card view in Curriculum Explorer
 */
export const PassageElementResultCardContents: FC<
  PassageElementResultCardContentsProps
> = ({ block, tags, position, isSelectable, inline }) => {
  const theme = useTheme();

  return (
    <>
      <StyledTopRow
        sx={{
          flexDirection: inline ? "row-reverse" : "row",
          justifyContent: inline ? "flex-end" : "space-between",
        }}
      >
        {!inline && (
          <StyledTextContainer>
            <Typography
              variant={"h1"}
              style={{
                color: theme.palette.text.primary,
              }}
            >
              {block.title ? block.title : "Passage"}
            </Typography>
            <Typography
              //@ts-expect-error
              variant={"h7"}
              style={{
                color: theme.palette.text.primary,
              }}
            >
              {block.description}
            </Typography>
          </StyledTextContainer>
        )}
        {inline && (
          <StyledChild>
            <Typography>{block.title}</Typography>
          </StyledChild>
        )}
        <Tooltip placement={"left"} title={"Passage"}>
          <img
            style={{
              height: "40px",
              marginBottom: 10,
              marginRight: "10px",
              display: "inline-block",
            }}
            src={PassageBlockIcon}
            alt={"Passage Block"}
          />
        </Tooltip>
      </StyledTopRow>

      {!inline && (
        <>
          <ExplorerResultChildInlineContent
            block={{
              id: parseInt(block.id),
              tags: tags,
              block: block.children,
              active: true,
              parent_id: 0,
              position: position,
              teacherNotes: block.teacherNotes || null,
              type: block.type,
            }}
            isSelectable={isSelectable}
          />
        </>
      )}
    </>
  );
};

const StyledTopRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const StyledTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StyledChild = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
