import { CustomEditorExtractedLogic } from "@altra-apps/common/src/molecules/CustomEditorExtractedLogic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FunctionsIcon from "@mui/icons-material/Functions";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import { Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { styled } from "@mui/material/styles";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { ReactEditor, useSlate } from "slate-react";
import { altraResourceBuilderTheme } from "../../styling/altra-resource-builder-theme";
import { useIsMobile } from "../../util/useIsMobile";
import {
  CustomSubmissionEditorExtractedLogic,
  FORMAT_TYPE,
} from "./CustomSubmissionEditorExtractedLogic";

export const CustomSubmissionToolbar: FC = () => {
  const theme = useTheme();
  const editor = useSlate();
  const history = useHistory();
  const submissionView = history.location.pathname.includes("/submission")
    ? "SUBMISSION"
    : history.location.pathname.includes("/reviewed")
    ? "REVIEWED"
    : "REVIEW";

  const renderIconButton = (
    onClick: () => void,
    isActive: boolean,
    Icon: OverridableComponent<SvgIconTypeMap> & { muiName: string }
  ) => (
    <StyledUtilityFormatButton className={"hover-toolbar"}>
      <StyledSelectedBackground
        className={"hover-toolbar"}
        sx={{
          backgroundColor: isActive ? "grey" : "transparent",
        }}
      >
        <IconButton onClick={onClick} className={"hover-toolbar"}>
          <Icon component={Icon} />
        </IconButton>
      </StyledSelectedBackground>
    </StyledUtilityFormatButton>
  );

  const isTabletOrMobile = useIsMobile();

  return (
    <CustomToolbar
      style={{
        backgroundColor: "#e2e2e2",
        position: isTabletOrMobile
          ? "inherit"
          : submissionView === "REVIEW"
          ? "absolute"
          : "initial",
        zIndex: 1,
        width: submissionView === "REVIEW" ? "99.5%" : "100%",
      }}
    >
      {
        <Tooltip placement={"bottom"} title={"Bold: Ctrl/Cmd + b"}>
          {renderIconButton(
            () => {
              CustomSubmissionEditorExtractedLogic.toggleMark(
                editor,
                FORMAT_TYPE.BOLD
              );
              ReactEditor.focus(editor);
            },
            CustomSubmissionEditorExtractedLogic.isMarkActive(
              editor,
              FORMAT_TYPE.BOLD
            ),
            FormatBoldIcon
          )}
        </Tooltip>
      }
      {
        <Tooltip placement={"bottom"} title={"Underline: Ctrl/Cmd + u"}>
          {renderIconButton(
            () => {
              CustomSubmissionEditorExtractedLogic.toggleMark(
                editor,
                FORMAT_TYPE.UNDERLINE
              );
              ReactEditor.focus(editor);
            },
            CustomSubmissionEditorExtractedLogic.isMarkActive(
              editor,
              FORMAT_TYPE.UNDERLINE
            ),
            FormatUnderlinedIcon
          )}
        </Tooltip>
      }
      {
        <Tooltip placement={"bottom"} title={"Italic: Ctrl/Cmd + i"}>
          {renderIconButton(
            () => {
              CustomSubmissionEditorExtractedLogic.toggleMark(
                editor,
                FORMAT_TYPE.ITALIC
              );
              ReactEditor.focus(editor);
            },
            CustomSubmissionEditorExtractedLogic.isMarkActive(
              editor,
              FORMAT_TYPE.ITALIC
            ),
            FormatItalicIcon
          )}
        </Tooltip>
      }
      <Tooltip placement={"bottom"} title={"Add some maths: Ctrl/Cmd + m"}>
        <Button
          sx={{
            margin: "5px",
            "&:hover": {
              backgroundColor: altraResourceBuilderTheme.palette.primary.main,
            },
            backgroundColor: altraResourceBuilderTheme.palette.primary.dark,
            color: altraResourceBuilderTheme.palette.primary.contrastText,
          }}
          endIcon={<FunctionsIcon />}
          onClick={(e) => {
            e.preventDefault();
            CustomEditorExtractedLogic.insertMath(editor, "");
            ReactEditor.focus(editor);
          }}
          variant={
            CustomSubmissionEditorExtractedLogic.isMathActive(editor)
              ? "outlined"
              : "contained"
          }
        >
          Insert Maths
        </Button>
      </Tooltip>
      <Tooltip placement={"bottom"} title={"Undo: Ctrl/Cmd + z"}>
        <IconButton onClick={() => editor.undo()}>
          <UndoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip placement={"bottom"} title={"Redo: Ctrl/Cmd + shift + z"}>
        <IconButton onClick={() => editor.redo()}>
          <RedoIcon />
        </IconButton>
      </Tooltip>
    </CustomToolbar>
  );
};

const CustomToolbar = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "50px",
}));

const StyledUtilityFormatButton = styled("div")(({ theme }) => ({
  textAlign: "center",
}));

const StyledSelectedBackground = styled("div")(({ theme }) => ({
  border: "2px black",
  backgroundColor: "#c5c5c5",
  width: "80%",
  height: "90%",
  display: "flex",
  justifyContent: "center",
  borderRadius: "8px",
  margin: "auto",
  boxShadow: "0 8px 36px rgba(66, 84, 102, 0.04)",
}));
