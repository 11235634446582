import QuestionBlockIcon from "@altra-apps/common/assets/blockTypeIcons/Question.svg";
import { Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import {
  AdditionalFieldsElement,
  QuestionTextOnlyElement,
} from "../../util/custom-editor-types";
import { ExplorerResultChildInlineContent } from "./ExplorerResultChildInlineContent";

type QuestionTextOnlyResultCardContentsProps = {
  block: QuestionTextOnlyElement & AdditionalFieldsElement;
  tags: Tag[];
  inline?: boolean;
  position: number | null | undefined;
  isSelectable: boolean;
};

/**
 * Question text only Block result card view in Curriculum Explorer
 */
export const QuestionTextOnlyResultCardContents: FC<
  QuestionTextOnlyResultCardContentsProps
> = ({ block, tags, inline, position, isSelectable }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: inline ? "row" : "column",
      }}
    >
      <StyledTopRow
        style={{ position: !inline ? "absolute" : "inherit", right: 0 }}
      >
        <Tooltip placement={"left"} title={"Question"}>
          <img
            style={{
              height: !inline ? "40px" : "30px",
              marginBottom: 10,
              marginRight: "10px",
              display: "inline-block",
            }}
            src={QuestionBlockIcon}
            alt={"Question Block"}
          />
        </Tooltip>
      </StyledTopRow>

      <StyledChildParagraph>
        {inline && (
          <StyledChild>
            <Typography>Question</Typography>
          </StyledChild>
        )}
        {!inline && (
          <>
            <ExplorerResultChildInlineContent
              block={{
                id: parseInt(block.id),
                tags: tags,
                block: block.children,
                active: true,
                parent_id: 0,
                position: position,
                teacherNotes: block.teacherNotes || null,
                type: block.type,
              }}
              isSelectable={isSelectable}
            />
          </>
        )}
      </StyledChildParagraph>
    </div>
  );
};

const StyledTopRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledChildParagraph = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
const StyledChild = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
