import {
  Button,
  CardContent,
  CircularProgress,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { FC, useEffect } from "react";
import { useIsMobile } from "../util/useIsMobile";

interface TutorialPopperProps {
  open: boolean;
  onClose: () => void;
  onNext: () => void;
  title: string;
  description: string;
  step: number;
  totalSteps: number;
  anchorEl: HTMLElement | null;
}

/**
 * Screen to toggle whether or not resource is public
 * @constructor
 */
export const TutorialPopper: FC<TutorialPopperProps> = ({
  open,
  onClose,
  onNext,
  title,
  description,
  step,
  totalSteps,
  anchorEl,
}) => {
  const theme = useTheme();
  const isTabletOrMobile = useIsMobile();

  const [localAnchor, setLocalAnchor] = React.useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    console.log("tutorial anchor changed", anchorEl);
    setLocalAnchor(anchorEl);
  }, [anchorEl]);

  return isTabletOrMobile ? (
    <div />
  ) : (
    <Popper
      open={open && anchorEl !== null}
      anchorEl={localAnchor}
      placement="right"
      disablePortal={false}
      style={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: "8px",
        zIndex: 1200,
        width: "400px",
        height: "200px",
        boxShadow: "rgb(27 31 35) 12px 12px 12px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-evenly",
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{ marginBottom: "0.5em" }}
              color={"white"}
              id="modal-modal-title"
              variant="h2"
            >
              {title}
            </Typography>
            <div style={{ position: "absolute", top: 20, right: 20 }}>
              <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  sx={{ color: "white" }}
                  value={step * (100 / totalSteps)}
                  variant="determinate"
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  <Typography variant="caption" component="div" color={"white"}>
                    {step}/{totalSteps}
                  </Typography>
                </Box>
              </Box>
            </div>
          </div>

          <Typography color={"white"} id="modal-modal-title" variant="h3">
            {description}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button onClick={onClose} color={"primary"} variant={"contained"}>
            Skip tour
          </Button>
          <Button
            onClick={onNext}
            sx={{ marginLeft: "1em" }}
            color={"inherit"}
            variant={"contained"}
          >
            Next
          </Button>
        </div>
      </CardContent>
    </Popper>
  );
};
