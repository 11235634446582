import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, IconButton } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { blocksSelectedForAddingToResource } from "../../redux/user/actions";
import { Block } from "../../redux/user/types";
import { alterCurriculumExplorerTheme } from "../../styling/altra-curriculum-explorer-theme";
import { ALTRA_APPS } from "../../util/custom-types";
import { renderPreviewModalContentForBlockType } from "../../util/render-preview-modal-for-block-type";
import { ExplorerPreviewModalContainer } from "../explorerPreviewModals/ExplorerPreviewModalContainer";
type ExplorerResultChildInlineContainerProps = {
  isSelectable: boolean;
  selected: boolean;
  appName: ALTRA_APPS;
  block: Block;
};

/**
 * Inline view of Curriculum Explorer result card used for displaying children within result card
 */
export const ExplorerResultChildInlineContainer: FC<
  ExplorerResultChildInlineContainerProps
> = ({ isSelectable, block, selected, appName, children }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => setOpen(false), []);

  const dispatch = useAppDispatch();

  const selectedBlockResults: Array<number> = useAppSelector(
    (state) => state.userAppInfo?.blocksSelectedForAddingToResource!
  );

  const selectBlock = () => {
    dispatch(
      blocksSelectedForAddingToResource([...selectedBlockResults, block.id])
    );
  };
  const deSelectBlock = () => {
    const localBlocks = Array.from(selectedBlockResults).filter(
      (b) => b !== block.id
    );
    dispatch(blocksSelectedForAddingToResource(localBlocks));
  };

  return (
    <>
      <StyledBlockCard
        style={{
          backgroundColor: "#FFF",
          // cursor: isSelectable ? "pointer" : "default",
          display: "flex",
          border: `2px solid ${
            selected && isSelectable && theme.palette.success.main
          }`,
        }}
      >
        <StyledCardContent>{children}</StyledCardContent>
        <StyledIconButtons>
          <IconButton
            sx={{ color: alterCurriculumExplorerTheme.palette.primary.main }}
            onClick={handleOpen}
          >
            <OpenInNewIcon />
          </IconButton>
          {isSelectable && (
            <Button
              sx={{ maxHeight: "2em", maxWidth: "1em" }}
              size={"small"}
              onClick={() =>
                selectedBlockResults.includes(block.id)
                  ? deSelectBlock()
                  : selectBlock()
              }
              variant={"contained"}
              color={
                selectedBlockResults?.includes(block.id) ? "success" : "primary"
              }
            >
              <CheckCircleIcon fontSize={"small"} />
            </Button>
          )}
        </StyledIconButtons>
      </StyledBlockCard>
      <ExplorerPreviewModalContainer
        appName={appName}
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        block={block}
      >
        {renderPreviewModalContentForBlockType(block)}
      </ExplorerPreviewModalContainer>
    </>
  );
};

const StyledBlockCard = styled("div")`
  flex-direction: row;
  margin: 10px;
  border-radius: 10px;
`;

const StyledCardContent = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
`;
const StyledIconButtons = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
