import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import CurriculumExplorer from "../../../assets/icons/GraphicPastPaperExplorer.svg";
import CEHomeBackground from "../../../assets/images/CESelectionBackground.svg";
import { InitialFlowCard } from "../../atoms/InitialFlowCard";
import { AltraTopAppBar } from "../../molecules/AltraTopAppBar";
import { InitialFlowContainerCard } from "../../molecules/InitialFlowContainerCard";
import { InitialFlowContainerCardMobile } from "../../molecules/InitialFlowContainerCardMobile";
import {
  Curriculum,
  Topic,
  TopicLabel,
} from "../../redux/applicationContext/types";
import { useAppSelector } from "../../redux/hook";
import { ALTRA_APPS } from "../../util/custom-types";
import {
  getTopicLabelIdsForTier,
  getUnionOfValuesForKeys,
} from "../../util/helpers";
import { useIsMobile } from "../../util/useIsMobile";

type AppSelectionProps = {
  appName: ALTRA_APPS;
};

export const AltraAppSelectionScreen: FC<AppSelectionProps> = ({ appName }) => {
  const history = useHistory();
  const teacherOrStudent = useAppSelector(
    (state) => state.userAppInfo.teacherOrStudent
  );
  const [loadingContinue, setLoadingContinue] = React.useState(false);

  const topicLabels: { [curriculumId: number]: TopicLabel[] } = useAppSelector(
    (state) => state.applicationContext.topicLabels
  );
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext.topics
  );
  const curriculums: Array<Curriculum> = useAppSelector(
    (state) => state.applicationContext.curriculums
  );
  const selectedCurriculumsIds: Array<number> = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId!
  );
  const isTabletOrMobile = useIsMobile();

  const topTierTopicsForSelectedCurriculums: Array<Topic> = useAppSelector(
    (state) => {
      const topTierTopicLevelIds: number[] = getTopicLabelIdsForTier(
        state.userAppInfo.selectedCurriculumsId || [],
        state.applicationContext.topicLabels,
        1
      );

      return getUnionOfValuesForKeys<Topic>(
        state.applicationContext.topics,
        state.userAppInfo?.selectedCurriculumsId
      ).filter((t) => topTierTopicLevelIds.includes(t.topic_label_id));
    }
  );
  const selectedTopTierTopicIds: Array<number> =
    getUnionOfValuesForKeys<number>(
      useAppSelector((state) => state.userAppInfo?.selectedTopicsId) || {},
      topTierTopicsForSelectedCurriculums.map((t) => t.topic_label_id)
    );

  const subject = selectedTopTierTopicIds
    ?.filter(
      (tId) =>
        getUnionOfValuesForKeys(topicLabels)
          ?.find(
            (tl) =>
              tl.id ===
              getUnionOfValuesForKeys(topics).find((t) => t.id === tId)
                ?.topic_label_id
          )
          ?.title?.toLowerCase() !== "quick search"
    )
    ?.map(
      (scId) =>
        topTierTopicsForSelectedCurriculums.find((c) => c.id === scId)?.title ||
        ""
    )
    .join(", ");

  const renderChooseAppContent = () => {
    return (
      <InitialFlowContainerCard
        onBackClick={() => history.push("/topicSelection")}
        progress={4}
      >
        <TypographyContainer variant={"h1"}>
          <Typography variant={"h1"}>
            How would you like to use Altra?
          </Typography>
        </TypographyContainer>
        <div
          style={{
            gridTemplateColumns: `repeat(${
              teacherOrStudent === "TEACHER" ? 2 : 1
            }, 1fr)`,
            padding: "0 1em",
            display: "grid",
            gap: "1em",
            marginBottom: "20px",
          }}
        >
          <InitialFlowCard
            loading={loadingContinue}
            title={`Find ${subject || ""} questions and ${
              teacherOrStudent === "TEACHER"
                ? "learning materials"
                : "revision materials"
            }`}
            icon={CurriculumExplorer}
            onClick={() => {
              setLoadingContinue(true);
              window.open(
                `${process.env.REACT_APP_CURRICULUM_EXPLORER_URL}/explorer`,
                "_self"
              );
            }}
            small={true}
            appName={appName}
          />
          {/*{teacherOrStudent === "TEACHER" && (*/}
          {/*  <InitialFlowCard*/}
          {/*    loading={loadingContinue}*/}
          {/*    title={`Create my own ${subject} learning materials`}*/}
          {/*    icon={ResourceBuilder}*/}
          {/*    onClick={() => {*/}
          {/*      setLoadingContinue(true);*/}
          {/*      window.open(*/}
          {/*        `${process.env.REACT_APP_RESOURCE_BUILDER_URL}/myResources`,*/}
          {/*        "_self"*/}
          {/*      );*/}
          {/*    }}*/}
          {/*    small={true}*/}
          {/*    appName={appName}*/}
          {/*  />*/}
          {/*)}*/}
        </div>
      </InitialFlowContainerCard>
    );
  };

  const renderMobileView = () => (
    <StyledContainer
      style={{
        backgroundImage: `url(${CEHomeBackground})`,
      }}
    >
      <AltraTopAppBar appName={appName} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: "3em auto auto auto",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <InitialFlowContainerCardMobile
          onBackClick={() => history.push("/topicSelection")}
          progress={4}
        >
          <TypographyContainer variant={"h1"}>
            <Typography variant={"h1"}>
              How would you like to use Altra?
            </Typography>
          </TypographyContainer>
          <div
            style={{
              padding: "0 1em",
              display: "grid",
              gap: "1em",
              marginBottom: "20px",
            }}
          >
            <InitialFlowCard
              title={`Find ${subject || ""} questions and ${
                teacherOrStudent === "TEACHER"
                  ? "learning materials"
                  : "revision materials"
              }`}
              icon={CurriculumExplorer}
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_CURRICULUM_EXPLORER_URL}/explorer`,
                  "_self"
                )
              }
              small={true}
              appName={appName}
            />
            {/*{teacherOrStudent === "TEACHER" && (*/}
            {/*  <InitialFlowCard*/}
            {/*    title={`Create my own ${subject} learning materials`}*/}
            {/*    icon={ResourceBuilder}*/}
            {/*    onClick={() =>*/}
            {/*      window.open(*/}
            {/*        `${process.env.REACT_APP_RESOURCE_BUILDER_URL}/myResources`,*/}
            {/*        "_self"*/}
            {/*      )*/}
            {/*    }*/}
            {/*    small={true}*/}
            {/*    appName={appName}*/}
            {/*  />*/}
            {/*)}*/}
          </div>
        </InitialFlowContainerCardMobile>
      </div>
    </StyledContainer>
  );

  return isTabletOrMobile ? (
    renderMobileView()
  ) : (
    <StyledContainer
      style={{
        backgroundImage: `url(${CEHomeBackground})`,
      }}
    >
      <AltraTopAppBar appName={appName} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: "7em auto auto auto",
          width: "70%",
        }}
      >
        {renderChooseAppContent()}
      </div>
    </StyledContainer>
  );
};
const TypographyContainer = styled(Typography)(({ theme }) => ({
  alignContent: "start",
  width: "90%",
  marginTop: 20,
  marginBottom: 20,
}));
const RootTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "7em auto auto auto",
  width: "70%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    marginTop: "12em",
  },
}));

const StyledContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "100vh",
  width: "100vw",
  left: 0,
}));
