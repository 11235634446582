import { ErrorSnackbar } from "@altra-apps/common/src/atoms/ErrorSnackbar";
import { AltraTopAppBar } from "@altra-apps/common/src/molecules/AltraTopAppBar";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Student from "../../assets/icons/Student.svg";
import Teacher from "../../assets/icons/Teacher.svg";
import { InitialFlowCard } from "../atoms/InitialFlowCard";
import { InitialFlowContainerCard } from "../molecules/InitialFlowContainerCard";
import { useAppDispatch } from "../redux/hook";
import { userAppInfoTeacherOrStudent } from "../redux/user/actions";
import { ALTRA_APPS } from "../util/custom-types";
type AppOverviewScreenProp = {
  appName: ALTRA_APPS;
};

/**
 * This component syncs the Auth0 user to the application database.
 */
export const AppOverviewScreen: React.FC<AppOverviewScreenProp> = (prop) => {
  // use to set runtime error
  const [error, setError] = useState<string>();
  // snackbar state handler to make it open or close
  const [snackbarState, setSnackbarState] = useState<boolean>(false);
  // use for redirecting user to the next screen

  const dispatch = useAppDispatch();

  const history = useHistory();

  const handleClick = (type: "TEACHER" | "STUDENT") => {
    dispatch(userAppInfoTeacherOrStudent(type));
    history.push("/curriculumSelection");
  };

  const renderIsTeacherContent = () => {
    return (
      <InitialFlowContainerCard onBackClick={() => null} progress={1}>
        <TypographyContainer variant={"h1"}>
          <Typography variant={"h1"}>
            Are you a teacher or a student?
          </Typography>
        </TypographyContainer>
        <div
          style={{
            gridTemplateColumns: `repeat(2, 1fr)`,
            padding: "0 1em",
            display: "grid",
            gap: "1em",
            marginBottom: "20px",
          }}
        >
          <InitialFlowCard
            title={"Teacher"}
            icon={Teacher}
            onClick={() => handleClick("TEACHER")}
            appName={prop.appName}
          />
          <InitialFlowCard
            title={"Student"}
            icon={Student}
            onClick={() => handleClick("STUDENT")}
            appName={prop.appName}
          />
        </div>
      </InitialFlowContainerCard>
    );
  };

  return (
    <>
      <AltraTopAppBar appName={prop.appName} />
      <ErrorSnackbar error={error!} severity={"error"} state={snackbarState} />
      <RootTitleContainer>{renderIsTeacherContent()}</RootTitleContainer>
    </>
  );
};

const RootTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "7em auto auto auto",
  width: "70%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    marginTop: "12em",
  },
}));

const TypographyContainer = styled(Typography)(({ theme }) => ({
  alignContent: "start",
  width: "90%",
  marginTop: 20,
  marginBottom: 20,
}));
