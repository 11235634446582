import {
  CreateSubmissionMutationVariables,
  useCreateSubmissionMutation,
} from "@altra-apps/common/src/graphql/types";
import { AltraTopAppBar } from "@altra-apps/common/src/molecules/AltraTopAppBar";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import { userAppInfoCreateSubmission } from "@altra-apps/common/src/redux/user/actions";
import { UserInfo } from "@altra-apps/common/src/redux/user/types";
import { ALTRA_APPS } from "@altra-apps/common/src/util/custom-types";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import QuizIcon from "@mui/icons-material/Quiz";
import { LoadingButton } from "@mui/lab";
import { Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SubmissionSidebar } from "../../molecules/SubmissionSidebar";

/**
 * Screen to view submissions, users can create a submission with stores answers and feedback for questions
 * @constructor
 */
export const AssignmentScreen: FC = () => {
  const routerParams = useParams();
  const history = useHistory();
  const isTabletOrMobile = useIsMobile();

  const darkMode = false;
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);

  const [creatingSubmission, setCreatingSubmission] = useState<boolean>(false);
  const [createSubmissionMutation] = useCreateSubmissionMutation();
  const dispatch = useAppDispatch();

  const createSubmissionAndNavigate = async () => {
    setCreatingSubmission(true);
    const createVariables: CreateSubmissionMutationVariables = {
      submission_spec: {
        //@ts-expect-error
        block_id: routerParams?.id,
        requester_user_id: user && user?.id ? user?.id : undefined,
        publicly_accessible: false,
      },
    };

    const { data, errors } = await createSubmissionMutation({
      variables: createVariables,
    });

    if (data && data?.insert_submission_one?.id) {
      dispatch(userAppInfoCreateSubmission(data?.insert_submission_one));
      history.push(`/submission/${data?.insert_submission_one?.id}`);
    }
    setCreatingSubmission(false);
  };

  const renderInfoBox = () => (
    <StyledDarkInfoBox isTabletOrMobile={isTabletOrMobile}>
      <StyledRow>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              color: "white",
            }}
            style={{ marginBottom: "0.5em" }}
            variant={"h1"}
          >
            Welcome to your assignment
          </Typography>
          <Typography
            sx={{
              color: "white",
            }}
            style={{ marginBottom: "0.5em" }}
            variant={"h3"}
          >
            Click below to begin answering questions in this assignment. <br />{" "}
            <br /> Once you are finished, you will be able to check your answers
            and send your submission to your educator for feedback.
          </Typography>
        </div>
        <QuizIcon
          sx={{
            color: "white",
          }}
        />
      </StyledRow>

      <LoadingButton
        loading={creatingSubmission}
        sx={{ width: "100%", marginTop: "1em" }}
        size="large"
        variant={"contained"}
        onClick={() => createSubmissionAndNavigate()}
      >
        Start my assignment
      </LoadingButton>
    </StyledDarkInfoBox>
  );

  return (
    <>
      <AltraTopAppBar displaySubCompoments appName={ALTRA_APPS.CE} />
      <>
        <Toolbar />
        <div
          style={{
            height: "95%",
            width: "100vw",
            overflow: "hidden",
            display: "flex",
            position: "absolute",
            flexDirection: isTabletOrMobile ? "column" : "row",
            left: 0,
            backgroundColor: darkMode ? "#262626" : "rgb(248, 248, 248)",
          }}
        >
          {!isTabletOrMobile && (
            <SubmissionSidebar>{renderInfoBox()}</SubmissionSidebar>
          )}
          {isTabletOrMobile && renderInfoBox()}
          <StyledPreviewColumn
            id={"revision-resource-column"}
            style={{
              position: "relative",

              overflow: "auto",
              justifyContent: "flex-start",
              alignItems: "center",
              width: isTabletOrMobile ? "100%" : "80%",
            }}
          >
            <StyledBorderedResource
              style={{
                borderRadius: isTabletOrMobile ? 0 : "8px",
                border: isTabletOrMobile ? "none" : "1px solid #606060",
                height: "100vh",
                width: isTabletOrMobile ? "100%" : "50%",
                margin: isTabletOrMobile ? 0 : "2em",
              }}
            />
          </StyledPreviewColumn>
        </div>
      </>
    </>
  );
};

const StyledPreviewColumn = styled("div")`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media print {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
  @media print {
    background-color: white;
    width: 100%;
    overflow: visible;
  }
`;

const StyledResourceContainer = styled("div")`
  display: flex;
  min-height: 90%;
  flex-direction: column;
  border-radius: 8px;
  @media print {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
`;

interface StyledTitleDescProps {
  isTabletOrMobile: boolean;
}
const StyledDarkInfoBox = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "isTabletOrMobile",
})<StyledTitleDescProps>(({ theme, isTabletOrMobile }) => ({
  backgroundColor: "#262626",
  display: "flex",
  flexDirection: "column",

  padding: "1em",
  borderRadius: isTabletOrMobile ? 0 : "20px",
  width: isTabletOrMobile ? "auto" : "80%",
  marginBottom: isTabletOrMobile ? 0 : "1em",
}));

const StyledRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));
const StyledBorderedResource = styled("div")`
  background-color: rgba(38, 38, 38, 0.49);
`;
