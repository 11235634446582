import ResourceBlockIcon from "@altra-apps/common/assets/blockTypeIcons/Resource.svg";
import { Tooltip, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import {
  AdditionalFieldsElement,
  ResourceElement,
} from "../../util/custom-editor-types";
import { ExplorerResultChildInlineContent } from "./ExplorerResultChildInlineContent";

type ResourceElementResultCardContentsProps = {
  block: ResourceElement & AdditionalFieldsElement;
  tags: Tag[];
  position: number | null | undefined;
  isSelectable: boolean;
};

/**
 * Resource Block result card view in Curriculum Explorer
 */
export const ResourceElementResultCardContents: FC<
  ResourceElementResultCardContentsProps
> = ({ block, tags, position, isSelectable }) => {
  const theme = useTheme();

  return (
    <>
      <StyledTopRow>
        <StyledTextContainer>
          <Typography
            variant={"h1"}
            style={{
              color: theme.palette.text.primary,
            }}
          >
            {block.title}
          </Typography>
          <Typography
            //@ts-expect-error
            variant={"h7"}
            style={{
              color: theme.palette.text.primary,
            }}
          >
            {block.description}
          </Typography>
        </StyledTextContainer>
        <Tooltip placement={"left"} title={"Resource"}>
          <img
            style={{
              height: "40px",
              marginBottom: 10,
              display: "inline-block",
            }}
            src={ResourceBlockIcon}
            alt={"Resource Block"}
          />
        </Tooltip>
      </StyledTopRow>

      <ExplorerResultChildInlineContent
        block={{
          id: parseInt(block.id),
          tags: tags,
          block: block.children,
          active: true,
          parent_id: 0,
          position: position,
          teacherNotes: block.teacherNotes || null,
          type: block.type,
        }}
        isSelectable={isSelectable}
      />
    </>
  );
};

const StyledTopRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const StyledTextContainer = styled("div")`
  display: flex;
  width: 75%;
  flex-direction: column;
  justify-content: space-between;
`;
