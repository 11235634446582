import { styled } from "@mui/system";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { userAppInfoSelectedBlocksId } from "../redux/user/actions";
import { KeyValue } from "../util/custom-types";
import { BlockCard } from "./BlockCard";

interface AutocompleteBlockMultiSelectDropDownProps {
  items: Array<KeyValue>;
  inputLabel: string;
}

/**
 * Autocomplete wrapper component for changing topics and curriculums
 * - Inlcludes logic to update redux values with selected topics
 */
export const AutocompleteBlockMultiSelectDropDown: FC<
  AutocompleteBlockMultiSelectDropDownProps
> = (prop) => {
  const dispatch = useAppDispatch();

  const selectedBlockIds: Array<number> = useAppSelector(
    (state) => state.userAppInfo?.selectedBlocksId!
  );

  const [selectedItems, setSelectedItems] =
    React.useState<Array<number>>(selectedBlockIds);

  const getItemLabel = (item: KeyValue): string => item.value;

  // useEffect(() => {
  //   console.log(selectedItems);
  //   setSelectedItems(selectedBlockIds);
  // }, [selectedBlockIds]);

  /**
   * Update selected blocks in Redux
   */
  const updateReduxStore = (selectedIdsArray: Array<number>): void => {
    dispatch(
      userAppInfoSelectedBlocksId({
        blockIds: selectedIdsArray,
      })
    );
  };

  /**
   * On item change updates selected ids with selected id
   * @param event
   * @param id
   */
  // const handleItemChange = (event, keyValues: Array<KeyValue>) => {
  //   let ids: Array<number> = keyValues.map((f) => f.key);
  //
  //   updateReduxStore(ids);
  //   setSelectedItems(ids);
  // };
  const handleCheckboxItemChange = (event, id: number) => {
    let ids: number[] = selectedBlockIds;
    if (event.target.checked) ids = ids.concat(id);
    else ids = ids.filter((id1) => id1 !== id);

    updateReduxStore(ids);
    setSelectedItems(ids);
  };

  return (
    <StyledFormControl
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {prop.items
        .filter((b) => [0, 1, 2].includes(b.key))
        ?.map((b) => (
          <BlockCard
            handleCheckboxItemChange={handleCheckboxItemChange}
            keyValue={b}
            selectedItems={selectedItems}
          />
        ))}
      {/*<Autocomplete*/}
      {/*  multiple*/}
      {/*  id="combo-box-demo"*/}
      {/*  options={prop.items}*/}
      {/*  size="small"*/}
      {/*  limitTags={2}*/}
      {/*  renderInput={(params) => (*/}
      {/*    <TextField {...params} rows={1} label={prop.inputLabel} />*/}
      {/*  )}*/}
      {/*  renderOption={(props, option) => (*/}
      {/*    <Box*/}
      {/*      component="li"*/}
      {/*      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}*/}
      {/*      {...props}*/}
      {/*    >*/}
      {/*      <img*/}
      {/*        loading="lazy"*/}
      {/*        width="20"*/}
      {/*        src={*/}
      {/*          BLOCK_ICONS_FOR_EXPLORER.find((b) => b.value === option.value)*/}
      {/*            ?.diagram || ""*/}
      {/*        }*/}
      {/*        alt=""*/}
      {/*      />*/}
      {/*      {option.value}*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*  renderTags={(tagValue, getTagProps) => {*/}
      {/*    return tagValue.map((option, index) => {*/}
      {/*      return (*/}
      {/*        <Chip*/}
      {/*          size={"small"}*/}
      {/*          label={option.value}*/}
      {/*          variant="outlined"*/}
      {/*          {...getTagProps({ index })}*/}
      {/*          sx={{*/}
      {/*            maxWidth: "200px",*/}
      {/*            backgroundColor: `${CHIP_TYPES.BLOCK_TYPE}`,*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      );*/}
      {/*    });*/}
      {/*  }}*/}
      {/*  getOptionLabel={(option) => getItemLabel(option)}*/}
      {/*  onChange={(event, newValue) => handleItemChange(event, newValue!)}*/}
      {/*  value={prop.items.filter(*/}
      {/*    (t) => selectedItems && selectedItems?.includes(t.key)*/}
      {/*  )}*/}
      {/*/>*/}
    </StyledFormControl>
  );
};

const StyledFormControl = styled("div")(({ theme }) => ({
  marginTop: "0.7em",
  marginBottom: "0.7em",
}));
