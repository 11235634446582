import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import {
  HeadingOneElement,
  HeadingTwoElement,
} from "../../util/custom-editor-types";
import { ViewHeadingElement } from "../curriculumExplorerPreviewElements/ViewHeadingElement";

type HeadingElementPreviewModalContentsProps = {
  block: HeadingOneElement | HeadingTwoElement;
  tags: Tag[];
  position: number | null | undefined;
  type: "ONE" | "TWO";
};

/**
 * Preview modal contents for a Heading block
 * @param block
 * @param tags
 * @param children
 * @constructor
 */
export const HeadingElementPreviewModalContents: FC<
  HeadingElementPreviewModalContentsProps
> = ({ block, type, tags, children }) => {
  return (
    <div style={{ width: "100%" }}>
      {/*<StyledTopRow>*/}
      {/*  <Tooltip placement={"left"} title={"Body text"}>*/}
      {/*    <img*/}
      {/*      style={{*/}
      {/*        height: "30px",*/}
      {/*        marginBottom: 10,*/}
      {/*        marginRight: "10px",*/}
      {/*        display: "inline-flex",*/}
      {/*      }}*/}
      {/*      src={type === "ONE" ? HeadingOneBlockIcon : HeadingTwoBlockIcon}*/}
      {/*      alt={"Heading Block"}*/}
      {/*    />*/}
      {/*  </Tooltip>*/}
      {/*</StyledTopRow>*/}

      <ViewHeadingElement doNotLoadChildren={false} block={block} type={type} />
    </div>
  );
};

const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
}));
