import { ViewParagraphElement } from "@altra-apps/common/src/molecules/curriculumExplorerPreviewElements/ViewParagraphElement";
import { MathsTypography } from "@altra-apps/common/src/molecules/MathsTypography";
import { altraTheme } from "@altra-apps/common/src/styling/altra-theme";
import { styled } from "@mui/material/styles";
import React, { FC, useMemo } from "react";
import { Descendant } from "slate";

type ViewSubmissionAnswerElementProps = {
  block: Descendant[];
};

/**
 * View only element for displaying Section blocks
 * @param block
 * @constructor
 */
export const ViewSubmissionAnswerElement: FC<
  ViewSubmissionAnswerElementProps
> = ({ block }) => {
  const renderBlock = useMemo(
    () =>
      block?.slice()?.map((childBlock, index) => (
        <div style={{ margin: "0.5em 0", padding: "1em" }}>
          {/*@ts-expect-error*/}
          {childBlock.type === "paragraph" ? (
            //@ts-expect-error
            <ViewParagraphElement block={childBlock} />
          ) : (
            <MathsTypography
              //@ts-expect-error
              latexString={childBlock.latex}
            />
          )}
        </div>
      )),
    [block]
  );

  return (
    <StyledSectionContainer style={altraTheme.typography.body1}>
      <>{renderBlock}</>
    </StyledSectionContainer>
  );
};

const StyledSectionContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 1em 0;
  height: 100%;
  @media print {
    position: relative;
  }
`;
const StyledTitleDesc = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "2em",
}));
