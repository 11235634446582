import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import { PassageElement } from "../../util/custom-editor-types";
import { ViewPassageElement } from "../curriculumExplorerPreviewElements/ViewPassageElement";

type PassageElementPreviewModalContentsProps = {
  block: PassageElement;
  tags: Tag[];
  position: number | null | undefined;
};

/**
 * Preview modal contents for a Passage block
 * @param block
 * @param tags
 * @param children
 * @constructor
 */
export const PassageElementPreviewModalContents: FC<
  PassageElementPreviewModalContentsProps
> = ({ block, tags, children }) => {
  return (
    <div style={{ width: "100%" }}>
      {/*<StyledTopRow>*/}
      {/*  <Tooltip placement={"left"} title={"Passage"}>*/}
      {/*    <img*/}
      {/*      style={{*/}
      {/*        height: "30px",*/}
      {/*        marginBottom: 10,*/}
      {/*        marginRight: "10px",*/}
      {/*        display: "inline-flex",*/}
      {/*      }}*/}
      {/*      src={PassageBlockIcon}*/}
      {/*      alt={"Passage Block"}*/}
      {/*    />*/}
      {/*  </Tooltip>*/}
      {/*</StyledTopRow>*/}

      <ViewPassageElement
        block={block}
        displayBorder={false}
        showPreviewIcon={true}
        viewWatermark={false}
        doNotLoadChildren={false}
      />
    </div>
  );
};

const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
}));
