import { ErrorSnackbar } from "@altra-apps/common/src/atoms/ErrorSnackbar";
import { AltraTopAppBar } from "@altra-apps/common/src/molecules/AltraTopAppBar";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Student from "../../assets/icons/Student.svg";
import Teacher from "../../assets/icons/Teacher.svg";
import { InitialFlowCard } from "../atoms/InitialFlowCard";
import { InitialFlowContainerCardMobile } from "../molecules/InitialFlowContainerCardMobile";
import { useAppDispatch } from "../redux/hook";
import { userAppInfoTeacherOrStudent } from "../redux/user/actions";
import { ALTRA_APPS } from "../util/custom-types";
type AppOverviewScreenProp = {
  appName: ALTRA_APPS;
};

/**
 * This component syncs the Auth0 user to the application database.
 */
export const AppOverviewScreenMobile: React.FC<AppOverviewScreenProp> = (
  prop
) => {
  // use to set runtime error
  const [error, setError] = useState<string>();
  // snackbar state handler to make it open or close
  const [snackbarState, setSnackbarState] = useState<boolean>(false);
  // use for redirecting user to the next screen

  const dispatch = useAppDispatch();

  const history = useHistory();

  const handleClick = (type: "TEACHER" | "STUDENT") => {
    dispatch(userAppInfoTeacherOrStudent(type));
    history.push("/curriculumSelection");
  };

  const renderIsTeacherContent = () => {
    return (
      <InitialFlowContainerCardMobile onBackClick={() => null} progress={1}>
        <TypographyContainer variant={"h1"}>
          <Typography variant={"h1"}>
            Are you a teacher or a student?
          </Typography>
        </TypographyContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InitialFlowCard
            title={"Teacher"}
            icon={Teacher}
            onClick={() => handleClick("TEACHER")}
            appName={prop.appName}
          />
          <InitialFlowCard
            title={"Student"}
            icon={Student}
            onClick={() => handleClick("STUDENT")}
            appName={prop.appName}
          />
        </div>
      </InitialFlowContainerCardMobile>
    );
  };

  return (
    <>
      <AltraTopAppBar appName={prop.appName} />
      <ErrorSnackbar error={error!} severity={"error"} state={snackbarState} />
      <RootTitleContainer>{renderIsTeacherContent()}</RootTitleContainer>
    </>
  );
};

const RootTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "3em auto auto auto",
  width: "100%",
  backgroundColor: "white",
}));

const TypographyContainer = styled(Typography)(({ theme }) => ({
  alignContent: "start",
  width: "90%",
  marginTop: 20,
  marginBottom: 20,
}));
