import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import { ParagraphElement } from "../../util/custom-editor-types";
import { ViewParagraphElement } from "../curriculumExplorerPreviewElements/ViewParagraphElement";

type ParagraphElementResultCardContentsProps = {
  block: ParagraphElement;
  tags: Tag[];
  inline?: boolean;
  isSelectable: boolean;
};

/**
 * Paragraph Block result card view in Curriculum Explorer
 */
export const ParagraphElementResultCardContents: FC<
  ParagraphElementResultCardContentsProps
> = ({ block, tags, inline }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: inline ? "row" : "column",
      }}
    >
      <StyledTopRow
        sx={{
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        {/*<Tooltip placement={"left"} title={"Body text"}>*/}
        {/*  <img*/}
        {/*    style={{*/}
        {/*      height: "30px",*/}
        {/*      marginBottom: 10,*/}
        {/*      marginRight: "10px",*/}
        {/*      display: "inline-flex",*/}
        {/*    }}*/}
        {/*    src={ParagraphBlockIcon}*/}
        {/*    alt={"Paragraph Block"}*/}
        {/*  />*/}
        {/*</Tooltip>*/}
      </StyledTopRow>
      <StyledChildParagraph>
        <ViewParagraphElement inline={inline} block={block} />
      </StyledChildParagraph>
    </div>
  );
};

const StyledTopRow = styled("div")`
  display: flex;
`;

const StyledChildParagraph = styled("div")(({ theme }) => ({
  display: "inline-block",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
