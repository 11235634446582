import SectionBlockIcon from "@altra-apps/common/assets/blockTypeIcons/Section.svg";
import { Tooltip, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import {
  AdditionalFieldsElement,
  SectionElement,
} from "../../util/custom-editor-types";
import { ExplorerResultChildInlineContent } from "./ExplorerResultChildInlineContent";

type SectionElementResultCardContentsProps = {
  block: SectionElement & AdditionalFieldsElement;
  tags: Tag[];
  inline?: boolean;
  position: number | null | undefined;
  isSelectable: boolean;
};

/**
 * Resource Block result card view in Curriculum Explorer
 */
export const SectionElementResultCardContents: FC<
  SectionElementResultCardContentsProps
> = ({ block, tags, inline, position, isSelectable }) => {
  const theme = useTheme();

  return (
    <>
      <StyledTopRow
        sx={{
          flexDirection: inline ? "row-reverse" : "row",
          justifyContent: inline ? "flex-end" : "space-between",
        }}
      >
        <StyledTextContainer>
          <Typography
            variant={inline ? "body1" : "h1"}
            style={{
              color: theme.palette.text.primary,
            }}
          >
            {block.title || "Section"}
          </Typography>
          <Typography
            //@ts-expect-error
            variant={inline ? "subtitle2" : "h7"}
            style={{
              color: theme.palette.text.primary,
            }}
          >
            {block.description}
          </Typography>
        </StyledTextContainer>
        <Tooltip placement={"left"} title={"Section"}>
          <img
            style={{
              height: "40px",
              marginBottom: 10,
              marginRight: "10px",
              display: "inline-block",
            }}
            src={SectionBlockIcon}
            alt={"Section Block"}
          />
        </Tooltip>
      </StyledTopRow>

      {!inline && (
        <>
          <ExplorerResultChildInlineContent
            block={{
              id: parseInt(block.id),
              tags: tags,
              block: block.children,
              active: true,
              parent_id: 0,
              position: position,
              teacherNotes: block.teacherNotes || null,
              type: block.type,
            }}
            isSelectable={isSelectable}
          />
        </>
      )}
    </>
  );
};

const StyledTopRow = styled("div")`
  display: flex;
`;
const StyledTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
