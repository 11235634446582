import { Close, Search } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { userAppInfoSearchTerm } from "../redux/user/actions";
import { Status } from "../util/custom-types";
import { useDebounce } from "../util/use-debounce";

export type SearchBarProps = {
  small?: boolean;
};

/**
 * Search Bar for Explorer application
 * @param fetchSearchResults
 * @constructor
 */
export const SearchBar: FC<SearchBarProps> = ({ small = false }) => {
  const dispatch = useAppDispatch();

  const searchTerm: string = useAppSelector(
    (state) => state.userAppInfo?.searchTerm
  );

  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);
  const debouncedSearchTerm = useDebounce(localSearchTerm, 500);

  useEffect(() => {
    setLocalSearchTerm(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(userAppInfoSearchTerm(debouncedSearchTerm));
  }, [debouncedSearchTerm]);

  const loadingStatus: Status = useAppSelector(
    (state) => state.applicationContext.loadingStatus
  ).status;

  return (
    <TextField
      label="Search by keyword"
      variant="outlined"
      size={small ? "small" : "medium"}
      color="primary"
      value={localSearchTerm}
      onChange={(event) => setLocalSearchTerm(event.target.value)}
      style={{
        backgroundColor: "#FFF",
        borderRadius: "5px",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="search" edge="end">
              {loadingStatus === Status.INITIAL ||
              loadingStatus === Status.SEARCHING ? (
                <CircularProgress size={20} />
              ) : localSearchTerm ? (
                <Close onClick={() => setLocalSearchTerm("")} />
              ) : (
                <Search />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
