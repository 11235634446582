import {
  GetTopicsWithChildrenQueryVariables,
  GetTopTierTopicsQueryVariables,
  useGetTopicsWithChildrenLazyQuery,
  useGetTopTierTopicsLazyQuery,
} from "@altra-apps/common/src/graphql/types";
import {
  updateLoadingStatus,
  updateTopicLabelsForCurriculum,
  updateTopicsForCurriculum,
} from "@altra-apps/common/src/redux/applicationContext/actions";
import {
  Topic,
  TopicLabel,
} from "@altra-apps/common/src/redux/applicationContext/types";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import {
  userAppInfoFirstSearchExecuted,
  userAppInfoRedirectPath,
  userAppInfoResetSelectedTopicsId,
  userAppInfoSelectedTopicsId,
} from "@altra-apps/common/src/redux/user/actions";
import { ALTRA_APPS, Status } from "@altra-apps/common/src/util/custom-types";
import {
  getParentTopicsForTopic,
  getUnionOfValuesForKeys,
} from "@altra-apps/common/src/util/helpers";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CEHomeBackground from "../../../assets/images/CESelectionBackground.svg";
import RBHomeBackground from "../../../assets/images/RBSelectionBackground.svg";
import { AltraTopAppBar } from "../../molecules/AltraTopAppBar";
import { InitialFlowContainerCard } from "../../molecules/InitialFlowContainerCard";
import { InitialFlowContainerCardMobile } from "../../molecules/InitialFlowContainerCardMobile";
import { ChooseItem } from "../../organisms/ChooseItem";
import { useIsMobile } from "../../util/useIsMobile";

type TopicSelectionScreenProp = {
  appName: ALTRA_APPS;
  onCompleteUrl: string;
};

/**
 * Explorer screen to choose top tier topics for the selected curriculum(s)
 * - Resets the current topics in redux and fetches the top tier topics
 * @param props
 * @constructor
 */
export const TopicSelectionScreen: React.FC<TopicSelectionScreenProp> = (
  props
) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const selectedCurriculumsIds: Array<number> =
    useAppSelector((state) => state.userAppInfo?.selectedCurriculumsId!) || [];
  const [loadingTopics, setLoadingTopics] = useState(true);
  const [loadingContinue, setLoadingContinue] = useState(false);

  const selectedTopicsId: { [topicLabelId: number]: Array<number> } =
    useAppSelector((state) => state.userAppInfo?.selectedTopicsId!);
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext?.topics
  );
  const redirectPath: string | undefined = useAppSelector(
    (state) => state.userAppInfo?.redirectPath
  );
  const isTabletOrMobile = useIsMobile();
  const teacherOrStudent = useAppSelector(
    (state) => state.userAppInfo.teacherOrStudent
  );
  const [
    getTopTierTopicsCallback,
    { data: getTopTierTopicsData, error: getTopTierTopicsError },
  ] = useGetTopTierTopicsLazyQuery();
  const [
    getTopicAndChildrenCallback,
    { data: getTopicAndChildrenData, error: getTopicAndChildrenError },
  ] = useGetTopicsWithChildrenLazyQuery();

  const getTopTierTopics = async () => {
    setLoadingTopics(true);
    let getTopicsInput: GetTopTierTopicsQueryVariables = {
      curriculumIds: selectedCurriculumsIds,
    };
    await getTopTierTopicsCallback({
      variables: getTopicsInput,
    });
  };

  /**
   * Initial load of all topics for selected curriculums
   */
  useEffect(() => {
    if (selectedCurriculumsIds && selectedCurriculumsIds.length > 0) {
      dispatch(
        updateTopicsForCurriculum({
          curriculumId: selectedCurriculumsIds[0],
          topics: [],
        })
      );
      getTopTierTopics();
    } else {
      history.push("/curriculumSelection");
    }
  }, [selectedCurriculumsIds]);

  const updateTopicsInRedux = (queryTopics) => {
    selectedCurriculumsIds?.map((curriculumId) => {
      let localTopicLabels: TopicLabel[] = [];

      const quickSearchTopics: Topic[] =
        getUnionOfValuesForKeys(topics).filter(
          (t) => t.topic_label_title?.toLowerCase() === "quick search"
        ) || [];
      console.log(quickSearchTopics);

      //@ts-expect-error
      const quickSearchTopicLabels: TopicLabel[] =
        quickSearchTopics
          .map((qst) => {
            if (
              qst.topic_label_title &&
              qst.topic_label_title &&
              qst?.topic_label_order_id
            ) {
              return {
                id: qst.topic_label_id,
                title: qst.topic_label_title,
                order_id: qst?.topic_label_order_id,
                topics: [],
              };
            }
          })
          .filter(
            (t) => t !== undefined && t.title.toLowerCase() === "quick search"
          ) || [];

      localTopicLabels = localTopicLabels.concat(quickSearchTopicLabels);
      localTopicLabels = uniqBy(localTopicLabels, "id");

      console.log(queryTopics);
      let localTopics =
        quickSearchTopics.map((t) => ({
          ...t,
          topic_label_id: t.topic_label_id,
          parent_topics: [],
          topic_label_title: t?.topic_label_title,
          topic_label_order_id: t?.topic_label_order_id,
          topic_unit_title: t.title,
          topic_label: {
            id: t.topic_label_id,
            title: t?.topic_label_title,
            order_id: t?.topic_label_order_id,
          },
        })) || [];
      console.log(localTopics);

      localTopics = localTopics.concat(
        queryTopics
          .filter(
            (topic1) =>
              topic1 !== undefined && topic1.curriculum_id === curriculumId
          )
          .map((topic2) => {
            //Add topic ids to each topic label
            if (topic2.topic_label) {
              const label = localTopicLabels.find(
                (tl) => tl.id === topic2.topic_label.id
              );
              if (label) {
                label.topics?.push(topic2.id);
              } else if (
                topic2.topic_label_title?.toLowerCase() !== "quick search"
              ) {
                localTopicLabels.push({
                  ...topic2.topic_label,
                  topics: [topic2.id],
                });
              }
            }

            return {
              ...topic2,
              topic_label_id: topic2?.topic_label?.id,
              parent_topics: [],
              topic_label_title: topic2?.topic_label?.title,
              topic_label_order_id: topic2?.topic_label?.order_id,
              topic_unit_title: "",
            };
          }) || []
      );

      console.log(localTopics);
      localTopics = uniqBy(localTopics, "id");
      console.log(localTopics);

      //@ts-expect-error
      localTopics = localTopics.map((topic3) => {
        const parentTopics =
          getParentTopicsForTopic(topic3.id, localTopics) || [];

        return {
          ...topic3,
          topic_unit_title:
            parentTopics
              ?.reverse()
              ?.map((t) => " -> " + t.value)
              ?.join(" ") + ` -> ${topic3.title}` || [],
          parent_topics: parentTopics,
        };
      });

      dispatch(
        updateTopicsForCurriculum({
          curriculumId: curriculumId,
          topics: localTopics,
        })
      );

      localTopicLabels = localTopicLabels.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      console.log(localTopicLabels);

      dispatch(
        updateTopicLabelsForCurriculum({
          curriculumId: curriculumId,
          topicLabels: localTopicLabels,
        })
      );
    });
  };

  /**
   *   On retrival of blocks, dispatch to redux
   */
  useEffect(() => {
    if (getTopTierTopicsError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get topics",
        })
      );
    } else if (
      getTopTierTopicsData &&
      getTopTierTopicsData.topic &&
      getTopTierTopicsData.topic
    ) {
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved topics",
        })
      );
      updateTopicsInRedux(getTopTierTopicsData.topic);
    }
    setLoadingTopics(false);
  }, [getTopTierTopicsData, getTopTierTopicsError]);

  /**
   *   On retrival of blocks, dispatch to redux
   */
  useEffect(() => {
    console.log(getTopicAndChildrenData);
    console.log(getTopicAndChildrenError);
    if (getTopicAndChildrenError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get topics",
        })
      );
    } else if (
      getTopicAndChildrenData &&
      getTopicAndChildrenData?.get_topics_with_children &&
      getTopicAndChildrenData?.get_topics_with_children
    ) {
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved topics",
        })
      );
      updateTopicsInRedux(getTopicAndChildrenData?.get_topics_with_children);
      setLoadingContinue(false);
      const redirectPathLocal = redirectPath;
      dispatch(userAppInfoRedirectPath(undefined));
      if (redirectPathLocal) {
        history.push(redirectPathLocal);
      } else {
        history.push(props.onCompleteUrl);
      }
    }
  }, [getTopicAndChildrenData, getTopicAndChildrenError]);

  useEffect(() => {
    console.log("restting");
    dispatch(userAppInfoResetSelectedTopicsId());
    if (selectedCurriculumsIds && selectedCurriculumsIds.length > 0) {
      dispatch(
        updateTopicsForCurriculum({
          curriculumId: selectedCurriculumsIds[0],
          topics: [],
        })
      );
      dispatch(userAppInfoFirstSearchExecuted(false));
    } else {
      history.push("/curriculumSelection");
    }
  }, []);

  const handleOnClick = async (selectedTopicId: number) => {
    setLoadingContinue(true);
    localStorage.setItem("subject", selectedTopicId.toString());

    const allTopics: Topic[] = getUnionOfValuesForKeys<Topic>(
      topics,
      selectedCurriculumsIds
    );

    const selectedTopicsByTopicLabel: { [topicLabelId: number]: number[] } = {};

    const topic: Topic | undefined = allTopics?.find(
      (t) => t.id === selectedTopicId
    );
    if (topic)
      selectedTopicsByTopicLabel[topic.topic_label_id]
        ? selectedTopicsByTopicLabel[topic.topic_label_id].push(selectedTopicId)
        : (selectedTopicsByTopicLabel[topic.topic_label_id] = [
            selectedTopicId,
          ]);

    Object.keys(selectedTopicsByTopicLabel).map((key) => {
      dispatch(
        userAppInfoSelectedTopicsId({
          topicIds: selectedTopicsByTopicLabel[key],
          topicLabelId: parseInt(key),
        })
      );
    });

    let getTopicAndChildrenCallbackVariables: GetTopicsWithChildrenQueryVariables =
      {
        ids: `{${selectedTopicId.toString()}}`,
      };
    getTopicAndChildrenCallback({
      variables: getTopicAndChildrenCallbackVariables,
    });
  };

  const renderMobileView = () => (
    <StyledContainer
      style={{
        backgroundImage: `url(${
          props.appName === ALTRA_APPS.CE ? CEHomeBackground : RBHomeBackground
        })`,
      }}
    >
      <AltraTopAppBar appName={props.appName} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: "3em auto auto auto",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <InitialFlowContainerCardMobile
          onBackClick={() => history.push("/curriculumSelection")}
          progress={3}
        >
          <TypographyContainer variant={"h1"}>
            <Typography variant={"h1"}>
              Which subject do you{" "}
              {teacherOrStudent === "TEACHER" ? "teach" : "study"}?
            </Typography>
          </TypographyContainer>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingBottom: "3em",
            }}
          >
            <ChooseItem
              loading={loadingContinue}
              activeOnly={true}
              handleOnClick={handleOnClick}
              items={topics[selectedCurriculumsIds[0]] || []}
              appName={props.appName}
              buttonText={"Continue"}
              title={"Choose your subject"}
              subtitle={""}
              type={"TOPIC"}
              initialLoading={topics === undefined || loadingTopics}
            />
          </div>
        </InitialFlowContainerCardMobile>
      </div>
    </StyledContainer>
  );

  return isTabletOrMobile ? (
    renderMobileView()
  ) : (
    <StyledContainer
      style={{
        backgroundImage: `url(${
          props.appName === ALTRA_APPS.CE ? CEHomeBackground : RBHomeBackground
        })`,
      }}
    >
      <AltraTopAppBar appName={props.appName} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: "7em auto auto auto",
          width: "70%",
        }}
      >
        <InitialFlowContainerCard
          onBackClick={() => history.push("/curriculumSelection")}
          progress={3}
        >
          <TypographyContainer variant={"h1"}>
            <Typography variant={"h1"}>
              Which subject do you{" "}
              {teacherOrStudent === "TEACHER" ? "teach" : "study"}?
            </Typography>
          </TypographyContainer>

          <div
            style={{
              width: "100%",
              height: "350px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ChooseItem
              loading={loadingContinue}
              activeOnly={true}
              handleOnClick={handleOnClick}
              items={topics[selectedCurriculumsIds[0]] || []}
              appName={props.appName}
              buttonText={"Continue"}
              title={"Choose your subject"}
              subtitle={""}
              type={"TOPIC"}
              initialLoading={topics === undefined || loadingTopics}
            />
          </div>
        </InitialFlowContainerCard>
      </div>
    </StyledContainer>
  );
};

const TypographyContainer = styled(Typography)(({ theme }) => ({
  alignContent: "start",
  width: "90%",
  marginTop: 20,
  marginBottom: 20,
}));
const RootTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "7em auto auto auto",
  width: "70%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    marginTop: "12em",
  },
}));

const StyledContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "100vh",
  width: "100vw",
  left: 0,
}));
