import { CodeElementResultCardContents } from "../molecules/explorerResultCards/CodeElementResultCardContents";
import { DiagramElementResultCardContents } from "../molecules/explorerResultCards/DiagramResultCardContents";
import { HeadingElementResultCardContents } from "../molecules/explorerResultCards/HeadingElementResultCardContents";
import { ParagraphElementResultCardContents } from "../molecules/explorerResultCards/ParagraphElementResultCardContents";
import { PassageElementResultCardContents } from "../molecules/explorerResultCards/PassageElementResultCardContents";
import { QuestionTextOnlyResultCardContents } from "../molecules/explorerResultCards/QuestionTextOnlyResultCardContents";
import { QuoteElementResultCardContents } from "../molecules/explorerResultCards/QuoteElementResultCardContents";
import { ResourceElementResultCardContents } from "../molecules/explorerResultCards/ResourceElementResultCardContents";
import { SectionElementResultCardContents } from "../molecules/explorerResultCards/SectionElementResultCardContents";
import { Block } from "../redux/user/types";
import { BLOCK_TYPES } from "./custom-types";

/**
 * Switch statement on bock to render corresponding result card
 * @param block
 * @param inline
 * @param isSelectable
 */
export const renderResultCardContentForBlockType = (
  block: Block,
  inline: boolean,
  isSelectable: boolean
) => {
  const blockType = block["type"];

  switch (blockType) {
    case "resource":
      return (
        <ResourceElementResultCardContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.RESOURCE,
            description: block.block["description"],
            title: block.block["title"],
            children: block.block,
          }}
          position={block.position}
          tags={block.tags}
          isSelectable={isSelectable}
        />
      );
    case "section":
      return (
        <SectionElementResultCardContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.SECTION,
            description: block.block["description"],
            title: block.block["title"],
            children: [],
          }}
          position={block.position}
          tags={block.tags}
          inline={inline}
          isSelectable={isSelectable}
        />
      );
    case "paragraph":
      if (
        (block.block["children"].length === 1 &&
          block.block["children"][0] === { text: "" }) ||
        block.block["children"].length === 0
      ) {
        return;
      }
      return (
        <ParagraphElementResultCardContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.PARAGRAPH,
            children: block.block["children"],
          }}
          tags={block.tags}
          inline={inline}
          isSelectable={isSelectable}
        />
      );
    // case "question-with-sub-parts":
    //   return (
    //     <QuestionWithSubPartsElementResultCardContents
    //       block={{
    //         id: block.id.toString(),
    //         type: blockType,
    //         children: block.block["children"],
    //       }}
    //       tags={block.tags}
    //     />
    //   );
    case "question-text-only":
      return (
        <QuestionTextOnlyResultCardContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.QUESTION_TEXT_ONLY,
            children: block.block["children"],
          }}
          tags={block.tags}
          inline={inline}
          isSelectable={isSelectable}
          position={block.position}
        />
      );
    case "diagram":
      return (
        <DiagramElementResultCardContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.DIAGRAM,
            children: block.block["children"],
            whiteboardImageS3Url: block.block["whiteboardImageS3Url"],
            whiteboardJsonS3Url: block.block["whiteboardJsonS3Url"],
          }}
          tags={block.tags}
          position={block.position}
          inline={inline}
          isSelectable={isSelectable}
        />
      );
    case "passage":
      return (
        <PassageElementResultCardContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.PASSAGE,
            children: block.block["children"],
            description: block.block["description"],
            title: block.block["title"],
          }}
          tags={block.tags}
          inline={inline}
          isSelectable={isSelectable}
          position={block.position}
        />
      );
    case "block-quote":
      return (
        <QuoteElementResultCardContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.BLOCK_QUOTE,
            children: block.block["children"],
          }}
          tags={block.tags}
          inline={inline}
          isSelectable={isSelectable}
          position={block.position}
        />
      );
    case "code":
      return (
        <CodeElementResultCardContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.CODE,
            children: block.block["children"],
          }}
          tags={block.tags}
          inline={inline}
          isSelectable={isSelectable}
          position={block.position}
        />
      );
    case "heading-1":
    case "heading-2":
      return (
        <HeadingElementResultCardContents
          block={{
            id: block.id.toString(),
            type:
              blockType === "heading-1"
                ? BLOCK_TYPES.HEADING_1
                : BLOCK_TYPES.HEADING_2,
            children: block.block["children"],
          }}
          tags={block.tags}
          inline={inline}
          isSelectable={isSelectable}
          type={blockType === "heading-1" ? "ONE" : "TWO"}
          position={block.position}
        />
      );
    default:
      return <div>Unsupported type: {blockType}</div>;
  }
};
