import { useUpdateSubmissionMutation } from "@altra-apps/common/src/graphql/types";
import { HeadingElement } from "@altra-apps/common/src/molecules/resourceBuilderElements/HeadingElement";
import { Leaf } from "@altra-apps/common/src/molecules/resourceBuilderElements/Leaf";
import { MathElement } from "@altra-apps/common/src/molecules/resourceBuilderElements/MathElement";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import { userAppInfoUpdateSubmissionAnswerFeedbackBlock } from "@altra-apps/common/src/redux/user/actions";
import { EditableSubmissionAnswerFeedbackProp } from "@altra-apps/common/src/redux/user/types";
import { useDebounce } from "@altra-apps/common/src/util/use-debounce";
import { Skeleton } from "@mui/lab";
import { Typography } from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createEditor, Descendant } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import { updateLoadingStatus } from "../../redux/applicationContext/actions";
import { altraResourceBuilderTheme } from "../../styling/altra-resource-builder-theme";
import { Status } from "../../util/custom-types";
import {
  onKeyDownSubmission,
  withCustomSubmissionNormaliser,
  withInlines,
} from "../CustomEditorExtractedLogic";
import { CustomSubmissionEditor } from "./CustomSubmissionEditor";

interface SubmissionEditorProps {
  blockId: number;
}
const initialSubmissionValue: Descendant[] = [
  {
    //@ts-expect-error
    type: "paragraph",
    children: [{ text: "" }],
  },
];

/**
 * Simple editor used for answers and feedback for submissions
 * @param blockId
 * @constructor
 */
export const SubmissionEditor: FC<SubmissionEditorProps> = ({ blockId }) => {
  const routerParams = useParams();
  const dispatch = useAppDispatch();
  const [initialLoad, setInitialLoad] = useState(true);

  const submissionBlocks: EditableSubmissionAnswerFeedbackProp[] =
    useAppSelector((state) => state.userAppInfo.editableSubmissionBlocks) || [];

  const submissionAnswerAndFeedbackBlock: EditableSubmissionAnswerFeedbackProp =
    useAppSelector((state) => {
      return (
        state.userAppInfo.editableSubmissionBlocks?.find(
          (b) => b.block_id === blockId
        ) || {
          //@ts-expect-error
          submission_id: routerParams?.id,
          block_id: blockId,
          requester_answer: initialSubmissionValue,
          marker_feedback: initialSubmissionValue,
        }
      );
    });

  const [updateSubmissionMutation] = useUpdateSubmissionMutation();
  const [value, setValue] = useState<undefined | Descendant[]>(undefined);
  const debouncedValueChange = useDebounce(value, 1500);

  // useEffect(() => {
  //   dispatch(
  //     updateLoadingStatus({
  //       status: Status.LOADING,
  //       message: "Saving resource",
  //     })
  //   );
  // }, [value]);

  /**
   * After debounce, update the value in redux and the database
   */
  const updateBlocksInDbFollowingFrontEndChange = async () => {
    console.log("updateBlocksInDbFollowingFrontEndChange", value);
    //@ts-expect-error
    const submissionId: number = routerParams?.id;

    if (submissionId && submissionAnswerAndFeedbackBlock) {
      //Get all submission answers currently stored in redux excluding the current block
      const submissionAnswersExcludingCurrent: EditableSubmissionAnswerFeedbackProp[] =
        submissionBlocks?.filter((b) => b.block_id !== blockId);

      //Store answer and feedback  for all blocks in redux
      let answersForEntireSubmission: { [blockId: number]: Descendant[] } = {};
      let feedbackForEntireSubmission: { [blockId: number]: Descendant[] } = {};

      submissionAnswersExcludingCurrent.map((b) => {
        answersForEntireSubmission[b.block_id] =
          b.requester_answer || initialSubmissionValue;
        feedbackForEntireSubmission[b.block_id] =
          b.marker_feedback || initialSubmissionValue;
      });

      const unChangedFeedbackForCurrentBlock =
        submissionBlocks.find((b) => b.block_id === blockId)?.marker_feedback ||
        initialSubmissionValue;

      let allSubmissionAnswersForUpdate = {
        ...answersForEntireSubmission,
        [blockId]: debouncedValueChange,
      };
      let allSubmissionFeedbackForUpdate = {
        ...feedbackForEntireSubmission,
        [blockId]: unChangedFeedbackForCurrentBlock,
      };

      const updatedSubmission = await updateSubmissionMutation({
        variables: {
          submission_id: submissionId,
          submission_spec: {
            submission_answers: allSubmissionAnswersForUpdate,
            questions_feedback: allSubmissionFeedbackForUpdate,
          },
        },
      });

      if (updatedSubmission) {
        dispatch(
          userAppInfoUpdateSubmissionAnswerFeedbackBlock({
            submission_id: submissionId,
            block_id: blockId,
            requester_answer: debouncedValueChange,
            marker_feedback: unChangedFeedbackForCurrentBlock,
          })
        );
        dispatch(
          updateLoadingStatus({
            status: Status.IDLE,
            message: "Saved resource",
          })
        );
      }
    }
  };

  useEffect(() => {
    if (debouncedValueChange && !initialLoad) {
      console.log("Not Initial load", debouncedValueChange);
      dispatch(
        updateLoadingStatus({
          status: Status.LOADING,
          message: "Saving submission",
        })
      );
      updateBlocksInDbFollowingFrontEndChange();
    }
    if (initialLoad && debouncedValueChange) {
      console.log("Initial load", debouncedValueChange);
      setInitialLoad(false);
    }
  }, [debouncedValueChange]);

  /**
   * Set the intial value
   */
  useEffect(() => {
    if (submissionAnswerAndFeedbackBlock && !value) {
      setValue(
        submissionAnswerAndFeedbackBlock.requester_answer &&
          submissionAnswerAndFeedbackBlock.requester_answer?.length > 0
          ? submissionAnswerAndFeedbackBlock.requester_answer
          : initialSubmissionValue
      );
    } else {
      setValue(initialSubmissionValue);
    }
  }, []);

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "heading-1":
        return <HeadingElement type={"ONE"} {...props} editor={editor} />;
      case "math":
        return <MathElement {...props} editor={editor} />;
      default:
        return <div {...props}>{props.children}</div>;
    }
  }, []);

  // Define a leaf rendering function that is memoized with `useCallback`.
  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  const [editor] = useState(() =>
    withReact(
      withHistory(withInlines(withCustomSubmissionNormaliser(createEditor())))
    )
  );

  console.log("submission");

  if (value === undefined) {
    return (
      <>
        <Skeleton animation="wave" height={20} />
        <Skeleton animation="wave" height={20} />
        <Skeleton animation="wave" height={20} />
        <Skeleton animation="wave" height={20} />
      </>
    );
  }

  return (
    <>
      <Typography
        sx={{
          margin: "10px",
          color: altraResourceBuilderTheme.palette.primary.main,
        }}
        variant={"h2"}
      >
        Your answer
      </Typography>
      <Slate editor={editor} value={value} onChange={setValue}>
        <div style={{ margin: "10px" }}>
          <CustomSubmissionEditor>
            <Editable
              renderLeaf={renderLeaf}
              renderElement={renderElement}
              placeholder="Type your answer here"
              onKeyDown={(e) => onKeyDownSubmission(e, editor)}
            />
          </CustomSubmissionEditor>
        </div>
      </Slate>
    </>
  );
};
