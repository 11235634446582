import { styled } from "@mui/material/styles";
import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { updateLoadingStatus } from "../../redux/applicationContext/actions";
import { useAppDispatch } from "../../redux/hook";
import { Status } from "../../util/custom-types";
import { EditBlockMaths } from "../EditBlockMaths";
/**
 * Inline mathe element block using our custom mathlive text editor
 * - Renders math block inline for editing maths values
 * @param attributes
 * @param children
 * @param element
 * @constructor
 */
export const MathElement = (props) => {
  const dispatch = useAppDispatch();
  // const editor = useSlate();

  const removeNode = () => {
    try {
      Transforms.removeNodes(props.editor, {
        at: ReactEditor.findPath(props.editor, props.element),
      });
    } catch (e) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not remove maths",
        })
      );
    }
  };

  const updateLatex = (latex: string) => {
    try {
      Transforms.setNodes(
        props.editor,
        { ...props.element, latex: latex },
        {
          at: ReactEditor.findPath(props.editor, props.element),
        }
      );
    } catch (e) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not update maths",
        })
      );
    }
  };

  return (
    <StyledMathElementContainer {...props.attributes}>
      <StyledMathElementContent
        style={{ userSelect: "none" }}
        contentEditable={false}
      >
        {props.children}
        <EditBlockMaths
          latexString={props.element.latex || ""}
          onLatexChange={updateLatex}
          onArrowKeyPress={() => null}
          onRemove={removeNode}
        />
      </StyledMathElementContent>
    </StyledMathElementContainer>
  );
};

const StyledMathElementContainer = styled("div")(({ theme }) => ({
  display: "inline-block",
  // verticalAlign: "middle",
}));
const StyledMathElementContent = styled("div")(({ theme }) => ({
  // display: "inline-flex",
}));
