import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CardContent, Dialog, IconButton } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { FC, useState } from "react";
import { getS3URL } from "../../util/convertToS3Url";
import { DiagramElement } from "../../util/custom-editor-types";
import { useIsMobile } from "../../util/useIsMobile";
type ViewDiagramElementProps = {
  block: DiagramElement;
  inline?: boolean;
  fullSize?: boolean;
  showPreviewIcon?: boolean;
  doNotLoadChildren?: boolean;
};

/**
 * View only element for displaying Diagram blocks
 * @param block
 * @param type
 * @constructor
 */
export const ViewDiagramElement: FC<ViewDiagramElementProps> = ({
  block,
  inline,
  showPreviewIcon,
  doNotLoadChildren,
}) => {
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const theme = useTheme();
  const isTabletOrMobile = useIsMobile();

  const fullSize = true;

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row-reverse",
      }}
    >
      {(isTabletOrMobile || showPreviewIcon) && !inline && (
        <StyledPreviewButton
          color={"primary"}
          onClick={() => setImagePreviewOpen(true)}
        >
          <VisibilityIcon />
        </StyledPreviewButton>
      )}
      <img
        style={{
          maxWidth: "100%",
          width: fullSize ? 700 || 700 : inline ? "100px" : "200px",
        }}
        src={getS3URL(block.whiteboardImageS3Url)}
        alt={"Whiteboard image"}
      />
      <Dialog open={imagePreviewOpen}>
        <StyledTopRow>
          <StyledTopRowContent>
            <IconButton
              onClick={() => setImagePreviewOpen(false)}
              style={{
                color: theme.palette.error.main,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </StyledTopRowContent>
        </StyledTopRow>
        <CardContent>
          <img
            style={{
              width: "100%",
            }}
            src={getS3URL(block.whiteboardImageS3Url)}
            alt={"Whiteboard image"}
          />
        </CardContent>
      </Dialog>
    </div>
  );
};

const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
}));
const StyledPreviewButton = styled(IconButton)`
  @media print {
    display: none;
  }
`;
