import { createTheme } from "@mui/material/styles";
import { altraTheme } from "./altra-theme";

export const altraProgressManagerTheme = createTheme({
  ...altraTheme,
  ...{
    palette: {
      primary: {
        main: "#26AC1B",
        dark: "#088A20",
      },
    },
  },
});
