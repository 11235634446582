import DiagramIcon from "@altra-apps/common/assets/blockTypeIcons/Image.svg";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import { DiagramElement } from "../../util/custom-editor-types";
import { ViewDiagramElement } from "../curriculumExplorerPreviewElements/ViewDiagramElement";

type DiagramElementResultCardContentsProps = {
  block: DiagramElement;
  tags: Tag[];
  inline?: boolean;
  isSelectable: boolean;
  position: number | null | undefined;
};

/**
 * Diagram Block result card view in Curriculum Explorer
 */
export const DiagramElementResultCardContents: FC<
  DiagramElementResultCardContentsProps
> = ({ block, tags, inline }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: inline ? "row" : "column",
        height: "100%",
      }}
    >
      <StyledTopRow
        sx={{
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Tooltip placement={"left"} title={`Diagram`}>
          <img
            style={{
              height: "30px",
              marginBottom: 10,
              marginRight: "10px",
              display: "inline-flex",
            }}
            src={DiagramIcon}
            alt={"Diagram Block"}
          />
        </Tooltip>
      </StyledTopRow>
      <StyledChildDiagram
        sx={{
          height: "100%",
        }}
      >
        <ViewDiagramElement block={block} inline={inline} />
      </StyledChildDiagram>
    </div>
  );
};

const StyledTopRow = styled("div")`
  display: flex;
`;

const StyledChildDiagram = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
