import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { FC } from "react";
import styled from "styled-components";

type RaisedIconButtonProps = {
  onClick: () => void;
  small?: boolean;
  selected?: boolean;
  remove?: boolean;
  disabled?: boolean;
  rounded?: boolean;
};

export const RaisedIconButton: FC<RaisedIconButtonProps> = ({
  children,
  onClick,
  small,
  selected,
  disabled,
  remove,
  rounded = true,
}) => {
  const theme = useTheme();

  return (
    <StyledIconButton
      disabled={disabled}
      size={small ? "small" : "medium"}
      onClick={onClick}
      style={{
        backgroundColor: selected
          ? theme.palette.success.main
          : theme.palette.primary.main,
        color: "#FFF",
        borderRadius: rounded ? "50%" : "20%",
      }}
    >
      {children}
    </StyledIconButton>
  );
};

const StyledIconButton = styled(IconButton)`
  display: flex;
`;
