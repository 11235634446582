import { GetSubscriptions } from "@altra-apps/common/src/graphql/queries";
import {
  GetSubscriptionsQuery,
  GetSubscriptionsQueryVariables,
} from "@altra-apps/common/src/graphql/types";
import {
  AltraTopAppBar,
  ToolbarContext,
} from "@altra-apps/common/src/molecules/AltraTopAppBar";
import { ExplorerContainer } from "@altra-apps/common/src/organisms/ExplorerContainer";
import { applicationContextSubscriptions } from "@altra-apps/common/src/redux/applicationContext/actions";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import { userAppInfoCurriculumExplorerTutorial } from "@altra-apps/common/src/redux/user/actions";
import { CurriculumExplorerTutorialStages } from "@altra-apps/common/src/redux/user/types";
import {
  ALTRA_APPS,
  CurriculumExplorerTutorialContent,
} from "@altra-apps/common/src/util/custom-types";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import {
  DocumentNode,
  gql,
  OperationVariables,
  useApolloClient,
} from "@apollo/client";
import {
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useCallback, useEffect, useRef } from "react";

function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode
) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables,
      }),
    [client]
  );
}

/**
 * The container for the Explorer screen which includes filters, search bar and results
 * @constructor
 */
export const ExplorerScreen: FC = () => {
  const ref = useRef();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const selectedCurriculumIds: number[] | undefined = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId!
  );
  const curriculumExplorerStage: CurriculumExplorerTutorialStages =
    useAppSelector((state) => state.userAppInfo.curriculumExplorerTutorial);

  const GetSubscriptionsCallback = useLazyQuery<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables
  >(
    gql`
      ${GetSubscriptions}
    `
  );

  useEffect(() => {
    initialLoadOfSubscriptions();
  }, []);

  const initialLoadOfSubscriptions = async () => {
    const res = await GetSubscriptionsCallback({});

    if (res.data) {
      dispatch(
        applicationContextSubscriptions(
          res.data.subscription.map((s) => ({ ...s, discount: 0 }))
        )
      );
    }
  };

  return (
    <ToolbarContext.Provider value={ref}>
      <AltraTopAppBar displaySubCompoments appName={ALTRA_APPS.CE} ref={ref} />

      {isMobile && curriculumExplorerStage !== "COMPLETE" && (
        <Dialog
          open={selectedCurriculumIds && selectedCurriculumIds.length > 0}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                marginBottom: "2em",
                position: "relative",
                display: "inline-flex",
              }}
            >
              <CircularProgress
                value={curriculumExplorerStage * (100 / 5)}
                variant="determinate"
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="caption" component="div">
                  {curriculumExplorerStage}/{5}
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{ marginBottom: "0.5em" }}
              id="modal-modal-title"
              variant="h1"
            >
              {
                CurriculumExplorerTutorialContent[curriculumExplorerStage - 1]
                  .title
              }
            </Typography>

            <Typography
              id="modal-modal-title"
              variant="h2"
              sx={{ marginBottom: "2em" }}
            >
              {
                CurriculumExplorerTutorialContent[curriculumExplorerStage - 1]
                  .description
              }
            </Typography>

            <Button
              sx={{ marginBottom: "1em" }}
              fullWidth
              onClick={() =>
                dispatch(userAppInfoCurriculumExplorerTutorial("COMPLETE"))
              }
              color={"inherit"}
              variant={"contained"}
            >
              Skip tour
            </Button>
            <Button
              fullWidth
              onClick={() =>
                curriculumExplorerStage > 0 && curriculumExplorerStage < 5
                  ? dispatch(
                      userAppInfoCurriculumExplorerTutorial(
                        //@ts-expect-error
                        curriculumExplorerStage + 1
                      )
                    )
                  : dispatch(userAppInfoCurriculumExplorerTutorial("COMPLETE"))
              }
              color={"primary"}
              variant={"contained"}
            >
              Next
            </Button>
          </CardContent>
        </Dialog>
      )}

      <ExplorerContainer
        appName={ALTRA_APPS.CE}
        fullScreen={!isMobile}
        displayInTab={false}
      />
    </ToolbarContext.Provider>
  );
};
