import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import { styled } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import { useSearchUsersLazyQuery } from "../graphql/types";
import { updateLoadingStatus } from "../redux/applicationContext/actions";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { userAppInfoSelectedAuthors } from "../redux/user/actions";
import { KeyValue, Status } from "../util/custom-types";
import { useDebounce } from "../util/use-debounce";
import { CHIP_TYPES } from "./KeyIssueChip";

interface AutocompleteBlockMultiSelectDropDownProps {
  inputLabel: string;
}

export const AutocompleteAuthorMultiSelectDropDown: FC<
  AutocompleteBlockMultiSelectDropDownProps
> = (prop) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const [options, setOptions] = useState<readonly KeyValue[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const selectedAuthors: Array<{ id: number; name: string }> = useAppSelector(
    (state) => state.userAppInfo?.selectedAuthors!
  );

  const [selectedItems, setSelectedItems] =
    useState<Array<{ id: number; name: string }>>(selectedAuthors);

  const getItemLabel = (item: KeyValue): string => item.value;

  useEffect(() => {}, [selectedAuthors]);

  const [
    getAuthorsForSearchTermCallback,
    { data: getAuthorsData, error: getAuthorsError },
  ] = useSearchUsersLazyQuery();

  useEffect(() => {
    if (getAuthorsError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get authors",
        })
      );
    } else if (getAuthorsData && getAuthorsData.search_users) {
      setOptions(
        getAuthorsData?.search_users?.map((author) => ({
          key: author.id,
          value: author.display_name || "",
        })) || []
      );
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved resource",
        })
      );
    }

    setLoading(false);
  }, [getAuthorsData, getAuthorsError]);

  useEffect(() => {
    if (debouncedSearchValue !== "") {
      setLoading(true);
      getAuthorsForSearchTermCallback({
        variables: { searchTerm: debouncedSearchValue },
      });
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    setSelectedItems(selectedAuthors);
  }, [selectedAuthors]);

  /**
   * Update selected blocks in Redux
   */
  const updateReduxStore = (
    selectedIdsArray: Array<{ id: number; name: string }>
  ): void => {
    dispatch(
      userAppInfoSelectedAuthors({
        authors: selectedIdsArray,
      })
    );
  };

  /**
   * On item change updates selected ids with selected id
   * @param event
   * @param keyValues
   */
  const handleItemChange = (event, keyValues: Array<KeyValue>) => {
    let authors: Array<{ id: number; name: string }> = keyValues.map((f) => ({
      id: f.key,
      name: f.value,
    }));

    console.log(authors);

    updateReduxStore(authors);
    setSelectedItems(authors);
  };

  return (
    <StyledFormControl
      sx={{
        width: "100%",
      }}
    >
      <Autocomplete
        noOptionsText={"Type to search users"}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        multiple
        id="combo-box-demo"
        options={options.filter(
          (o) => !selectedItems.some((i) => i.id === o.key)
        )}
        size="small"
        limitTags={2}
        renderInput={(params) => (
          <TextField
            {...params}
            rows={1}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </React.Fragment>
              ),
            }}
            label={prop.inputLabel}
          />
        )}
        renderTags={(tagValue: KeyValue[], getTagProps) => {
          return tagValue.map((option: KeyValue, index) => {
            return (
              <Chip
                size={"small"}
                label={option.value}
                variant="outlined"
                {...getTagProps({ index })}
                sx={{
                  maxWidth: "200px",
                  backgroundColor: `${CHIP_TYPES.AUTHOR}`,
                }}
              />
            );
          });
        }}
        getOptionLabel={(option) => getItemLabel(option)}
        inputValue={searchValue || ""}
        onInputChange={(event, value) => setSearchValue(value)}
        // value={
        //   selectedItems?.map((item) => ({
        //     key: item.id,
        //     value: item.name,
        //   })) || []
        // }
        onChange={(event, newValue) => handleItemChange(event, newValue)}
      />
    </StyledFormControl>
  );
};

const StyledFormControl = styled("div")(({ theme }) => ({
  // marginTop: "0.7em",
  marginBottom: "0.7em",
}));
