import { Button, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { AltraTopAppBar } from "../molecules/AltraTopAppBar";
import { ALTRA_APPS } from "../util/custom-types";

type PageNotFoundProps = {
  appName: ALTRA_APPS;
};

/**
 * Screen for pages with 404
 * @param handleOnClick
 * @param appName
 * @constructor
 */
export const PageNotFoundScreen: FC<PageNotFoundProps> = ({ appName }) => {
  const history = useHistory();

  return (
    <>
      <AltraTopAppBar appName={appName} />
      <Toolbar />
      <StyledCenteredErrorContainer>
        <StyledCenteredError>
          <Typography variant={"h1"} sx={{ marginBottom: "20px" }}>
            This page does not exist
          </Typography>
          <Button
            size={"large"}
            variant={"contained"}
            onClick={() => history.push("/")}
          >
            Return to safety
          </Button>
        </StyledCenteredError>
      </StyledCenteredErrorContainer>
    </>
  );
};
const StyledCenteredError = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5em",
  flexDirection: "column",
  width: "40%",
}));
const StyledCenteredErrorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));
