import QuoteIcon from "@altra-apps/common/assets/blockTypeIcons/Quote.svg";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import { BlockQuoteElement } from "../../util/custom-editor-types";
import { ViewQuoteElement } from "../curriculumExplorerPreviewElements/ViewQuoteElement";

type QuoteElementResultCardContentsProps = {
  block: BlockQuoteElement;
  tags: Tag[];
  inline?: boolean;
  isSelectable: boolean;
  position: number | null | undefined;
};

/**
 * Quote Block result card view in Curriculum Explorer
 */
export const QuoteElementResultCardContents: FC<
  QuoteElementResultCardContentsProps
> = ({ block, tags, inline }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: inline ? "row" : "column",
        alignItems: inline ? "flex-start" : "flex-end",
      }}
    >
      <StyledTopRow
        sx={{
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Tooltip placement={"left"} title={"Quote"}>
          <img
            style={{
              height: "30px",
              marginBottom: 10,
              marginRight: "10px",
              display: "inline-flex",
            }}
            src={QuoteIcon}
            alt={"Quote Block"}
          />
        </Tooltip>
      </StyledTopRow>
      <StyledChildQuote>
        <ViewQuoteElement block={block} inline={inline} />
      </StyledChildQuote>
    </div>
  );
};

const StyledTopRow = styled("div")`
  display: flex;
`;

const StyledChildQuote = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
}));
