import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import { DiagramElement } from "../../util/custom-editor-types";
import { ViewDiagramElement } from "../curriculumExplorerPreviewElements/ViewDiagramElement";

type DiagramElementPreviewModalContentsProps = {
  block: DiagramElement;
  tags: Tag[];
  position: number | null | undefined;
};

/**
 * Preview modal contents for a Diagram block
 * @param block
 * @param tags
 * @param children
 * @constructor
 */
export const DiagramElementPreviewModalContents: FC<
  DiagramElementPreviewModalContentsProps
> = ({ block, tags, children }) => {
  return (
    <div style={{ width: "100%" }}>
      {/*<StyledTopRow>*/}
      {/*  <Tooltip placement={"left"} title={"Body text"}>*/}
      {/*    <img*/}
      {/*      style={{*/}
      {/*        height: "30px",*/}
      {/*        marginBottom: 10,*/}
      {/*        marginRight: "10px",*/}
      {/*        display: "inline-flex",*/}
      {/*      }}*/}
      {/*      src={DiagramBlockIcon}*/}
      {/*      alt={"Diagram Block"}*/}
      {/*    />*/}
      {/*  </Tooltip>*/}
      {/*</StyledTopRow>*/}

      <ViewDiagramElement
        doNotLoadChildren={false}
        fullSize={true}
        block={block}
      />
    </div>
  );
};

const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
}));
