import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { IconButton, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import React, { FC } from "react";
import CEWave from "../../assets/images/CEWave";
import RBWave from "../../assets/images/RBWave";
import { ALTRA_APPS } from "../util/custom-types";

interface InitialFlowCardProps {
  title: string;
  small?: boolean;
  icon: string;
  customIconUrl?: string;
  onClick: () => void;
  appName: ALTRA_APPS;
  loading?: boolean;
}

export const InitialFlowCard: FC<InitialFlowCardProps> = ({
  title,
  small,
  icon,
  customIconUrl,
  onClick,
  appName,
  loading,
}) => {
  const theme = useTheme();
  console.log(customIconUrl);
  return (
    <StyledCardContainer>
      <StyledCardIconContainer>
        {appName === ALTRA_APPS.CE ? <CEWave /> : <RBWave />}
      </StyledCardIconContainer>
      <img
        style={{ height: "50px", top: "20px", position: "absolute" }}
        src={customIconUrl || icon}
        alt={"Student"}
      />
      <StyledTextContainer>
        {small ? (
          <Typography sx={{ fontSize: "14px", width: "90%" }} variant={"h2"}>
            {title}
          </Typography>
        ) : (
          <Typography sx={{ width: "90%" }} variant={"h2"}>
            {title}
          </Typography>
        )}
        <IconButton
          onClick={onClick}
          sx={{
            marginTop: "0.5em",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          {loading ? (
            <CircularProgress sx={{ width: "100%" }} />
          ) : (
            <PlayArrowIcon sx={{ color: "white" }} />
          )}
        </IconButton>
      </StyledTextContainer>
    </StyledCardContainer>
  );
};
const StyledCardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  textAlign: "center",
  height: "250px",
  width: "200px",
  boxShadow: "0px 0px 12px 0px rgba(156, 122, 253, 0.34)",
  borderRadius: "15px",
  margin: "1em",
}));
const StyledTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  height: "50%",
  justifyContent: "center",
  width: "100%",
  overflow: "hidden",
  marginBottom: "0.5em",
  borderRadius: "15px",
}));

const StyledCardIconContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  height: "50%",
  width: "100%",
  overflow: "hidden",
  borderRadius: "15px",
}));
