import { MathfieldConfig, MathfieldElement } from "mathlive";
import "mathlive/dist/mathlive-fonts.css";
import React, { CSSProperties, FC, useEffect, useRef } from "react";

type MathsTypographyProps = {
  style?: CSSProperties;
  options?: Partial<MathfieldConfig>;
  latexString?: string;
  onClick?: () => void;
  headerStyle?: boolean;
};

/**
 * Component for displaying static non editable maths
 * @param latexString
 * @param onClick
 * @constructor
 */
export const MathsTypography: FC<MathsTypographyProps> = ({
  latexString,
  onClick,
  headerStyle,
}) => {
  const divRef = useRef<HTMLDivElement>();
  const mathsRef = useRef<MathfieldElement | null>(null);

  const _createMathLiveElement = () => {
    if (divRef.current && !mathsRef.current) {
      mathsRef.current = new MathfieldElement();
      mathsRef.current?.setAttribute("read-only", "true");
      mathsRef.current?.addEventListener("focusin", () => {
        onClick && onClick();
      });
      divRef.current.firstChild?.remove();
      divRef.current.appendChild(mathsRef.current);
    }
  };

  useEffect(() => {
    _createMathLiveElement();
  }, [divRef]);

  useEffect(() => {
    if (mathsRef.current && mathsRef.current.getValue() !== latexString) {
      mathsRef.current.setValue(latexString);
    }
  }, [latexString]);

  return (
    <div
      ref={divRef as any}
      style={{
        fontSize: headerStyle ? "22px" : "medium",
        fontWeight: headerStyle ? "800" : "auto",
        display: "inline-block",
        whiteSpace: "normal",
        borderRadius: "10px",
        outline: "0px solid transparent",
        margin: "0 0.3em",
        backgroundColor: "#FFF",
      }}
    />
  );
};
