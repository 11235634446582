import { alterCurriculumExplorerTheme } from "@altra-apps/common/src/styling/altra-curriculum-explorer-theme";
import { FileDownload } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CHIP_TYPES, KeyIssueChip } from "../../atoms/KeyIssueChip";
import {
  CreateSubmissionMutationVariables,
  useCreateSubmissionMutation,
} from "../../graphql/types";
import { Topic } from "../../redux/applicationContext/types";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { userAppInfoCreateSubmission } from "../../redux/user/actions";
import { Block, UserInfo } from "../../redux/user/types";
import { altraResourceBuilderTheme } from "../../styling/altra-resource-builder-theme";
import { ALTRA_APPS } from "../../util/custom-types";
import { getUnionOfValuesForKeys, openInNewTab } from "../../util/helpers";
import { prettyBlockType } from "../../util/pretty-text";
import { renderPreviewModalContentForBlockType } from "../../util/render-preview-modal-for-block-type";
import { useIsMobile } from "../../util/useIsMobile";
import { ExplorerPreviewModalContainer } from "../explorerPreviewModals/ExplorerPreviewModalContainer";

type ExplorerResultCardContainerProps = {
  selectQuestion?: () => void;
  deSelectQuestion?: () => void;
  isSelectable: boolean;
  selected: boolean;
  appName: ALTRA_APPS;
  block: Block;
};

/**
 * Curriculum Explorer result card container
 * TODO(JACK): Update selecting and draggable props for resource builder view
 */
export const ExplorerResultCardContainer: FC<
  ExplorerResultCardContainerProps
> = ({
  selectQuestion,
  deSelectQuestion,
  isSelectable,
  selected,
  appName,
  children,
  block,
}) => {
  const [open, setOpen] = useState(false);
  const theme = alterCurriculumExplorerTheme;
  const isTabletOrMobile = useIsMobile();
  const history = useHistory();
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext?.topics
  );
  const dispatch = useAppDispatch();
  const selectedCurriculumsId: number | undefined = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId![0]
  );
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);

  const handleSelect = useCallback(() => {
    selectQuestion && selectQuestion();
  }, [selectQuestion]);

  const handleDeSelect = useCallback(() => {
    deSelectQuestion && deSelectQuestion();
  }, [deSelectQuestion]);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const menuOpen = Boolean(menuAnchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };
  const teacherOrStudent = useAppSelector(
    (state) => state.userAppInfo.teacherOrStudent
  );

  const [createSubmissionMutation] = useCreateSubmissionMutation();

  useEffect(() => {
    if (
      selectedCurriculumsId[0] &&
      !topics[selectedCurriculumsId[0]][0] &&
      (!topics[selectedCurriculumsId[0]][0].topic_unit_title ||
        topics[selectedCurriculumsId[0]][0].topic_unit_title === "")
    ) {
      history.push("/topicSelection");
    }
  }, []);

  const createSubmissionAndNavigate = async () => {
    const createVariables: CreateSubmissionMutationVariables = {
      submission_spec: {
        block_id: block.id,
        requester_user_id: user && user?.id ? user?.id : undefined,
        publicly_accessible: false,
      },
    };

    const { data, errors } = await createSubmissionMutation({
      variables: createVariables,
    });

    if (data && data?.insert_submission_one?.id) {
      dispatch(userAppInfoCreateSubmission(data?.insert_submission_one));
      history.push(`/submission/${data?.insert_submission_one?.id}`);
    }
  };

  return (
    <>
      <StyledExplorerCardContainer
        style={{
          maxHeight: "400px",
          position: "relative",
          backgroundColor: "#FFF",
          // cursor: isSelectable ? "pointer" : "default",
          display: "flex",
          border: `2px solid ${
            selected && isSelectable
              ? theme.palette.success.main
              : theme.palette.text.primary
          }`,
        }}
      >
        <StyledCardContent>{children}</StyledCardContent>
        <div
          style={{ position: "sticky", bottom: 0, backgroundColor: "white" }}
        >
          <div
            style={{ overflow: "auto", maxHeight: "75px", padding: "0.5em" }}
          >
            {block.tags?.map((tag) => {
              const label = tag.label;
              return (
                <KeyIssueChip
                  label={label}
                  showAll={false}
                  color={CHIP_TYPES.TOPIC}
                  tooltipLabel={
                    getUnionOfValuesForKeys(topics)?.find(
                      (t) => t.id === tag?.topicId
                    )?.topic_unit_title || label
                  }
                />
              );
            })}
            {/*<KeyIssueChip*/}
            {/*  label={"Author"}*/}
            {/*  showAll={false}*/}
            {/*  color={CHIP_TYPES.AUTHOR}*/}
            {/*/>*/}
          </div>
          {!isSelectable && (
            <StyledButtonContainer>
              <Button
                sx={{ margin: "10px", width: "90%" }}
                endIcon={<OpenInNewIcon />}
                size={"large"}
                onClick={handleOpen}
                color={"primary"}
                variant="contained"
                fullWidth
              >
                Preview {prettyBlockType(block.type)}
              </Button>
              {/*{!isTabletOrMobile && teacherOrStudent === "STUDENT" && (*/}
              {/*  <Button*/}
              {/*    onClick={createSubmissionAndNavigate}*/}
              {/*    sx={{*/}
              {/*      margin: "10px 0",*/}
              {/*      width: "25%",*/}
              {/*      "&:hover": {*/}
              {/*        backgroundColor:*/}
              {/*          altraResourceBuilderTheme.palette.primary.main,*/}
              {/*      },*/}
              {/*      backgroundColor:*/}
              {/*        altraResourceBuilderTheme.palette.primary.dark,*/}
              {/*      color:*/}
              {/*        altraResourceBuilderTheme.palette.primary.contrastText,*/}
              {/*    }}*/}
              {/*    fullWidth*/}
              {/*    size={"large"}*/}
              {/*    color={"primary"}*/}
              {/*    variant="contained"*/}
              {/*    disabled={true}*/}
              {/*  >*/}
              {/*    Revise - Coming soon*/}
              {/*  </Button>*/}
              {/*)}*/}
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={menuOpen ? "long-menu" : undefined}
                aria-expanded={menuOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClickMenu}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    width: "20ch",
                  },
                }}
              >
                <MenuItem
                  key={"Issue"}
                  onClick={() =>
                    openInNewTab(
                      `https://2uluvvlx386.typeform.com/to/rhLlAWSr#name=${user.display_name}&block=${block.id}&email=${user.email}`
                    )
                  }
                >
                  <ListItemIcon>
                    <ErrorOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Issue</Typography>
                </MenuItem>
                <MenuItem
                  key={"export"}
                  onClick={() => history.push(`/export/${block.id}`)}
                >
                  <ListItemIcon>
                    <FileDownload fontSize="small" />
                  </ListItemIcon>
                  <Typography>Export</Typography>
                </MenuItem>
              </Menu>
            </StyledButtonContainer>
          )}

          {isSelectable && (
            <StyledButtonContainer>
              <Button
                sx={{ margin: "10px", width: "85%" }}
                endIcon={<CheckCircleIcon />}
                size={"large"}
                onClick={selected ? handleDeSelect : handleSelect}
                color={"primary"}
                variant="contained"
                style={{
                  backgroundColor: selected
                    ? altraResourceBuilderTheme.palette.success.main
                    : altraResourceBuilderTheme.palette.primary.main,
                  textTransform: "none",
                  color: altraResourceBuilderTheme.palette.primary.contrastText,
                }}
              >
                Select {prettyBlockType(block.type)}
              </Button>
              <Button
                fullWidth
                size={"large"}
                onClick={handleOpen}
                style={{
                  backgroundColor:
                    alterCurriculumExplorerTheme.palette.primary.main,
                  textTransform: "none",
                  color:
                    alterCurriculumExplorerTheme.palette.primary.contrastText,
                }}
                variant="contained"
                sx={{ width: "30%", margin: "10px" }}
              >
                Preview
              </Button>
            </StyledButtonContainer>
          )}
        </div>
      </StyledExplorerCardContainer>
      <ExplorerPreviewModalContainer
        appName={appName}
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        block={block}
      >
        {renderPreviewModalContentForBlockType(block)}
      </ExplorerPreviewModalContainer>
    </>
  );
};

const StyledButtonRow = styled("div")`
  border-top: 1px solid #eee;
  display: grid;
  gap: 1em;
  grid-auto-flow: column;
  justify-content: left;
  padding: 1em !important;
`;

const StyledExplorerCardContainer = styled("div")`
  flex-direction: column;
  border-radius: 10px;
  overflow: auto;
`;

const StyledCardContent = styled("div")`
  display: flex;
  padding: 1em;
  flex: 1;
  flex-direction: column;
`;
const StyledButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
`;
