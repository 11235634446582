import { LoadingCenteredSpinnerWithText } from "@altra-apps/common/src/atoms/LoadingCenteredSpinnerWithText";
import { RaisedIconButton } from "@altra-apps/common/src/atoms/RaisedIconButton";
import {
  Block_Insert_Input,
  CreateSubmissionMutationVariables,
  GetBlockWithIdQueryVariables,
  InsertTagsMutationVariables,
  Subscription,
  useCheckBlockIsPublicLazyQuery,
  useCreateBlockMutation,
  useCreateSubmissionMutation,
  useGetBlockWithIdLazyQuery,
  useInsertTagsMutation,
} from "@altra-apps/common/src/graphql/types";
import { AltraTopAppBar } from "@altra-apps/common/src/molecules/AltraTopAppBar";
import { ShareAssignmentLink } from "@altra-apps/common/src/molecules/ShareAssignmentLink";
import { ShareLinkAndQRModal } from "@altra-apps/common/src/molecules/ShareLinkAndQRModal";
import { ResourceSettingsDialog } from "@altra-apps/common/src/organisms/ResourceSettingsDialog";
import { updateLoadingStatus } from "@altra-apps/common/src/redux/applicationContext/actions";
import {
  Curriculum,
  LoadingStatus,
  Topic,
  TopicLabel,
} from "@altra-apps/common/src/redux/applicationContext/types";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import {
  userAppInfoCreateSubmission,
  userAppInfoDarkMode,
  userAppInfoExplorerBlocks,
} from "@altra-apps/common/src/redux/user/actions";
import {
  Block,
  EditableBlockProp,
  UserInfo,
} from "@altra-apps/common/src/redux/user/types";
import {
  ALTRA_APPS,
  BLOCK_TYPES,
  Status,
} from "@altra-apps/common/src/util/custom-types";
import { openInNewTab } from "@altra-apps/common/src/util/helpers";
import { renderContentNavigationForBlockType } from "@altra-apps/common/src/util/render-content-navigation-for-block-type";
import { renderViewOnlyBlockForBlockType } from "@altra-apps/common/src/util/render-view-only-block-for-block-type";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MenuIcon from "@mui/icons-material/Menu";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SettingsIcon from "@mui/icons-material/Settings";
import ShareIcon from "@mui/icons-material/Share";
import {
  Button,
  CardActions,
  CircularProgress,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

export const FullPageViewerScreen: FC = () => {
  const routerParams = useParams();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isTabletOrMobile = useIsMobile();
  const [authorised, setAuthorised] = useState<boolean>();
  const [resourceInfo, setResourceInfo] =
    useState<{ author: number | null; public: boolean }>();
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  const [block, setBlock] = useState<Block>();

  const userExplorerBlocks: Block[] = useAppSelector(
    (state) => state.userAppInfo.userExplorerBlocks
  );
  const teacherOrStudent = useAppSelector(
    (state) => state.userAppInfo.teacherOrStudent
  );
  useEffect(() => {
    const localBlock = userExplorerBlocks.find(
      //@ts-expect-error
      (b) => b.id === parseInt(routerParams?.id)
    );
    localBlock && setBlock(localBlock);
  }, [userExplorerBlocks, routerParams]);

  const [insertTagMutation] = useInsertTagsMutation();
  const [createBlockMutation] = useCreateBlockMutation();
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false);
  const [shareQrModalOpen, setShareQrModalOpen] = useState(false);

  const userSubscriptionPlan: Subscription = useAppSelector((state) =>
    state.applicationContext.subscriptions?.find(
      (element) => element.id === user.subscription_id!
    )
  ) as Subscription;
  const userId = useAppSelector((state) => state.user.userInfo.id);
  const topicLabels: { [curriculumId: number]: TopicLabel[] } = useAppSelector(
    (state) => state.applicationContext.topicLabels
  );
  const verified = useAppSelector((state) => state.user.userInfo.verified);

  const curriculums: Array<Curriculum> = useAppSelector(
    (state) => state.applicationContext.curriculums
  );
  const selectedCurriculumsIds: Array<number> = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId!
  );
  const loadingStatus: LoadingStatus = useAppSelector(
    (state) => state.applicationContext.loadingStatus
  );

  const darkMode = false;
  const setDarkMode = (darkMode: boolean) =>
    dispatch(userAppInfoDarkMode(darkMode));
  const userExplorerBlocksChildren: { [parentId: number]: Array<Block> } =
    useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);
  const editableBlockProp: Array<EditableBlockProp> = useAppSelector(
    (state) => state.userAppInfo.userEditableBlockProps
  ) as Array<EditableBlockProp>;
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext?.topics
  );
  const [resourceToViewId, setResourceToViewId] = useState<number>();
  const [resourceToView, setResourceToView] = useState<Block>();
  const [shareAssignmentModalOpen, setShareAssignmentModalOpen] =
    useState(false);
  const [resourceSettingsModalOpen, setResourceSettingsModalOpen] =
    useState(false);
  const [
    getPublicCallback,
    { data: checkPublicData, error: checkPublicError },
  ] = useCheckBlockIsPublicLazyQuery();

  useEffect(() => {
    // console.log(resourceToView);
    if (resourceToView) {
      const urlTitle = resourceToView?.block["title"] || resourceToView?.type;
      if (urlTitle) {
        try {
          window.history.replaceState(
            "",
            urlTitle,
            `/explorer/${resourceToViewId}/${urlTitle
              .replaceAll(/%20/g, "-")
              .replaceAll(" ", "-")}`
          );
          document.title = `Altra - ${urlTitle}`;
        } catch (e) {}
      }
    }
  }, [resourceToView]);

  const insertTags = async (tags: InsertTagsMutationVariables) => {
    await insertTagMutation({
      variables: tags,
    });
  };

  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    console.log(checkPublicData);
    console.log(checkPublicError);
    if (checkPublicError) {
      setResourceInfo({ author: null, public: false });
    } else if (checkPublicData && checkPublicData.block_by_pk) {
      setResourceInfo({
        author: checkPublicData.block_by_pk.created_by_id || null,
        public: checkPublicData.block_by_pk.publicly_accessible || false,
      });
    }
  }, [checkPublicData, checkPublicError]);

  useEffect(() => {
    setResourceToViewId(undefined);
    setResourceToView(undefined);
    //@ts-ignore
    const resourceId: number = routerParams?.id;
    if (resourceId) setResourceToViewId(resourceId);
  }, []);

  useEffect(() => {
    getPublic();
  }, [resourceToViewId, routerParams]);

  useEffect(() => {
    console.log(resourceInfo);
    console.log(authorised);
    if (
      resourceInfo &&
      (resourceInfo.public || user.id === resourceInfo.author)
    ) {
      getResource();
      setAuthorised(true);
    } else if (resourceInfo !== undefined) {
      setAuthorised(false);
    }
  }, [resourceInfo]);

  const getPublic = async () => {
    console.log(resourceToViewId);
    resourceToViewId
      ? getPublicCallback({
          variables: { id: resourceToViewId },
        })
      : setResourceInfo({ author: null, public: false });
  };

  const [
    getResourceCallback,
    { data: getResourceData, error: getResourceError },
  ] = useGetBlockWithIdLazyQuery();

  const getResource = async () => {
    let getResourceInput: GetBlockWithIdQueryVariables = {
      blockId: resourceToViewId,
    };
    getResourceCallback({
      variables: getResourceInput,
    });
  };

  const [creatingBlock, setCreatingBlock] = useState<number | null>(null);

  const [createSubmissionMutation] = useCreateSubmissionMutation();

  const createSubmissionAndNavigate = async () => {
    const createVariables: CreateSubmissionMutationVariables = {
      submission_spec: {
        block_id: block?.id,
        requester_user_id: user && user?.id ? user?.id : undefined,
        publicly_accessible: false,
      },
    };

    const { data, errors } = await createSubmissionMutation({
      variables: createVariables,
    });

    if (data && data?.insert_submission_one?.id) {
      dispatch(userAppInfoCreateSubmission(data?.insert_submission_one));
      history.push(`/submission/${data?.insert_submission_one?.id}`);
    }
  };

  /**
   * Created child blocks in the backend and iterates to create nested children
   * @param childBlock
   * @param parentId
   */
  const createChildBlockInBackend = async (
    childBlock: Block,
    parentId: number
  ) => {
    const hasChildren = userExplorerBlocksChildren[childBlock.id]?.length > 0;
    if (block) {
      let blockInsertInput: Block_Insert_Input = {
        archived: false,
        active: true,
        block: childBlock.block,
        //@ts-expect-error
        type: childBlock.block.type,
        note: block.teacherNotes,
        created_time: Date.now(),
        has_children: hasChildren,
        publicly_accessible: false,
        last_edited_time: Date.now(),
        position: childBlock.position,
        parent_id: parentId,
        created_by_id: userId || null,
        verified: verified,
      };
      const response = await createBlockMutation({
        variables: {
          block_spec: blockInsertInput,
        },
      });
      if (response.errors) console.log(response.errors);
      if (response.data?.insert_block_one && hasChildren) {
        console.log("DUPLICATING TAGS");
        await insertTags({
          tags:
            childBlock.tags.map((tag) => ({
              //@ts-expect-error
              block_id: response.data?.insert_block_one.id,
              topic_id: tag.topicId,
            })) || [],
        });
        await Promise.all(
          userExplorerBlocksChildren[childBlock.id]?.map(async (b1) => {
            if (response.data?.insert_block_one?.id) {
              await createChildBlockInBackend(
                b1,
                response.data?.insert_block_one?.id
              );
            }
          })
        );
      }
      return response;
    }
  };

  /**
   * Create a copy of a block in the backend
   */
  const createExplorerBlockInBackend = async () => {
    if (block) {
      let resourceDbId: number | undefined = undefined;
      let sectionDbId: number | undefined = undefined;

      //Create resource
      // - If block being duplicated is not a resource block, create an empty resource block to contain children
      // -  If block being duplicated is a resource block, duplicate it and return
      let blockInsertInputResource: Block_Insert_Input = {
        archived: false,
        active: true,
        //@ts-expect-error
        block: block.block.type === BLOCK_TYPES.RESOURCE ? block.block : {},
        type: BLOCK_TYPES.RESOURCE,
        created_time: Date.now(),
        has_children: true,
        publicly_accessible: false,
        last_edited_time: Date.now(),
        position: block.position,
        parent_id: null,
        created_by_id: userId || null,
        note:
          //@ts-expect-error
          block.block.type === BLOCK_TYPES.RESOURCE ? block.teacherNotes : null,
        verified: verified,
      };
      const responseResource = await createBlockMutation({
        variables: {
          block_spec: blockInsertInputResource,
        },
      });
      if (responseResource.errors) console.log(responseResource.errors);
      if (responseResource.data?.insert_block_one) {
        resourceDbId = responseResource.data.insert_block_one.id;
        if (
          //@ts-expect-error
          block.block.type === BLOCK_TYPES.RESOURCE &&
          responseResource.data?.insert_block_one?.id !== undefined
        ) {
          console.log("DUPLICATING TAGS");
          await insertTags({
            tags:
              block.tags.map((tag) => ({
                //@ts-expect-error
                block_id: responseResource.data?.insert_block_one.id,
                topic_id: tag.topicId,
              })) || [],
          });

          console.log("CREATING RESOURCE");
          console.log(`CREATING RESOURCE CHILDREN WITH PARENT${resourceDbId}`);

          await Promise.all(
            userExplorerBlocksChildren[block.id].map(async (b1) => {
              if (resourceDbId) {
                await createChildBlockInBackend(b1, resourceDbId);
              }
            })
          );
          return resourceDbId;
        }
      }

      //If not a section block, create a section block to contain all child blocks and store ID
      if (
        //@ts-expect-error
        block.block.type !== BLOCK_TYPES.SECTION &&
        //@ts-expect-error
        block.block.type !== BLOCK_TYPES.RESOURCE
      ) {
        let blockInsertInputSection: Block_Insert_Input = {
          archived: false,
          active: true,
          block: {},
          type: BLOCK_TYPES.SECTION,
          created_time: Date.now(),
          has_children: true,
          publicly_accessible: false,
          last_edited_time: Date.now(),
          position: block.position,
          parent_id: resourceDbId,
          created_by_id: userId || null,
          verified: verified,
        };
        const responseSection = await createBlockMutation({
          variables: {
            block_spec: blockInsertInputSection,
          },
        });
        if (responseSection.errors) console.log(responseSection.errors);
        if (responseSection.data?.insert_block_one)
          sectionDbId = responseSection.data.insert_block_one.id;
      }

      const hasChildren = userExplorerBlocksChildren[block.id]?.length > 0;

      //Create explorer block
      let blockInsertInput: Block_Insert_Input = {
        archived: false,
        active: true,
        block: block.block,
        type: block.type,
        created_time: Date.now(),
        has_children: hasChildren,
        publicly_accessible: false,
        last_edited_time: Date.now(),
        position: block.position,
        parent_id:
          //@ts-expect-error
          block.block.type === BLOCK_TYPES.SECTION ? resourceDbId : sectionDbId,
        created_by_id: userId || null,
        note: block.teacherNotes,
        verified: verified,
      };
      const response = await createBlockMutation({
        variables: {
          block_spec: blockInsertInput,
        },
      });
      if (response.errors) console.log(response.errors);
      if (response.data?.insert_block_one && hasChildren) {
        console.log("DUPLICATING TAGS");
        await insertTags({
          tags:
            block.tags.map((tag) => ({
              //@ts-expect-error
              block_id: response.data?.insert_block_one.id,
              topic_id: tag.topicId,
            })) || [],
        });
        await Promise.all(
          userExplorerBlocksChildren[block.id].map(async (b1) => {
            response.data?.insert_block_one?.id &&
              (await createChildBlockInBackend(
                b1,
                response.data?.insert_block_one?.id
              ));
          })
        );
      }

      return resourceDbId;
    }
  };

  const createBlockFromExplorerBlock = async () => {
    if (block) {
      setCreatingBlock(block.id);
      const dbResourceId = await createExplorerBlockInBackend();
      openInNewTab(
        `${process.env.REACT_APP_RESOURCE_BUILDER_URL}/editor/${dbResourceId}`
      );
      setCreatingBlock(null);
    }
  };

  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    if (getResourceError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get child blocks",
        })
      );
    } else if (getResourceData && resourceToViewId && getResourceData.block) {
      // console.log(getResourceData);
      const explorerPayload: Block = {
        id: getResourceData.block[0].id,
        tags: getResourceData.block[0].tags.map((tag) => ({
          id: tag.id,
          topicId: tag.topic?.id,
          label: tag.topic?.title || "",
        })),
        active: getResourceData.block[0].active,
        block: getResourceData.block[0].block,
        position: getResourceData.block[0].position,
        teacherNotes: getResourceData.block[0].note,
        parent_id: getResourceData.block[0].parent_id,
        type: getResourceData.block[0].type,
      };
      dispatch(userAppInfoExplorerBlocks([explorerPayload]));
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved child blocks",
        })
      );
      setResourceToView(explorerPayload);
    }
  }, [getResourceData, getResourceError]);

  const [localSideBarTabOpen, setLocalSideBarTabOpen] =
    useState<boolean>(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (authorised === undefined || resourceInfo === undefined) {
    return <LoadingCenteredSpinnerWithText text={"Loading your resource"} />;
  }

  return (
    <>
      <AltraTopAppBar displaySubCompoments appName={ALTRA_APPS.CE} />
      <Toolbar />

      <StyledViewScreen
        style={{ backgroundColor: darkMode ? "#262626" : "rgb(248, 248, 248)" }}
      >
        <StyledSidebar
          style={{
            width: localSideBarTabOpen ? "25%" : "0%",
            padding: localSideBarTabOpen ? "2em" : "0",
          }}
        >
          <div style={{ width: "100%" }}>
            <IconButton
              sx={{ marginBottom: "1em" }}
              color={"error"}
              onClick={() => setLocalSideBarTabOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            {block &&
              renderContentNavigationForBlockType(
                block,
                userExplorerBlocksChildren,
                block?.id,
                "EXPLORER",
                //@ts-expect-error
                routerParams?.id
              )}
          </div>
        </StyledSidebar>
        <StyledPreviewColumn
          sx={{
            height: "100%",
            width: localSideBarTabOpen ? "75%" : "100%",
            position: "relative",
            overflow: "auto",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <StyledTopBar>
            <div style={{ alignItems: "center", display: "flex" }}>
              {!isTabletOrMobile && (
                <Tooltip
                  sx={{ m: 1 }}
                  placement={
                    localSideBarTabOpen ? "bottom-start" : "bottom-end"
                  }
                  title={
                    localSideBarTabOpen
                      ? "Hide side bar"
                      : "View contents and navigate through resource"
                  }
                >
                  <StyledRaisedButton>
                    <RaisedIconButton
                      onClick={() => {
                        setLocalSideBarTabOpen(!localSideBarTabOpen);
                      }}
                    >
                      {localSideBarTabOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                    </RaisedIconButton>
                  </StyledRaisedButton>
                </Tooltip>
              )}
              {/*{!isTabletOrMobile &&*/}
              {/*  (teacherOrStudent === "TEACHER" ? (*/}
              {/*    <>*/}
              {/*      <Tooltip*/}
              {/*        title={*/}
              {/*          "Easily create your own copy of this resource, adapt it to your needs, import other users' content and export in no time at all"*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <LoadingButton*/}
              {/*          loading={creatingBlock !== null}*/}
              {/*          size="small"*/}
              {/*          variant={"contained"}*/}
              {/*          sx={{*/}
              {/*            "&:hover": {*/}
              {/*              backgroundColor:*/}
              {/*                altraResourceBuilderTheme.palette.primary.main,*/}
              {/*            },*/}
              {/*            backgroundColor:*/}
              {/*              altraResourceBuilderTheme.palette.primary.dark,*/}
              {/*            color:*/}
              {/*              altraResourceBuilderTheme.palette.primary*/}
              {/*                .contrastText,*/}
              {/*            margin: "0 1em",*/}
              {/*          }}*/}
              {/*          onClick={*/}
              {/*            resourceInfo?.author === userId &&*/}
              {/*            block?.type === "resource"*/}
              {/*              ? () =>*/}
              {/*                  window.open(*/}
              {/*                    //@ts-expect-error*/}
              {/*                    `${process.env.REACT_APP_RESOURCE_BUILDER_URL}/editor/${routerParams?.id}`*/}
              {/*                  )*/}
              {/*              : createBlockFromExplorerBlock*/}
              {/*          }*/}
              {/*          startIcon={<EditIcon />}*/}
              {/*          disabled={*/}
              {/*            loadingStatus.status === Status.LOADING ||*/}
              {/*            !block ||*/}
              {/*            resourceInfo === undefined ||*/}
              {/*            (!resourceInfo.public &&*/}
              {/*              resourceInfo.author !== userId)*/}
              {/*          }*/}
              {/*        >*/}
              {/*          {resourceInfo?.author === userId &&*/}
              {/*          block?.type === "resource"*/}
              {/*            ? "Edit original"*/}
              {/*            : "Create my own copy to adapt"}*/}
              {/*        </LoadingButton>*/}
              {/*      </Tooltip>*/}
              {/*      <Tooltip*/}
              {/*        title={` Make changes to this 100% Customisable ${prettyBlockType(*/}
              {/*          block?.type*/}
              {/*        )}, cherry-pick from other*/}
              {/*          teachers’ resources and share with your students`}*/}
              {/*      >*/}
              {/*        <Button*/}
              {/*          size="small"*/}
              {/*          variant={"contained"}*/}
              {/*          startIcon={<QuizIcon />}*/}
              {/*          sx={{*/}
              {/*            "&:hover": {*/}
              {/*              backgroundColor:*/}
              {/*                altraProgressManagerTheme.palette.primary.main,*/}
              {/*            },*/}
              {/*            backgroundColor:*/}
              {/*              altraProgressManagerTheme.palette.primary.dark,*/}
              {/*            color:*/}
              {/*              altraProgressManagerTheme.palette.primary*/}
              {/*                .contrastText,*/}
              {/*          }}*/}
              {/*          onClick={() => setShareAssignmentModalOpen(true)}*/}
              {/*        >*/}
              {/*          Create assignment*/}
              {/*        </Button>*/}
              {/*      </Tooltip>*/}
              {/*    </>*/}
              {/*  ) : (*/}
              {/*    <Tooltip*/}
              {/*      title={*/}
              {/*        "Practise answering questions within this resource, compare your answers and submit your answers for feedback"*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <Button*/}
              {/*        size="small"*/}
              {/*        onClick={createSubmissionAndNavigate}*/}
              {/*        sx={{*/}
              {/*          "&:hover": {*/}
              {/*            backgroundColor:*/}
              {/*              altraResourceBuilderTheme.palette.primary.main,*/}
              {/*          },*/}
              {/*          backgroundColor:*/}
              {/*            altraResourceBuilderTheme.palette.primary.dark,*/}
              {/*          color:*/}
              {/*            altraResourceBuilderTheme.palette.primary*/}
              {/*              .contrastText,*/}
              {/*          margin: "0 1em",*/}
              {/*        }}*/}
              {/*        color={"primary"}*/}
              {/*        variant="contained"*/}
              {/*        startIcon={<QuizIcon />}*/}
              {/*      >*/}
              {/*        Revise this {prettyBlockType(block?.type)}*/}
              {/*      </Button>*/}
              {/*    </Tooltip>*/}
              {/*  ))}*/}
            </div>
            <StyledButtonRow>
              <StyledIconLabelButton>
                <IconButton
                  //@ts-expect-error
                  onClick={() => history.push(`/export/${routerParams?.id}`)}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <FileDownloadIcon sx={{ color: "white" }} />
                </IconButton>
              </StyledIconLabelButton>
              <StyledIconLabelButton>
                <IconButton
                  onClick={() => setShareLinkModalOpen(true)}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <ShareIcon sx={{ color: "white" }} />
                </IconButton>
              </StyledIconLabelButton>
              <StyledIconLabelButton>
                <IconButton
                  onClick={() => setShareQrModalOpen(true)}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <QrCodeScannerIcon sx={{ color: "white" }} />
                </IconButton>
              </StyledIconLabelButton>
              <StyledIconLabelButton>
                <IconButton
                  onClick={() => setResourceSettingsModalOpen(true)}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <SettingsIcon sx={{ color: "white" }} />
                </IconButton>
              </StyledIconLabelButton>
            </StyledButtonRow>
            <ShareLinkAndQRModal
              open={shareLinkModalOpen}
              //@ts-expect-error
              id={routerParams?.id}
              toggleOpen={setShareLinkModalOpen}
              type={"linkOnly"}
            />
            <ShareLinkAndQRModal
              open={shareQrModalOpen}
              //@ts-expect-error
              id={routerParams?.id}
              toggleOpen={setShareQrModalOpen}
            />
          </StyledTopBar>
          {!authorised ? (
            <StyledCenteredErrorContainer>
              <StyledCenteredError>
                <Typography variant={"h1"} sx={{ marginBottom: "20px" }}>
                  You do not have permission to view this resource
                </Typography>
                <Button
                  size={"large"}
                  variant={"contained"}
                  onClick={() => history.push("/")}
                >
                  Find more resources
                </Button>
              </StyledCenteredError>
            </StyledCenteredErrorContainer>
          ) : (
            <StyledResourceContainer
              sx={{ width: isTabletOrMobile ? "80%" : "60%" }}
            >
              {resourceToView ? (
                renderViewOnlyBlockForBlockType(
                  resourceToView,
                  true,
                  false,
                  true
                )
              ) : (
                <CircularProgress />
              )}
            </StyledResourceContainer>
          )}
        </StyledPreviewColumn>
        <ShareAssignmentLink
          open={shareAssignmentModalOpen}
          //@ts-expect-error
          id={routerParams?.id}
          toggleOpen={setShareAssignmentModalOpen}
        />
      </StyledViewScreen>
      <ResourceSettingsDialog
        settingsModalOpen={resourceSettingsModalOpen}
        setSettingsModalOpen={setResourceSettingsModalOpen}
      />
    </>
  );
};

const StyledViewScreen = styled("div")`
  //justify-content: flex-start;
  //align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  //flex: 1;
  //padding-bottom: 50vh;
  left: 0;
  overflow: hidden;
  height: 95%;
`;
const StyledPreviewColumn = styled("div")`
  justify-content: flex-start;
  display: flex;
  align-items: center;
  transition: width 200ms;
  flex-direction: column;
  @media print {
    background-color: white;
    width: 100%;
    overflow: visible;
  }
  overflow: auto;
`;

const StyledResourceContainer = styled("div")`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #606060;
  border-radius: 10px;
  margin: 2em;
  padding: 1em;
  justify-content: flex-start;
  @media print {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    margin: 0;
    border: none;
  }
`;

const StyledCenteredError = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5em",
  flexDirection: "column",
  width: "40%",
}));
const StyledCenteredErrorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));
const StyledTopBar = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "50px",
  padding: "0.5em",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#E3E3E3",
}));
const StyledButtonRow = styled(CardActions)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledIconLabelButton = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
const StyledSidebar = styled("div")(({ theme }) => ({
  display: "flex",
  transition: `width 200ms`,
  backgroundColor: "white",
  overflowY: "auto",
  overflowX: "hidden",

  justifyContent: "flex-start",
}));
const StyledRaisedButton = styled("div")(({ theme }) => ({
  // alignSelf: "center",
  // right: "-1em",
  // width: 0,
  // zIndex: 1,
}));
const StyledBody = styled("div")(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  flexDirection: "row",
  flex: 1,
  width: "100%",
  height: "100vh",
}));
