import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import { CodeElement } from "../../util/custom-editor-types";
import { ViewBlockCodeElement } from "../curriculumExplorerPreviewElements/ViewBlockCodeElement";

type CodeElementPreviewModalContentsProps = {
  block: CodeElement;
  tags: Tag[];
  position: number | null | undefined;
};

/**
 * Preview modal contents for a Code block
 * @param block
 * @param tags
 * @param children
 * @constructor
 */
export const CodeElementPreviewModalContents: FC<
  CodeElementPreviewModalContentsProps
> = ({ block, tags, children }) => {
  return (
    <div style={{ width: "100%" }}>
      {/*<StyledTopRow>*/}
      {/*  <Tooltip placement={"left"} title={"Body text"}>*/}
      {/*    <img*/}
      {/*      style={{*/}
      {/*        height: "30px",*/}
      {/*        marginBottom: 10,*/}
      {/*        marginRight: "10px",*/}
      {/*        display: "inline-flex",*/}
      {/*      }}*/}
      {/*      src={CodeBlockIcon}*/}
      {/*      alt={"Code Block"}*/}
      {/*    />*/}
      {/*  </Tooltip>*/}
      {/*</StyledTopRow>*/}

      <ViewBlockCodeElement doNotLoadChildren={false} block={block} />
    </div>
  );
};

const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
}));
