import { AltraSpeedDial } from "@altra-apps/common/src/molecules/AltraSpeedDial";
import { ApolloClientContext } from "@altra-apps/common/src/providers/ApolloClientContext";
import { alterCurriculumExplorerTheme } from "@altra-apps/common/src/styling/altra-curriculum-explorer-theme";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MainRouter } from "./routers/MainRouter";

function App() {
  const isTabletOrMobile = useIsMobile();
  return (
    <div
      style={{ overflowX: isTabletOrMobile ? "hidden" : "inherit" }}
      className="App"
    >
      <ThemeProvider theme={alterCurriculumExplorerTheme}>
        <ApolloClientContext>
          <Router>
            <MainRouter />
          </Router>
          <AltraSpeedDial />
        </ApolloClientContext>
      </ThemeProvider>
    </div>
  );
}

export default App;
