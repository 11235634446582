import { ViewAnswerElement } from "../molecules/curriculumExplorerPreviewElements/ViewAnswerElement";
import { ViewBlockCodeElement } from "../molecules/curriculumExplorerPreviewElements/ViewBlockCodeElement";
import { ViewDiagramElement } from "../molecules/curriculumExplorerPreviewElements/ViewDiagramElement";
import { ViewHeadingElement } from "../molecules/curriculumExplorerPreviewElements/ViewHeadingElement";
import { ViewParagraphElement } from "../molecules/curriculumExplorerPreviewElements/ViewParagraphElement";
import { ViewPassageElement } from "../molecules/curriculumExplorerPreviewElements/ViewPassageElement";
import { ViewQuestionTextOnlyElement } from "../molecules/curriculumExplorerPreviewElements/ViewQuestionTextOnlyElement";
import { ViewQuoteElement } from "../molecules/curriculumExplorerPreviewElements/ViewQuoteElement";
import { ViewResourceElement } from "../molecules/curriculumExplorerPreviewElements/ViewResourceElement";
import { ViewSectionElement } from "../molecules/curriculumExplorerPreviewElements/ViewSectionElement";
import { Block } from "../redux/user/types";
import { BLOCK_TYPES } from "./custom-types";
import { QUESTION_TYPE } from "./questionTypes";

/**
 * Switch statement on bock to render corresponding result card
 * @param block
 * @param displayBorder
 * @param viewWatermark
 * @param showPreviewIcon
 * @param doNotLoadChildren
 * @param submissionView
 */
export const renderViewOnlyBlockForBlockType = (
  block: Block,
  displayBorder?: boolean,
  viewWatermark?: boolean,
  showPreviewIcon?: boolean,
  doNotLoadChildren?: boolean,
  submissionView?: "SUBMISSION" | "REVIEW" | "REVIEWED"
) => {
  const blockType: string = block["type"];
  // console.log(block);
  switch (blockType) {
    case "resource":
      return (
        <ViewResourceElement
          displayBorder={displayBorder}
          showPreviewIcon={showPreviewIcon}
          viewWatermark={viewWatermark}
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.RESOURCE,
            description: block.block["description"],
            title: block.block["title"],
            children: block.block,
          }}
          doNotLoadChildren={doNotLoadChildren}
          submissionView={submissionView}
        />
      );
    case "section":
      return (
        <ViewSectionElement
          viewWatermark={viewWatermark}
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.SECTION,
            description: block.block["description"],
            title: block.block["title"],
            children: [],
          }}
          displayBorder={displayBorder}
          showPreviewIcon={showPreviewIcon}
          doNotLoadChildren={doNotLoadChildren}
          submissionView={submissionView}
        />
      );
    case "paragraph":
    case "answer-text":
    case "additional-guidance":
    case "generic-guidance":
      return (
        <ViewParagraphElement
          block={{
            id: block.id.toString(),
            //@ts-expect-error
            type: blockType,
            children: block.block["children"],
          }}
          doNotLoadChildren={doNotLoadChildren}
        />
      );
    case "question-text-only":
      return (
        <ViewQuestionTextOnlyElement
          displayBorder={true}
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.QUESTION_TEXT_ONLY,
            children: block.block["children"],
          }}
          showPreviewIcon={showPreviewIcon}
          doNotLoadChildren={doNotLoadChildren}
          submissionView={submissionView}
        />
      );
    case "passage":
      return (
        <ViewPassageElement
          displayBorder={true}
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.PASSAGE,
            children: block.block["children"],
            description: block.block["description"],
            title: block.block["title"],
          }}
          showPreviewIcon={showPreviewIcon}
          viewWatermark={viewWatermark}
          doNotLoadChildren={doNotLoadChildren}
        />
      );
    case "diagram":
      return (
        <ViewDiagramElement
          fullSize={true}
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.DIAGRAM,
            children: block.block["children"],
            width: block.block["width"],
            whiteboardImageS3Url: block.block["whiteboardImageS3Url"],
            whiteboardJsonS3Url: block.block["whiteboardJsonS3Url"],
          }}
          showPreviewIcon={showPreviewIcon}
          doNotLoadChildren={doNotLoadChildren}
        />
      );
    case "block-quote":
      return (
        <ViewQuoteElement
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.BLOCK_QUOTE,
            children: block.block["children"],
          }}
          doNotLoadChildren={doNotLoadChildren}
        />
      );
    case "heading-1":
    case "heading-2":
      return (
        <ViewHeadingElement
          block={{
            id: block.id.toString(),
            type:
              blockType === "heading-1"
                ? BLOCK_TYPES.HEADING_1
                : BLOCK_TYPES.HEADING_2,
            children: block.block["children"],
          }}
          type={blockType === "heading-1" ? "ONE" : "TWO"}
          doNotLoadChildren={doNotLoadChildren}
        />
      );
    case "answer":
      return (
        <ViewAnswerElement
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.ANSWER,
            children: block.block["children"],
            questionType: QUESTION_TYPE.QUESTION_TEXT_ONLY,
            marks: block.block["marks"],
          }}
          showPreviewIcon={showPreviewIcon}
          viewWatermark={viewWatermark}
          displayBorder={true}
          doNotLoadChildren={doNotLoadChildren}
          submissionView={submissionView}
        />
      );
    case "code":
      return (
        <ViewBlockCodeElement
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.CODE,
            children: block.block["children"],
          }}
          doNotLoadChildren={doNotLoadChildren}
        />
      );
    default:
      return <div>Unsupported type: {blockType}</div>;
  }
};
