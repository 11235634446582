import { Skeleton } from "@mui/lab";
import { Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Block as AwsBlock,
  GetBlocksWithChildrenQueryVariables,
  useGetBlocksWithChildrenLazyQuery,
} from "../../graphql/types";
import { updateLoadingStatus } from "../../redux/applicationContext/actions";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { userAppInfoExplorerBlocksChildren } from "../../redux/user/actions";
import { Block } from "../../redux/user/types";
import { FONT_WEIGHTS } from "../../styling/altra-theme";
import { SectionElement } from "../../util/custom-editor-types";
import { Status } from "../../util/custom-types";
import { sortChildrenIntoBlocks } from "../../util/helpers";
import { renderViewOnlyBlockForBlockType } from "../../util/render-view-only-block-for-block-type";
import { useIsMobile } from "../../util/useIsMobile";
import { ResourceBuilderWatermark } from "../ResourceBuilderWatermark";

type ViewSectionElementProps = {
  block: SectionElement;
  displayBorder?: boolean;
  viewWatermark?: boolean;
  showPreviewIcon?: boolean;
  doNotLoadChildren?: boolean;
  submissionView?: "SUBMISSION" | "REVIEW" | "REVIEWED";
};

/**
 * View only element for displaying Section blocks
 * @param block
 * @param displayBorder
 * @param viewWatermark
 * @param showPreviewIcon
 * @param doNotLoadChildren
 * @param submissionView
 * @constructor
 */
export const ViewSectionElement: FC<ViewSectionElementProps> = ({
  block,
  displayBorder,
  viewWatermark,
  showPreviewIcon,
  doNotLoadChildren,
  submissionView,
}) => {
  const dispatch = useAppDispatch();

  const [childrenLoading, setChildrenLoading] = useState(true);
  const userExplorerBlocksChildren: { [parentId: number]: Array<Block> } =
    useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);

  const [
    getBlocksWithChildrenCallback,
    { data: getChildrenData, error: getChildrenError },
  ] = useGetBlocksWithChildrenLazyQuery();

  /**
   * Initial load of all blocks for selected curriculum and topics
   */
  useEffect(() => {
    if (!doNotLoadChildren) {
      setChildrenLoading(true);
      getAllChildren();
    } else {
      setChildrenLoading(false);
    }
  }, []);

  const getAllChildren = async () => {
    let getChildrenInput: GetBlocksWithChildrenQueryVariables = {
      ids: `{${block.id}}`,
    };
    getBlocksWithChildrenCallback({
      variables: getChildrenInput,
    });
  };

  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    if (getChildrenError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get child blocks",
        })
      );
    } else if (getChildrenData && getChildrenData) {
      const childBlocksAll: AwsBlock[] =
        getChildrenData.get_blocks_with_children.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        ) as AwsBlock[];
      const childBlocksForRedux: { id: number; children: AwsBlock[] }[] =
        sortChildrenIntoBlocks(parseInt(block.id), childBlocksAll);

      dispatch(
        userAppInfoExplorerBlocksChildren(
          childBlocksForRedux.map((cb) => ({
            parentId: cb.id,
            children:
              cb.children.map((block) => ({
                id: block.id,
                tags: block.tags.map((tag) => ({
                  id: tag.id,
                  topicId: tag.topic?.id,
                  label: tag.topic?.title || "",
                })),
                active: block.active,
                block: block.block,
                type: block.type,
                position: block.position,
                parent_id: block.parent_id,
                teacherNotes: block.note,
              })) || [],
          }))
        )
      );
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved child blocks",
        })
      );
    }
    setChildrenLoading(false);
  }, [getChildrenData, getChildrenError]);
  const isTabletOrMobile = useIsMobile();

  /**
   * Callback to render children of resource only to be called if userExplorerBlocksChildren changes
   */
  const renderChildren = useMemo(
    () => (
      <>
        {userExplorerBlocksChildren[block.id]
          ?.slice()
          .sort((a, b) => (a?.position || 0) - (b?.position || 0))
          ?.map((childBlock) => (
            <div>
              {renderViewOnlyBlockForBlockType(
                childBlock,
                true,
                viewWatermark,
                showPreviewIcon,
                doNotLoadChildren,
                submissionView
              )}
            </div>
          ))}
      </>
    ),
    [userExplorerBlocksChildren, submissionView]
  );

  return (
    <StyledSectionContainer isTabletOrMobile={isTabletOrMobile}>
      {childrenLoading ? (
        <>
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
        </>
      ) : (
        <StyledHashLinkContainer id={block.id.toString()}>
          <StyledTitleDesc isTabletOrMobile={isTabletOrMobile}>
            <Typography variant={"h1"}>{block.title}</Typography>
            {/*  @ts-expect-error*/}
            <Typography variant={"h2Light"}>{block.description}</Typography>
          </StyledTitleDesc>
          {<StyledDivider style={{ margin: "0.5em 0" }} />}

          {renderChildren}
          {viewWatermark && <ResourceBuilderWatermark />}
        </StyledHashLinkContainer>
      )}
    </StyledSectionContainer>
  );
};

interface StyledTitleDescProps {
  isTabletOrMobile: boolean;
}
const StyledSectionContainer = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "isTabletOrMobile",
})<StyledTitleDescProps>(({ theme, isTabletOrMobile }) => ({
  display: "flex",
  borderRadius: "8px",
  padding: isTabletOrMobile ? "0 30px" : "0 50px",
  fontWeight: FONT_WEIGHTS.REGULAR_500,
  fontSize: 16,
  backgroundColor: "white",
  flexDirection: "column",
  alignItems: "stretch",
  height: "100%",
  breakAfter: "page",
  "@media print": {
    padding: 0,
    margin: 0,
    display: "block",
  },
}));
const StyledTitleDesc = styled("div", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "isTabletOrMobile",
})<StyledTitleDescProps>(({ theme, isTabletOrMobile }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: isTabletOrMobile ? "30px" : "50px",

  "@media print": { display: "block", padding: 0 },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  "@media print": {
    display: "none",
  },
}));
const StyledHashLinkContainer = styled("div")(({ theme }) => ({
  marginTop: "-50px",
  paddingTop: "50px",
  display: "block",
}));
