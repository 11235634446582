import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC, useEffect, useState } from "react";
import {
  Block as AwsBlock,
  GetBlocksWithChildrenQueryVariables,
  useGetBlocksWithChildrenLazyQuery,
} from "../../graphql/types";
import { updateLoadingStatus } from "../../redux/applicationContext/actions";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { userAppInfoExplorerBlocksChildren } from "../../redux/user/actions";
import { Block } from "../../redux/user/types";
import { altraTheme } from "../../styling/altra-theme";
import { PassageElement } from "../../util/custom-editor-types";
import { Status } from "../../util/custom-types";
import { sortChildrenIntoBlocks } from "../../util/helpers";
import { renderViewOnlyBlockForBlockType } from "../../util/render-view-only-block-for-block-type";

type ViewPassageElementProps = {
  block: PassageElement;
  displayBorder?: boolean;
  viewWatermark;
  showPreviewIcon;
  doNotLoadChildren?: boolean;
};

/**
 * View only element for displaying Passage blocks
 * @param block
 * @constructor
 */
export const ViewPassageElement: FC<ViewPassageElementProps> = ({
  block,
  displayBorder,
  viewWatermark,
  showPreviewIcon,
  doNotLoadChildren,
}) => {
  const dispatch = useAppDispatch();

  const [childrenLoading, setChildrenLoading] = useState(true);
  const userExplorerBlocksChildren: { [parentId: number]: Array<Block> } =
    useAppSelector((state) => state.userAppInfo.userExplorerBlocksChildren);

  const [
    getBlocksWithChildrenCallback,
    { data: getChildrenData, error: getChildrenError },
  ] = useGetBlocksWithChildrenLazyQuery();

  /**
   * Initial load of all blocks for selected curriculum and topics
   */
  useEffect(() => {
    if (!doNotLoadChildren) {
      setChildrenLoading(true);
      getAllChildren();
    } else {
      setChildrenLoading(false);
    }
  }, []);

  const getAllChildren = async () => {
    console.log("getting children for passage");
    let getChildrenInput: GetBlocksWithChildrenQueryVariables = {
      ids: `{${block.id}}`,
    };
    getBlocksWithChildrenCallback({
      variables: getChildrenInput,
    });
  };

  //On retrieval of child blocks, dispatch to redux
  useEffect(() => {
    if (getChildrenError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get child blocks",
        })
      );
    } else if (getChildrenData && getChildrenData) {
      const childBlocksAll: AwsBlock[] =
        getChildrenData.get_blocks_with_children.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        ) as AwsBlock[];
      const childBlocksForRedux: { id: number; children: AwsBlock[] }[] =
        sortChildrenIntoBlocks(parseInt(block.id), childBlocksAll);

      dispatch(
        userAppInfoExplorerBlocksChildren(
          childBlocksForRedux.map((cb) => ({
            parentId: cb.id,
            children:
              cb.children.map((block) => ({
                id: block.id,
                tags: block.tags.map((tag) => ({
                  id: tag.id,
                  topicId: tag.topic?.id,
                  label: tag.topic?.title || "",
                })),
                active: block.active,
                block: block.block,
                type: block.type,
                position: block.position,
                parent_id: block.parent_id,
                teacherNotes: block.note,
              })) || [],
          }))
        )
      );
      dispatch(
        updateLoadingStatus({
          status: Status.IDLE,
          message: "Retrieved child blocks",
        })
      );
    }
    setChildrenLoading(false);
  }, [getChildrenData, getChildrenError]);
  return (
    <div style={{ width: "100%", padding: "25px 0" }}>
      <StyledPassageContainer
        sx={{
          width: displayBorder ? "100%" : "100%",
          borderRadius: displayBorder ? "10px" : 0,
          border: displayBorder ? "1px solid #DBDBDB" : 0,
        }}
        style={altraTheme.typography.body1}
      >
        <StyledTitleDesc>
          <Typography variant={"h4"}>{block.title}</Typography>
          {/*@ts-expect-error*/}
          <Typography variant={"h7"}>{block.description}</Typography>
        </StyledTitleDesc>

        <div style={{ margin: "1em" }}>
          {userExplorerBlocksChildren[block.id]
            ?.slice()
            .sort((a, b) => (a?.position || 0) - (b?.position || 0))
            ?.map((childBlock) =>
              renderViewOnlyBlockForBlockType(
                childBlock,
                false,
                viewWatermark,
                showPreviewIcon,
                true
              )
            )}
        </div>
      </StyledPassageContainer>
    </div>
  );
};

const StyledPassageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  height: "100%",
  margin: "1em 0",
}));
const StyledTitleDesc = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "1em",
}));
