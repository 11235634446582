import CloseIcon from "@mui/icons-material/Close";
import { Chip, ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC, useState } from "react";
import { altraTheme } from "../styling/altra-theme";
import { useIsMobile } from "../util/useIsMobile";

export enum CHIP_COLOURS {
  ORANGE = "#FDDFCC",
  RED = "#FFCCD1",
  PURPLE = "#E1D3F8",
  BLUE = "#C2DFF9",
  YELLOW = "#FBEECC",
  PINK = "#F8CCE6",
}

export const CHIP_TYPES = {
  CURRICULUM: CHIP_COLOURS.ORANGE,
  TOPIC: CHIP_COLOURS.PINK,
  BLOCK_TYPE: CHIP_COLOURS.YELLOW,
  AUTHOR: CHIP_COLOURS.BLUE,
};

type KeyIssueChipProps = {
  label: string;
  showAll: boolean;
  onRemove?: () => void;
  color: CHIP_COLOURS;
  tooltipLabel?: string;
};

export const KeyIssueChip: FC<KeyIssueChipProps> = ({
  label,
  onRemove,
  showAll,
  color,
  tooltipLabel,
}) => {
  const theme = useTheme();
  const isTabletOrMobile = useIsMobile();

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return isTabletOrMobile ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div style={{ display: "inline" }}>
        <Tooltip
          title={tooltipLabel || label}
          placement="top"
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableTouchListener
        >
          <Chip
            onClick={handleTooltipOpen}
            size={"small"}
            sx={altraTheme.typography.body2}
            style={{
              textOverflow: showAll ? "inherit" : "elipsis",
              maxWidth: showAll ? "100%" : "15em",
              width: "min-content",
              margin: "0.2em",
              backgroundColor: color,
            }}
            label={label}
            // deleteIcon={type === "remove" ? <CloseIcon /> : <MoreHorizIcon />}
            icon={
              onRemove && (
                <IconButton
                  style={{ color: theme.palette.error.main }}
                  onClick={onRemove}
                >
                  <CloseIcon />
                </IconButton>
              )
            }
          />
        </Tooltip>
      </div>
    </ClickAwayListener>
  ) : (
    <Tooltip title={tooltipLabel || label} placement="top">
      <Chip
        size={"small"}
        style={{
          textOverflow: showAll ? "inherit" : "elipsis",
          maxWidth: showAll ? "100%" : "15em",
          width: "min-content",
          margin: "0.2em",
          backgroundColor: color,
        }}
        label={label}
        // deleteIcon={type === "remove" ? <CloseIcon /> : <MoreHorizIcon />}
        icon={
          onRemove && (
            <IconButton
              style={{ color: theme.palette.error.main }}
              onClick={onRemove}
            >
              <CloseIcon />
            </IconButton>
          )
        }
      />
    </Tooltip>
  );
};
