import AltraNavyLogoLongWithIcon from "@altra-apps/common/assets/logos/AltraNavyLogoLongWithIcon.svg";
import ResourceBuilderLogoStacked from "@altra-apps/common/assets/logos/ResourceBuilderLogoStacked.svg";
import React, { FC } from "react";
import styled from "styled-components";

export const ResourceBuilderWatermark: FC = () => (
  <StyledWatermark>
    <img
      style={{ width: "20%" }}
      src={ResourceBuilderLogoStacked}
      alt={"Diagram"}
    />
    {/*<Typography style={{ width: "50%" }} variant={"caption"}>*/}
    {/*  Create your own resource at*/}
    {/*  <a*/}
    {/*    target={"_blank"}*/}
    {/*    href={`https://resourcebuilder.altra.space/`}*/}
    {/*    style={{*/}
    {/*      display: "flex",*/}
    {/*      alignItems: "center",*/}
    {/*      justifyContent: "center",*/}
    {/*    }}*/}
    {/*    rel="noreferrer"*/}
    {/*  >*/}
    {/*    resourcebuilder.altra.space*/}
    {/*  </a>*/}
    {/*</Typography>*/}
    <div style={{ width: "20%" }}>
      <img
        style={{ width: "80%" }}
        src={AltraNavyLogoLongWithIcon}
        alt={"Diagram"}
      />
    </div>
  </StyledWatermark>
);

const StyledWatermark = styled.div`
  display: none;
  @media print {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.5;
    text-align: center;
  }
`;
