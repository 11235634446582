import StopIcon from "@mui/icons-material/Stop";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import React, { FC } from "react";
import styled from "styled-components";
import { RaisedIconButton } from "../atoms/RaisedIconButton";
import { useIsMobile } from "../util/useIsMobile";

type SearchResultsGridProps = {
  displayGrid: boolean;
  renderResultCount: JSX.Element;
  renderSuggestedSearches: JSX.Element;
  renderNoResults: () => JSX.Element | undefined;
  displaySize: number;
  setDisplaySize: (size: number) => void;
  isSelectable: boolean;
};

export const SearchResultsGrid: FC<SearchResultsGridProps> = ({
  displayGrid,
  renderSuggestedSearches,
  renderResultCount,
  children,
  setDisplaySize,
  displaySize,
  renderNoResults,
  isSelectable,
}) => {
  const isTabletOrMobile = useIsMobile();
  /**
   * Buttons to change from 3 results per row to 2 or 1
   */
  const renderResizeButtons = () => (
    <div
      style={{
        display: "grid",
        gap: "0.5em",
        gridAutoFlow: "column",
        width: "7em",
        height: "2em",
      }}
    >
      <RaisedIconButton
        selected={displaySize === 1}
        small={true}
        onClick={() => {
          setDisplaySize(1);
        }}
        rounded={false}
      >
        <StopIcon />
      </RaisedIconButton>

      <RaisedIconButton
        selected={displaySize === 2}
        small={true}
        onClick={() => {
          setDisplaySize(2);
        }}
        rounded={false}
      >
        <ViewCompactIcon />
      </RaisedIconButton>

      <RaisedIconButton
        selected={displaySize === 3}
        small={true}
        onClick={() => {
          setDisplaySize(3);
        }}
        rounded={false}
      >
        <ViewComfyIcon />
      </RaisedIconButton>
    </div>
  );

  return (
    <>
      <StyledTopRow>
        <div>{renderResultCount}</div>
        {displayGrid && !isSelectable && renderResizeButtons()}
      </StyledTopRow>

      {isTabletOrMobile && <div>{renderSuggestedSearches}</div>}

      <StyledResultsContainer
        style={{
          gridTemplateColumns: `repeat(${
            displayGrid
              ? isTabletOrMobile
                ? 1
                : isSelectable
                ? 2
                : displaySize
              : 1
          }, 1fr)`,
        }}
      >
        {children}
        {renderNoResults()}
      </StyledResultsContainer>
    </>
  );
};

const StyledTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 1em 0;
  margin-bottom: 1em;
`;

const StyledResultsContainer = styled.div`
  padding: 0 1em;
  display: grid;
  gap: 1em;
  margin-bottom: 20px;
`;
