import { CodeElementPreviewModalContents } from "../molecules/explorerPreviewModals/CodeElementPreviewModalContents";
import { DiagramElementPreviewModalContents } from "../molecules/explorerPreviewModals/DiagramElementPreviewModalContents";
import { HeadingElementPreviewModalContents } from "../molecules/explorerPreviewModals/HeadingElementPreviewModalContents";
import { ParagraphElementPreviewModalContents } from "../molecules/explorerPreviewModals/ParagraphElementPreviewModalContents";
import { PassageElementPreviewModalContents } from "../molecules/explorerPreviewModals/PassageElementPreviewModalContents";
import { QuestionTextOnlyElementPreviewModalContents } from "../molecules/explorerPreviewModals/QuestionTextOnlyElementPreviewModalContents";
import { QuoteElementPreviewModalContents } from "../molecules/explorerPreviewModals/QuoteElementPreviewModalContents";
import { ResourceElementPreviewModalContents } from "../molecules/explorerPreviewModals/ResourceElementPreviewModalContents";
import { SectionElementPreviewModalContents } from "../molecules/explorerPreviewModals/SectionElementPreviewModalContents";
import { Block } from "../redux/user/types";
import { BLOCK_TYPES } from "./custom-types";

/**
 * Switch statement on block to render corresponding preview modal
 * @param block
 */
export const renderPreviewModalContentForBlockType = (block: Block) => {
  const blockType = block["type"];

  switch (blockType) {
    case "resource":
      return (
        <ResourceElementPreviewModalContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.RESOURCE,
            description: block.block["description"],
            title: block.block["title"],
            children: block.block,
          }}
          position={block.position}
          tags={block.tags}
        />
      );
    case "section":
      return (
        <SectionElementPreviewModalContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.SECTION,
            description: block.block["description"],
            title: block.block["title"],
            children: [],
          }}
          position={block.position}
          tags={block.tags}
        />
      );
    case "paragraph":
      return (
        <ParagraphElementPreviewModalContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.PARAGRAPH,
            children: block.block["children"],
          }}
          tags={block.tags}
          position={block.position}
        />
      );
    case "diagram":
      return (
        <DiagramElementPreviewModalContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.DIAGRAM,
            children: block.block["children"],
            whiteboardImageS3Url: block.block["whiteboardImageS3Url"],
            whiteboardJsonS3Url: block.block["whiteboardJsonS3Url"],
          }}
          tags={block.tags}
          position={block.position}
        />
      );
    // case "question-with-sub-parts":
    //   return (
    //     <QuestionWithSubPartsElementPreviewModalContents
    //       block={{
    //         id: block.id.toString(),
    //         type: blockType,
    //         children: block.block["children"],
    //       }}
    //       tags={block.tags}
    //     />
    //   );
    case "question-text-only":
      return (
        <QuestionTextOnlyElementPreviewModalContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.QUESTION_TEXT_ONLY,
            children: block.block["children"],
          }}
          tags={block.tags}
          position={block.position}
        />
      );
    case "passage":
      return (
        <PassageElementPreviewModalContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.PASSAGE,
            children: block.block["children"],
            description: block.block["description"],
            title: block.block["title"],
          }}
          tags={block.tags}
          position={block.position}
        />
      );
    case "block-quote":
      return (
        <QuoteElementPreviewModalContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.BLOCK_QUOTE,
            children: block.block["children"],
          }}
          tags={block.tags}
          position={block.position}
        />
      );
    case "code":
      return (
        <CodeElementPreviewModalContents
          block={{
            id: block.id.toString(),
            type: BLOCK_TYPES.CODE,
            children: block.block["children"],
          }}
          tags={block.tags}
          position={block.position}
        />
      );
    case "heading-1":
    case "heading-2":
      return (
        <HeadingElementPreviewModalContents
          block={{
            id: block.id.toString(),
            type:
              blockType === "heading-1"
                ? BLOCK_TYPES.HEADING_1
                : BLOCK_TYPES.HEADING_2,
            children: block.block["children"],
          }}
          tags={block.tags}
          type={blockType === "heading-1" ? "ONE" : "TWO"}
          position={block.position}
        />
      );
    default:
      return <div>Unsupported type: {blockType}</div>;
  }
};
