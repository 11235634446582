import { Autocomplete, Chip, TextField, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { FC, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Topic } from "../redux/applicationContext/types";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import {
  userAppInfoSelectedCurriculumsId,
  userAppInfoSelectedTopicsId,
} from "../redux/user/actions";
import { KeyValue, MultiSelectDropDownMode } from "../util/custom-types";
import { getUnionOfValuesForKeys } from "../util/helpers";
import { CHIP_TYPES } from "./KeyIssueChip";

interface AutocompleteMultiSelectDropdownProp {
  selectedItems: Array<number>;
  items: Array<KeyValue>;
  inputLabel: string;
  type: MultiSelectDropDownMode;
  topicLabelIds: number[];
}
/**
 * Autocomplete wrapper component for changing topics and curriculums
 * - Inlcludes logic to update redux values with selected topics
 */
export const AutocompleteTopicCurriculumMultiSelectDropDown: FC<
  AutocompleteMultiSelectDropdownProp
> = (prop) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [selectedItems, setSelectedItems] = React.useState<Array<number>>(
    prop.selectedItems
  );
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext?.topics
  );
  const selectedCurriculumsId: number | undefined = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId![0]
  );

  useEffect(() => {
    if (
      selectedCurriculumsId[0] &&
      !topics[selectedCurriculumsId[0]][0] &&
      (!topics[selectedCurriculumsId[0]][0].topic_unit_title ||
        topics[selectedCurriculumsId[0]][0].topic_unit_title === "") &&
      !history.location.pathname.includes("editor")
    ) {
      history.push("/topicSelection");
    }
  }, []);

  /**
   * Update selected topics in Redux
   */
  const updateReduxStore = (selectedIdsArray: Array<number>): void => {
    if (prop.type === MultiSelectDropDownMode.CURRICULUM) {
      dispatch(userAppInfoSelectedCurriculumsId(selectedIdsArray));
    } else {
      prop.topicLabelIds.map((topicLabelId) => {
        const topicsArray: Array<Topic> =
          getUnionOfValuesForKeys<Topic>(topics);

        const selectedTopicsForLabelArray: Array<Topic> = topicsArray.filter(
          (t1) =>
            selectedIdsArray.includes(t1.id) &&
            t1.topic_label_id === topicLabelId
        );

        dispatch(
          userAppInfoSelectedTopicsId({
            topicLabelId: topicLabelId,
            topicIds: selectedTopicsForLabelArray.map((T) => T.id),
          })
        );
      });
    }
  };

  const getItemLabel = (item: KeyValue): string => item.value;

  /**
   * On item change updates selected ids with selected id
   * @param event
   * @param keyValues
   */
  const handleItemChange = (event, keyValues: Array<KeyValue>) => {
    let ids: Array<number> = keyValues.map((f) => f.key);
    updateReduxStore(ids);
    setSelectedItems(ids);
  };

  return useMemo(
    () => (
      <StyledFormControl
        sx={{
          width: prop.type === MultiSelectDropDownMode.TOPIC ? "100%" : "22em",
        }}
      >
        <Autocomplete
          multiple
          id="combo-box-demo"
          // Filter options to include those which have not been selected
          options={prop.items.filter(
            (t1) =>
              !prop.items
                ?.filter((t) => selectedItems && selectedItems?.includes(t.key))
                ?.some((t2) => t2.key === t1.key)
          )}
          size="small"
          limitTags={2}
          renderInput={(params) => (
            <TextField {...params} rows={1} label={prop.inputLabel} />
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
              return (
                <Chip
                  size={"small"}
                  label={option.value}
                  variant="outlined"
                  {...getTagProps({ index })}
                  sx={{
                    maxWidth: "200px",
                    backgroundColor: `${
                      prop.type === MultiSelectDropDownMode.CURRICULUM
                        ? CHIP_TYPES.CURRICULUM
                        : CHIP_TYPES.TOPIC
                    }`,
                  }}
                />
              );
            });
          }}
          getOptionLabel={(option) => getItemLabel(option)}
          onChange={(event, newValue) => handleItemChange(event, newValue!)}
          value={prop.items.filter(
            (t) => selectedItems && selectedItems?.includes(t.key)
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography>{option.value}</Typography>
                  <Typography
                    sx={{ textAlign: "left", color: "gray" }}
                    variant={"button"}
                  >
                    {getUnionOfValuesForKeys(topics)?.find(
                      (t) => t.id === option.key
                    )?.topic_unit_title || ""}
                  </Typography>
                </div>
              </div>
            </Box>
          )}
        />
      </StyledFormControl>
    ),
    [prop.items, selectedItems]
  );
};

const StyledFormControl = styled("div")(({ theme }) => ({
  marginTop: "0.7em",
  marginBottom: "0.7em",
}));
