import { styled } from "@mui/material/styles";
import React, { FC } from "react";

export const SubmissionSidebar: FC = (props) => {
  return (
    <StyledSidebar id={"submission-sidebar"}>{props.children}</StyledSidebar>
  );
};

const StyledSidebar = styled("div")(({ theme }) => ({
  justifyContent: "flex-start",
  display: "flex",
  width: "20%",
  transition: `width 200ms`,
  flexDirection: "column",
  backgroundColor: "#F1F1F1",
  padding: "2em",
  overflowY: "auto",
  overflowX: "hidden",
}));
