import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Autocomplete } from "@mui/lab";
import {
  Box,
  Button,
  CardContent,
  Chip,
  Dialog,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import { styled, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { uniqBy } from "lodash";
import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BaseEditor, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor } from "slate-react";
import { CHIP_TYPES } from "../../atoms/KeyIssueChip";
import {
  useDeleteTagsByIdsMutation,
  useInsertTagsMutation,
} from "../../graphql/types";
import { updateLoadingStatus } from "../../redux/applicationContext/actions";
import { Topic, TopicLabel } from "../../redux/applicationContext/types";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import {
  userAppInfoManualBlockMove,
  userAppInfoTagModalOpen,
  userAppInfoUpdateEditableBlockPropsTags,
} from "../../redux/user/actions";
import { EditableBlockProp, Tag } from "../../redux/user/types";
import { CustomElement } from "../../util/custom-editor-types";
import { BLOCK_TYPES, Status } from "../../util/custom-types";
import {
  arraysEqual,
  createNewIdsForChildBlocks,
  getParentTopicsForTopic,
  getTopicLabelIdsForTier,
  getUnionOfValuesForKeys,
  useHover,
} from "../../util/helpers";
import { prettyBlockType } from "../../util/pretty-text";

interface EditableBlockContainerProps {
  element: CustomElement;
  dragHandleProps?: any;
  style?: CSSProperties;
  onEdit?: () => void;
  editor: BaseEditor & ReactEditor & HistoryEditor;
  onDelete?: () => Promise<void>;
}

/**
 * Container for all blocks (elements) within the editor
 * - Consists of a drag and drop icon to move blocks about
 * - A sub menu for the drag icon for secondary actions
 * - A comment button dispalyed on larger elements to tag block and add pedagogy notes
 * @param children
 * @param style
 * @param dragHandleProps
 * @param element
 * @param props
 * @constructor
 */
export const EditableBlockContainer: FC<EditableBlockContainerProps> = ({
  children,
  style,
  dragHandleProps,
  element,
  onEdit,
  editor,
  onDelete,
}) => {
  //Editor Values for current node
  const path = ReactEditor.findPath(editor, element);
  const parentPath = path.slice(0, 1);
  const editableBlockProp: EditableBlockProp | undefined = useAppSelector(
    (state) =>
      state.userAppInfo.userEditableBlockProps?.find(
        (b) => b.dbId?.toString() === element.id || b.client_id === element.id
      )
  );
  //@ts-expect-error
  const parentChildrenLength = editor.children[0]?.children?.length;
  const topicLabels: { [curriculumId: number]: TopicLabel[] } = useAppSelector(
    (state) => state.applicationContext.topicLabels
  );
  //Redux
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const tagRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {}, []);

  /**
   * Get top tier topics by finding topic label with order_id 1
   */
  const topTierTopicsForSelectedCurriculums: Array<Topic> = useAppSelector(
    (state) => {
      const topTierTopicLevelIds: number[] = getTopicLabelIdsForTier(
        state.userAppInfo.selectedCurriculumsId || [],
        state.applicationContext.topicLabels,
        1
      );

      return getUnionOfValuesForKeys<Topic>(
        state.applicationContext.topics,
        state.userAppInfo?.selectedCurriculumsId
      ).filter((t) => topTierTopicLevelIds.includes(t.topic_label_id));
    }
  );

  const selectedTopTierTopicIds: Array<number> =
    getUnionOfValuesForKeys<number>(
      useAppSelector((state) => state.userAppInfo?.selectedTopicsId) || {},
      topTierTopicsForSelectedCurriculums.map((t) => t.topic_label_id)
    );
  const selectedCurriculumIds: number[] | undefined = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId!
  );
  const topics: { [curriculumId: number]: Array<Topic> } = useAppSelector(
    (state) => state.applicationContext.topics
  );
  const selectedTopicIds: { [topicLabelId: number]: Array<number> } =
    useAppSelector((state) => state.userAppInfo?.selectedTopicsId!);

  const tagModalOpenRedux: string | undefined = useAppSelector(
    (state) => state.userAppInfo?.tagModalOpen
  );

  const allSelectedTopicIds: number[] =
    getUnionOfValuesForKeys<number>(selectedTopicIds);
  const allTopicsArray: Topic[] = getUnionOfValuesForKeys<Topic>(topics);
  const allSelectedTopics: Topic[] = allTopicsArray.filter((t) =>
    allSelectedTopicIds.includes(t.id)
  );

  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [localTopicsForDropdown, setLocalTopicsForDropdown] = useState<Topic[]>(
    []
  );
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  useEffect(() => {
    if (tagModalOpenRedux === element.id && !tagModalOpen && tagRef) {
      //@ts-expect-error
      setTagModalAnchorEl(tagRef.current);
      dispatch(userAppInfoTagModalOpen(element.id));
    }
    if (tagModalOpenRedux !== element.id) {
      setTagModalAnchorEl(null);
    }
  }, [tagModalOpenRedux, tagRef]);

  const menuOpen = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [tagModalAnchorEl, setTagModalAnchorEl] = React.useState(null);
  const tagModalOpen = Boolean(tagModalAnchorEl);

  const handleOpenTagModal = (event) => {
    setTagModalAnchorEl(event.currentTarget);
    dispatch(userAppInfoTagModalOpen(element.id));
  };

  /**
   * Function to get all topics for the given array of topics
   * @param currentTopics
   */
  const getAllChildTopics = (currentTopics: Topic[]): Topic[] => {
    const allTopicsArray: Topic[] = getUnionOfValuesForKeys<Topic>(topics);

    const directChildrenForGivenTopics = allTopicsArray.filter((t1) =>
      currentTopics.map((t2) => t2.id).includes(t1.parent_id)
    );

    const nestedChildIds =
      directChildrenForGivenTopics.length > 0
        ? getAllChildTopics(directChildrenForGivenTopics)
        : [];

    return currentTopics.concat(nestedChildIds);
  };

  useEffect(() => {
    let localTopicsForSelected = getAllChildTopics(allSelectedTopics);
    localTopicsForSelected = localTopicsForSelected.concat(
      getUnionOfValuesForKeys<Topic>(topics).filter(
        (t) =>
          getUnionOfValuesForKeys(topicLabels)
            ?.find((tl) => tl.id === t.topic_label_id)
            ?.title?.toLowerCase() === "quick search"
      )
    );
    setLocalTopicsForDropdown(localTopicsForSelected);
  }, [selectedTopicIds]);

  const editableBlocks: Array<EditableBlockProp> | undefined = useAppSelector(
    (state) => state.userAppInfo.userEditableBlockProps
  );
  const dbId = editableBlocks?.find(
    (block) =>
      block.client_id === element.id || block.dbId === parseInt(element.id)
  )?.dbId;
  useEffect(() => {
    if (
      !getUnionOfValuesForKeys(topics)[0] ||
      !getUnionOfValuesForKeys(topics)[0].topic_unit_title ||
      getUnionOfValuesForKeys(topics)[0].topic_unit_title === ""
    ) {
      history.push("/topicSelection");
    }
  }, []);
  const [localTopics, setLocalTopics] = useState<Topic[]>([]);
  const [localNotes, setLocalNotes] = useState(element.teacherNotes);
  const [insertTags] = useInsertTagsMutation();
  const [deleteTags] = useDeleteTagsByIdsMutation();

  useEffect(() => {
    Transforms.setNodes(
      editor,
      {
        ...element,
        teacherNotes: localNotes,
      },
      { at: ReactEditor.findPath(editor, element) }
    );
  }, [localNotes]);

  const [initialTags, setInitialTags] = useState<Tag[] | null>(null);

  const isTopTierSubjectEmpty = (): boolean =>
    selectedTopTierTopicIds
      ?.filter(
        (tId) =>
          getUnionOfValuesForKeys(topicLabels)
            ?.find(
              (tl) =>
                tl.id ===
                getUnionOfValuesForKeys(topics).find((t) => t.id === tId)
                  ?.topic_label_id
            )
            ?.title?.toLowerCase() !== "quick search"
      )
      ?.map(
        (scId) =>
          topTierTopicsForSelectedCurriculums.find((c) => c.id === scId)
            ?.title || ""
      ).length < 1;

  useEffect(() => {
    if (editableBlockProp && tagModalOpen) {
      setInitialTags(editableBlockProp?.tags || []);
      setLocalTopics(
        localTopicsForDropdown.filter((lt) =>
          editableBlockProp?.tags?.some((t) => lt.id === t.topicId)
        ) || []
      );
    }
  }, [tagModalOpen]);

  useEffect(() => {
    setInitialTags(editableBlockProp?.tags || []);
    setLocalTopics(
      localTopicsForDropdown.filter((lt) =>
        editableBlockProp?.tags?.some((t) => lt.id === t.topicId)
      ) || []
    );
  }, []);

  const handleMoveBlockManually = () => {
    dispatch(
      userAppInfoManualBlockMove({
        status: "PENDING",
        blockId: element.id,
        blockType: element.type,
      })
    );
  };

  /**
   * Handles moving up and down of sections first checking the move is legal
   * @param direction
   */
  const onMoveSection = (direction: "UP" | "DOWN") => {
    if (path.length === 2 && direction === "UP" && path[1] !== 0) {
      const oldPath = Array.from(path);
      path.splice(1, 1, path[1] - 1);
      Transforms.moveNodes(editor, { at: oldPath, to: path });
    } else if (
      path.length === 2 &&
      direction === "DOWN" &&
      parentChildrenLength &&
      path[1] !== parentChildrenLength - 1
    ) {
      const oldPath = Array.from(path);
      path.splice(1, 1, path[1] + 1);
      Transforms.moveNodes(editor, { at: oldPath, to: path });
    }
  };

  /**
   * Deletes block in frontend value
   * - This will trigger a delete in database, CASCADE relationship will take care of nested children
   */
  const onDeleteBlock = async () => {
    handleCloseMenu();
    Transforms.removeNodes(editor, {
      at: ReactEditor.findPath(editor, element),
    });
  };

  /**
   * Handles duplication of a block and all its children
   */
  const onDuplicateBlock = () => {
    const _elementToUpdate = JSON.parse(JSON.stringify(element));
    const updatedElement = createNewIdsForChildBlocks(_elementToUpdate);
    handleCloseMenu();
    Transforms.insertNodes(editor, updatedElement, {
      at: ReactEditor.findPath(editor, element),
    });
  };

  /**
   * When modal is closed, inserts local tags to the DB
   * TODO: CHECK THIS ISNT DUPLICATIG EXISTING TAGS
   */
  const onModalClose = async () => {
    const newTagsList: Tag[] =
      initialTags?.filter((tag) => tag !== undefined) || [];

    let tagsToDelete: Tag[] =
      initialTags?.filter(
        (tag) =>
          tag && !localTopics.some((localTop) => localTop.id === tag.topicId)
      ) || [];
    const topicIdsToDelete = tagsToDelete.map((tag) => tag.topicId);
    tagsToDelete = tagsToDelete.concat(
      initialTags?.filter(
        (tag) => tag && topicIdsToDelete.includes(tag.topicId)
      ) || []
    );

    //@ts-expect-error
    const initialTopicIds: number[] =
      initialTags
        ?.filter((tag) => tag && tag.topicId)
        .map((tag) => tag.topicId) || [];
    let tagsToInsert: Tag[] =
      localTopics
        ?.filter((localTop) => !initialTopicIds.includes(localTop.id))
        .map((topic) => ({
          id: undefined,
          topicId: topic.id,
          label: topic.title,
        })) || [];

    newTagsList.filter((tag) => !topicIdsToDelete.includes(tag.topicId));

    if (dbId && tagsToInsert.length > 0) {
      const insertTagResult = await insertTags({
        variables: {
          tags: tagsToInsert.map((tag) => ({
            block_id: dbId,
            topic_id: tag.topicId,
          })),
        },
      });

      if (insertTagResult.errors) {
        dispatch(
          updateLoadingStatus({
            status: Status.ERROR,
            message: "Could not insert tags for block",
          })
        );
      } else if (insertTagResult.data && insertTagResult.data.insert_tag) {
        insertTagResult.data.insert_tag.returning.map((t) => {
          if (t.topic && t.id) {
            newTagsList.push({
              topicId: t.topic?.id || undefined,
              id: t.id || undefined,
              label: t.topic?.title || "",
            });
          }
        });
      }
    }

    if (dbId && tagsToDelete.length > 0) {
      const deleteTagsResult = await deleteTags({
        variables: {
          //@ts-expect-error
          ids: tagsToDelete.filter((t) => t.id).map((t) => t.id),
        },
      });

      if (deleteTagsResult.errors) {
        dispatch(
          updateLoadingStatus({
            status: Status.ERROR,
            message: "Could not delete tags for block",
          })
        );
      } else if (deleteTagsResult.data && deleteTagsResult.data.delete_tag) {
        const deletedIds: number[] =
          deleteTagsResult.data?.delete_tag?.returning?.map((tag) => tag.id) ||
          [];
        newTagsList.filter((t) => t?.id && deletedIds.includes(t?.id));
      }
    }

    setInitialTags(newTagsList);
    setTagModalAnchorEl(null);
    dispatch(userAppInfoTagModalOpen(undefined));
  };

  return (
    <>
      <StyledBox
        style={style}
        sx={{
          width: "100%",
          alignItems:
            element.type === BLOCK_TYPES.SECTION ||
            element.type === BLOCK_TYPES.RESOURCE ||
            element.type === BLOCK_TYPES.PASSAGE ||
            element.type === BLOCK_TYPES.CODE ||
            element.type === BLOCK_TYPES.QUESTION_WITH_SUB_PART ||
            element.type === BLOCK_TYPES.QUESTION_TEXT_ONLY
              ? "flex-start"
              : "center",
        }}
        ref={hoverRef}
      >
        <StyledIconRow
          style={{
            flexDirection: "column",
            display: "flex",
            visibility: isHovered ? "inherit" : "hidden",
            userSelect: "none",
          }}
          contentEditable={false}
        >
          {(element.type === BLOCK_TYPES.QUESTION_TEXT_ONLY ||
            element.type === BLOCK_TYPES.PASSAGE) && (
            <>
              <Tooltip title={`Move ${prettyBlockType(element.type)} `}>
                <IconButton onClick={handleMoveBlockManually}>
                  <CompareArrowsIcon
                    color={"primary"}
                    sx={{ transform: "rotate(90deg)" }}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
          {/*Display a drag indicator for all blocks apart from sections and resource*/}
          {element.type !== BLOCK_TYPES.SECTION &&
            element.type !== BLOCK_TYPES.RESOURCE &&
            element.type !== BLOCK_TYPES.QUESTION_TEXT_ONLY &&
            element.type !== BLOCK_TYPES.PASSAGE && (
              <IconButton onClick={handleOpenMenu} {...dragHandleProps}>
                <DragIndicatorIcon color={"primary"} />
              </IconButton>
            )}
          {(element.type === BLOCK_TYPES.SECTION ||
            element.type === BLOCK_TYPES.RESOURCE ||
            element.type === BLOCK_TYPES.PASSAGE ||
            element.type === BLOCK_TYPES.QUESTION_TEXT_ONLY ||
            element.type === BLOCK_TYPES.QUESTION_WITH_SUB_PART) && (
            <>
              <Tooltip
                title={`Add curricular tags to this ${prettyBlockType(
                  element.type
                )} `}
              >
                <IconButton ref={tagRef} onClick={handleOpenTagModal}>
                  <LocalOfferIcon color={"primary"} />
                </IconButton>
              </Tooltip>
            </>
          )}

          {element.type === BLOCK_TYPES.SECTION && (
            <>
              {path.length === 2 && path[1] !== 0 && (
                <>
                  <Tooltip title={`Move ${prettyBlockType(element.type)} up`}>
                    <IconButton onClick={() => onMoveSection("UP")}>
                      <ArrowUpwardIcon color={"primary"} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {path.length === 2 &&
                parentChildrenLength &&
                path[1] !== parentChildrenLength - 1 && (
                  <>
                    <Tooltip
                      title={`Move ${prettyBlockType(element.type)} down`}
                    >
                      <IconButton onClick={() => onMoveSection("DOWN")}>
                        <ArrowDownwardIcon color={"primary"} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
            </>
          )}
          {element.type === BLOCK_TYPES.DIAGRAM && (
            <>
              <Tooltip title={`Edit ${prettyBlockType(element.type)} `}>
                <IconButton
                  onClick={() => {
                    onEdit && onEdit();
                  }}
                >
                  <EditIcon color={"primary"} />
                </IconButton>
              </Tooltip>
            </>
          )}
          {(element.type === BLOCK_TYPES.QUESTION_TEXT_ONLY ||
            element.type === BLOCK_TYPES.PASSAGE) && (
            <>
              <Tooltip title={`Duplicate ${prettyBlockType(element.type)} `}>
                <IconButton onClick={onDuplicateBlock}>
                  <ContentCopyIcon color={"primary"} />
                </IconButton>
              </Tooltip>
            </>
          )}
          {(element.type === BLOCK_TYPES.SECTION ||
            element.type === BLOCK_TYPES.QUESTION_TEXT_ONLY ||
            element.type === BLOCK_TYPES.PASSAGE) &&
            (!arraysEqual(path, [0, 0]) ||
              // @ts-ignore-error
              editor.children[0]?.children.length > 1) && (
              <>
                <Tooltip title={`Delete ${prettyBlockType(element.type)} `}>
                  <IconButton onClick={() => setDeleteModalOpen(true)}>
                    <DeleteOutlineIcon color={"primary"} />
                  </IconButton>
                </Tooltip>
              </>
            )}
        </StyledIconRow>
        <Menu
          id="basic-menu"
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {element.type !== BLOCK_TYPES.RESOURCE && (
            <MenuItem onClick={onDuplicateBlock}>
              <ListItemIcon>
                <ContentCopyIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Duplicate {prettyBlockType(element.type)}</Typography>
            </MenuItem>
          )}
          {element.type !== BLOCK_TYPES.RESOURCE && (
            <MenuItem onClick={() => setDeleteModalOpen(true)}>
              <ListItemIcon>
                <DeleteOutlineIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Delete {prettyBlockType(element.type)}</Typography>
            </MenuItem>
          )}
          {element.type !== BLOCK_TYPES.PARAGRAPH &&
            element.type !== BLOCK_TYPES.HEADING_1 &&
            element.type !== BLOCK_TYPES.HEADING_2 &&
            element.type !== BLOCK_TYPES.DIAGRAM &&
            element.type !== BLOCK_TYPES.PASSAGE && (
              <MenuItem
                onClick={(e) => {
                  handleCloseMenu();
                  handleOpenTagModal(e);
                }}
              >
                <ListItemIcon>
                  <LocalOfferIcon fontSize="small" />
                </ListItemIcon>
                <Typography>Edit curriculum tags</Typography>
              </MenuItem>
            )}
          {element.type !== BLOCK_TYPES.SECTION && (
            <MenuItem
              onClick={(e) => {
                handleCloseMenu();
                handleMoveBlockManually();
              }}
            >
              <ListItemIcon>
                <CompareArrowsIcon
                  sx={{ transform: "rotate(90deg)" }}
                  fontSize="small"
                />
              </ListItemIcon>
              <Typography>Move {prettyBlockType(element.type)}</Typography>
            </MenuItem>
          )}
        </Menu>

        <StyledContainer style={style}>{children}</StyledContainer>
      </StyledBox>

      <Popper
        open={tagModalOpen}
        anchorEl={tagModalAnchorEl}
        placement="left"
        disablePortal={false}
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          zIndex: 1200,
          margin: "20px",
          boxShadow: "rgb(27 31 35) 10px 11px 12px",
        }}
      >
        <ClickAwayListener onClickAway={onModalClose}>
          <>
            <StyledTopRow>
              <StyledTopRowContent>
                <IconButton
                  onClick={onModalClose}
                  style={{
                    color: theme.palette.error.main,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </StyledTopRowContent>
            </StyledTopRow>
            <CardContent
              sx={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "space-between",
                minWidth: "400px",
                maxWidth: "500px",
              }}
            >
              <div>
                <Typography variant={"h2"}>
                  Curriculum tags for {prettyBlockType(element.type)} block
                </Typography>

                <Typography sx={{ marginTop: "0.5em" }} variant={"subtitle1"}>
                  Make your blocks easy to find by tagging them with topics from
                  your selected curriculums
                </Typography>
                {isTopTierSubjectEmpty() ? (
                  <Button
                    size={"large"}
                    variant={"contained"}
                    sx={{ width: "100%", marginTop: "1em" }}
                    onClick={() => history.push("/topicSelection")}
                  >
                    Choose a Subject
                  </Button>
                ) : (
                  <Button
                    variant={"text"}
                    onClick={() => history.push("/topicSelection")}
                  >
                    {
                      selectedTopTierTopicIds
                        ?.filter(
                          (tId) =>
                            getUnionOfValuesForKeys(topicLabels)
                              ?.find(
                                (tl) =>
                                  tl.id ===
                                  getUnionOfValuesForKeys(topics).find(
                                    (t) => t.id === tId
                                  )?.topic_label_id
                              )
                              ?.title?.toLowerCase() !== "quick search"
                        )
                        ?.map(
                          (scId) =>
                            topTierTopicsForSelectedCurriculums.find(
                              (c) => c.id === scId
                            )?.title || ""
                        )[0]
                    }{" "}
                    (Change Subject)
                  </Button>
                )}
              </div>

              {!isTopTierSubjectEmpty() && (
                <>
                  <Autocomplete
                    filterSelectedOptions
                    filterOptions={(options, state) => {
                      // console.log(options);
                      // console.log(state);

                      return options.filter((o) =>
                        o?.label?.toLowerCase()?.includes(state.inputValue)
                      );
                    }}
                    loading={initialTags === null}
                    value={localTopics.map((topic) => ({
                      value: topic,
                      label: topic.title,
                    }))}
                    onChange={(event, value) => {
                      setLocalTopics(
                        uniqBy(
                          value?.map((v) => v.value),
                          "id"
                        )
                      );
                      dispatch(
                        userAppInfoUpdateEditableBlockPropsTags({
                          id: element.id,
                          tags: value?.map((v) => ({
                            id: undefined,
                            topicId: v.value.id,
                            label: v.value.title,
                          })),
                        })
                      );
                    }}
                    multiple
                    sx={{ width: "90%", margin: "10px", marginTop: "1em" }}
                    renderInput={(params) => (
                      <TextField label={"Topics"} {...params} />
                    )}
                    options={localTopicsForDropdown
                      .filter(
                        (t1) => !localTopics.some((t2) => t2.id === t1.id)
                      )
                      .map((topic) => ({
                        value: topic,
                        label: topic.title,
                      }))}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {option.value.title && option.value.title && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography>{option.value.title}</Typography>
                              <Typography
                                sx={{ textAlign: "left", color: "gray" }}
                                variant={"button"}
                              >
                                {getUnionOfValuesForKeys(topics)?.find(
                                  (t) => t.id === option?.value.id
                                )?.topic_unit_title || option.value.title}
                              </Typography>
                            </div>
                          )}
                        </div>
                      </Box>
                    )}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => {
                        const label = option.value.title;
                        return (
                          <Tooltip
                            title={
                              label +
                                " " +
                                getParentTopicsForTopic(
                                  option.value.id,
                                  getUnionOfValuesForKeys<Topic>(topics)
                                )
                                  .map((t) => " -> " + t.value)
                                  .join(" ") || label
                            }
                            placement="top"
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableTouchListener
                          >
                            <Chip
                              size={"small"}
                              label={label}
                              variant="outlined"
                              {...getTagProps({ index })}
                              sx={{
                                maxWidth: "200px",
                                backgroundColor: CHIP_TYPES.TOPIC,
                              }}
                            />
                          </Tooltip>
                        );
                      });
                    }}
                  />
                  <Button
                    size={"large"}
                    variant={"contained"}
                    sx={{ width: "100%", marginTop: "1em" }}
                    onClick={onModalClose}
                  >
                    Save
                  </Button>
                </>
              )}
            </CardContent>
            {/*</Dialog>*/}
          </>
        </ClickAwayListener>
      </Popper>
      <Dialog
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        open={deleteModalOpen}
      >
        <CardContent
          sx={{
            minWidth: "400px",
            minHeight: "200px",
            flexDirection: "column",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            {/*TODO: CLEARER MESSAGE INCLUDE PUBLIC STATUS AND LIST OF CHILD BLOCKS */}
            <Typography variant={"h2"}>
              Are you sure you want to delete this{" "}
              {prettyBlockType(element.type)} block?
            </Typography>
            <Typography sx={{ marginTop: "0.5em" }} variant={"subtitle1"}>
              Deleting this block cannot be undone and will delete any blocks
              within it
            </Typography>
          </div>

          <div>
            <Button
              size={"large"}
              variant={"outlined"}
              color={"warning"}
              sx={{ width: "100%", marginTop: "1em" }}
              onClick={() => {
                onDeleteBlock();
                setDeleteModalOpen(false);
              }}
            >
              Yes, delete this block
            </Button>
            <Button
              size={"large"}
              variant={"contained"}
              sx={{ width: "100%", marginTop: "1em" }}
              onClick={() => setDeleteModalOpen(false)}
            >
              No, do not delete this block
            </Button>
          </div>
        </CardContent>
      </Dialog>
    </>
  );
};

const StyledIconRow = styled("div")(({ theme }) => ({
  height: "5%",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  margin: "auto",
}));

const StyledContainer = styled("div")(({ theme }) => ({
  width: "90%",
  flexDirection: "row",
  wordBreak: "break-word",
  cursor: "text",
  display: "inlineFlex",
  alignItems: "center",
  flexWrap: "wrap",
}));
const StyledTopRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));
const StyledTopRowContent = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));
