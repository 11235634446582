import CodeIcon from "@altra-apps/common/assets/blockTypeIcons/Code.svg";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import { CodeElement } from "../../util/custom-editor-types";
import { ViewBlockCodeElement } from "../curriculumExplorerPreviewElements/ViewBlockCodeElement";

type CodeElementResultCardContentsProps = {
  block: CodeElement;
  tags: Tag[];
  inline?: boolean;
  isSelectable: boolean;
  position: number | null | undefined;
};

/**
 * Code Block result card view in Curriculum Explorer
 */
export const CodeElementResultCardContents: FC<
  CodeElementResultCardContentsProps
> = ({ block, tags, inline }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: inline ? "row" : "column",
        alignItems: inline ? "flex-start" : "flex-end",
      }}
    >
      <StyledTopRow
        sx={{
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Tooltip placement={"left"} title={"Code"}>
          <img
            style={{
              height: "30px",
              marginBottom: 10,
              marginRight: "10px",
              display: "inline-flex",
            }}
            src={CodeIcon}
            alt={"Code Block"}
          />
        </Tooltip>
      </StyledTopRow>
      {!inline && (
        <StyledChildCode>
          <ViewBlockCodeElement block={block} inline={inline} />
        </StyledChildCode>
      )}
      <StyledChildParagraph>{inline && "Code"}</StyledChildParagraph>
    </div>
  );
};

const StyledTopRow = styled("div")`
  display: flex;
`;

const StyledChildCode = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
}));
const StyledChildParagraph = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
