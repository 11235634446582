import { LoadingButton } from "@mui/lab";
import { CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, styled } from "@mui/system";
import React, { FC, ReactNode } from "react";
import { useIsMobile } from "../util/useIsMobile";

interface InitialFlowContainerCardProps {
  progress: 1 | 2 | 3 | 4;
  onBackClick: () => void;
  children?: ReactNode;
}

export const InitialFlowContainerCardMobile: FC<
  InitialFlowContainerCardProps
> = (props) => {
  const isTabletOrMobile = useIsMobile();
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      <PageContentContainer>
        <StyledTopRow style={{ backgroundColor: theme.palette.primary.main }}>
          <Typography
            sx={{
              color: "white",
              fontWeight: 500,
              margin: "1.5em",
            }}
            variant="h3"
          >
            Welcome to <strong> Altra</strong>
          </Typography>

          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              margin: "1.5em",
            }}
          >
            <CircularProgress
              sx={{ color: "white" }}
              value={props.progress * 25}
              variant="determinate"
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
              }}
            >
              <Typography variant="caption" component="div" color={"white"}>
                {props.progress}/4
              </Typography>
            </Box>
          </Box>
        </StyledTopRow>
        {props.children}
        <ButtonContainer>
          <LoadingButton
            disabled={props.progress === 1}
            onClick={props.progress === 1 ? () => null : props.onBackClick}
            variant="text"
            size="large"
          >
            Back
          </LoadingButton>
        </ButtonContainer>
      </PageContentContainer>
    </div>
  );
};

const StyledTopRow = styled("div")(({ theme }) => ({
  width: "100%",
  height: "10%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
}));

const ImageContainer = styled("div")(({ theme }) => ({
  width: "150px",
  margin: "1em",
  Height: "auto",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    width: "50%",
    Height: "50%",
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  width: "100%",
  alignContent: "flex-start",
  left: 2,
  bottom: 2,
  display: "flex",
  position: "absolute",
}));
const RootTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "7em auto auto auto",
  width: "70%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    marginTop: "12em",
  },
}));

const RootContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "2em auto",
  width: "70%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    marginTop: "12em",
  },
}));

const TypographyContainer = styled(Typography)(({ theme }) => ({
  alignContent: "start",
  width: "90%",
  marginTop: 20,
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    width: "100%",
  },
}));

const PageContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  position: "relative",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    width: "100%",
  },
}));
