import { CardActionArea, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ViewParagraphElement } from "../molecules/curriculumExplorerPreviewElements/ViewParagraphElement";
import { Block } from "../redux/user/types";
import { BLOCK_ICONS_FOR_EXPLORER, BLOCK_TYPES } from "./custom-types";
import { QUESTION_TYPE } from "./questionTypes";

export const renderContentNavigationForBlockType = (
  block: Block,
  userExplorerBlocksChildren: { [parentId: number]: Array<Block> },
  parentBlockId: number,
  submissionView: "SUBMISSION" | "REVIEW" | "REVIEWED" | "EXPLORER",
  submissionId: number
) => {
  const history = useHistory();
  const blockType: string = block["type"];

  switch (blockType) {
    case "resource":
      return (
        <StyledHashLink
          smooth
          to={`/${submissionView.toLocaleLowerCase()}/${submissionId}/#${
            block.id
          }`}
          scroll={(el) =>
            document
              .getElementById(block.id.toString())
              ?.scrollIntoView({ behavior: "smooth" })
          }
        >
          <StyledTypography
            style={{ marginBottom: "0.5em", width: "100%" }}
            variant={"h2"}
          >
            {block?.block["title"]}
          </StyledTypography>
          <StyledTypography
            style={{ marginBottom: "2em", width: "100%" }}
            variant={"h5"}
          >
            {block?.block["description"]}
          </StyledTypography>
          {userExplorerBlocksChildren[block.id]
            ?.filter((b) => b.type !== "answer")
            ?.slice()
            .sort((a, b) => (a?.position || 0) - (b?.position || 0))
            ?.map((childBlock) => (
              <div>
                {renderContentNavigationForBlockType(
                  childBlock,
                  userExplorerBlocksChildren,
                  parentBlockId,
                  submissionView,
                  submissionId
                )}
              </div>
            ))}
        </StyledHashLink>
      );
    case "section":
      return (
        <div style={{ marginBottom: "1.5em" }}>
          <StyledHashLink
            smooth
            to={`/${submissionView.toLocaleLowerCase()}/${submissionId}/#${
              block.id
            }`}
            scroll={(el) =>
              document
                .getElementById(block.id.toString())
                ?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <CardActionArea>
              <div
                style={{
                  display: "flex",
                  padding: "1em 0.5em",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={
                      BLOCK_ICONS_FOR_EXPLORER.find(
                        (b) => b.value === "Section"
                      )?.diagram || ""
                    }
                    alt=""
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "1em",
                      width: "90%",
                    }}
                  >
                    <StyledTypography sx={{ width: "100%" }} variant={"h2"}>
                      {block?.block["title"]}
                    </StyledTypography>
                  </div>
                </div>
              </div>
            </CardActionArea>

            {userExplorerBlocksChildren[block.id]
              ?.filter((b) => b.type !== "answer")
              ?.slice()
              .sort((a, b) => (a?.position || 0) - (b?.position || 0))
              ?.map((childBlock) => (
                <div>
                  {renderContentNavigationForBlockType(
                    childBlock,
                    userExplorerBlocksChildren,
                    parentBlockId,
                    submissionView,
                    submissionId
                  )}
                </div>
              ))}
          </StyledHashLink>
        </div>
      );

    case "question-text-only":
      return (
        <div style={{ marginBottom: "1em", marginLeft: "1em" }}>
          <StyledHashLink
            smooth
            to={`/${submissionView.toLocaleLowerCase()}/${submissionId}/#${
              block.id
            }`}
            scroll={(el) =>
              document
                .getElementById(block.id.toString())
                ?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <CardActionArea>
              <div
                style={{
                  display: "flex",
                  padding: "0.2em 0.5em",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={
                      BLOCK_ICONS_FOR_EXPLORER.find(
                        (b) => b.value === "Question"
                      )?.diagram || ""
                    }
                    alt=""
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "1em",
                      width: "90%",
                    }}
                  >
                    <StyledTypography
                      sx={{
                        width: "100%",
                      }}
                      variant={"h2"}
                    >
                      {block?.id &&
                        userExplorerBlocksChildren[block.id]
                          ?.slice()
                          ?.filter((b) => b.type !== "answer")
                          .sort(
                            (a, b) => (a?.position || 0) - (b?.position || 0)
                          )
                          ?.map(
                            (childBlock, index) =>
                              index === 0 &&
                              block?.type ===
                                QUESTION_TYPE.QUESTION_TEXT_ONLY && (
                                <ViewParagraphElement
                                  block={{
                                    id: childBlock.id.toString(),
                                    type: BLOCK_TYPES.PARAGRAPH,
                                    children: childBlock.block["children"],
                                  }}
                                  doNotLoadChildren={true}
                                  headerStyle={false}
                                  trimText={true}
                                />
                              )
                          )}
                    </StyledTypography>
                  </div>
                </div>
              </div>
            </CardActionArea>
          </StyledHashLink>
        </div>
      );
    default:
      return <></>;
  }
};

const StyledHashLink = styled(HashLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
