function RBWave(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="461"
      height="188"
      viewBox="0 0 461 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2786_53976)">
        <path
          d="M1533 118.347L1518.93 114.386C1504.86 110.619 1476.72 102.407 1448.58 118.347C1420.44 134.288 1392.31 173.415 1364.17 177.521C1336.03 181.627 1307.89 149.746 1279.75 122.308C1251.61 94.6779 1223.47 71.0084 1195.33 71.0084C1167.19 71.0084 1139.06 94.6779 1110.92 118.347C1082.78 142.017 1054.64 165.686 1026.5 161.725C998.36 157.958 970.22 126.076 942.08 118.347C913.94 110.619 885.81 126.076 857.67 126.221C829.53 126.076 801.39 110.619 773.25 114.386C745.11 118.347 716.97 142.017 688.83 145.978C660.69 149.746 632.56 134.288 604.42 114.386C576.28 94.6779 548.14 71.0084 520 86.8042C491.861 102.407 463.722 157.957 435.583 173.56C407.444 189.356 379.306 165.686 351.167 153.852C323.028 142.017 294.889 142.017 266.75 149.891C238.611 157.958 210.472 173.415 182.333 181.482C154.194 189.356 126.056 189.356 97.917 185.395C69.778 181.627 41.639 173.415 13.5 145.978C-14.639 118.347 -42.778 71.0084 -70.917 71.0084C-99.056 71.0084 -127.194 118.347 -155.333 126.221C-183.472 134.288 -211.611 102.407 -239.75 102.552C-267.889 102.407 -296.028 134.288 -324.167 145.978C-352.306 157.957 -380.444 149.746 -408.583 145.978C-436.722 142.017 -464.861 142.017 -478.93 142.017H-493V0H-478.93C-464.861 0 -436.722 0 -408.583 0C-380.444 0 -352.306 0 -324.167 0C-296.028 0 -267.889 0 -239.75 0C-211.611 0 -183.472 0 -155.333 0C-127.194 0 -99.056 0 -70.917 0C-42.778 0 -14.639 0 13.5 0C41.639 0 69.778 0 97.917 0C126.056 0 154.194 0 182.333 0C210.472 0 238.611 0 266.75 0C294.889 0 323.028 0 351.167 0C379.306 0 407.444 0 435.583 0C463.722 0 491.861 0 520 0C548.14 0 576.28 0 604.42 0C632.56 0 660.69 0 688.83 0C716.97 0 745.11 0 773.25 0C801.39 0 829.53 0 857.67 0C885.81 0 913.94 0 942.08 0C970.22 0 998.36 0 1026.5 0C1054.64 0 1082.78 0 1110.92 0C1139.06 0 1167.19 0 1195.33 0C1223.47 0 1251.61 0 1279.75 0C1307.89 0 1336.03 0 1364.17 0C1392.31 0 1420.44 0 1448.58 0C1476.72 0 1504.86 0 1518.93 0H1533V118.347Z"
          fill="url(#paint0_linear_2786_53976)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2786_53976"
          x1="1533"
          y1="7.15256e-06"
          x2="1533"
          y2="188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75176" />
          <stop offset="0.0001" stopColor="#EE5277" />
        </linearGradient>
        <clipPath id="clip0_2786_53976">
          <rect width="461" height="188" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RBWave;
