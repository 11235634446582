import SearchIcon from "@mui/icons-material/Search";
import { Skeleton } from "@mui/lab";
import { Button, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Teacher from "../../assets/icons/Teacher.svg";
import { InitialFlowCard } from "../atoms/InitialFlowCard";
import {
  Curriculum,
  Topic,
  TopicLabel,
} from "../redux/applicationContext/types";
import { useAppSelector } from "../redux/hook";
import { UserInfo } from "../redux/user/types";
import { getS3URL } from "../util/convertToS3Url";
import { ALTRA_APPS, AppRole } from "../util/custom-types";
import { openInNewTab } from "../util/helpers";
import { useIsMobile } from "../util/useIsMobile";

type ChooseItemsProps = {
  handleOnClick: (items: number) => void;
  items: Array<Curriculum> | Array<Topic>;
  title: string;
  subtitle: string;
  appName: ALTRA_APPS;
  buttonText: string;
  activeOnly?: boolean;
  loading?: boolean;
  initialLoading?: boolean;
  type: "TOPIC" | "CURRICULUM";
};

/**
 * Allows user to select the singular item(Curriculum, Topic) which they found interesting
 * @constructor
 */

export const ChooseItem: React.FC<ChooseItemsProps> = (prop) => {
  // Selected Ids list
  const [searchTerm, setSearchTerm] = useState("");
  const user: UserInfo = useAppSelector((state) => state.user.userInfo);
  const topicLabels: Array<TopicLabel> = useAppSelector((state) => {
    const curriculumId = state.userAppInfo.selectedCurriculumsId![0];

    if (curriculumId)
      return state.applicationContext.topicLabels![curriculumId] || [];
    return [];
  });

  const [localItems, setLocalItems] = useState<
    Array<Curriculum> | Array<Topic>
  >([]);

  useEffect(() => {
    setLocalItems(prop.items);
  }, [prop.items]);

  const history = useHistory();
  const isTabletOrMobile = useIsMobile();

  const userRole: string = useAppSelector(
    (state) => state.userAppInfo.role
  ) as string;

  useEffect(() => {
    if (prop.appName === ALTRA_APPS.CB && userRole !== AppRole.SUPER_ADMIN) {
      history.push("/");
    }
  }, []);

  const selectAndNavigate = (id: number) => {
    prop.handleOnClick(id);
  };

  return prop.initialLoading ? (
    <LoadingCardContainer
      style={{ display: isTabletOrMobile ? "block" : "grid" }}
    >
      <Skeleton
        sx={{ margin: "auto" }}
        animation="wave"
        width={200}
        height={200}
      />
      <Skeleton
        sx={{ margin: "auto" }}
        animation="wave"
        width={200}
        height={200}
      />
      {!isTabletOrMobile && (
        <>
          <Skeleton
            sx={{ margin: "auto" }}
            animation="wave"
            width={200}
            height={200}
          />
          <Skeleton
            sx={{ margin: "auto" }}
            animation="wave"
            width={200}
            height={200}
          />
        </>
      )}
    </LoadingCardContainer>
  ) : (
    <div style={{ width: isTabletOrMobile ? "90%" : "500px" }}>
      {prop?.items?.length > 1 && (
        <div
          style={{
            width: isTabletOrMobile ? "100%" : "500px",
            boxShadow: "0px 0px 12px 0px #9C7AFD57",
          }}
        >
          <TextField
            autoFocus={!isTabletOrMobile}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: "100%" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={"Search"}
            variant={"outlined"}
            size={"small"}
          />
        </div>
      )}
      <CardContainer
        style={{
          gridTemplateColumns: isTabletOrMobile ? "none" : `repeat(2, 1fr)`,
          height: isTabletOrMobile ? "100%" : "350px",
          justifyContent: "center",
        }}
      >
        {prop?.items
          //@ts-expect-error
          ?.filter(
            (t) =>
              (!prop.activeOnly || t.active || t.publicly_accessible) &&
              (searchTerm === "" ||
                t?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
                t?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())) &&
              topicLabels
                ?.find((tl) => tl.id === t.topic_label_id)
                ?.title?.toLowerCase() !== "quick search"
          )?.length === 0 && (
          <div
            style={{ display: "flex", flexDirection: "column", width: "500px" }}
          >
            <Typography sx={{ marginTop: "1em" }} color={"error"}>
              No results for search term
            </Typography>
            <Button
              onClick={() =>
                openInNewTab(
                  `https://2uluvvlx386.typeform.com/to/CxLRLlOf#email=${user.email}&name=${user.display_name}&block=xxxxx&accountid=${user.id}`
                )
              }
              sx={{ marginTop: "10px" }}
              variant={"contained"}
            >
              Request a new subject
            </Button>
          </div>
        )}
        {localItems
          //@ts-expect-error
          ?.filter(
            (t) =>
              (!prop.activeOnly || t.active || t.publicly_accessible) &&
              (searchTerm === "" ||
                t?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
                t?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())) &&
              topicLabels
                ?.find((tl) => tl.id === t.topic_label_id)
                ?.title?.toLowerCase() !== "quick search"
          )
          ?.map((item) => {
            return (
              <InitialFlowCard
                title={item.name || item.title}
                icon={Teacher}
                customIconUrl={getS3URL(item?.url)}
                onClick={() => selectAndNavigate(item.id)}
                appName={prop.appName}
              />
            );
          })}
      </CardContainer>
    </div>
  );
};

const RootContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "80vh",
  textAlign: "center",
  marginTop: "5em",
  [theme.breakpoints.down("md")]: {
    marginTop: "10em",
  },
}));

const CardContainer = styled("div")(({ theme }) => ({
  padding: "0 1em",
  gap: "1em",
  paddingBottom: "20px",
  overflowY: "auto",
  overflowX: "hidden",
  display: "grid",
}));
const LoadingCardContainer = styled("div")(({ theme }) => ({
  padding: "0 1em",
  gap: "1em",
  paddingBottom: "20px",
  overflowY: "auto",
  overflowX: "hidden",
  width: "100%",
  gridTemplateColumns: "repeat(2, 1fr)",
  justifyContent: "center",
}));
