export const COLORS = {
  BLUE: "#1A73E8",
  PURPLE: "#4F68EE",
  PINK: "#B642CD",
  GREEN: "#128762",
  RED: "#D53964",
  ORANGE: "#E8710E",
  YELLOW: "#F1AC00",
  BLACK: "#111137",
  DARK_GREY: "#425466",
  LIGHT_GREY: "#6B737B",
};
