import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { CodeElement } from "../../util/custom-editor-types";
import { ViewCodeLineElement } from "./ViewCodeLineElement";

type ViewCodeElementProps = {
  block: CodeElement;
  inline?: boolean;
  doNotLoadChildren?: boolean;
};

/**
 * View only element for displaying Code blocks
 * @param block
 * @constructor
 */
export const ViewBlockCodeElement: FC<ViewCodeElementProps> = ({
  block,
  doNotLoadChildren,
}) => {
  return (
    <StyledCodeBlock>
      <StyledPre>
        <code
          style={{
            backgroundColor: "#f3f3f3",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {block.children.map((textEl) => (
            <ViewCodeLineElement block={textEl} />
          ))}
        </code>
      </StyledPre>
    </StyledCodeBlock>
  );
};

const StyledPre = styled("pre")(({ theme }) => ({
  overflowY: "auto",
  "@media print": {
    backgroundColor: "#f3f3f3",
    overflow: "visible",
  },
}));

const StyledCodeBlock = styled("div")(({ theme }) => ({
  backgroundColor: "#f3f3f3",
  borderRadius: "8px",
  lineHeight: "1.2em",
  width: "90%",
  margin: "1em 0",
  padding: "1em",
}));
