import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { altraTheme } from "../../styling/altra-theme";
import { BlockQuoteElement } from "../../util/custom-editor-types";
import { Leaf } from "../resourceBuilderElements/Leaf";

type ViewQuoteElementProps = {
  block: BlockQuoteElement;
  inline?: boolean;
  doNotLoadChildren?: boolean;
};

/**
 * View only element for displaying Quote blocks
 * @param block
 * @constructor
 */
export const ViewQuoteElement: FC<ViewQuoteElementProps> = ({
  block,
  inline,
  doNotLoadChildren,
}) => {
  return (
    <StyledQuoteContainer
      sx={{ margin: inline ? 0 : "1em 0" }}
      style={altraTheme.typography.body1}
    >
      <StyledQuoteBar
        style={{
          margin: inline ? 0 : "0 1em",
          display: inline ? "none" : "inherit",
        }}
      />
      <span
        style={{
          display: "inline",
          alignItems: !inline ? "center" : "flex-start",
          margin: !inline ? ".5em 0" : 0,
        }}
      >
        {block.children.map((textEl) => (
          <Leaf leaf={textEl}>{textEl.text}</Leaf>
        ))}
      </span>
    </StyledQuoteContainer>
  );
};

const StyledQuoteBar = styled("div")(({ theme }) => ({
  minWidth: "4px",
  backgroundColor: "black",
}));
const StyledQuoteContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
}));
