import { AltraTopAppBar } from "@altra-apps/common/src/molecules/AltraTopAppBar";
import { CancelSubscriptionModal } from "@altra-apps/common/src/molecules/CancelSubscriptionModal";
import { ChangeSubscriptionModal } from "@altra-apps/common/src/molecules/ChangeSubscriptionModal";
import { AccountScreen } from "@altra-apps/common/src/screens";
import { AltraAppSelectionScreen } from "@altra-apps/common/src/screens/AppSelectionScreen/AltraAppSelectionScreen";
import { CurriculumSelectionScreen } from "@altra-apps/common/src/screens/CurriculumSelection/CurriculumSelectionScreen";
import { ExportScreen } from "@altra-apps/common/src/screens/ExportScreen/ExportScreen";
import { PageNotFoundScreen } from "@altra-apps/common/src/screens/PageNotFound";
import { SuccessScreen } from "@altra-apps/common/src/screens/SuccessScreen";
import { TopicSelectionScreen } from "@altra-apps/common/src/screens/TopicSelection/TopicSelectionScreen";
import { ALTRA_APPS } from "@altra-apps/common/src/util/custom-types";
import { useIsMobile } from "@altra-apps/common/src/util/useIsMobile";
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { ExplorerScreen, HomeScreen } from "../screens";
import { FullPageViewerScreen } from "../screens/FullPageViewerScreen/FullPageViewerScreen";
import { AssignmentScreen } from "../screens/SubmissionScreen/AssignmentScreen";
import { SubmissionScreen } from "../screens/SubmissionScreen/SubmissionScreen";

export const MainRouter = () => {
  const isTabletOrMobile = useIsMobile();

  return (
    <>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route
          path="/success/:session_id/:user_id"
          render={() => (
            <SuccessScreen
              renderTopBar={<AltraTopAppBar appName={ALTRA_APPS.CE} />}
            />
          )}
        />
        <Route
          exact
          path="/curriculumSelection"
          render={() => <CurriculumSelectionScreen appName={ALTRA_APPS.CE} />}
        />{" "}
        <Route
          exact
          path="/appSelection"
          render={() => <AltraAppSelectionScreen appName={ALTRA_APPS.CE} />}
        />
        <Route
          exact
          path="/topicSelection"
          render={() => (
            <TopicSelectionScreen
              appName={ALTRA_APPS.CE}
              onCompleteUrl={isTabletOrMobile ? "/explorer" : "/appSelection"}
            />
          )}
        />
        <Route exact path="/explorer" component={ExplorerScreen} />
        <Route
          path="/explorer/:id/:title?/:description?"
          component={FullPageViewerScreen}
        />
        <Route path="/submission/:id" component={SubmissionScreen} />
        <Route path="/assignment/:id" component={AssignmentScreen} />
        <Route path="/review/:id" component={SubmissionScreen} />
        <Route path="/reviewed/:id" component={SubmissionScreen} />
        <Route exact path="/export/:id" component={ExportScreen} />
        <Route
          exact
          path="/updateSubscription"
          render={() => (
            <ChangeSubscriptionModal open={true} appName={ALTRA_APPS.CE} />
          )}
        />
        <Route
          path="/cancelSubscription"
          render={() => <CancelSubscriptionModal />}
        />
        <Route
          path="/account/:mode"
          render={() => <AccountScreen appName={ALTRA_APPS.CE} />}
        />
        <Route
          exact
          path="*"
          render={() => <PageNotFoundScreen appName={ALTRA_APPS.CE} />}
        />
      </Switch>
    </>
  );
};
