import { Curriculum } from "@altra-apps/common/src/redux/applicationContext/types";
import {
  useAppDispatch,
  useAppSelector,
} from "@altra-apps/common/src/redux/hook";
import {
  userAppInfoResetSelectedTopicsId,
  userAppInfoSelectedCurriculumsId,
} from "@altra-apps/common/src/redux/user/actions";
import { ALTRA_APPS, Status } from "@altra-apps/common/src/util/custom-types";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CEHomeBackground from "../../../assets/images/CESelectionBackground.svg";
import RBHomeBackground from "../../../assets/images/RBSelectionBackground.svg";
import { useGetActiveCurriculumsLazyQuery } from "../../graphql/types";
import { AltraTopAppBar } from "../../molecules/AltraTopAppBar";
import { InitialFlowContainerCard } from "../../molecules/InitialFlowContainerCard";
import { InitialFlowContainerCardMobile } from "../../molecules/InitialFlowContainerCardMobile";
import { ChooseItem } from "../../organisms/ChooseItem";
import {
  updateCurriculums,
  updateLoadingStatus,
} from "../../redux/applicationContext/actions";
import { useIsMobile } from "../../util/useIsMobile";

type CurriculumSelectionScreenProp = {
  appName: ALTRA_APPS;
};

/**
 * Screen for selecting one or more curriculums to be used in the explorer
 * @param props
 * @constructor
 */
export const CurriculumSelectionScreen: React.FC<
  CurriculumSelectionScreenProp
> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loadingCurriculums, setLoadingCurriculums] = useState(true);
  const [loadingContinue, setLoadingContinue] = useState(false);
  const isTabletOrMobile = useIsMobile();

  const teacherOrStudent = useAppSelector(
    (state) => state.userAppInfo.teacherOrStudent
  );
  const curriculums: Array<Curriculum> = useAppSelector(
    (state) => state.applicationContext.curriculums
  );

  const selectedCurriculumsIds: Array<number> = useAppSelector(
    (state) => state.userAppInfo?.selectedCurriculumsId!
  );

  const [
    getActiveCurriculumsCallback,
    { data: activeCurriculumsData, error: activeCurriculumsError },
  ] = useGetActiveCurriculumsLazyQuery();

  const getCurriculums = async () => {
    await getActiveCurriculumsCallback();
  };

  useEffect(() => {
    console.log("restting");

    dispatch(userAppInfoSelectedCurriculumsId([]));
    dispatch(userAppInfoResetSelectedTopicsId());
  }, []);

  useEffect(() => {
    console.log(curriculums);
  }, [curriculums]);

  /**
   * Initial load of all topics for selected curriculums
   */
  useEffect(() => {
    getCurriculums();
  }, []);

  //On retrival of curriculums, dispatch to redux
  useEffect(() => {
    if (activeCurriculumsError) {
      dispatch(
        updateLoadingStatus({
          status: Status.ERROR,
          message: "Could not get topics",
        })
      );
    } else if (activeCurriculumsData && activeCurriculumsData.curriculum) {
      dispatch(
        updateCurriculums(
          activeCurriculumsData.curriculum.map((c) => ({
            ...c,
            active: c.publicly_accessible,
          }))
        )
      );
      setLoadingCurriculums(false);
    }
  }, [activeCurriculumsData, activeCurriculumsError]);

  const handleOnClick = (selectedCurriculums: number) => {
    setLoadingContinue(true);
    dispatch(userAppInfoSelectedCurriculumsId([selectedCurriculums]));
    history.push("/topicSelection");
    setLoadingContinue(false);
  };

  const renderMobileView = () => (
    <StyledContainer
      style={{
        backgroundImage: `url(${
          props.appName === ALTRA_APPS.CE ? CEHomeBackground : RBHomeBackground
        })`,
      }}
    >
      <AltraTopAppBar appName={props.appName} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: "3em auto auto auto",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <InitialFlowContainerCardMobile
          onBackClick={() => history.push("/")}
          progress={2}
        >
          <TypographyContainer variant={"h1"}>
            <Typography variant={"h1"}>
              What do you {teacherOrStudent === "TEACHER" ? "teach" : "study"}?
            </Typography>
          </TypographyContainer>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingBottom: "3em",
            }}
          >
            <ChooseItem
              loading={loadingContinue}
              handleOnClick={handleOnClick}
              items={curriculums}
              appName={props.appName}
              buttonText={"Continue"}
              title={
                props.appName === ALTRA_APPS.CE
                  ? "Find learning materials that make sense for you"
                  : "Create learning materials that are useful for you"
              }
              subtitle={""}
              activeOnly={true}
              type={"CURRICULUM"}
              initialLoading={loadingCurriculums}
            />
          </div>
        </InitialFlowContainerCardMobile>
      </div>
    </StyledContainer>
  );

  return isTabletOrMobile ? (
    renderMobileView()
  ) : (
    <StyledContainer
      style={{
        backgroundImage: `url(${
          props.appName === ALTRA_APPS.CE ? CEHomeBackground : RBHomeBackground
        })`,
      }}
    >
      <AltraTopAppBar appName={props.appName} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: "7em auto auto auto",
          width: "70%",
        }}
      >
        <InitialFlowContainerCard
          onBackClick={() => history.push("/")}
          progress={2}
        >
          <TypographyContainer variant={"h1"}>
            <Typography variant={"h1"}>
              What do you {teacherOrStudent === "TEACHER" ? "teach" : "study"}?
            </Typography>
          </TypographyContainer>

          <div
            style={{
              width: "100%",
              maxHeight: "400px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <ChooseItem
              loading={loadingContinue}
              handleOnClick={handleOnClick}
              items={curriculums}
              appName={props.appName}
              buttonText={"Continue"}
              title={
                props.appName === ALTRA_APPS.CE
                  ? "Find learning materials that make sense for you"
                  : "Create learning materials that are useful for you"
              }
              subtitle={""}
              activeOnly={true}
              type={"CURRICULUM"}
              initialLoading={loadingCurriculums}
            />
          </div>
        </InitialFlowContainerCard>
      </div>
    </StyledContainer>
  );
};
const TypographyContainer = styled(Typography)(({ theme }) => ({
  alignContent: "start",
  width: "90%",
  marginTop: 20,
  marginBottom: 20,
}));
const RootTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "7em auto auto auto",
  width: "70%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    marginTop: "12em",
  },
}));

const StyledContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  height: "100vh",
  width: "100vw",
  left: 0,
}));
