import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { getStyleForDroppable } from "../util/helpers";

interface DraggingBlockProps {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  setIsDragging: (dragging: boolean) => void;
  label: string;
}

export const DraggingBlock: FC<DraggingBlockProps> = ({
  provided,
  snapshot,
  setIsDragging,
  label,
}) => (
  <StyledContainer
    ref={provided.innerRef}
    {...provided.draggableProps}
    style={getStyleForDroppable(provided.draggableProps.style, snapshot)}
  >
    {setIsDragging(snapshot.isDragging)}
    <StyledDraggingOval {...provided.dragHandleProps}>
      <Typography variant={"h1"} color={"white"}>
        {label}
      </Typography>
    </StyledDraggingOval>
  </StyledContainer>
);

const StyledContainer = styled("div")(({ theme }) => ({
  width: "250px",
  height: "15px",
}));
const StyledDraggingOval = styled("div")(({ theme }) => ({
  position: "absolute",
  filter: "drop-shadow(0px 2px 16px rgba(66, 84, 102, 0.24))",
  backgroundColor: "#D53964",
  width: "250px",
  height: "50px",
  borderRadius: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
