import HeadingOneIcon from "@altra-apps/common/assets/blockTypeIcons/Header1.svg";
import HeadingTwoIcon from "@altra-apps/common/assets/blockTypeIcons/Header2.svg";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";
import { Tag } from "../../redux/user/types";
import {
  HeadingOneElement,
  HeadingTwoElement,
} from "../../util/custom-editor-types";
import { ViewHeadingElement } from "../curriculumExplorerPreviewElements/ViewHeadingElement";

type HeadingElementResultCardContentsProps = {
  block: HeadingOneElement | HeadingTwoElement;
  tags: Tag[];
  inline?: boolean;
  isSelectable: boolean;
  position: number | null | undefined;
  type: "ONE" | "TWO";
};

/**
 * Heading Block result card view in Curriculum Explorer
 */
export const HeadingElementResultCardContents: FC<
  HeadingElementResultCardContentsProps
> = ({ block, tags, type, inline }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: inline ? "row" : "column",
      }}
    >
      <StyledTopRow
        sx={{
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Tooltip
          placement={"left"}
          title={`Heading ${type === "ONE" ? "1" : "2"}`}
        >
          <img
            style={{
              height: "30px",
              marginBottom: 10,
              marginRight: "10px",
              display: "inline-flex",
            }}
            src={type === "ONE" ? HeadingOneIcon : HeadingTwoIcon}
            alt={"Heading Block"}
          />
        </Tooltip>
      </StyledTopRow>
      <StyledChildHeading>
        <ViewHeadingElement block={block} type={type} inline={inline} />
      </StyledChildHeading>
    </div>
  );
};

const StyledTopRow = styled("div")`
  display: flex;
`;

const StyledChildHeading = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
